import React from 'react';
import { useTranslation } from 'react-i18next';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './OperationTable.module.css';
import OperationTableTitle from './OperationTableTitle';

export default function OperationTable({ operations, shareholders }) {
  const { t } = useTranslation();
  return (
    <ul className={styles.list}>
      <OperationTableTitle />
      {
        operations.map((operation) => {
          const from = shareholders?.filter(
            (shareholder) => (
              shareholder?.id === operation?.fromUserId
            ),
          )[0];
          const to = shareholders?.filter(
            (shareholder) => (
              shareholder?.id === operation?.toUserId
            ),
          )[0];
          return (
            <li
              key={operation.id}
              className={styles.row}
            >
              <div
                className={(operation.status === 'verified') ? styles.verified : styles.unverified}
              >
                {
                  (operation.status === 'verified')
                    ? (
                      <VerifiedUserIcon className={styles.icon} />
                    )
                    : (
                      <ErrorIcon className={styles.icon} />
                    )
                }
                <p>
                  { operation.counter }
                </p>
              </div>
              <p>
                <span className={styles.label}>
                  Date :
                </span>
                { operation.date }
              </p>
              <p>
                <span className={styles.label}>
                  {`${t('operation.table.operation')} :`}
                </span>
                { t(`operation.table.${operation.type}`) }
              </p>
              <p>
                <span className={styles.label}>
                  {`${t('operation.table.quantity')} :`}
                </span>
                { operation.quantity?.toLocaleString() }
              </p>
              <p>
                <span className={styles.label}>
                  {`${t('operation.table.from')} :`}
                </span>
                {(from?.societyName || `${from?.firstName || ''} ${from?.lastName || ''}`)}
              </p>
              <p>
                <span className={styles.label}>
                  {`${t('operation.table.to')} :`}
                </span>
                {(to?.societyName || `${to?.firstName || ''} ${to?.lastName || ''}`)}
              </p>
              <p>
                <span className={styles.label}>
                  {`${t('operation.table.title')} :`}
                </span>
                { operation.name }
              </p>
            </li>
          );
        })
      }
    </ul>
  );
}
