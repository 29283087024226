import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openPopUp, closePopUp } from 'services/store/features/popup/actions';
import { fetchHistory, setActiveHistory } from 'services/store/features/history/actions';
import {
  getHistories,
  getHoldingHistories,
  getHistoryById,
  getHoldingHistoryById,
  getLastHistory,
  getLastHoldingHistory,
} from 'services/store/features/history/selectors';
import { getCapitalData } from 'services/store/features/shareholders/selectors';

import formatDate from '../../../utils/formatDate';
import ShareholdersCapitalization from './ShareholdersCapitalization';

const mapStateToProps = (state) => {
  const isHolding = window.location.href.includes('holding');
  const id = isHolding ? state.history.holdingHistoryToDisplay : state.history.historyToDisplay;

  const getHistory = () => {
    if (id && id !== -1) {
      return (isHolding) ? getHoldingHistoryById(state, id) : getHistoryById(state, id);
    }
    return (isHolding) ? getLastHoldingHistory(state) : getLastHistory(state);
  };

  const historyToDisplay = getHistory();

  return ({
    history: {
      historyToDisplay: id,
      status: state.history.status,
      hasHistory: (isHolding ? getHoldingHistories(state) : getHistories(state)).length > 1,
      date: historyToDisplay?.date,
      name: historyToDisplay?.name,
      parameters: {
        ...historyToDisplay?.parameters,
        sharesNumber: historyToDisplay?.parameters?.sharesNumber,
        date: formatDate(historyToDisplay?.date?.begin)?.toLocaleDateString('fr'),
      },
      history: isHolding ? getHoldingHistories(state).reverse() : getHistories(state).reverse(),
    },
    capital: getCapitalData(state).capital,
    roles: state.roles.roles,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setActiveHistory,
    openPopUp,
    closePopUp,
    fetchHistory,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersCapitalization);
