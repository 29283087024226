import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import Loading from '../../../components/library/Loading';
import * as statuses from '../../../services/store/states';

import OperationsCreationForm from './OperationsCreationForm';

function OperationCreation({
  currentOperation, operationHistory, history, societyId, actions,
  isHolding,
}) {
  const { society, type } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
  }, []);

  useEffect(() => {
    if (societyId && operationHistory.status === statuses.IDLE) {
      actions.fetchHistory();
    }
  }, [societyId]);

  if (operationHistory.status === 'SUCCESS') {
    return (
      <OperationsCreationForm
        type={type}
        history={history}
        actions={actions}
        societyId={societyId}
        currentOperation={currentOperation}
        operationHistory={operationHistory}
        isHolding={isHolding}
      />
    );
  }

  return (
    <Loading />
  );
}

export default withRouter(OperationCreation);
