import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyPlan from './AssemblyPlan';
import { fetchAssemblyPlan, deleteAssemblyPlanById } from '../../../services/store/features/assembly/actions';
import { openPopUp, closePopUp } from '../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  status: state.assembly.plan.status,
  plan: state.assembly.plan.list.map((assembly) => {
    const [date, time] = (typeof assembly.date === 'string')
      ? assembly.date.split(' ')
      : [assembly.date, assembly.time];
    return {
      ...assembly,
      date,
      time,
    };
  }),
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyPlan,
    deleteAssemblyPlanById,
    openPopUp,
    closePopUp,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyPlan);
