import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setType, setName,
} from '../../../services/store/features/operations/actions';

import OperationTableTitle from './OperationTableTitle';
import { getNames, getTypes } from '../../../services/store/features/operations/selectors';

const mapStateToProps = (state) => ({
  types: getTypes(state),
  selectedTypes: state.operations.typeFilter,
  names: getNames(state),
  selectedNames: state.operations.nameFilter,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setType,
    setName,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationTableTitle);
