import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import SendIcon from '@material-ui/icons/Send';

import Button from '../../components/library/Button/Button';
import AssemblyResolutions from '../../components/Assembly/AssemblyResolutions/AssemblyResolutions';
import AssemblyDocuments from '../../components/Assembly/AssemblyDocuments/AssemblyDocuments';
import AssemblyInvitation from '../../components/Assembly/AssemblyInvitation/AssemblyInvitation';
import AssemblyVote from '../../components/Assembly/AssemblyVote';
import AssemblyMessage from '../../components/Assembly/AssemblyMessage';
import AssemblyPower from '../../components/Assembly/AssemblyPower';
import * as statuses from '../../services/store/states';

import styles from './AssemblyInvestors.module.css';
import DocumentsVisualisation from '../../components/Documents/DocumentsVisualisation';

export default function AssemblyInvestors({
  status, assembly, shareholders, user, selectedDocument, actions,
}) {
  const [isVoteOpen, setIsVoteOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isPowerOpen, setIsPowerOpen] = useState(false);

  const { id, society: societyId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (status.assembly === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
    if (status.user === statuses.IDLE) {
      actions.fetchUserInfo();
    }
    if (status.shareholders === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (status.history === statuses.IDLE) {
      actions.fetchHistory();
    }
    actions.setActiveAssembly(Number.parseInt(id, 10));
  }, []);

  const handleVoteSubmit = (newVotes) => {
    actions.voteAssemblyResolution(newVotes, id);
  };

  const handlePowerSubmit = (power) => {
    const { invitations } = assembly;
    const index = invitations?.map((i) => i.mail).indexOf(user.mail);
    if (index && index !== -1) {
      const newAssembly = {
        ...assembly,
        invitations: [
          ...assembly.invitations.splice(0, index),
          {
            ...assembly.invitations[index],
            mail: user.mail,
            presence: 'represented',
            representedBy: power,
          },
          ...assembly.invitations.splice(index + 1),
        ],
      };
      actions.updateAssemblyPlanById(Number.parseInt(id, 10), newAssembly);
    }
  };

  if (!assembly) {
    return (
      <section>
        <p>
          Loading
        </p>
      </section>
    );
  }

  return (
    <section className={styles.assembly}>
      { (selectedDocument) && <DocumentsVisualisation selectedDocument={selectedDocument} />}
      {
        (isVoteOpen && assembly)
        && (
          <AssemblyVote
            resolutions={assembly.resolutions}
            id={user.id}
            close={() => setIsVoteOpen(false)}
            submit={handleVoteSubmit}
          />
        )
      }
      {
        (isMessageOpen)
        && (
          <AssemblyMessage
            close={() => setIsMessageOpen(false)}
            submit={(message) => actions.sendMessage(user.mail, assembly.title, message)}
            status={status.message}
          />
        )
      }
      {
        (isPowerOpen)
        && (
          <AssemblyPower
            close={() => setIsPowerOpen(false)}
            submit={handlePowerSubmit}
            status={status.assembly}
            shareholders={shareholders}
            user={user.mail}
          />
        )
      }
      <header className={styles.header}>
        <div className={styles.title}>
          <Link
            to={`/${societyId}/assemblee/invitation`}
            className={styles.back}
          >
            <ArrowBackIcon />
          </Link>
          <h1>
            { assembly.title }
          </h1>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.buttonGroup}>
          <Button
            label={t('assembly.invitation.vote')}
            icon={<HowToVoteIcon />}
            onButtonClick={() => setIsVoteOpen(true)}
          />
          <Button
            label={t('assembly.invitation.question')}
            icon={<SendIcon />}
            onButtonClick={() => {
              actions.sendMessageReset();
              setIsMessageOpen(true);
            }}
          />
          {
            /*
              <Button
                label={t('assembly.invitation.power'}
                onButtonClick={() => setIsPowerOpen(true)}
              />
             */
          }
        </div>
        <div className={styles.content}>
          <AssemblyInvitation
            assembly={assembly}
            user={user.mail}
            sendInvitationResponse={(response) => actions.sendInvitationResponse(id, response)}
          />
          <AssemblyResolutions
            assembly={assembly}
          />
          <AssemblyDocuments
            assembly={assembly}
            openDocument={(doc) => actions.setSelectedDocument(doc)}
            societyId={societyId}
            assemblyId={id}
          />
        </div>
      </main>
    </section>
  );
}
