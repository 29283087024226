import React from 'react';
import { TrendingUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import * as statuses from '../../services/store/states';
import styles from './Society.module.css';

export default function SocietyShare({
  status, update, share, valuation,
}) {
  const { t } = useTranslation();
  if (status === statuses.LOADING) {
    return (
      <div className={`${styles.shareContainer} ${styles.shareContainerPlaceholder}`} />
    );
  }
  return (
    <div className={`fadeIn ${styles.shareContainer}`}>
      <p className={styles.update}>
        {`${t('society.share.update')} ${(new Date(update)).toLocaleDateString('fr')}`}
      </p>
      <div className={styles.shareWrapper}>
        <div>
          <p className={styles.shareTitle}>
            {t('society.share.price')}
          </p>
          <p
            className={styles.shareNumber}
          >
            {share && t('currency', { value: share })}
            <TrendingUp
              style={{
                color: '#0EDC97',
                marginLeft: '8px',
              }}
            />
          </p>
        </div>
        <div>
          <p
            className={styles.shareTitle}
          >
            {t('society.share.valuation')}
          </p>
          <p
            className={styles.shareNumber}
          >
            {valuation && t('currencyNoCents', { value: valuation })}
          </p>
        </div>
      </div>
    </div>
  );
}
