import React, { useState, useRef } from 'react';

import Button from '../../library/Button/Button';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../../Error/ErrorAnimation/ErrorAnimation';
import useOutsideClickAlert from '../../library/hook/clickOutside';

import * as statuses from '../../../services/store/states';
import styles from './AssemblyPower.module.css';

export default function AssemblyPower({
  shareholders, status, close, submit, user,
}) {
  const [hasSubmit, setHasSubmit] = useState(false);
  const [representedBy, setRepresentedBy] = useState(-1);
  const popup = useRef();
  useOutsideClickAlert(popup, close);

  const handleSubmit = () => {
    submit(representedBy);
    setHasSubmit(true);
  };

  if (hasSubmit && status === 'success') {
    const selected = shareholders.find((i) => i.id === Number.parseInt(representedBy, 10));
    return (
      <div className={styles.power}>
        <section className={`${styles.popup} ${styles.notification}`} ref={popup}>
          <SuccessAnimation />
          <h2>Votre pouvoir va être transferé</h2>
          <p>
            Pour confirmer votre transfert de pouvoir, vous et
            { ` ${selected.firstName} ${selected.lastName} `}
            {' '}
            allez recevoir la procuration à signer électroniquement
          </p>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
        </section>
      </div>
    );
  }
  if (hasSubmit && status === statuses.FAIL) {
    return (
      <div className={styles.power}>
        <section className={`${styles.popup} ${styles.notification}`} ref={popup}>
          <ErrorAnimation />
          <h2>Votre pouvoir n&apos;a pas pu être transferé</h2>
          <p>
            Un problème technique est survenue, le message n&apos;a pas pu être envoyé.
            Nous vous invitons à réessayer ultérieurement.
            Si le problème persiste, contactez notre équipe.
          </p>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
        </section>
      </div>
    );
  }
  return (
    <div className={styles.power}>
      <section
        ref={popup}
        className={styles.popup}
      >
        <h2>
          Donner une procuration
        </h2>
        <p className={styles.label}>
          À qui souhaitez-vous céder votre pouvoir ?
        </p>
        <select
          value={representedBy}
          className={styles.dropdown}
          onChange={(e) => setRepresentedBy(e.target.value)}
        >
          <option
            value={-1}
            disabled
          >
            Selectionner un actionnaires
          </option>
          {
            shareholders.filter((i) => i.mail !== user).map((shareholder) => (
              <option
                key={shareholder.id}
                value={shareholder.id}
              >
                {`${shareholder.firstName} ${shareholder.lastName} - ${shareholder.category}`}
              </option>
            ))
          }
        </select>
        <div className={styles.buttonGroup}>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
          <Button
            label="Valider"
            onButtonClick={handleSubmit}
            disabled={representedBy === -1}
          />
        </div>
      </section>
    </div>
  );
}
