import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventIcon from '@material-ui/icons/Event';
import RoomIcon from '@material-ui/icons/Room';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from '../../library/Button/Button';
import * as statuses from '../../../services/store/states';

import styles from './AssemblyInvitation.module.css';

export default function AssemblyInvitation({ assembly, user, sendInvitationResponse }) {
  const [response, setResponse] = useState(statuses.IDLE);

  const { t } = useTranslation();

  useEffect(() => {
    const { invitations } = assembly;
    const newResponse = invitations?.find((i) => i.mail === user)?.presence;
    if (newResponse) {
      setResponse(newResponse);
    }
  }, [assembly, user]);

  return (
    <section
      className={styles.invitation}
    >
      <iframe
        title="map"
        width="500"
        height="250"
        className={styles.map}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQyUNkEbrMMvJY-X0F6gx4y5B2KLv__U4&q=${assembly.place}`}
      />
      <div className={styles.assembly}>
        <header className={styles.header}>
          <h3>
            {' '}
            { assembly.title }
          </h3>
          {
              (response === 'yes')
              && (
              <div className={styles.responseGroup}>
                <div className={styles.yes}>
                  <CheckCircleIcon className={styles.icon} />
                  <p className={styles.response}>
                    {t('assembly.invitation.yes')}
                  </p>
                </div>
              </div>
              )
            }
          {
                (response === 'no')
                && (
                <div className={styles.responseGroup}>
                  <div className={styles.no}>
                    <CancelIcon className={styles.icon} />
                    <p className={styles.response}>
                      {t('assembly.invitation.no')}
                    </p>
                  </div>
                </div>
                )
              }
          {
                    (response === 'represented')
                    && (
                    <div className={styles.responseGroup}>
                      <div className={styles.represented}>
                        <CancelIcon className={styles.icon} />
                        <p className={styles.response}>
                          {t('assembly.invitation.represented')}
                        </p>
                      </div>
                    </div>
                    )
                  }
        </header>
        <div className={styles.event}>
          <EventIcon />
          <p>
            {`${assembly.date} ${assembly.time}`}
          </p>
          <RoomIcon />
          <p>
            {`${assembly.place}`}
          </p>
        </div>
        {
            (response === 'no_response')
            && (
            <div className={styles.buttonGroup}>
              <Button
                label={t('assembly.invitation.noResponse')}
                variant="ghost"
                onButtonClick={() => sendInvitationResponse('no')}
              />
              <Button
                label={t('assembly.invitation.yesResponse')}
                onButtonClick={() => sendInvitationResponse('yes')}
              />
            </div>
            )
          }
      </div>
    </section>
  );
}
