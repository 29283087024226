import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

import * as statuses from '../../services/store/states';
import styles from './StateHandler.module.css';

export default function StateHandler({ status, message, isActive = true }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (isActive && status === statuses.LOADING) {
      setIsUpdating(true);
    }
    if (isActive && isUpdating) {
      setShowToast(true);
      const timer = window.setTimeout(() => setShowToast(false), 2 * 1000);
      return () => window.clearTimeout(timer);
    }
    return null;
  }, [status]);

  const handleAnimationEnd = () => {
    if (!showToast) {
      setIsUpdating(false);
    }
  };

  if (isUpdating && status === statuses.SUCCESS) {
    return (
      <div
        className={`${showToast ? 'bounceIn' : 'fadeOut'} ${styles.toast} ${styles.success}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <FontAwesomeIcon
          className={styles.toastIcon}
          icon={faCheck}
        />
        <p>{ message.succeeded }</p>
      </div>
    );
  }

  if (isUpdating && status === 'failed') {
    return (
      <div
        className={`${showToast ? 'bounceIn' : 'fadeOut'} ${styles.toast} ${styles.failed}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <FontAwesomeIcon
          className={styles.toastIcon}
          icon={faBan}
        />
        <span>{ message.failed }</span>
      </div>
    );
  }
  return null;
}
