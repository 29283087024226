export default function validate(values, shareholdersQuantity = 0, t) {
  const errors = {};
  if (!values.from) {
    errors.from = t('operation.error.shareholder');
  }
  if (!values.to) {
    errors.to = t('operation.error.shareholder');
  } else if (values.from === values.to) {
    errors.to = t('operation.error.otherShareholder');
  }
  if (!values.quantity) {
    errors.quantity = t('operation.error.quantity');
  } else if (values.quantity > shareholdersQuantity) {
    errors.quantity = t('operation.error.quantityShareholder');
  }
  return errors;
}
