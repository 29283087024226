import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendDocumentRequest, resetDocumentRequest } from '../../../services/store/features/documents/actions';
import { closePopUp } from '../../../services/store/features/popup/actions';

import DocumentRequest from './DocumentRequest';

const mapStateToProps = (state) => ({
  status: state.documents.documentRequest.status,
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    sendDocumentRequest,
    closePopUp,
    resetDocumentRequest,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRequest);
