import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/library/Button/Button';
import Header from 'components/Header/Header';
import StateHandler from 'components/StateHandler/StateHandler';
import * as statuses from 'services/store/states';

import links from '../Report/navigation';
import styles from '../Report/Report.module.css';
import customStyles from './ReportPlanification.module.css';

export default function Report({
  status, societyId, userId, reports, roles, actions,
}) {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const { userId: user, society } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!userId) {
      actions.fetchUserInfo(user);
    }
  }, []);

  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchPlannedReportsData();
    }
  }, [societyId]);

  const handleDeleteConfirmation = (report) => {
    const handleDelete = () => {
      actions.deleteReport(report);
      if (!isActive) setIsActive(true);
      actions.closePopUp();
    };
    actions.openPopUp(
      t('reports.plan.delete.confirmation'),
      '',
      <div className={customStyles.confirmation}>
        <Button
          label={t('cancel')}
          variant="ghost"
          onButtonClick={actions.closePopUp}
        />
        <Button
          label={t('validate')}
          onButtonClick={handleDelete}
        />
      </div>,
    );
  };

  if (!societyId && !userId) return null;

  return (
    <section className={styles.report}>
      <StateHandler
        status={status}
        message={{
          succeeded: t('reports.plan.status.success'),
          failed: t('reports.plan.status.fail'),
        }}
        isActive={isActive}
      />
      <Header
        title={t('reports.report')}
        navigation={links(t, societyId, userId)}
        roles={roles}
      />
      <main className={styles.content}>
        <h2>
          {t('reports.plan.title')}
        </h2>
        {
          (reports && reports.length > 0)
            ? (
              <ul className={styles.list}>
                {
                  reports.map((report) => (
                    <div
                      className={customStyles.report}
                      key={report.id}
                    >
                      <Link
                        to={`/${societyId}/rapport/edition/${report.id}`}
                        className={styles.reportLink}
                      >
                        <div className={styles.reportTitle}>
                          <p className={styles.title}>
                            { report.title }
                          </p>
                          <p className={styles.period}>
                            {t('reports.period', { begin: report.period?.begin, end: report.period?.end })}
                          </p>
                        </div>
                        <span className={styles.publication}>
                          { `${t('reports.plan.date')} ${report.publicationDate}` }
                        </span>
                      </Link>
                      <div className={customStyles.tools}>
                        <Link
                          to={`/${societyId}/rapport/edition/${report.id}`}
                          className={customStyles.edit}
                        >
                          <EditIcon />
                        </Link>
                        <button
                          type="button"
                          className={customStyles.delete}
                          onClick={() => handleDeleteConfirmation(report.id)}
                        >
                          {
                            (status === statuses.LOADING)
                              ? <FontAwesomeIcon icon={faSpinner} spin />
                              : <DeleteIcon />
                          }
                        </button>
                      </div>
                    </div>
                  ))
                }
              </ul>
            )
            : (
              <div className={styles.empty}>
                <p className={styles.title}>{ t('reports.empty') }</p>
                <NavLink
                  className={styles.button}
                  to={`/${societyId}/rapport/edition`}
                >
                  { t('reports.navigation.edit') }
                </NavLink>
              </div>
            )
        }
      </main>
    </section>
  );
}
