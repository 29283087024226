import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  error: null,
  query: '',
  typeFilter: [],
  nameFilter: [],
  operations: [],
  creation: {
    status: statuses.IDLE,
    error: null,
  },
  capital: {
    selectedShareholders: [],
  },
};

export default function operationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_OPERATIONS_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_OPERATIONS_SUCCEEDED:
      return {
        ...state,
        status: statuses.SUCCESS,
        operations: [...new Map([
          ...state?.operations,
          ...payload,
        ].map((operation) => [operation.id, operation])).values()],
      };
    case types.FETCH_OPERATIONS_FAILED:
      return {
        ...state,
        status: statuses.FAIL,
        error: payload,
      };
    case types.ADD_OPERATION_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.ADD_OPERATION_FAILED:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.FAIL,
          error: payload,
        },
      };
    case types.ADD_OPERATION_SUCCEEDED:
      return {
        ...state,
        operations: [...state.operations, payload],
        creation: {
          ...state.creation,
          status: statuses.SUCCESS,
        },
      };
    case types.ADD_AYOMI_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: [...state.operations, ...payload],
      };
    case types.ADD_MULTIPLE_OPERATIONS_SUCCEEDED:
      return {
        ...state,
        operations: [...state.operations, ...payload],
        creation: {
          ...state.creation,
          status: statuses.SUCCESS,
        },
      };
    case types.ADD_MULTIPLE_OPERATIONS_FAILED:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.FAIL,
        },
      };
    case types.UPDATE_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: payload,
        creation: {
          ...state.creation,
          status: statuses.SUCCESS,
        },
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_TYPE_FILTER:
      return {
        ...state,
        typeFilter: payload,
      };
    case types.SET_NAME_FILTER:
      return {
        ...state,
        nameFilter: payload,
      };
    case types.SET_OPERATION_CREATION:
      return {
        ...state,
        creation: {
          ...payload,
          status: 'edit',
          error: null,
        },
      };
    case types.SET_SELECTED_SHAREHOLDERS:
      return {
        ...state,
        capital: {
          ...state.capital,
          selectedShareholders: payload,
        },
      };
    default:
      return state;
  }
}
