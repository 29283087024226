import { keysToCamel, keysToSnake } from '../../../../utils/converter';

import * as types from './types';
import {
  createDocumentRequest,
  createNewDocument, deleteDocumentById, getDocuments, getUserDocuments,
} from '../../../clients/document';

export function setSelectedDocument(url) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_DOCUMENT, payload: url });
  };
}

export function setSelectedUserDocument(url) {
  return (dispatch) => {
    dispatch({ type: types.SET_USER_SELECTED_DOCUMENT, payload: url });
  };
}

export function fetchDocumentsData() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({
        type: types.FETCH_DOCUMENTS_REQUEST,
      });
      try {
        const { data } = await getDocuments(id);
        dispatch({
          type: types.FETCH_DOCUMENTS_SUCCEEDED,
          payload: keysToCamel(data),
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_DOCUMENTS_FAILED,
          payload: err,
        });
      }
    }
  };
}

export function fetchUserDocumentsData() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    dispatch({
      type: types.FETCH_USER_DOCUMENTS_REQUEST,
    });
    try {
      const { data } = await getUserDocuments(id);
      dispatch({
        type: types.FETCH_USER_DOCUMENTS_SUCCEEDED,
        payload: keysToCamel(data),
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_USER_DOCUMENTS_FAILED,
        payload: err,
      });
    }
  };
}

export function deleteDocument(id) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_DOCUMENT_REQUEST });
    try {
      await deleteDocumentById(id);
      dispatch({
        type: types.DELETE_DOCUMENT_SUCCEEDED,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_DOCUMENT_FAILED,
        payload: err,
      });
    }
  };
}

export function deleteUserDocument(id) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_USER_DOCUMENT_REQUEST });
    try {
      await deleteDocumentById(id);
      dispatch({
        type: types.DELETE_USER_DOCUMENT_SUCCEEDED,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_USER_DOCUMENT_FAILED,
        payload: err,
      });
    }
  };
}

export function uploadDocument(title, file, iss = null) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPLOAD_DOCUMENT_REQUEST });
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      formData.append('society_id', getState().society.id);
      const { data } = await createNewDocument(formData, iss);
      dispatch({
        type: types.UPLOAD_DOCUMENT_SUCCEEDED,
        payload: {
          iss,
          ...data,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({ type: types.UPLOAD_DOCUMENT_FAILED, payload: err });
    }
  };
}

export function uploadUserDocument(title, file, iss) {
  return async (dispatch, getState) => {
    dispatch(uploadDocument(title, file, iss || getState().roles.iss));
  };
}

export function resetUploadDocument() {
  return (dispatch) => {
    dispatch({ type: types.UPLOAD_DOCUMENT_RESET });
  };
}

export function resetUserUploadDocument() {
  return (dispatch) => {
    dispatch({ type: types.UPLOAD_USER_DOCUMENT_RESET });
  };
}

export function sendDocumentRequest(request) {
  return async (dispatch, getState) => {
    const { id: userIss } = getState().user;
    dispatch({ type: types.SEND_DOCUMENT_REQUEST });
    try {
      await createDocumentRequest(keysToSnake({ ...request, userIss }));
      dispatch({ type: types.SEND_DOCUMENT_SUCCEEDED });
    } catch (err) {
      dispatch({ type: types.SEND_DOCUMENT_FAILED, payload: err });
    }
  };
}

export function resetDocumentRequest() {
  return (dispatch) => {
    dispatch({ type: types.RESET_DOCUMENT_REQUEST });
  };
}

export function setQuery(query) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function setFolder(folder) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FOLDER,
      payload: folder,
    });
  };
}

export function setSort(value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SORT,
      payload: value,
    });
  };
}
