import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Assembly from './Assembly';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  societyId: state.society.id,
  user: state.user,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSocietyId, fetchUserInfo }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assembly);
