import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../library/Button/Button';
import EventsPopUp from '../EventsPopUp';
import * as statuses from '../../../services/store/states';

import EventsPreviewList from './EventsPreviewList';
import EventsPreviewEmpty from './EventsPreviewEmpty';
import styles from './EventsPreview.module.css';

export default function EventsPreview({
  events, status, societyId, isAuthorized, actions,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchEventsData();
    }
  }, [societyId, status]);

  const handleAddEvent = () => {
    actions.resetEventStatus();
    actions.openPopUp(
      t('events.add.title'),
      t('events.add.description'),
      <EventsPopUp />,
    );
  };

  if (status === statuses.SUCCESS) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <h2
              className={styles.title}
            >
              {t('events.events')}
            </h2>
            {
              events.length > 0
                ? (
                  <EventsPreviewList
                    events={events}
                    openPopUp={actions.openPopUp}
                    reset={actions.resetEventStatus}
                  />
                )
                : <EventsPreviewEmpty />
            }
          </div>
          {
            isAuthorized
            && (
              <div
                className={styles.buttonGroup}
              >
                <Button
                  type="secondary"
                  size="small"
                  label={t('events.add.button')}
                  onButtonClick={handleAddEvent}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
  if (status === statuses.FAIL) {
    return null;
  }
  return <div className={styles.container} />;
}
