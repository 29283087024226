import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useForm from '../../../hook/useForm';
import InputText from '../../library/Input/InputText/InputText';
import Button from '../../library/Button/Button';
import * as statuses from '../../../services/store/states';

import OperationsShareholdersFormPerson from '../OperationsShareholdersFormPerson/OperationsShareholdersFormPerson';
import validate from './formValidate';
import styles from './OperationsShareholdersFormSociety.module.css';

export default function OperationsShareholdersFormSociety({ callback, actions }) {
  const [file, setFile] = useState(null);
  const submit = (shareholder) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      birthday,
      shares,
      address,
      city,
      zipCode,
      siren,
      societyName,
      rcsCity,
      formJuridique,
    } = shareholder;

    const newShareholder = {
      societyName,
      siren,
      rcsCity,
      formJuridique,
      firstName,
      lastName,
      email,
      phone,
      birthday: (birthday) ? `${birthday.day}/${birthday.month}/${birthday.year}` : undefined,
      shares: shares ? Number.parseInt(shares, 10) : 0,
      isHolding: false,
      isInvestor: true,
      address,
      city,
      zipCode,
      img: file,
      category: 'Investisseur',
      holdingName: 'default',
    };
    actions.addNewShareholders(newShareholder, callback);
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submit, (form) => validate(form, file));

  const { t } = useTranslation();

  return (
    <form className={styles.form}>
      <h4>{t('shareholders.edit.society.information')}</h4>
      <InputText
        label={t('settings.investors.form.siren2')}
        name="siren"
        valueInput={values.siren || ''}
        onTextChange={handleChange}
        state={errors.siren && statuses.FAIL}
        hint={errors.siren}
        required
      />
      <InputText
        label={t('settings.investors.form.rcsCity')}
        type="text"
        name="rcsCity"
        valueInput={values.rcsCity || ''}
        onTextChange={handleChange}
        state={errors.rcsCity && statuses.FAIL}
        hint={errors.rcsCity}
      />
      <InputText
        label={t('shareholders.edit.society.name')}
        name="societyName"
        valueInput={values.societyName || ''}
        onTextChange={handleChange}
        state={errors.societyName && statuses.FAIL}
        hint={errors.societyName}
      />
      <InputText
        label={t('settings.investors.form.formJuridique')}
        name="formJuridique"
        valueInput={values.formJuridique || ''}
        onTextChange={handleChange}
        state={errors.formJuridique && statuses.FAIL}
        hint={errors.formJuridique}
      />
      <h4>{t('shareholders.edit.society.legal')}</h4>
      <OperationsShareholdersFormPerson
        shareholder={values}
        actions={handleChange}
        errors={errors || {}}
        handleFile={(value) => setFile(value)}
        file={file}
      />
      <Button
        label={t('validate')}
        className={styles.button}
        onButtonClick={handleSubmit}
      />
    </form>
  );
}
