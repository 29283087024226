import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useForm from '../../../hook/useForm';
import validate from './validate';

import InputText from '../../../components/library/Input/InputText/InputText';
import InputDate from '../../../components/library/Input/InputDate/InputDate';
import Button from '../../../components/library/Button/Button';
import * as statuses from '../../../services/store/states';

import shared from '../shared/assembly.module.css';
import styles from './AssemblyCreation.module.css';
import AssemblyCreationSuccess from './AssemblyCreationSuccess';

export default function AssemblyCreation({
  status, societyId, actions,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (status === 'success') {
      actions.resetAssemblyCreation();
    }
  }, []);

  const submit = (values) => {
    actions.createAssembly(values);
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submit, (v) => validate(v, t));

  const handleDateChange = (date, e) => {
    e.target.name = 'date';
    e.target.value = '';
    handleChange(e, date);
  };

  if (status === 'success') {
    return (<AssemblyCreationSuccess societyId={societyId} />);
  }

  return (
    <section className={`${styles.section}`}>
      <header className={shared.header}>
        <h2>
          { t('assembly.creation.title') }
        </h2>
      </header>
      <main className={styles.main}>
        <form className={styles.form}>
          <InputText
            label={t('assembly.creation.name')}
            type="text"
            name="title"
            valueInput={values?.title || ''}
            onTextChange={handleChange}
            state={errors?.title && statuses.FAIL}
            hint={errors?.title}
          />
          <div className={styles.row}>
            <InputDate
              label={t('assembly.creation.date')}
              name="date"
              day={values?.date?.day || ''}
              month={values?.date?.month || ''}
              year={values?.date?.year || ''}
              onChange={handleDateChange}
              includeDay
              state={errors?.date && statuses.FAIL}
              hint={errors?.date}
            />
            <div
              className={styles.time}
            >
              <p className={styles.label}>
                { t('assembly.creation.time') }
              </p>
              <input
                type="time"
                name="time"
                className={`${styles.timeInput} ${(errors?.time) ? styles.error : ''}`}
                value={values?.time}
                onChange={handleChange}
                min="06:00"
                max="23:00"
              />
              {
                (errors?.time)
                && (
                  <p
                    className={`${styles.hint} ${styles.error}`}
                  >
                    {errors.time}
                  </p>
                )
              }
            </div>
          </div>
          <InputText
            label={t('assembly.creation.place')}
            type="text"
            name="place"
            valueInput={values?.place || ''}
            onTextChange={handleChange}
            state={errors?.place && statuses.FAIL}
            hint={errors?.place}
          />
        </form>
        <div>
          <p className={styles.hint}>
            { t('assembly.creation.help') }
            <Link
              to={`/${societyId}/contacts`}
              className={`${styles.hint} ${styles.link}`}
            >
              { t('assembly.creation.contact') }
            </Link>
          </p>
          <div className={styles.description}>
            <h3>
              { t('assembly.creation.details.title') }
            </h3>
            <p>
              { t('assembly.creation.details.description') }
            </p>
          </div>
        </div>
      </main>
      <div
        className={styles.submit}
      >
        <Button
          label={t('assembly.creation.title')}
          onButtonClick={handleSubmit}
          className={styles.assemblyButton}
        />
      </div>
    </section>
  );
}
