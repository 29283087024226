import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyPresence from './AssemblyPresence';
import {
  getInvitations, fetchAssemblyPlan, setActiveAssembly,
  signAssemblyPresenceById,
} from '../../../services/store/features/assembly/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';

const mapStateToProps = (state) => ({
  invitations: getInvitations(state),
  status: {
    shareholders: state.shareholders.status,
    assembly: state.assembly.plan.status,
    history: state.history.status,
  },
  userId: state.user.id,
  societyId: state.society.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    fetchAssemblyPlan,
    fetchHistory,
    setActiveAssembly,
    signAssemblyPresenceById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyPresence);
