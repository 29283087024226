import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OperationsTransfertForm.module.css';
import { getShareholderShare } from '../../../services/store/features/shareholders/utils';
import Button from '../../library/Button/Button';
import InputText from '../../library/Input/InputText/InputText';
import * as statuses from '../../../services/store/states';

export default function OperationsTransfertRow({
  from, to, quantity, errors, shareholders, isHolding, societyId, holdingId,
  hasEnoughShares, onChange, openForm,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <div className={styles.dropdown}>
        <p className={styles.label}>
          {t('operation.between')}
        </p>
        <select
          name="from"
          value={from}
          onChange={onChange}
          className={(errors?.from) && styles.SelectError}
          defaultValue={-1}
        >
          <option value={-1} disabled>
            {t('operation.shareholderChoice')}
          </option>
          {
            shareholders
              .filter((shareholder) => (getShareholderShare(
                shareholder,
                !isHolding ? societyId : holdingId,
              ) !== 0))
              .map((shareholder) => (
                <option
                  key={shareholder.societyName ? `society${shareholder.id}` : shareholder.id}
                  value={shareholder.societyName ? `society${shareholder.id}` : shareholder.id}
                >
                  {(!shareholder.societyName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.societyName}
                </option>
              ))
          }
        </select>
        {
          (errors?.from)
          && (
            <p className={`${styles.label} ${styles.error}`}>
              {errors?.from}
            </p>
          )
        }
      </div>
      <div className={styles.dropdown}>
        <p className={styles.label}>
          {t('operation.and')}
        </p>
        <select
          name="to"
          value={to}
          onChange={onChange}
          className={(errors?.to) && styles.SelectError}
          defaultValue={-1}
        >
          <option value={-1} disabled>
            {t('operation.shareholderChoice')}
          </option>
          {
            shareholders
              .map((shareholder) => (
                <option
                  key={shareholder.societyName ? `society${shareholder.id}` : shareholder.id}
                  value={shareholder.societyName ? `society${shareholder.id}` : shareholder.id}
                >
                  {(!shareholder.societyName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.societyName}
                </option>
              ))
          }
        </select>
        {
          (errors?.to)
          && (
            <p className={`${styles.label} ${styles.error}`}>
              {errors?.to}
            </p>
          )
        }
        {
          (to && (to === from))
          && (
            <p className={`${styles.label} ${styles.error}`}>
              {t('operation.transfers.sameShareholders')}
            </p>
          )
        }
        {
          (!to)
          && (
            <Button
              label={t('operation.shareholderCreation')}
              variant="ghost"
              onButtonClick={openForm}
              className={styles.creationButton}
            />
          )
        }
      </div>
      <div className={styles.quantity}>
        <InputText
          label={t('operation.transferTitle')}
          type="number"
          name="quantity"
          valueInput={quantity || ''}
          onTextChange={onChange}
          state={(!hasEnoughShares({ from, to, quantity }) || errors?.quantity) && statuses.FAIL}
          hint={(!hasEnoughShares({ from, to, quantity }) || errors?.quantity) && t('operation.transferError.quantity')}
        />
      </div>
    </div>
  );
}
