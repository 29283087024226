import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  error: null,
  contacts: [],
  feedback: {
    status: statuses.IDLE,
    error: null,
  },
};

export default function contactsReducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case types.FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        status: 'success',
        contacts: payload,
      };
    case types.FETCH_CONTACTS_FAIL:
      return {
        ...state,
        status: 'fail',
        error: payload,
      };
    case types.RESET_FEEDBACK:
      return {
        ...state,
        feedback: {
          status: statuses.IDLE,
          error: null,
        },
      };
    case types.SEND_FEEDBACK_REQUEST:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          status: statuses.LOADING,
        },
      };
    case types.SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          status: 'success',
          error: null,
        },
      };
    case types.SEND_FEEDBACK_FAIL:
      return {
        ...state,
        status: statuses.FAIL,
        error: payload,
      };
    default:
      return state;
  }
}
