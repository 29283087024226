import React from 'react';
import { Error } from '@material-ui/icons';

import stylesForm from 'components/Operation/OperationCapitalForm/OperationCapitalForm.module.css';

export default function ErrorMessage({ message }) {
  return (
    <p className={stylesForm.error}>
      <Error className={stylesForm.icon} />
      <span className={stylesForm.hint}>
        {message}
      </span>
    </p>
  );
}
