import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';

import * as statuses from '../../services/store/states';

import AssemblyHeader from './AssemblyHeader/AssemblyHeader';
import AssemblyRegister from './AssemblyRegister';
import AssemblyPlan from './AssemblyPlan';
import AssemblyCreation from './AssemblyCreation';
import AssemblyInvitation from './AssemblyInvitation';
import styles from './Assembly.module.css';

export default function Assembly({
  societyId, user, roles, actions, isAuthorized,
}) {
  const { society, userId } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (user.status === statuses.IDLE) {
      actions.fetchUserInfo(userId);
    }
  }, []);

  if (!societyId) return null;

  return (
    <section
      className={styles.assembly}
    >
      <AssemblyHeader
        societyId={societyId}
        userId={userId}
        roles={roles}
        isAuthorized={isAuthorized}
      />
      <main>
        <Route
          path="/:society/assemblee/invitation"
          component={AssemblyInvitation}
        />
        <Route
          path="/:society/assemblee/plan"
          component={AssemblyPlan}
        />
        <Route
          path="/:society/assemblee/registre"
          component={AssemblyRegister}
        />
        <Route
          path="/:society/assemblee/create"
          component={AssemblyCreation}
        />
      </main>
    </section>
  );
}
