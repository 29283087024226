import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/library/Button/Button';

import styles from './Setting.module.css';
import SettingsSociety from './SettingsSociety';
import SettingsInvestors from './SettingsInvestors';

export default function Settings() {
  const [tab, setTab] = useState('society');
  const { t } = useTranslation();

  return (
    <section className={styles.settings}>
      <header className={styles.header}>
        <h1>
          {t('settings.title')}
        </h1>
        <div className={styles.separator} />
        <Button
          variant="ghost"
          label={t('settings.society')}
          onButtonClick={() => setTab('society')}
        />
        <Button
          variant="ghost"
          label={t('settings.investors.access')}
          onButtonClick={() => setTab('investors')}
        />
      </header>
      {(tab === 'society') && <SettingsSociety />}
      {(tab === 'investors') && <SettingsInvestors />}
    </section>
  );
}
