import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';

import Button from 'components/library/Button/Button';
import SuccessAnimation from 'components/SuccessAnimation/SuccessAnimation';
import { getShareholderShare, mergeShareholders } from 'services/store/features/shareholders/utils';
import * as statuses from 'services/store/states';

import useForm from '../../../hook/useForm';
import validate from './formValidation';
import styles from './OperationsTransfertForm.module.css';
import OperationsTransfertRow from './OperationsTransfertRow';

export default function OperationsTransfertForm({
  openForm, shareholdersStatus, shareholders, history, operation, actions, societyId,
  holdingId, isHolding = false,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();

  const formatShareholderId = (id) => Number.parseInt(id.toString().replace('society', ''), 10);

  const submit = ({ transfers }) => {
    setHasSubmitted(true);
    const newOperations = transfers.map((data) => ({
      date: (typeof operation.date === 'string')
        ? operation.date
        : `${operation.date?.day}/${operation.date?.month}/${operation.date?.year}`,
      fromUserId: formatShareholderId(data.from),
      from_society: data.from.toString().includes('society'),
      toUserId: formatShareholderId(data.to),
      to_society: data.to.toString().includes('society'),
      quantity: Number.parseInt(data.quantity, 10),
      name: operation.name,
      type: operation.type,
      status: 'verified',
      holding: isHolding,
    }));
    actions.addNewOperation(newOperations);
  };

  useEffect(() => {
    if (history.status === statuses.IDLE) {
      actions.fetchHistory();
    }
    if (shareholdersStatus === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, []);

  const handleValidation = (values) => validate(
    values,
    values.transfers.map((transfer) => {
      const fromShareholder = mergeShareholders(shareholders)
        .filter((shareholder) => shareholder.id === Number.parseInt(transfer.from.replace('society', ''), 10))[0];
      if (!fromShareholder) return 0;
      return getShareholderShare(
        fromShareholder,
        isHolding ? holdingId : societyId,
      );
    }),
  );

  const hasEnoughShares = (values) => {
    if (values.from && values.quantity) {
      const fromShareholder = mergeShareholders(shareholders)
        .filter((shareholder) => shareholder.id === Number.parseInt(values?.from?.replace('society', ''), 10))[0];
      return getShareholderShare(
        fromShareholder,
        isHolding ? holdingId : societyId,
      ) >= values.quantity;
    }
    return true;
  };

  const {
    values, errors, handleChange, handleSubmit, setNewValues,
  } = useForm(submit, handleValidation, { transfers: [{ id: 0 }] });

  const onTransferChange = (id, e) => {
    const newTransfers = values.transfers.map((transfer) => (
      (transfer.id === id)
        ? ({
          ...transfer,
          [e.target.name]: e.target.value,
        })
        : transfer
    ));
    handleChange({ target: { name: 'transfers', value: newTransfers } });
  };

  const addTransfer = () => {
    const newTransfers = [
      ...values.transfers,
      { id: Math.max(...(values.transfers.map(({ id }) => id))) + 1 },
    ];
    setNewValues('transfers', newTransfers);
  };

  if (operation.status === statuses.IDLE) {
    return <Redirect to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`} />;
  }

  if (hasSubmitted && operation.status === statuses.SUCCESS) {
    return (
      <div className={styles.success}>
        <SuccessAnimation />
        <h2>
          {t('operation.succeed')}
        </h2>
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations`}
          className="button"
        >
          {t('operation.back')}
        </Link>
      </div>
    );
  }

  return (
    <div>
      <h3>
        {t('operation.transfer')}
      </h3>
      <form>
        {
          values?.transfers?.map(({
            id, from, to, quantity,
          }, key) => (
            <OperationsTransfertRow
              key={id}
              from={from}
              to={to}
              quantity={quantity}
              errors={errors[key]}
              shareholders={shareholders}
              isHolding={isHolding}
              societyId={societyId}
              holdingId={holdingId}
              hasEnoughShares={hasEnoughShares}
              onChange={(e) => onTransferChange(id, e)}
              openForm={openForm}
            />
          ))
        }
        <Button
          label={t('operation.transfers.add')}
          variant="ghost"
          icon={<AddCircle />}
          onButtonClick={addTransfer}
        />
      </form>
      <div className={styles.row}>
        <Link
          className={styles.ghostButton}
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/transfert`}
        >
          {t('operation.previous')}
        </Link>
        <Button
          label={t('operation.create')}
          className={styles.button}
          onButtonClick={handleSubmit}
          disabled={!hasEnoughShares(values)}
        />
      </div>
    </div>
  );
}
