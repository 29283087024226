export default function validate(values, t) {
  const errors = {};
  if (!values.title) {
    errors.title = t('assembly.creation.errors.title');
  }
  if (!values.date) {
    errors.date = t('assembly.creation.errors.date');
  }
  if (values.date) {
    let convocation;
    if (values.date && typeof values.date === 'string') {
      const assemblyDate = values.date.split('/');
      const date = new Date(assemblyDate[2], assemblyDate[1] - 1, assemblyDate[0]);
      date.setDate(date.getDate() - 15);
      convocation = date;
    } else {
      const date = new Date(
        values.date.year,
        Number.parseInt(values.date.month, 10) - 1,
        values.date.day,
      );
      date.setDate(date.getDate() - 14);
      convocation = date;
    }
    if (convocation < new Date()) {
      errors.date = t('assembly.creation.errors.date_too_soon');
    }
  }
  if (!values.time) {
    errors.time = t('assembly.creation.errors.time');
  }
  if (!values.place) {
    errors.place = t('assembly.creation.errors.place');
  }
  return errors;
}
