import React from 'react';

import styles from './Society.module.css';
import ImgPlaceholder from '../ImgPlaceholder';

export default function SocietyHeader({ src, alt }) {
  return (
    <div className={`slideInTop ${styles.header}`}>
      <ImgPlaceholder
        src={src}
        alt={alt}
        className={styles.image}
      >
        <div className={`${styles.image} ${styles.default} ${styles.header}`} />
      </ImgPlaceholder>
    </div>
  );
}
