import * as types from './types';
import {
  getUserInfo, logOutUser, updateUserInfo,
} from '../../../clients/clientAPI';
import {
  createNewDocument, deleteDocumentById, updateDocumentsById,
} from '../../../clients/document';

export function fetchUserInfo(societyId) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    try {
      if (id || societyId) {
        dispatch({ type: types.FETCH_USER_INFO_REQUEST });
        const { data } = await getUserInfo(id || societyId);
        dispatch({
          type: types.FETCH_USER_INFO_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_USER_INFO_FAIL,
        payload: err,
      });
    }
  };
}

export function logOut() {
  return async (dispatch) => {
    dispatch({ type: types.LOG_OUT_REQUEST });
    try {
      await logOutUser();
      window.location.assign('https://lendeers.com/');
    } catch (err) {
      dispatch({
        type: types.LOG_OUT_FAILED,
        payload: err,
      });
    }
  };
}

export function setFolder(folder) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FOLDER,
      payload: folder,
    });
  };
}

export function updateUserById(id, user) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_USER_REQUEST });
    try {
      const newUser = {
        ...getState().user,
        ...user,
      };
      await updateUserInfo(id, newUser);
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: user,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_USER_FAIL,
        payload: err,
      });
    }
  };
}

export function updateDocument(id, value) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_USER_REQUEST });
    try {
      await updateDocumentsById(getState().user.id, id, value);
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: { id, value },
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_USER_FAIL,
        payload: err,
      });
    }
  };
}

export function uploadDocument(title, file) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPLOAD_USER_DOCUMENT_REQUEST });
    try {
      const user = [getState().user.id];
      const formData = new FormData();
      formData.append('title', title);
      formData.append('doc', file);
      formData.append('societyId', getState().society.id);
      formData.append('users', user);
      const { data } = await createNewDocument(formData);
      dispatch({
        type: types.UPLOAD_USER_DOCUMENT_SUCCESS,
        payload: {
          id: data.id,
          title,
          url: window.URL.createObjectURL(file),
          date: new Date().toLocaleDateString('fr-FR'),
        },
      });
    } catch (err) {
      dispatch({ type: types.UPLOAD_USER_DOCUMENT_FAILED, payload: err });
    }
  };
}

export function resetUploadDocument() {
  return (dispatch) => {
    dispatch({
      type: types.UPLOAD_USER_DOCUMENT_RESET,
    });
  };
}

export function deleteDocument(id) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_USER_DOCUMENT_REQUEST });
    try {
      await deleteDocumentById(id);
      dispatch({ type: types.DELETE_USER_DOCUMENT_SUCCESS, payload: id });
    } catch (err) {
      dispatch({ type: types.DELETE_USER_DOCUMENT_FAIL, payload: err });
    }
  };
}

export function setUserDocumentQuery(query) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function setUserDocumentSort(value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SORT,
      payload: value,
    });
  };
}

export function setSelectedDocument(doc) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_DOCUMENT,
      payload: doc,
    });
  };
}
