import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  shareholders: [],
  holding: undefined,
  export: null,
  exportStatus: statuses.IDLE,
  query: '',
  activeTab: 'default',
  categoryFilters: [],
  error: null,
  contact: null,
  creation: {
    status: statuses.IDLE,
    error: null,
  },
};

export default function ShareholdersReducer(state = initialState, action) {
  const { type, payload } = action;
  const isHolding = window.location.href.includes('holding');
  switch (type) {
    case types.FETCH_SHAREHOLDERS_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_SHAREHOLDERS_FAIL:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.FETCH_SHAREHOLDERS_SUCCESS:
      return {
        ...state,
        status: 'success',
        shareholders: payload,
      };
    case types.FETCH_HOLDING_SUCCESS:
      return {
        ...state,
        status: 'success',
        holding: payload,
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_ACTIVE_TABS:
      return {
        ...state,
        activeTab: payload,
      };
    case types.SET_ACTIVE_CONTACT:
      return {
        ...state,
        contact: payload,
      };
    case types.SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case types.SET_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilters: payload,
      };
    case types.UPDATE_SHAREHOLDER_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.UPDATE_SHAREHOLDER_SUCCESS:
      return {
        ...state,
        shareholders: !window.location.href.includes('holding') ? payload : state.shareholders,
        holding: window.location.href.includes('holding') ? payload : state.holding,
        status: 'success',
      };
    case types.UPDATE_SHAREHOLDER_FAIL:
      return {
        ...state,
        status: 'error-update',
        error: payload,
      };
    case types.EXPORT_SHAREHOLDERS_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.EXPORT_SHAREHOLDERS_SUCCESS:
      return {
        ...state,
        exportStatus: 'success',
        export: payload,
      };
    case types.EXPORT_SHAREHOLDERS_FAIL:
      return {
        ...state,
        exportStatus: 'error-export',
        error: payload,
      };
    case types.CREATE_SHAREHOLDER_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.CREATE_SHAREHOLDER_SUCCESS:
      return {
        ...state,
        ...(
          isHolding
            ? {
              holding: [
                ...(state?.holding || []),
                payload,
              ],
            }
            : {
              shareholders: [
                ...(state?.shareholders || []),
                payload,
              ],
            }
        ),
        creation: {
          ...state.creation,
          status: 'success',
        },
      };
    case types.CREATE_SHAREHOLDER_FAIL:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: 'fail',
        },
      };
    case types.CREATE_HOLDING_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.CREATE_HOLDING_SUCCESS:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.SUCCESS,
        },
        holding: payload,
      };
    case types.CREATE_HOLDING_FAIL:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.FAIL,
        },
      };
    case 'ADD_AYOMI_SHAREHOLDER_SUCCESS':
      return {
        ...state,
        status: 'success',
        shareholders: payload.shareholders,
        holding: payload.holding,
      };
    case types.EXPORT_SHAREHOLDERS_RESET:
      return {
        ...state,
        export: null,
        exportStatus: statuses.IDLE,
      };
    case types.RESET_CREATION:
      return {
        ...state,
        creation: {
          status: statuses.IDLE,
          error: null,
        },
      };
    default:
      return state;
  }
}
