import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAssemblyPlan, setActiveAssembly, uploadReportById } from '../../../services/store/features/assembly/actions';

import AssemblyReport from './AssemblyReport';

const mapStateToProps = (state) => ({
  assemblyStatus: state.assembly.plan.status,
  isAttendanceSheetComplete: state.assembly.plan.list
    .find((i) => i.id === state.assembly.selected)?.presence !== undefined,
  isVotesComplete: state.assembly.plan.list
    .find((i) => i.id === state.assembly.selected)?.votes?.length === state.assembly.plan.list
    .find((i) => i.id === state.assembly.selected)?.presence?.reduce((acc, current) => {
      if (current.isPresent) {
        return acc + 1;
      }
      return acc + 0;
    }, 0),
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    uploadReportById,
    fetchAssemblyPlan,
    setActiveAssembly,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyReport);
