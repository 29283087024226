import * as types from './types';

const initialState = {
  isOpen: false,
  title: null,
  description: null,
  children: null,
};

export default function popupReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_POP_UP:
      return {
        ...state,
        isOpen: payload.isOpen,
        title: payload.title,
        description: payload.description,
        children: payload.children,
      };
    default:
      return state;
  }
}
