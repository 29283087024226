import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as statuses from '../../services/store/states';

import styles from '../Documents/DocumentsPreview/DocumentsPreview.module.css';
import customStyles from './DashboardReportPreview.module.css';

export default function DashboardReportPreview({
  status, societyId, reports = [], actions,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchReportsData();
    }
  }, [societyId]);
  if (status === statuses.LOADING) {
    return (
      <div>
        <p>
          {`${t(statuses.LOADING)}...`}
        </p>
      </div>
    );
  }
  if (status === 'failed') {
    return (
      <div className={`${styles.container} ${customStyles.marginTop}`}>
        <div>
          <div className={styles.row}>
            <h2 className={styles.title}>
              {t('reports.recent')}
            </h2>
          </div>
          <p>
            {t('error.title')}
          </p>
        </div>
      </div>
    );
  }
  if (reports.length === 0) {
    return (
      <div className={`${styles.container} ${customStyles.marginTop}`}>
        <div className={styles.content}>
          <div className={styles.row}>
            <h2 className={styles.title}>
              {t('reports.recent')}
            </h2>
          </div>
          <p>
            {`${t('reports.empty')}`}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={`${styles.container} ${customStyles.marginTop}`}>
      <div className={styles.content}>
        <div className={styles.row}>
          <h2 className={styles.title}>
            {t('reports.recent')}
          </h2>
          <Link
            className={styles.link}
            to={`/${societyId}/rapport`}
          >
            {t('all')}
          </Link>
        </div>
        <ul className={customStyles.list}>
          {
            reports.map((report) => (
              <Link
                key={report.id}
                to={`${societyId}/rapport/${report.id}`}
                className={customStyles.link}
              >
                <div className={customStyles.linkContent}>
                  <p className={customStyles.title}>
                    { report.title }
                  </p>
                  <p className={customStyles.period}>
                    {t('reports.period', { begin: report.period.begin, end: report.period.end })}
                  </p>
                </div>
              </Link>
            ))
          }
        </ul>
      </div>
    </div>
  );
}
