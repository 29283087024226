import { keysToSnake } from '../../../../utils/converter';

// eslint-disable-next-line import/prefer-default-export
export const operationToApi = (values) => keysToSnake({
  date: values.date,
  fromUserId: values.from?.shareholderId || values.fromUserId,
  historyId: values.historyId,
  name: values.name,
  quantity: values.quantity,
  societyId: values.societyId,
  status: values.status,
  toUserId: values.to?.shareholderId || values.toUserId,
  type: values.type,
});
