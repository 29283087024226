import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Admin from './Admin';
import { fetchSocietyData } from '../../services/store/features/society/actions';

const mapStateToProps = (state) => ({
  society: state.society,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSocietyData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
