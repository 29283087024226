import { combineReducers } from 'redux';
import societyReducer from './features/society/reducer';
import documentsReducer from './features/documents/reducer';
import eventsReducer from './features/events/reducer';
import shareholdersReducer from './features/shareholders/reducer';
import popupReducer from './features/popup/reducer';
import contactsReducer from './features/contacts/reducer';
import reportReducer from './features/report/reducer';
import chatReducer from './features/chat/reducer';
import userReducer from './features/user/reducer';
import operationsReducer from './features/operations/reducer';
import historyReducer from './features/history/reducer';
import assemblyReducer from './features/assembly/reducer';
import rolesReducer from './features/roles/reducer';
import toastsReducer from './features/toasts/reducer';
import onboardingReducer from './features/onboarding/reducer';

const rootReducer = combineReducers({
  society: societyReducer,
  documents: documentsReducer,
  events: eventsReducer,
  shareholders: shareholdersReducer,
  contacts: contactsReducer,
  popup: popupReducer,
  reports: reportReducer,
  chat: chatReducer,
  user: userReducer,
  operations: operationsReducer,
  history: historyReducer,
  assembly: assemblyReducer,
  roles: rolesReducer,
  toasts: toastsReducer,
  onboarding: onboardingReducer,
});

export default rootReducer;
