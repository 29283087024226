import axios from '../services/clients/axiosClient';

const fetchMedia = async (callback, placeholder, src) => {
  if (!src) return;
  try {
    const path = new URL(src).pathname;
    const { data } = await axios.get(path, { responseType: 'blob' });
    callback(window.URL.createObjectURL(data));
  } catch (err) {
    callback(placeholder);
  }
};

export default fetchMedia;
