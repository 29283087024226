import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DocumentsPreview from './DocumentsPreview';
import { fetchDocumentsData, setSelectedDocument } from '../../../services/store/features/documents/actions';
import { getMostRecentFilesSelection } from '../../../services/store/features/documents/selectors';

const mapStateToProps = (state) => ({
  societyId: state.society.id,
  userId: state.user.id,
  documents: getMostRecentFilesSelection(state.documents.documents).slice(0, 3),
  status: state.documents.status,
  error: state.documents.error,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchDocumentsData,
    setSelectedDocument,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPreview);
