import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/extensions
import SettingsInvestors from './SettingsInvestors.jsx';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';

const mapStateToProps = (state) => ({
  status: state.shareholders.status,
  investors: [...state.shareholders.shareholders, ...(state.shareholders?.holding || [])],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllShareholders }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsInvestors);
