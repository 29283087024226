import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Settings from './Settings';
import { updateSocietyData, fetchSocietyData, setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  society: state.society,
  user: state.user,
  isInvestor: state.user.isInvestor,
  userStatus: state.user.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateSocietyData,
    fetchSocietyData,
    fetchUserInfo,
    setSocietyId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
