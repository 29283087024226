import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import InputDate from '../../library/Input/InputDate/InputDate';
import Checkbox from '../../library/Input/Checkbox/Checkbox';
import Button from '../../library/Button/Button';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import * as statuses from '../../../services/store/states';

import styles from './ReportEditionPlanification.module.css';

export default function ReportEditionPlanification({
  societyId, publication, status, actions,
}) {
  const { t } = useTranslation();
  const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const [date, setDate] = useState({
    day: (publication) ? publication.split('/')[0] : today.getDate(),
    month: (publication) ? publication.split('/')[1] : today.getMonth() + 1,
    year: (publication) ? publication.split('/')[2] : 1900 + today.getYear(),
  });
  const [shouldPublishedNow, setShouldPublishedNow] = useState(!(publication));
  const [shouldNotify] = useState(false);

  const getButtonLabel = () => {
    if (status === statuses.LOADING) {
      return (shouldPublishedNow) ? t('reports.plan.form.publish.loading') : t('reports.plan.form.plan.loading');
    }
    return (shouldPublishedNow) ? t('reports.plan.form.publish.button') : t('reports.plan.form.plan.button');
  };

  const getDates = () => ((date.month < 10 && `${date.month}`.length < 2)
    ? `${((`${date.day}`.length < 2) && (date.day < 10)) ? 0 : ''}${date.day}/0${date.month}/${date.year}`
    : `${((`${date.day}`.length < 2) && (date.day < 10)) ? 0 : ''}${date.day}/${date.month}/${date.year}`);

  const handleDateBlur = () => {
    setDate((state) => {
      if (state.month !== '' && state.month < 10 && `${date.month}`.length < 2) {
        return ({
          ...state,
          month: `0${state.month}`,
        });
      }
      return state;
    });
  };

  if (status === statuses.SUCCESS) {
    if (shouldPublishedNow) {
      return (
        <section className={styles.success}>
          <SuccessAnimation />
          <p className={styles.title}>
            {t('reports.plan.published.title')}
          </p>
          <p className={styles.description}>
            {t('reports.plan.published.description')}
          </p>
          <Link
            to={`/${societyId}/rapport`}
            className={styles.button}
            onClick={() => actions.closePopUp()}
          >
            {t('reports.plan.published.button')}
          </Link>
        </section>
      );
    }
    return (
      <section className={styles.success}>
        <SuccessAnimation />
        <p className={styles.title}>
          {t('reports.plan.planned.title')}
        </p>
        <p className={styles.description}>
          {t('reports.plan.planned.description')}
          {` ${date.day}/${date.month}/${date.year}`}
        </p>
        <Link
          to={`/${societyId}/rapport/planification`}
          className={styles.button}
          onClick={() => actions.closePopUp()}
        >
          {t('reports.plan.planned.button')}
        </Link>
      </section>
    );
  }
  return (
    <section>
      <main className={styles.content}>
        <Checkbox
          label={t('reports.plan.form.publishNow')}
          checked={shouldPublishedNow}
          onChange={(bool) => setShouldPublishedNow(bool)}
          className={styles.publication}
        />
        {
          (!shouldPublishedNow)
          && (
            <InputDate
              label={t('reports.plan.form.date')}
              className={styles.date}
              day={date.day}
              month={date.month}
              year={date.year}
              onChange={(value) => setDate(value)}
              onBlur={handleDateBlur}
              includeDay
            />
          )
        }
        {
          /*
            <Checkbox
              label={t('reports.plan.form.notification')}
              checked={shouldNotify}
              onChange={(bool) => setShouldNotify(bool)}
            />
           */
        }
      </main>
      <footer className={styles.footer}>
        <Button
          type="custom"
          label={t('cancel')}
          className={styles.tertiaryButton}
          onButtonClick={() => actions.closePopUp()}
        />
        {
          (publication)
            ? (
              <Button
                type="secondary"
                label={getButtonLabel()}
                onButtonClick={() => actions.publishReport(getDates(), shouldNotify, true)}
                disabled={new Date(date.year, date.month, date.day) < today}
              />
            )
            : (
              <Button
                type="secondary"
                label={getButtonLabel()}
                onButtonClick={() => actions.publishReport(getDates(), shouldNotify)}
                disabled={new Date(date.year, date.month - 1, date.day) < today}
              />
            )
        }
      </footer>
    </section>
  );
}
