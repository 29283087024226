import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DocumentsByFolder from '../DocumentsByFolder/DocumentsByFolder';
import {
  fetchUserInfo, updateDocument, setFolder, setSelectedDocument, setUserDocumentSort,
} from '../../services/store/features/user/actions';
import { openPopUp, closePopUp } from '../../services/store/features/popup/actions';
import { deleteUserDocument } from '../../services/store/features/documents/actions';
import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';
import { getDocumentsByFolderProps } from '../../services/store/features/documents/selectors';

const mapStateToProps = (state) => getDocumentsByFolderProps(state, 'user');

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchDocumentsData: fetchUserInfo,
    updateDocument,
    setFolder,
    fetchAllShareholders,
    setSort: setUserDocumentSort,
    openPopUp,
    closePopUp,
    deleteDocument: deleteUserDocument,
    setSelectedDocument,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsByFolder);
