import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Shareholders from './Shareholders';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  status: state.society.status,
  societyId: state.society.id,
  user: state.user.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSocietyId,
    fetchUserInfo,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shareholders);
