import React from 'react';

import RouteConfig from './services/routes/routes';
import { SocketContext, socket } from './services/socket/socket';

/* eslint-disable import/no-unresolved */
import 'library/style/normalize.css';
import 'library/style/reset.css';
import 'library/style/typography.css';
import 'library/style/animation.css';
import Toasts from './components/library/Toasts';
/* eslint-enable import/no-unresolved */

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <RouteConfig />
        <Toasts />
      </div>
    </SocketContext.Provider>
  );
}

export default App;
