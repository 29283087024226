import { connect } from 'react-redux';

// eslint-disable-next-line import/extensions
import DocumentAssembly from './DocumentAssembly.jsx';

const mapStateToProps = (state) => ({
  societyId: state.society.id,
});

export default connect(mapStateToProps)(DocumentAssembly);
