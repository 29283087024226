import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons';

import * as statuses from 'services/store/states';
import Checkbox from 'components/library/Input/Checkbox/Checkbox';
import Button from 'components/library/Button/Button';
import SuccessAnimation from 'components/SuccessAnimation/SuccessAnimation';

import styles from './ShareholdersExport.module.css';

export default function ShareholdersExport({ status, exportLink, actions }) {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [fields, setFields] = useState({
    firstName: false,
    lastName: false,
    mail: false,
    shares: false,
    birthday: false,
    capital: false,
    phone: false,
    address: false,
    identityPaper: false,
    homeCertificate: false,
    engagement: false,
  });

  useEffect(() => {
    actions.resetExportStatus();
  }, []);

  const handleSelectAll = (bool) => {
    if (bool) {
      setFields({
        firstName: true,
        lastName: true,
        mail: true,
        shares: true,
        birthday: true,
        capital: true,
        phone: true,
        address: true,
        identityPaper: true,
        homeCertificate: true,
        engagement: true,
      });
    } else {
      setFields({
        firstName: false,
        lastName: false,
        mail: false,
        shares: false,
        birthday: false,
        capital: false,
        phone: false,
        address: false,
        identityPaper: false,
        homeCertificate: false,
        engagement: false,
      });
    }
    setSelectAll(bool);
  };

  if (status === statuses.LOADING) {
    return (
      <section>
        <main className={styles.loading}>
          <FontAwesomeIcon icon={faSpinner} spin />
          <p>{ t('shareholders.export.loading') }</p>
        </main>
      </section>
    );
  }
  if (status === 'success') {
    return (
      <section>
        <main
          className={`${styles.content} ${styles.success}`}
        >
          <SuccessAnimation />
          <h3>
            { t('shareholders.export.download.title') }
          </h3>
          <a
            href={exportLink}
            download="actionnaires"
            className={styles.successButton}
            target="_blank"
            rel="noreferrer"
          >
            { t('shareholders.export.download.button') }
          </a>
        </main>
      </section>
    );
  }
  return (
    <section>
      <main className={styles.content}>
        {
          (status === 'error-export')
          && (
            <div className={styles.error}>
              <div className={styles.row}>
                <FontAwesomeIcon
                  className={styles.errorIcon}
                  icon={faBan}
                />
                <h3 className={styles.errorTitle}>
                  { t('shareholders.export.error.title') }
                </h3>
              </div>
              <p className={styles.errorDescription}>
                { t('shareholders.export.error.description') }
              </p>
            </div>
          )
        }
        <Checkbox
          label={t('shareholders.export.form.selectAll')}
          onChange={handleSelectAll}
          checked={selectAll}
        />
        <h3>
          { t('shareholders.export.form.fields') }
        </h3>
        <div className={styles.grid}>
          <Checkbox
            label={t('firstName')}
            onChange={(bool) => setFields(
              (state) => ({
                ...state, firstName: bool,
              }),
            )}
            checked={fields.firstName}
          />
          <Checkbox
            label={t('shareholders.edit.mail')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, mail: bool }),
            )}
            checked={fields.mail}
          />
          <Checkbox
            label={t('shareholders.edit.share')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, shares: bool }),
            )}
            checked={fields.shares}
          />
          <Checkbox
            label={t('lastName')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, lastName: bool }),
            )}
            checked={fields.lastName}
          />
          <Checkbox
            label={t('shareholders.edit.birthday')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, birthday: bool }),
            )}
            checked={fields.birthday}
          />
          <Checkbox
            label={t('shareholders.edit.capital')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, capital: bool }),
            )}
            checked={fields.capital}
          />
          <Checkbox
            label={t('shareholders.edit.phone')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, phone: bool }),
            )}
            checked={fields.phone}
          />
          <Checkbox
            label={t('shareholders.edit.adress')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, address: bool }),
            )}
            checked={fields.address}
          />
        </div>
        <h3>
          {t('shareholders.export.form.attached.title')}
        </h3>
        <div className={styles.grid}>
          <Checkbox
            label={t('shareholders.export.form.attached.identity')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, identityPaper: bool }),
            )}
            checked={fields.identityPaper}
          />
          <Checkbox
            label={t('shareholders.export.form.attached.home')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, homeCertificate: bool }),
            )}
            checked={fields.homeCertificate}
          />
          <Checkbox
            label={t('shareholders.export.form.attached.souscription')}
            onChange={(bool) => setFields(
              (state) => ({ ...state, engagement: bool }),
            )}
            checked={fields.engagement}
          />
        </div>
      </main>
      <footer className={styles.footer}>
        <Button
          type="custom"
          label={t('cancel')}
          className={styles.tertiaryButton}
          onButtonClick={actions.closePopUp}
        />
        <Button
          type="secondary"
          label={t('shareholders.export.button')}
          onButtonClick={() => actions.getShareholdersExportLink(fields)}
        />
      </footer>
    </section>
  );
}
