const getProjectId = (value) => {
  const url = value.split('?')[0];
  const splitURL = url.split('/');
  // remove '' element
  const lastElement = splitURL.pop();
  // get the id
  if (lastElement !== '') {
    return lastElement;
  }
  return splitURL[splitURL.length - 1];
};

export default getProjectId;
