import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import DocumentsSearchbar from '../../components/Documents/DocumentsSearchbar';
import DocumentsSelection from '../../components/Documents/DocumentsSelection/DocumentsSelection';
import DocumentsFolders from '../../components/Documents/DocumentsFolder/DocumentsFolder';
import DocumentsTable from '../../components/Documents/DocumentsTable/DocumentsTable';
import DocumentsVisualisation from '../../components/Documents/DocumentsVisualisation';
import DocumentUpload from '../../components/Documents/DocumentUpload';
import DocumentRequest from '../../components/Documents/DocumentsRequest';
import Button from '../../components/library/Button/Button';
import {
  getMostRecentFilesSelection,
  getSortedDocumentsByQuery,
} from '../../services/store/features/documents/selectors';
import * as statuses from '../../services/store/states';

import { downloadUserDocuments } from '../../services/clients/document';
import styles from './Documents.module.css';

export default function Documents({
  sort, query, status, documents, favorites, selectedDocument,
  folders, societyId, userId, actions, roles, domain, isAuthorized,
  isUserDocument, isRelatedDocument,
}) {
  const { t } = useTranslation();
  const { society, userId: user } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!userId) {
      actions.fetchUserInfo(user, society);
    }
  }, []);

  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchDocumentsData();
    }
  }, [documents, societyId]);

  const handlePopUp = () => {
    actions.openPopUp(
      t('documents.request'),
      t('documents.requestDescription'),
      (<DocumentRequest />),
    );
  };

  const handleAddPopUp = () => {
    actions.openPopUp(
      t('documents.add.title'),
      t('documents.add.description'),
      (<DocumentUpload domain={domain} actions={actions} />),
    );
  };

  const handleDownloadAll = async () => {
    try {
      const { data } = await downloadUserDocuments(societyId);
      const url = new Blob([data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.zip');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className={styles.documents}>
      <Header
        title="Documents"
        searchbar={
          (
            <>
              <DocumentsSearchbar placeholder={t('documents.search')} />
              {
                isAuthorized
                && (
                  <Button
                    label={t('documents.add.button')}
                    className={`${styles.button} ${styles.add}`}
                    onButtonClick={handleAddPopUp}
                  />
                )
              }
            </>
          )
        }
        button={
          {
            label: t('documents.request'),
            action: handlePopUp,
            style: styles.button,
          }
        }
      />
      <main className={styles.content}>
        { (selectedDocument) && <DocumentsVisualisation selectedDocument={selectedDocument} />}
        {
          (query === '')
          && (
          <div className={styles.wrapper}>
            <DocumentsSelection
              title={t('documents.recent')}
              documents={getMostRecentFilesSelection(documents)}
              favorites={favorites}
              selectDocument={actions.setSelectedDocument}
              handleChange={actions.updateDocument}
            />
          </div>
          )
        }
        {
          (query === '')
          && (folders)
          && (
            <DocumentsFolders
              folders={folders}
              isLoading={!documents || documents.length === 0}
            />
          )
        }
        <DocumentsTable
          documents={getSortedDocumentsByQuery(documents, query, sort)}
          favorites={favorites}
          updateDocumentById={actions.updateDocument}
          deleteDocumentById={actions.deleteDocument}
          sort={actions.setSort}
          selectDocument={actions.setSelectedDocument}
          roles={roles}
          isAuthorized={isAuthorized}
          actions={actions}
          isRelatedDocument={isRelatedDocument}
        />
        {
          (isUserDocument)
          && (
            <Button
              label={t('documents.download')}
              className={`${styles.button} ${styles.add} ${styles.download}`}
              onButtonClick={handleDownloadAll}
            />
          )
        }
      </main>
    </section>
  );
}
