import formatDate from '../../../../utils/formatDate';
import { getShareholderShare } from '../shareholders/utils';
import { createNewHistory } from '../../../clients/clientAPI';
import { historyToApi } from './adapter';

// eslint-disable-next-line import/prefer-default-export
export const sortByDate = (array) => {
  const newArray = array;
  newArray?.sort((a, b) => {
    const dateA = formatDate(a?.date?.begin);
    const dateB = formatDate(b?.date?.begin);
    if (dateA - dateB === 0) {
      return a.id - b.id;
    }
    return dateA - dateB;
  });
  return newArray;
};

export async function createHistory(id, history, creation, selectedShareholders, operationStatus) {
  const sharesNumber = selectedShareholders
    .filter((shareholder) => shareholder.societies.includes(id))
    .reduce((acc, curr) => acc + getShareholderShare(curr, id, !!curr.siren)
        + (curr?.buy || 0) - (curr?.sell || 0), 0);
  const newHistory = {
    ...history[0],
    ...creation,
    societyId: id,
    date: {
      begin: `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year}`,
    },
    shareholders: [...new Set(
      selectedShareholders
        .filter((i) => (i.buy !== 0 || i.sell !== 0)
          || (getShareholderShare(i, id, !!i.siren) > 0))
        .map((i) => i.id),
    )],
    parameters: {
      ...creation.parameters,
      sharesNumber,
      valorisation: creation?.parameters?.unitSharePrice * sharesNumber,
      sharesPrice: creation?.parameters?.unitSharePrice,
    },
    operationStatus,
    holding: window.location.href.includes('holding'),
  };
  const newHistoryResponse = await createNewHistory(historyToApi(newHistory));
  return {
    ...newHistory,
    ...newHistoryResponse,
    id: newHistoryResponse.data.id,
  };
}
