export const toCamel = (s) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export const toSnake = (s) => {
  if (s === s.toUpperCase()) return s;
  return s.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
};

export const toHuman = (s) => {
  if (s === s.toUpperCase()) return s;
  return s.replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`).replaceAll('_', ' ').replaceAll('.', ' - ');
};

const isArray = (a) => Array.isArray(a);

export const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const flattenData = (values) => values.reduce((acc, value) => {
  if (isObject(value)) {
    return [...acc, ...flattenData(Object.values(value))];
  }
  return [...acc, value];
}, []);

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
};

export const keysToSnake = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toSnake(k)] = keysToSnake(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map((i) => keysToSnake(i));
  }

  return o;
};

export const capitalize = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;
