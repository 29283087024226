import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCamera, faSpinner, faPen,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '../../Button/Button';
import styles from './InputFile2.module.css';

export default function InputFileLoading({
  label, inputRef, onFilesAdded, cameraRef, openCamera,
  openFileDialog, onDrop, onDragLeave, onDragOver, hover,
  cancel,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <p className={styles.label}>
        { label }
      </p>
      <div
        className={`${styles.onMobile}`}
      >
        <input
          type="file"
          accept=".jpg,.jpeg,.png,capture=camera"
          ref={inputRef}
          onChange={onFilesAdded}
          hidden
        />
        <input
          type="file"
          id="imageFile"
          capture="environment"
          accept=".jpg,.jpeg,.png,capture=camera"
          ref={cameraRef}
          onChange={onFilesAdded}
          hidden
        />
        <div className={styles.row}>
          <Button
            icon={<FontAwesomeIcon className={styles.icon} icon={faCamera} />}
            label={t('library.file.picture')}
            className={styles.button}
            type="secondary"
            onButtonClick={openCamera}
          />
          <Button
            label={t('library.file.select')}
            variant="outline"
            className={styles.buttonFile}
            type="secondary"
            onButtonClick={openFileDialog}
          />
        </div>
      </div>
      <div
        className={`${styles.desktop} ${styles.dropfile}  ${(hover) ? styles.hover : ''}`}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <div className={`${styles.illustration} ${(hover) ? styles.hover : ''}`}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faSpinner}
            spin
          />
        </div>
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          onChange={onFilesAdded}
          hidden
        />
        <input
          type="file"
          id="imageFile"
          capture="environment"
          accept="image/*"
          ref={cameraRef}
          hidden
        />
      </div>
      <div className={`${styles.desktop} ${styles.hintColumn}`}>
        <Button
          type="ghost"
          label={t('library.file.modify')}
          icon={<FontAwesomeIcon icon={faPen} />}
          className={styles.action}
          onButtonClick={cancel}
        />
      </div>
    </div>
  );
}
