import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CKEditor from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

import Header from '../../components/Header/Header';
import InputText from '../../components/library/Input/InputText/InputText';
import InputDate from '../../components/library/Input/InputDate/InputDate';
import Button from '../../components/library/Button/Button';
import ReportEditionPlanification from '../../components/Report/ReportEditionPlanification';

import links from '../Report/navigation';
import styles from './ReportEdition.module.css';
import ReportEditionPreview from './ReportEditionPreview';

export default function ReportEdition({
  report, societyId, roles, actions,
}) {
  const [showPreview, setShowPreview] = useState(false);
  const [period, setPeriod] = useState({
    begin: { day: '', month: '', year: '' },
    end: { day: '', month: '', year: '' },
  });

  const { society, id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
  }, []);

  useEffect(() => {
    if (id && societyId) {
      actions.setSelectedReport(id);
    } else {
      actions.resetActiveReport();
    }
  }, [societyId]);

  useEffect(() => {
    if (id && report?.period && typeof report?.period?.begin === 'string') {
      const [beginDay, beginMonth, beginYear] = report.period.begin.split('/');
      const [endDay, endMonth, endYear] = report.period.end.split('/');
      setPeriod({
        begin: { day: beginDay, month: beginMonth, year: beginYear },
        end: { day: endDay, month: endMonth, year: endYear },
      });
    }
  }, [report]);

  const isDateValid = () => {
    let isValid = true;
    const { begin, end } = period;
    if (begin && end) {
      const keys = ['day', 'month', 'year'];
      keys.forEach((key) => {
        if (begin[key]?.trim() === '' || end[key]?.trim() === '') {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }
    return isValid;
  };

  const handleDateChange = (value, key) => {
    setPeriod((state) => ({
      ...state,
      [key]: value,
    }));
    actions.editReport({
      period: {
        ...report.period,
        [key]: value,
      },
    });
  };

  const handleDateBlur = (value, key) => {
    setPeriod((state) => ({
      ...state,
      [key]: value,
    }));
    actions.editReport({
      period: {
        ...report.period,
        [key]: value,
      },
    });
  };

  const handlePlanification = () => {
    actions.openPopUp(
      t('reports.edit.plan.title'),
      t('reports.edit.plan.description'),
      <ReportEditionPlanification
        publication={(id) && report.publicationDate}
      />,
    );
  };

  const openPreview = () => setShowPreview(true);

  const handleCkeditorChange = (event, editor, key) => {
    if (editor.getData() !== '') actions.editReport({ [key]: editor.getData() });
    else actions.editReport({ [key]: '<p></p>' });
  };

  const handleReportChange = (e) => {
    if (e.target.type === 'number') {
      actions.editReport({ [e.target.name]: parseInt(e.target.value, 10) });
    } else {
      actions.editReport({ [e.target.name]: e.target.value });
    }
  };

  if (!societyId) return null;

  if (showPreview) {
    return (
      <ReportEditionPreview
        roles={roles}
        societyId={societyId}
        report={report}
        setShowPreview={setShowPreview}
        handlePlanification={handlePlanification}
      />
    );
  }

  return (
    <section className={styles.edition}>
      <Header
        title={t('reports.report')}
        navigation={links(t, societyId)}
        roles={roles}
      />
      <main className={styles.content}>
        <h2>{t('reports.new')}</h2>
        <p>
          {' ( ' }
          <span className={styles.required}>*</span>
          {` ) ${t('reports.edit.required')}`}
        </p>
        <InputText
          label="Titre"
          name="title"
          valueInput={report?.title || ''}
          onTextChange={handleReportChange}
          required
        />
        <p className={styles.label}>
          <span className={styles.required}>
            {' * '}
          </span>
          {t('reports.edit.activity')}
        </p>
        <div className={styles.row}>
          <p>
            {t('reports.edit.from')}
          </p>
          <InputDate
            day={period?.begin?.day}
            month={period?.begin?.month}
            year={period?.begin?.year}
            onChange={(value) => handleDateChange(value, 'begin')}
            onBlur={(value) => handleDateBlur(value, 'begin')}
            includeDay
          />
          <p>
            {t('reports.edit.to')}
          </p>
          <InputDate
            day={period?.end?.day}
            month={period?.end?.month}
            year={period?.end?.year}
            onChange={(value) => handleDateChange(value, 'end')}
            onBlur={(value) => handleDateBlur(value, 'end')}
            includeDay
          />
        </div>
        <InputText
          label={t('reports.capital')}
          type="number"
          name="turnover"
          valueInput={report?.turnover || ''}
          onTextChange={handleReportChange}
        />
        <InputText
          label={t('reports.treasury')}
          type="number"
          name="treasury"
          valueInput={report?.treasury || ''}
          onTextChange={handleReportChange}
        />
        <InputText
          label={t('reports.expense')}
          type="number"
          name="expenses"
          valueInput={report?.expenses || ''}
          onTextChange={handleReportChange}
        />
        <p>
          {t('reports.comment')}
        </p>
        <CKEditor
          editor={BalloonEditor}
          data={report?.general || '<p></p>'}
          onChange={(event, editor) => handleCkeditorChange(event, editor, 'general')}
        />
        <p>
          {t('reports.good')}
        </p>
        <CKEditor
          editor={BalloonEditor}
          data={report?.good || '<p></p>'}
          onChange={(event, editor) => handleCkeditorChange(event, editor, 'good')}
        />
        <p>
          {t('reports.bad')}
        </p>
        <CKEditor
          editor={BalloonEditor}
          data={report?.bad || '<p></p>'}
          onChange={(event, editor) => handleCkeditorChange(event, editor, 'bad')}
        />
        <p>
          {t('reports.help')}
        </p>
        <CKEditor
          editor={BalloonEditor}
          data={report?.help || '<p></p>'}
          onChange={(event, editor) => handleCkeditorChange(event, editor, 'help')}
        />
      </main>
      <div className={styles.buttonGroup}>
        <Button
          variant="custom"
          label={t('reports.edit.visualisation')}
          className={styles.button}
          onButtonClick={openPreview}
        />
        <Button
          label={t('reports.edit.plan.title')}
          onButtonClick={handlePlanification}
          disabled={(report?.title?.trim() === '') || !isDateValid()}
        />
      </div>
    </section>
  );
}
