import * as types from './types';
import {
  sendMail,

} from '../../../clients/clientAPI';
import * as statuses from '../../states';
import { addToast } from '../toasts/actions';
import { formatAssembly, formatAssemblies, formatAssemblyForBack } from './adapter';
import {
  createPlannedAssembly, deletePlannedAssembly,
  deleteResolution, getArchivedAssembly, getPlannedAssembly,
  sendReminderAssembly,
  sendResponseAssemblyInvitation, updatePlannedAssemblyById,
  voteResolution,
} from '../../../clients/assembly';

export function fetchAssemblyArchives() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({
        type: types.FETCH_ARCHIVED_REQUEST,
      });
      try {
        const { data } = await getArchivedAssembly(id);
        dispatch({
          type: types.FETCH_ARCHIVED_SUCCESS,
          payload: formatAssemblies(data),
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_ARCHIVED_FAIL,
          payload: err,
        });
      }
    }
  };
}

export function fetchAssemblyPlan(societyId) {
  return async (dispatch, getState) => {
    let society = societyId;
    if (!societyId) {
      society = getState().society.id;
    }
    dispatch({
      type: types.FETCH_PLAN_REQUEST,
    });
    try {
      const { data } = await getPlannedAssembly(society);
      dispatch({
        type: types.FETCH_PLAN_SUCCESS,
        payload: formatAssemblies(data),
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_PLAN_FAIL,
        payload: err,
      });
    }
  };
}

export function deleteAssemblyPlanById(id) {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_PLAN_REQUEST,
    });
    try {
      await deletePlannedAssembly(id);
      dispatch({
        type: types.DELETE_PLAN_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_PLAN_FAIL,
        payload: err,
      });
    }
  };
}

export function createAssembly(values) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    dispatch({
      type: types.CREATE_ASSEMBLY_REQUEST,
    });
    try {
      const assembly = formatAssemblyForBack({ ...values, societyId: id });
      const { data } = await createPlannedAssembly(assembly);
      dispatch({
        type: types.CREATE_ASSEMBLY_SUCCESS,
        payload: { ...assembly, id: data.id },
      });
    } catch (err) {
      dispatch({
        type: types.CREATE_ASSEMBLY_FAIL,
        payload: err,
      });
    }
  };
}

export function updateAssemblyPlanById(id, newAssembly) {
  return async (dispatch, getState) => {
    const { id: societyId } = getState().society;
    dispatch({
      type: types.UPDATE_PLAN_REQUEST,
    });
    try {
      const { data } = await updatePlannedAssemblyById(
        id,
        formatAssemblyForBack({ ...newAssembly, societyId }),
      );
      console.log(getState()?.assembly);
      const assembly = (getState()?.assembly?.plan?.list || []).map((assemblyPlanned) => (
        (assemblyPlanned.id === id)
          ? formatAssembly(data)
          : assemblyPlanned
      ));
      console.log('assembly: ', assembly);
      dispatch({
        type: types.UPDATE_PLAN_SUCCESS,
        payload: assembly,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: types.UPDATE_PLAN_FAIL,
        payload: err,
      });
    }
  };
}

export function signAssemblyPresenceById(id, presence) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_PLAN_REQUEST,
    });
    try {
      const selectedAssembly = getState().assembly.plan.list
        .filter((assembly) => assembly.id === Number.parseInt(id, 10))[0];
      if (!selectedAssembly) {
        throw new Error('L\'assemblée selectionnée n\'existe pas');
      }
      const newAssembly = {
        ...selectedAssembly,
        presence,
      };
      await updatePlannedAssemblyById(getState().society.id, id, newAssembly);
      const index = getState().assembly.plan.list
        .map((i) => i.id)
        .indexOf(id);
      const assembly = [
        ...getState().assembly.plan.list.slice(0, index),
        newAssembly,
        ...getState().assembly.plan.list.slice(index + 1),
      ];
      dispatch({
        type: types.UPDATE_PLAN_SUCCESS,
        payload: assembly,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_PLAN_FAIL,
        payload: err,
      });
    }
  };
}

export function deleteAssemblyResolution(id, assembly) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_RESOLUTION_REQUEST });
    try {
      await deleteResolution(id);
      dispatch({ type: types.DELETE_RESOLUTION_SUCCESS, payload: { id, assembly } });
    } catch (err) {
      dispatch({ type: types.DELETE_RESOLUTION_FAIL, payload: err });
    }
  };
}

export function sendInvitationResponse(id, response) {
  return async (dispatch, getState) => {
    try {
      const invitationResponse = {
        assemblyId: id,
        userEmail: getState().user.mail,
        presence: response,
      };
      const { data } = await sendResponseAssemblyInvitation(invitationResponse);
      if (data && !data.error) {
        dispatch({
          type: types.INVITATION_RESPONSE_SUCCESS,
          payload: invitationResponse,
        });
      } else {
        dispatch(addToast({
          type: statuses.FAIL,
          title: 'assembly.invitation.error',
          description: data?.error,
          shouldTranslate: true,
        }));
      }
    } catch (err) {
      dispatch(addToast({
        type: statuses.FAIL,
        title: 'assembly.invitation.error',
        description: `${err}`,
        shouldTranslate: true,
      }));
    }
  };
}

export function reminder(id) {
  return async (dispatch, getState) => {
    const assembly = getState().assembly.plan.list
      .find((item) => item.id === Number.parseInt(id, 10));
    const newAssembly = {
      ...assembly,
      status: statuses.LOADING,
      reminder: new Date(),
    };
    const newAssemblyList = [
      ...getState().assembly.plan.list
        .filter((item) => item.id !== Number.parseInt(id, 10)),
      newAssembly,
    ];
    dispatch({ type: types.UPDATE_REMINDER, payload: newAssemblyList });
    try {
      await sendReminderAssembly(id);
      const newAssemblySuccess = {
        ...assembly,
        status: 'success',
        hasSendReminder: true,
        reminder: new Date(),
      };
      const newAssemblyListSuccess = [
        ...getState().assembly.plan.list
          .filter((item) => item.id !== Number.parseInt(id, 10)),
        newAssemblySuccess,
      ];
      dispatch({
        type: types.UPDATE_REMINDER,
        payload: newAssemblyListSuccess,
      });
      window.setTimeout(() => {
        const newAssemblyReset = {
          ...assembly,
          status: 'success',
          hasSendReminder: false,
          reminder: new Date(),
        };
        const newAssemblyListReset = [
          ...getState().assembly.plan.list
            .filter((item) => item.id !== Number.parseInt(id, 10)),
          newAssemblyReset,
        ];
        dispatch({
          type: types.UPDATE_REMINDER,
          payload: newAssemblyListReset,
        });
      }, 3 * 1000);
    } catch (err) {
      const newAssemblyError = {
        ...assembly,
        status: 'failed',
        reminder: null,
        error: err,
      };
      const newAssemblyListError = [
        ...getState().assembly.plan.list
          .filter((item) => item.id !== Number.parseInt(id, 10)),
        newAssemblyError,
      ];
      dispatch({
        type: types.UPDATE_REMINDER,
        payload: newAssemblyListError,
      });
      dispatch(addToast({
        type: statuses.FAIL,
        title: 'assembly.reminder.error',
        description: `${err}`,
      }));
    }
  };
}

export function uploadReportById(id, report) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_PLAN_REQUEST,
    });
    try {
      const selectedAssembly = getState().assembly.plan.list
        .find((assembly) => assembly.id === Number.parseInt(id, 10));
      if (!selectedAssembly) {
        throw new Error('L\'assemblée selectionnée n\'existe pas');
      }
      const newAssembly = {
        ...selectedAssembly,
        report,
      };
      await updatePlannedAssemblyById(id, newAssembly);
      const assembly = [
        ...getState().assembly.plan.list
          .filter((currentAssembly) => currentAssembly.id !== newAssembly.id),
        newAssembly,
      ];
      dispatch({
        type: types.UPDATE_PLAN_SUCCESS,
        payload: assembly,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_PLAN_FAIL,
        payload: err,
      });
    }
  };
}

export function resetAssemblyCreation() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_ASSEMBLY_CREATION,
    });
  };
}

export function setActiveAssembly(id) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ASSEMBLY_SELECTED,
      payload: id,
    });
  };
}

export const getInvitations = (state) => {
  const { assembly, shareholders, history } = state;
  const activeAssemblyIndex = assembly.selected;
  if (!activeAssemblyIndex) return [];
  const activeAssembly = assembly.plan.list
    .find((i) => i.id === Number.parseInt(assembly?.selected, 10));
  if (!activeAssembly) return [];
  if (!activeAssembly.hasSentConvocation) return [];
  let { invitations } = activeAssembly;
  const activeHistory = history.history[0];
  let currentShareholders = shareholders.shareholders;
  if (activeHistory?.shareholders) {
    const shareholdersToInclude = activeHistory.shareholders;
    currentShareholders = currentShareholders
      .filter((shareholder) => (
        shareholdersToInclude.includes(shareholder.id) || shareholder.shares === 0));
  }
  invitations = invitations.map((guest) => {
    const currentGuest = currentShareholders
      .find((shareholder) => shareholder.email === guest.email);
    const sharesNumber = currentShareholders.reduce(
      (acc, curr) => acc + (curr.sharesHistories.find((h) => h?.id === activeHistory?.id)?.total),
      0,
    );
    if (currentGuest) {
      return ({
        id: guest.email,
        mail: guest.email,
        name: `${currentGuest.firstName} ${currentGuest.lastName}`,
        img: currentGuest.img,
        category: currentGuest.category || 'Inconnu',
        status: guest.presence,
        shares: currentGuest.history.find((h) => h?.id === activeHistory?.id)?.total,
        capital: (activeHistory)
          ? ((currentGuest.history.find((h) => h?.id === activeHistory?.id)?.total * 100)
              / sharesNumber).toFixed(2)
          : 0,
      });
    }
    return ({
      id: guest.email,
      mail: guest.email,
      name: guest.email,
      category: 'Inconnu',
      status: guest.presence,
      shares: 0,
    });
  }) || [];
  return invitations;
};

export function sendMessageReset() {
  return (dispatch) => {
    dispatch({
      type: types.SEND_MAIL_RESET,
    });
  };
}

export function sendMessage(from, object, body) {
  return async (dispatch) => {
    dispatch({
      type: types.SEND_MAIL_REQUEST,
    });
    try {
      await sendMail({ from, object, body });
      dispatch({
        type: types.SEND_MAIL_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: types.SEND_MAIL_ERROR,
      });
    }
  };
}

export function voteAssemblyResolution(votes, assemblyId) {
  return async (dispatch, getState) => {
    const { id } = getState().user;
    try {
      const votesPromise = votes
        .map((vote) => voteResolution({ resolutionId: vote.id, userId: id, vote: vote.response }));
      await Promise.all(votesPromise);
      dispatch({
        type: types.SEND_VOTE_SUCCESS,
        payload: { votes, id, assemblyId },
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export const getNewVotes = (votes, resolution, id) => {
  const currVote = votes.find((vote) => vote.id === resolution.id);
  if (currVote.response === 'no') {
    return {
      ...resolution,
      votes: {
        ...resolution.votes,
        no: resolution.votes.no?.indexOf(resolution.id) === -1
          ? [...resolution.votes.no, id]
          : resolution.votes.no,
        yes: resolution.votes.yes?.filter((vote) => vote.id !== id),
        white: (resolution.votes.white || []).filter((vote) => vote.id !== id),
        no_response: resolution.votes.no_response?.filter((vote) => vote.id !== id),
      },
    };
  }
  if (currVote.response === 'yes') {
    return {
      ...resolution,
      votes: {
        ...resolution.votes,
        yes: resolution.votes.yes?.indexOf(resolution.id) === -1
          ? [...resolution.votes.yes, id]
          : resolution.votes.yes,
        no: resolution.votes.no?.filter((vote) => vote.id !== id),
        white: (resolution.votes.white || []).filter((vote) => vote.id !== id),
        no_response: resolution.votes.no_response?.filter((vote) => vote.id !== id),
      },
    };
  }
  if (currVote.response === 'white') {
    return {
      ...resolution,
      votes: {
        ...resolution.votes,
        white: (resolution.votes.white || []).indexOf(resolution.id) === -1
          ? [...resolution.votes.white, id]
          : resolution.votes.white,
        yes: resolution.yes?.filter((vote) => vote.id !== id),
        no: resolution.no?.filter((vote) => vote.id !== id),
        no_response: resolution.no_response?.filter((vote) => vote.id !== id),
      },
    };
  }
  return resolution;
};
