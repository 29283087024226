import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReportPreview.module.css';

export default function ReportPreview({ report }) {
  const { t } = useTranslation();
  return (
    <main className={styles.content}>
      <h2>
        { report?.title }
      </h2>
      {
        (report?.period?.begin && report?.period?.end)
        && (
          <p className={styles.period}>
            {t('reports.period', { begin: report?.period?.begin, end: report?.period?.end })}
          </p>
        )
    }
      <div className={styles.row}>
        <div className={styles.cell}>
          <h3>
            {t('reports.capital')}
          </h3>
          <p>
            { report?.turnover.toLocaleString() }
          </p>
        </div>
        <div className={styles.cell}>
          <h3>
            {t('reports.expense')}
          </h3>
          <p>
            { report?.expenses.toLocaleString() }
          </p>
        </div>
        <div className={styles.cell}>
          <h3>
            {t('reports.treasury')}
          </h3>
          <p>
            { report?.treasury.toLocaleString() }
          </p>
        </div>
      </div>
      <div className={styles.section}>
        <h3>
          {t('reports.comment')}
        </h3>
        <div
          className={styles.ckeditor}
          dangerouslySetInnerHTML={{ __html: report?.general }}
        />
      </div>
      <div className={styles.section}>
        <h3>
          {t('reports.good')}
        </h3>
        <div
          className={styles.ckeditor}
          dangerouslySetInnerHTML={{ __html: report?.good }}
        />
      </div>
      <div className={styles.section}>
        <h3>
          {t('reports.bad')}
        </h3>
        <div
          className={styles.ckeditor}
          dangerouslySetInnerHTML={{ __html: report?.bad }}
        />
      </div>
      <div className={styles.section}>
        <h3>
          {t('reports.help')}
        </h3>
        <div
          className={styles.ckeditor}
          dangerouslySetInnerHTML={{ __html: report?.help }}
        />
      </div>
    </main>
  );
}
