import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EventsPopUp.module.css';
import ErrorAnimation from '../../Error/ErrorAnimation/ErrorAnimation';

export default function EventsPopUpFail() {
  const { t } = useTranslation();
  return (
    <section>
      <main className={`${styles.content} ${styles.error}`}>
        <ErrorAnimation />
        <p className={styles.title}>
          {t('error.title')}
        </p>
        <p className={styles.description}>
          {t('error.description')}
        </p>
      </main>
    </section>
  );
}
