import React from 'react';

import Button from 'components/library/Button/Button';
import OperationsShareholdersFormPerson from 'components/OperationsShareholdersForm/OperationsShareholdersFormPerson/OperationsShareholdersFormPerson';
import validate from 'components/OperationsShareholdersForm/OperationsShareholdersFormPerson/formValidation';

import useForm from '../../../hook/useForm';
import styles from './OperationsShareholderUpdate.module.css';

export default function OperationsShareholderUpdate({ actions, selectedShareholders }) {
  const submit = (data) => {
    const {
      firstName,
      lastName,
      mail,
      phone,
      birthday,
      shares,
      address,
      city,
      zipCode,
      oldId,
    } = data;

    const newShareholder = {
      oldId,
      firstName,
      lastName,
      mail,
      phone,
      birthday: `${birthday.day}/${birthday.month}/${birthday.year}`,
      shares: Number.parseInt(shares, 10),
      isHolding: false,
      address,
      city,
      zipCode,
      category: 'Investisseur',
      holdingName: 'default',
    };

    actions.addNewShareholders(newShareholder);
  };
  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submit, validate, selectedShareholders);

  return (
    <section className={styles.form}>
      <OperationsShareholdersFormPerson
        shareholder={values}
        errors={errors}
        actions={handleChange}
      />
      <Button
        label="Valider"
        onButtonClick={handleSubmit}
      />
    </section>
  );
}
