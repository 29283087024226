import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button/Button';
import Header from 'components/Header/Header';
import ReportPreview from 'components/Report/ReportPreview/ReportPreview';

import links from '../Report/navigation';
import styles from './ReportEdition.module.css';

export default function ReportEditionPreview({
  roles, societyId, report, setShowPreview, handlePlanification,
}) {
  const { t } = useTranslation();

  const close = () => setShowPreview(false);

  return (
    <section className={styles.edition}>
      <Header
        title={t('reports.report')}
        navigation={links(t, societyId)}
        roles={roles}
      />
      <ReportPreview report={report} />
      <div className={styles.buttonGroup}>
        <Button
          variant="custom"
          label={t('reports.edit.title')}
          className={styles.button}
          onButtonClick={close}
        />
        <Button
          label={t('reports.edit.plan.title')}
          onButtonClick={handlePlanification}
        />
      </div>
    </section>
  );
}
