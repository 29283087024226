import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Searchbar from '../../Searchbar/Searchbar';
import { setQuery } from '../../../services/store/features/documents/actions';

const mapStateToProps = (state) => ({
  query: state.documents.query,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);
