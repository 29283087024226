import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Messages from './Messages';
import { fetchUserInfo } from '../../services/store/features/user/actions';
import { setMostRecentConversationAsActiveRoom } from '../../services/store/features/chat/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';

const mapStateToProps = (state) => ({
  userStatus: state.user.status,
  userId: state.user.id,
  contactStatus: state.chat.contacts.length > 0,
  activeId: state.chat.activeId,
  shareholdersStatus: state.user.status,
  societyId: state.society.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchUserInfo,
    setMostRecentConversationAsActiveRoom,
    setSocietyId,
    fetchAllShareholders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
