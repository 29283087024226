import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders } from 'services/store/features/shareholders/actions';
import { addMultipleTransferOperation } from 'services/store/features/operations/actions';
import { fetchHistory } from 'services/store/features/history/actions';

import OperationsTransfertForm from './OperationsTransfertForm';

const mapStateToProps = (state) => ({
  shareholdersStatus: state.shareholders.status,
  shareholders: [
    ...(state.shareholders?.shareholders || []),
    ...(state.shareholders?.holding || []),
  ],
  operation: state.operations.creation,
  currentOperations: state.operations.operations,
  history: state.history,
  societyId: state.society.id,
  holdingId: state.society.idHolding,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    addNewOperation: addMultipleTransferOperation,
    fetchHistory,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsTransfertForm);
