import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventsPopUp from './EventsPopUp';
import { addEvent, resetEventStatus, updateEvent } from '../../../services/store/features/events/actions';
import { closePopUp } from '../../../services/store/features/popup/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';

const findContactsByQuery = (contacts, query) => {
  if (!query) return contacts;
  return contacts.filter((contact) => {
    const contactToStr = `${contact.firstName} ${contact.lastName} ${contact.mail}`;
    return (contactToStr.search(query.toLowerCase()) !== -1);
  });
};

const mapStateToProps = (state) => ({
  status: state.events.creationStatus,
  shareholders: findContactsByQuery(
    state.shareholders.shareholders.filter((shareholder) => shareholder.isInvestor === true),
    state.shareholders.query,
  ),
  statusShareholders: state.shareholders.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    addEvent,
    closePopUp,
    resetEventStatus,
    updateEvent,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsPopUp);
