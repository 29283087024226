import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useParams } from 'react-router-dom';

import Button from '../../components/library/Button/Button';
import Checkbox from '../../components/library/Input/Checkbox/Checkbox';
import InputText from '../../components/library/Input/TextArea/TextArea';
import SuccessAnimation from '../../components/SuccessAnimation/SuccessAnimation';
import * as statuses from '../../services/store/states';

import styles from './Feedback.module.css';

export default function Feedback({
  status, societyId, actions,
}) {
  const [feedback, setFeedback] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [isFeedbackValid, setIsFeedbackValid] = useState(true);
  const { t } = useTranslation();
  const { society } = useParams();

  const feedbackPlaceholder = {
    1: {
      type: 'Bug',
      title: t('feedback.bug.title'),
      placeholder: t('feedback.bug.placeholder'),
    },
    2: {
      type: 'Features',
      title: t('feedback.features.button'),
      placeholder: '',
    },
    3: {
      type: 'Other',
      title: t('feedback.other.button'),
      placeholder: '',
    },
  };

  useEffect(() => {
    actions.resetFeedback();
    if (!societyId) {
      actions.setSocietyId(society);
    }
  }, []);

  const handleSubmit = () => {
    if (feedbackText && feedbackText !== '') {
      const feedbackMessage = {
        type: feedbackPlaceholder[feedback].type,
        content: feedbackText,
      };
      actions.sendFeedback(feedbackMessage);
    } else {
      setIsFeedbackValid(false);
    }
  };
  return (
    <section className={styles.feedback}>
      <header className={styles.header}>
        <h1>
          Contact
        </h1>
        <div className={styles.separator} />
        <NavLink to={`/${societyId}/contacts`}>
          <Button
            type="custom"
            variant="ghost"
            label="Contacts"
            className={styles.passive}
          />
        </NavLink>
        <Button
          variant="ghost"
          label="Feedback"
        />
      </header>
      {
        (status === 'success')
          ? (
            <main className={`${styles.success} ${styles.content}`}>
              <SuccessAnimation />
              <h2>
                {t('feedback.success.title')}
              </h2>
              <Button
                label={t('feedback.bug.button')}
                onButtonClick={() => {
                  setFeedbackText('');
                  setFeedback(null);
                  actions.resetFeedback();
                }}
              />
            </main>
          )
          : (
            <main className={styles.content}>
              <h2>
                Feedback
              </h2>
              {
                (status === statuses.FAIL)
                && (
                  <div className={styles.error}>
                    <div className={styles.row}>
                      <FontAwesomeIcon icon={faBan} />
                      <h3>
                        {t('feedback.error.title')}
                      </h3>
                    </div>
                    <p>
                      {t('feedback.bug.description')}
                    </p>
                  </div>
                )
              }
              <p>
                {t('feedback.about')}
              </p>
              <div
                className={styles.row}
              >
                <Checkbox
                  label={t('feedback.bug.button')}
                  checked={(feedback === 1)}
                  onChange={() => setFeedback(1)}
                />
                <Checkbox
                  label={t('feedback.features.button')}
                  checked={(feedback === 2)}
                  onChange={() => setFeedback(2)}
                />
                <Checkbox
                  label={t('feedback.other.button')}
                  checked={(feedback === 3)}
                  onChange={() => setFeedback(3)}
                />
              </div>
              {
                (feedback)
                && (
                  <div className={styles.input}>
                    <InputText
                      label={feedbackPlaceholder[feedback].title}
                      valueInput={(feedbackText === '') ? feedbackPlaceholder[feedback].placeholder : feedbackText}
                      onTextChange={(e) => setFeedbackText(e.target.value)}
                      hint={(!isFeedbackValid) && t('feedback.error.noEmpty')}
                    />
                    <Button
                      type="secondary"
                      variant="fill"
                      label={(status === statuses.LOADING)
                        ? t('feedback.send.loading')
                        : t('feedback.send.button')}
                      icon={(status === statuses.LOADING)
                        && <FontAwesomeIcon icon={faSpinner} spin />}
                      onButtonClick={handleSubmit}
                    />
                  </div>
                )
              }
            </main>
          )
      }
    </section>
  );
}
