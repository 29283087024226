import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ShareholdersHeader from 'components/Shareholders/ShareholdersHeader/index';
import ShareholdersCapitalization from 'components/Shareholders/ShareholdersCapitalization';
import ShareholdersTable from 'components/Shareholders/ShareholdersTable';
import ShareholdersDescription from 'components/Shareholders/ShareholdersDescription';
import Loading from 'components/library/Loading';
import * as statuses from 'services/store/states';

import styles from './Shareholders.module.css';

export default function Shareholders({
  status, societyId, user, isHolding, actions,
}) {
  const { society, userId } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!user) {
      actions.fetchUserInfo(userId);
    }
  }, []);

  if ((status === statuses.SUCCESS) && societyId) {
    return (
      <section
        className={styles.shareholders}
      >
        <ShareholdersHeader id={society} isHolding={isHolding} />
        <ShareholdersCapitalization isHolding={isHolding} />
        <ShareholdersTable isHolding={isHolding} />
        <ShareholdersDescription isHolding={isHolding} />
      </section>
    );
  }

  return (
    <Loading />
  );
}
