import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Documents from './Documents';
import {
  fetchDocumentsData, deleteDocument,
  setSort, setSelectedDocument, uploadDocument, resetUploadDocument,
} from '../../services/store/features/documents/actions';
import { updateDocument, fetchUserInfo } from '../../services/store/features/user/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { closePopUp, openPopUp } from '../../services/store/features/popup/actions';
import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';
import { getDocumentProps } from '../../services/store/features/documents/selectors';

const mapStateToProps = (state) => getDocumentProps(state, 'documents');

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSocietyId,
    fetchDocumentsData,
    updateDocument,
    deleteDocument,
    uploadDocument,
    fetchAllShareholders,
    resetUploadDocument,
    openPopUp,
    closePopUp,
    setSort,
    setSelectedDocument,
    fetchUserInfo,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
