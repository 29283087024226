import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ShareholdersHeader from 'components/Shareholders/ShareholdersHeader/index';
import Button from 'components/library/Button/Button';
import OperationTable from 'components/OperationTable/OperationTable';
import OperationSearchbar from 'components/Operation/OperationSearchbar';
import * as statuses from 'services/store/states';

import downloadBlob from '../../utils/downloadBlob';
import styles from './MovementsRegister.module.css';

export default function MovementsRegister({
  status, shareholders, operations, query,
  societyId, userId, isHolding = false, actions,
}) {
  const { society, userId: id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!userId) {
      actions.fetchUserInfo(id);
    }
  }, []);

  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchAllOperations();
    }
    if (societyId && shareholders.status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, [societyId]);

  const formatOperation = (operation) => {
    const to = shareholders.list.find((s) => s?.id === operation.toUserId);
    const from = shareholders.list.find((s) => s?.id === operation.fromUserId);
    return {
      id: operation.id,
      date: operation.date,
      name: operation.name,
      type: operation.type,
      quantity: operation.quantity,
      to: `${to?.firstName || ''} ${to?.lastName || ''}`,
      from: `${from?.firstName || ''} ${from?.lastName || ''}`,
    };
  };

  const filteredOperations = operations.filter((operation) => ((isHolding)
    ? operation.holding === isHolding
    : operation.holding !== true));

  const exportOperations = () => {
    const formattedOperations = filteredOperations.map((operation) => formatOperation(operation));
    const operationRows = formattedOperations.map((operation) => Object.values(operation));
    const csvContent = `${Object.keys((formattedOperations[0])).join(';')}\n${operationRows.map((o) => o.join(';')).join('\n')}`;
    downloadBlob(csvContent, 'operations.csv');
  };

  return (
    <section className={styles.register}>
      <ShareholdersHeader id={societyId} isHolding={isHolding} />
      <main className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('operation.title')}
          </h2>
          <div className={styles.tools}>
            <OperationSearchbar
              placeholder={t('operation.search')}
              query={query}
              actions={actions}
            />
            <Link
              to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`}
              className={styles.button}
            >
              {t('operation.create')}
            </Link>
          </div>
        </div>
        <div className={styles.movement}>
          <p>
            <span className={styles.number}>
              { filteredOperations.length }
            </span>
            {t('operation.movement')}
          </p>
          {
            (filteredOperations.length > 0)
            && (
              <Button
                label={t('operation.export')}
                variant="ghost"
                onButtonClick={exportOperations}
              />
            )
          }
        </div>
        <OperationTable
          operations={filteredOperations}
          shareholders={shareholders.list}
        />
      </main>
    </section>
  );
}
