import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

import Button from '../../../components/library/Button/Button';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';
import * as statuses from '../../../services/store/states';

import styles from '../AssemblySummons/AssemblySummons.module.css';
import reportStyles from './AssemblyReport.module.css';

import template from './template';
import { generateOfficialReport } from '../../../services/clients/assembly';

export default function AssemblyReportEdition({
  assemblyStatus, report, actions, societyId,
}) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [body, setBody] = useState(template.default);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [hasSendToSignature, setHasSendToSignature] = useState(false);

  const textAreaRef = useRef();
  const { id } = useParams();
  const { t } = useTranslation();

  const includeTextToCurrentPos = (event, text, ref) => {
    ref.current.model.change((writer) => writer
      .insertText(text, ref.current.model.document.selection.getFirstPosition()));
  };

  const handleTemplateChange = (e) => {
    if (e.target.value === 'default') {
      setSelectedTemplate('default');
      setBody(template.default);
    }
    if (e.target.value === 'custom') {
      setSelectedTemplate('custom');
      setBody(template.custom);
    }
  };

  const submit = async () => {
    setStatus(statuses.LOADING);
    try {
      const { data } = await generateOfficialReport(societyId, { body });
      setBody(data.body);
      setStatus('success-generation');
    } catch (err) {
      setStatus('fail');
    }
  };

  const sign = () => {
    setHasSendToSignature(true);
    const newReport = {
      ...report,
      body,
    };
    actions.uploadReportById(id, newReport);
  };

  if (assemblyStatus === 'success' && hasSendToSignature) {
    return (
      <main className={reportStyles.success}>
        <SuccessAnimation />
        <h3>
          {t('assembly.minutes.succeed')}
        </h3>
        <Link
          to={`/${societyId}/assemblee/view/${id}`}
          className="button"
        >
          {t('assembly.minutes.backAssembly')}
        </Link>
      </main>
    );
  }

  if (status === statuses.LOADING) {
    return (
      <main className={reportStyles.loading}>
        <FontAwesomeIcon icon={faSpinner} spin />
        <p>
          {t('assembly.minutes.generationOngoing')}
        </p>
      </main>
    );
  }

  if (status === 'success-generation') {
    return (
      <main>
        <div className={`bounceIn ${reportStyles.notification}`}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={reportStyles.icon}
          />
          <p>
            {t('assembly.minutes.succeed-generation')}
          </p>
        </div>
        <div className={reportStyles.edition}>
          <div className={styles.textEditor}>
            <CKEditor
              editor={BalloonEditor}
              data={body}
              onChange={(event, editor) => {
                if (editor.getData() !== '') setBody(editor.getData());
                else setBody('<p></p>');
              }}
              onInit={(editor) => {
                textAreaRef.current = editor;
              }}
            />
          </div>
        </div>
        <div className={reportStyles.floatButton}>
          <Button
            label={t('assembly.minutes.sign')}
            onButtonClick={sign}
          />
        </div>
      </main>
    );
  }
  return (
    <main>
      <div className={reportStyles.edition}>
        <div className={reportStyles.form}>
          <p className={reportStyles.label}>Template</p>
          <select
            value={selectedTemplate}
            className={styles.dropdown}
            onChange={handleTemplateChange}
          >
            <option value="default">
              Default
            </option>
            <option value="custom">
              {t('assembly.minutes.custom')}
            </option>
          </select>
          <div className={styles.textEditor}>
            <CKEditor
              editor={BalloonEditor}
              data={body}
              onChange={(event, editor) => {
                if (editor.getData() !== '') setBody(editor.getData());
                else setBody('<p></p>');
              }}
              onInit={(editor) => {
                textAreaRef.current = editor;
              }}
            />
          </div>
        </div>
        <div className={reportStyles.info}>
          <div className={styles.description}>
            <h4>
              {t('assembly.minutes.title')}
            </h4>
            <p>
              {t('assembly.minutes.details')}
            </p>
          </div>
          <div>
            <p className={reportStyles.subtitle}>
              {t('assembly.summons.editor.variables')}
            </p>
            <p className={styles.hint}>
              {t('assembly.summons.editor.variablesHint')}
              {' {{ variable }}'}
            </p>
            <p className={`${reportStyles.label}`}>
              {t('assembly.summons.editor.society')}
            </p>
            <div className={styles.buttonGrid}>
              <Button
                label={t('assembly.summons.editor.societyName')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{firstName}}', textAreaRef)}
              />
              <Button
                label={t('assembly.summons.editor.address')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{address}}', textAreaRef)}
              />
              <Button
                label="Email"
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{mail}}', textAreaRef)}
              />
              <Button
                label={t('assembly.summons.editor.societyLastname')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{name}}', textAreaRef)}
              />
            </div>
            <p className={reportStyles.margin}>
              {t('assembly.summons.editor.address')}
            </p>
            <div className={styles.buttonGrid}>
              <Button
                label="Date"
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{date}}', textAreaRef)}
              />
              <Button
                label={t('assembly.summons.editor.place')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{place}}', textAreaRef)}
              />
              <Button
                label={t('assembly.summons.editor.time')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{time}}', textAreaRef)}
              />
              <Button
                label={t('assembly.minutes.presentList')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{present}}', textAreaRef)}
              />
              <Button
                label={t('assembly.minutes.resolutions')}
                variant="outline"
                size="small"
                onButtonClick={(e) => includeTextToCurrentPos(e, '{{resolutions}}', textAreaRef)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={reportStyles.floatButton}>
        <Button
          label={t('assembly.minutes.generate')}
          onButtonClick={submit}
        />
      </div>
    </main>
  );
}
