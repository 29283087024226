import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/Header/Header';
import links, { investorLinks } from './navigation';

export default function AssemblyHeader({ roles, societyId, isAuthorized }) {
  const { t } = useTranslation();
  if (!isAuthorized) {
    return (
      <Header
        title={t('assembly.title')}
        navigation={investorLinks(societyId, t)}
        button={null}
        roles={roles}
      />
    );
  }
  return (
    <Header
      title={t('assembly.title')}
      navigation={links(societyId, t)}
      button={null}
      roles={roles}
    />
  );
}
