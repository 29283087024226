const links = (societyId, t, isHolding, callback) => [
  {
    url: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}`,
    label: t('history.title'),
    exact: true,
  },
  {
    url: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/liste`,
    label: t('history.liste.title'),
    exact: true,
  },
  {
    url: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations`,
    label: t('operation.title'),
    exact: true,
    className: 'operation',
  },
  {
    url: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`,
    label: t('operation.add'),
    exact: true,
    className: 'addOperation',
    roles: ['POST-INVEST_OPERATION_CREATE', 'POST-INVEST_SHARES_HISTORY_CREATE'],
    onClick: callback,
  },
];

export const investorLinks = (societyId, t, isHolding) => [
  {
    url: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}`,
    label: t('history.title'),
    exact: true,
  },
];

export default links;
