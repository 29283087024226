import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// eslint-disable-next-line import/no-unresolved,import/extensions
import OperationsReduction from './OperationsReduction.js';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { setSocietyId } from '../../../services/store/features/society/actions';

const mapStateToProps = (state) => ({
  shareholdersStatus: state.shareholders.status,
  shareholders: state.shareholders.shareholders,
  societyId: state.society.id,
  userId: state.user.id,
  status: state.operations.creation.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    setSocietyId,
    // setSelectedShareholders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsReduction);
