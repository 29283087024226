import React from 'react';
import { useTranslation } from 'react-i18next';
import TableDropdown from '../../TableDropdown/TableDropdown';

import styles from '../ShareholdersTableRows.module.css';

export default function ShareholdersTableRowTitle({ categories, categoriesSelected, actions }) {
  const { t } = useTranslation();

  const handleClick = (category) => {
    if (categoriesSelected.includes(category)) {
      actions.setCategoryFilter(categoriesSelected.filter((c) => c !== category));
    } else {
      actions.setCategoryFilter([...categoriesSelected, category]);
    }
  };

  return (
    <div className={styles.tableRowTitle}>
      <div />
      <p className={styles.tableCellTitle}>
        {t('name')}
      </p>
      <div className={`${styles.tableCellTitle} ${styles.category} ${styles.onlyDesktop}`}>
        <p>
          {t('shareholders.category')}
        </p>
        <TableDropdown
          categories={categories}
          categoriesSelected={categoriesSelected}
          handleClick={handleClick}
        />
      </div>
      <p className={styles.tableCellTitle}>
        {t('shareholders.edit.share')}
      </p>
      <p className={styles.tableCellTitle}>
        {t('shareholders.edit.capital')}
      </p>
    </div>
  );
}
