import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar, faClock, faEdit, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faMapMarkedAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/library/Button/Button';
import * as statuses from 'services/store/states';

import EventsPopUp from '../EventsPopUp';
import styles from './EventsDescription.module.css';

export default function EventsDescription({
  event, status, isAuthorized, actions,
}) {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.SUCCESS) {
      actions.closePopUp();
    }
  }, [status]);

  const handleDelete = () => {
    actions.deleteEvent(event.id);
  };

  const handleModifyEvent = () => {
    actions.resetEventStatus();
    actions.closePopUp();
    actions.openPopUp(
      t('events.edit.title'),
      t('events.edit.description'),
      <EventsPopUp event={event} />,
    );
  };

  if (isDeleteConfirmationOpen) {
    return (
      <main className={styles.event}>
        <div className={styles.content}>
          <p className={styles.confirmation}>
            {t('events.delete.confirmation')}
          </p>
          <div className={`${styles.row} ${styles.center}`}>
            <Button
              label={t('cancel')}
              onButtonClick={() => setIsDeleteConfirmationOpen(false)}
            />
            <Button
              label={t('validate')}
              onButtonClick={handleDelete}
            />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className={styles.event}>
      {
        (event.imagePath)
        && (
          <img
            className={styles.img}
            src={event.imagePath}
            alt={event.title}
          />
        )
      }
      <div className={styles.content}>
        <div className={`${styles.row} ${styles.buttonGroup}`}>
          {
            isAuthorized
            && (
              <button
                type="button"
                className={styles.button}
                onClick={handleModifyEvent}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className={styles.icon}
                />
                {t('events.update')}
              </button>
            )
          }
          {
            isAuthorized
            && (
              <button
                type="button"
                className={styles.button}
                onClick={() => setIsDeleteConfirmationOpen(true)}
              >
                <FontAwesomeIcon
                  icon={(status === statuses.LOADING) ? faSpinner : faTrashAlt}
                  className={styles.icon}
                  spin={(status === statuses.LOADING)}
                />
                {
                  (status === statuses.LOADING)
                    ? t('events.delete.loading')
                    : t('events.delete.button')
                }
              </button>
            )
          }
        </div>
        <h2
          className={styles.title}
        >
          { event.title }
        </h2>
        <p className={styles.description}>
          { event.description }
        </p>
        <h3
          className={styles.details}
        >
          {t('events.details')}
        </h3>
        <div
          className={`${styles.row} ${styles.timetable}`}
        >
          <div
            className={styles.row}
          >
            <FontAwesomeIcon icon={faCalendar} />
            <p>{ event.date }</p>
          </div>
          <div
            className={styles.row}
          >
            <FontAwesomeIcon icon={faClock} />
            {
              event.isAllDay
                ? <p>{t('events.fullDay')}</p>
                : <p>{ `${event?.hourBeginning} ${(event?.hourEnding) ? `- ${event?.hourEnding}` : ''}` }</p>
            }
          </div>
        </div>
        <div
          className={styles.row}
        >
          <FontAwesomeIcon icon={faMapMarkedAlt} />
          <p>{ event.place }</p>
        </div>
      </div>
    </main>
  );
}
