export default function validate(values, t) {
  const errors = {};
  if (!values.title) {
    errors.title = t('assembly.creation.errors.title');
  }
  if (!values.date) {
    errors.date = t('assembly.creation.errors.date');
  }
  if (!values.time) {
    errors.time = t('assembly.creation.errors.finalTime');
  }
  if (!values.place) {
    errors.place = t('assembly.creation.errors.place');
  }
  return errors;
}
