import React from 'react';
import { useTranslation } from 'react-i18next';

import stylesForm from 'components/Operation/OperationCapitalForm/OperationCapitalForm.module.css';

import OperationReductionFormTableRow from './OperationReductionFormTableRow';

export default function OperationReductionFormTable({
  shareholdersList, id, totalOfShares,
  onShareholderSell,
}) {
  const { t } = useTranslation();

  return (
    <form>
      <ul className={`${stylesForm.grid} ${stylesForm.gridTitle}`}>
        <li>{t('operation.shareholders')}</li>
        <li className={stylesForm.number}>{t('operation.sharesBefore')}</li>
        <li className={stylesForm.number}>{t('operation.sharesDelete')}</li>
        <li className={stylesForm.number}>{t('operation.sharesAfter')}</li>
        <li className={stylesForm.number}>{t('operation.newAllocation')}</li>
      </ul>
      {
        shareholdersList && shareholdersList.map((shareholder) => (
          <OperationReductionFormTableRow
            key={`${shareholder.invest_by_society ? 'society' : ''}${shareholder.id}`}
            shareholder={shareholder}
            id={id}
            totalOfShares={totalOfShares}
            onShareholderSell={onShareholderSell}
          />
        ))
      }
    </form>
  );
}
