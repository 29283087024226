export const FETCH_OPERATIONS_REQUEST = 'FETCH_OPERATIONS_REQUEST';
export const FETCH_OPERATIONS_SUCCEEDED = 'FETCH_OPERATIONS_SUCCEEDED';
export const FETCH_OPERATIONS_FAILED = 'FETCH_OPERATIONS_FAILED';

export const ADD_OPERATION_REQUEST = 'ADD_OPERATION_REQUEST';
export const ADD_OPERATION_SUCCEEDED = 'ADD_OPERATION_SUCCEEDED';
export const ADD_OPERATION_FAILED = 'ADD_OPERATION_FAILED';
export const ADD_MULTIPLE_OPERATIONS_SUCCEEDED = 'ADD_OPERATIONS_SUCCEEDED';
export const ADD_MULTIPLE_OPERATIONS_FAILED = 'ADD_MULTIPLE_OPERATIONS_FAILED';
export const UPDATE_OPERATIONS_SUCCESS = 'UPDATE_OPERATIONS_SUCCESS';

export const SET_QUERY = 'SET_QUERY';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SET_NAME_FILTER = 'SET_NAME_FILTER';
export const SET_OPERATION_CREATION = 'SET_OPERATION_CREATION';
export const SET_SELECTED_SHAREHOLDERS = 'SET_SELECTED_SHAREHOLDERS';

export const ADD_AYOMI_OPERATIONS_SUCCESS = 'ADD_AYOMI_OPERATIONS_SUCCESS';
export const ADD_AYOMI_OPERATIONS_FAILED = 'ADD_AYOMI_OPERATIONS_FAILED';
