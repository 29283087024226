import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';

import styles from './DocumentsSelection.module.css';

const Document = ({
  document, isFavorite, handleChange, selectDocument,
}) => {
  const [isStarHovered, setIsStarHovered] = useState(false);
  const handleFavorite = (e) => {
    e.preventDefault();
    handleChange(document.id, !isFavorite);
    if (e.stopPropagation) e.stopPropagation();
  };

  const handlePreview = () => {
    selectDocument(document);
  };
  return (
    <div
      role="button"
      className={styles.documentButton}
      onClick={handlePreview}
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handlePreview();
        }
      }}
    >
      <button
        type="button"
        className={styles.favorite}
        onMouseEnter={() => setIsStarHovered(true)}
        onMouseLeave={() => setIsStarHovered(false)}
        onClick={handleFavorite}
      >
        {
          (isStarHovered)
            ? (
              <FontAwesomeIcon
                icon={(isFavorite) ? faStarEmpty : faStar}
                className={(isFavorite) ? '' : styles.star}
              />
            )
            : (
              <FontAwesomeIcon
                icon={(isFavorite) ? faStar : faStarEmpty}
                className={(isFavorite) ? styles.star : ''}
              />
            )
        }
      </button>
      <p className={styles.documentTitle}>
        {document.title}
      </p>
      <p className={styles.documentDate}>
        {document.date}
      </p>
    </div>
  );
};

export default function DocumentsSelection({
  title, documents, favorites, handleChange, selectDocument,
}) {
  if (documents.length === 0) {
    return (
      <section className={styles.selection}>
        <h2>
          { title }
        </h2>
        <ul className={styles.list}>
          <li className={`${styles.item} ${styles.skeleton}`} />
          <li className={`${styles.item} ${styles.skeleton}`} />
        </ul>
      </section>
    );
  }

  return (
    <section className={styles.selection}>
      <h2>
        { title }
      </h2>
      <ul className={styles.list}>
        {
          documents.map((document) => (
            <li
              key={document.id}
              className={styles.item}
            >
              <Document
                document={document}
                selectDocument={selectDocument}
                handleChange={handleChange}
                isFavorite={favorites.includes(document.id)}
              />
            </li>
          ))
        }
      </ul>
    </section>
  );
}
