import React from 'react';

import ShareholdersHeader from 'components/Shareholders/ShareholdersHeader';
import styles from './ShareholdersAccount.module.css';
import ShareholdersAccountList from './ShareholdersAccountList';

export default function ShareholdersAccount({ society, isHolding }) {
  return (
    <section
      className={styles.shareholders}
    >
      <ShareholdersHeader
        id={society}
        isHolding={isHolding}
      />
      <ShareholdersAccountList
        isHolding={isHolding}
      />
    </section>
  );
}
