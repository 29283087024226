import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/library/Button/Button';
import stylesForm from 'components/Operation/OperationCapitalForm/OperationCapitalForm.module.css';

import styles from '../../../OperationsCapital/OperationsCapital.module.css';

export default function OperationsReductionFormLayout({
  societyId, isHolding, isSubmitDisabled, onSubmit, children,
}) {
  const { t } = useTranslation();
  return (
    <section>
      <header>
        <h3>
          {t('operation.reduction.title')}
        </h3>
      </header>
      <main>
        { children }
      </main>
      <footer className={`${stylesForm.buttonGroup} ${stylesForm.footer}`}>
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/reduction`}
          className={stylesForm.outline}
        >
          {t('operation.previous')}
        </Link>
        <Button
          label={t('operation.create')}
          className={styles.button}
          onButtonClick={() => onSubmit('verified')}
          disabled={isSubmitDisabled}
        />
      </footer>
    </section>
  );
}
