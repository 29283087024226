import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import * as statuses from '../../services/store/states';
import AccountLoading from '../library/Loading';

export default function SocietyManager({
  status, isForbidden, actions, children,
}) {
  useEffect(() => {
    const society = window.location.pathname.split('/')[1];
    if (status !== statuses.SUCCESS && society !== 'forbidden') {
      actions.fetchSocietyData(society);
    }
  }, []);

  if (status === statuses.IDLE || status === statuses.LOADING) {
    return <AccountLoading />;
  }

  if (isForbidden) {
    return <Redirect to="/forbidden" />;
  }

  return (
    children
  );
}
