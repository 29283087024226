import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';

import styles from './ShareholdersForm.module.css';

export default function ShareholdersFormRespondent({ setStep }) {
  return (
    <div className={styles.content}>
      <button
        type="button"
        className={styles.button}
        onClick={() => setStep('create')}
      >
        <span
          className={styles.iconContainer}
        >
          <PersonIcon />
        </span>
        <p
          className={styles.label}
        >
          Renseigner les informations personnels de votre actionnaire
        </p>
      </button>
      <button
        type="button"
        className={styles.button}
        onClick={() => setStep('ask')}
      >
        <span
          className={styles.iconContainer}
        >
          <EmailIcon />
        </span>
        <p
          className={styles.label}
        >
          Demander à votre actionnaire de remplir ses informations
        </p>
      </button>
      {/* <button
        type="button"
        className={styles.button}
        onClick={() => setStep('update')}
      >
        <span
          className={styles.iconContainer}
        >
          <SearchIcon />
        </span>
        <p
          className={styles.label}
        >
          Reprendre les informations d’un actionnaire existant
        </p>
      </button> */
      }
    </div>
  );
}
