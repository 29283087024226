import React from 'react';
import { useTranslation } from 'react-i18next';
import { LiveHelp } from '@material-ui/icons';
import { NavLink, Route } from 'react-router-dom';

import Header from '../../components/Header/Header';
import styles from './Documentation.module.css';
import DocumentationDashboard from './DocumentationDashboard';
import DocumentationShareholders from './DocumentationShareholders';
import DocumentationReport from './DocumentationReport';
import DocumentationAssembly from './DocumentAssembly';

export default function Documentation() {
  const { t } = useTranslation();
  return (
    <section className={styles.section}>
      <Header
        title="Documentation"
      />
      <main className={styles.main}>
        <h1 className={styles.title}>
          {t('documentation.title')}
        </h1>
        <p className={styles.description}>
          {t('documentation.description')}
          <a href={`https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'ayomi.fr'}/contact`} target="_blank" rel="noreferrer">
            {t('documentation.support')}
          </a>
          .
        </p>
        <div className={styles.row}>
          <div className={styles.card}>
            <p className={styles.title}>
              {t('documentation.quickstart.title')}
            </p>
            <p className={styles.description}>
              {t('documentation.quickstart.description')}
            </p>
            <ul className={styles.list}>
              <li>
                <LiveHelp className={styles.icon} />
                <NavLink to="/documentation/dashboard">
                  {t('documentation.quickstart.dashboard')}
                </NavLink>
              </li>
              <li>
                <LiveHelp className={styles.icon} />
                <NavLink to="/documentation/shareholders">
                  {t('documentation.quickstart.shareholders')}
                </NavLink>
              </li>
              <li>
                <LiveHelp className={styles.icon} />
                <NavLink to="/documentation/report">
                  {t('documentation.quickstart.report')}
                </NavLink>
              </li>
              <li>
                <LiveHelp className={styles.icon} />
                <NavLink to="/documentation/assembly">
                  {t('documentation.quickstart.assembly')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.documentation}>
            <Route
              path="/documentation/dashboard"
              component={DocumentationDashboard}
            />
            <Route
              path="/documentation/shareholders"
              component={DocumentationShareholders}
            />
            <Route
              path="/documentation/report"
              component={DocumentationReport}
            />
            <Route
              path="/documentation/assembly"
              component={DocumentationAssembly}
            />
          </div>
        </div>
      </main>
    </section>
  );
}
