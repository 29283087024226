import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setQuery } from 'services/store/features/operations/actions';
import Searchbar from 'components/Searchbar/Searchbar';

const mapStateToProps = (state) => ({
  query: state.operations.query,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);
