import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchSocietyData } from '../../../services/store/features/society/actions';
import AdminHeader from './AdminHeader';

const mapStateToProps = (state) => ({
  society: state.society,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSocietyData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
