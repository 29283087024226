import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InputText from 'components/library/Input/InputText/InputText';
import InputDate from 'components/library/Input/InputDate/InputDate';
import Button from 'components/library/Button/Button';
import OperationHeader from 'components/Operation/OperationHeader';
import * as statuses from 'services/store/states';

import useForm from '../../../../hook/useForm';
import validate from '../formValidation';
import styles from '../OperationCreation.module.css';

export default function OperationsCreationForm({
  type, history, actions, societyId, operationHistory, currentOperation, isHolding = false,
}) {
  const { t } = useTranslation();

  const OPERATION = {
    transfert: 'cession',
    capital: 'souscription',
    reduction: 'reduction',
  };

  const submit = (newOperation) => {
    const {
      name, sharesPrice, date, unitSharePrice,
    } = newOperation;
    const operation = {
      name,
      date,
      type: OPERATION[type],
      parameters: {
        sharesPrice: (sharesPrice) ? Number.parseFloat(sharesPrice) : 0,
        emissionPrice: Number.parseFloat(unitSharePrice) - Number.parseFloat(sharesPrice),
        unitSharePrice: Number.parseFloat(unitSharePrice),
      },
      holding: isHolding,
    };
    actions.setOperation(operation);
    const OPERATION_TYPE = {
      transfert: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/transfert`,
      capital: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/capital`,
      reduction: `/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/reduction`,
    };
    history.push(OPERATION_TYPE[type]);
  };

  const {
    values, errors, handleSubmit, handleChange,
  } = useForm(submit, (inputValues) => validate(
    inputValues,
    type,
    t,
    operationHistory?.history?.date?.begin,
  ),
  {
    ...currentOperation,
    sharesPrice: currentOperation?.sharesPrice
      || operationHistory?.history?.parameters?.sharesPrice,
  } || {});

  return (
    <div className={styles.choices}>
      <div className={styles.header}>
        <OperationHeader
          type={type}
          step={0}
        />
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`}
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        <h3>
          {t('operation.create')}
        </h3>
        <div>
          <form>
            <InputText
              label={t('operation.name')}
              hint={t('operation.nameHint')}
              name="name"
              valueInput={values.name || ''}
              onTextChange={handleChange}
              state={errors.name && statuses.FAIL}
            />
            <div>
              {
                (
                  !(currentOperation?.sharesPrice
                    || operationHistory?.history?.parameters?.sharesPrice)
                  || ((currentOperation?.sharesPrice
                    || operationHistory?.history?.parameters?.sharesPrice) === 0))
                  ? (
                    <InputText
                      label={t('operation.parValue')}
                      type="number"
                      name="sharesPrice"
                      valueInput={values.sharesPrice || ''}
                      onTextChange={handleChange}
                      state={errors.sharesPrice && statuses.FAIL}
                      hint={errors.sharesPrice}
                      icon="€"
                    />
                  )
                  : (
                    <>
                      <p className={styles.label}>
                        {t('operation.parValue')}
                      </p>
                      <div className={styles.disabledInput}>
                        {
                        (Number.parseFloat(values.sharesPrice) || 0)
                      }
                        €
                      </div>
                    </>
                  )
              }
              <p className={styles.label}>
                {t('operation.premium')}
              </p>
              <div className={styles.disabledInput}>
                {
                  ((Number.parseFloat(values.unitSharePrice) || 0)
                    - (Number.parseFloat(values.sharesPrice) || 0)).toFixed(2)
                }
                €
              </div>
              <InputText
                label={t('operation.unitValue')}
                type="number"
                name="unitSharePrice"
                valueInput={values.unitSharePrice || ''}
                onTextChange={handleChange}
                state={errors.unitSharePrice && statuses.FAIL}
                hint={errors.unitSharePrice}
                icon="€"
              />
            </div>
            <InputDate
              label={t('operation.date')}
              day={values.date?.day || ''}
              month={values.date?.month || ''}
              year={values.date?.year || ''}
              onChange={(date, e) => {
                e.target.name = 'date';
                e.target.value = '';
                handleChange(e, date);
              }}
              includeDay
              state={errors.date && statuses.FAIL}
              hint={errors.date}
            />
          </form>
          <Button
            label={t('operation.next')}
            onButtonClick={handleSubmit}
            disabled={!values.unitSharePrice || values.unitSharePrice < values.sharesPrice}
          />
        </div>
      </div>
    </div>
  );
}
