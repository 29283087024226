import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as statuses from '../../../services/store/states';
import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import shared from '../shared/assembly.module.css';

import AssemblyReportCreate from './AssemblyReportCreate';
import AssemblyReportEdition from './AssemblyReportEdition';
import styles from './AssemblyReport.module.css';

const AssemblyReportState = ({
  state, isAttendanceSheetComplete, isVotesComplete, actions, report, next, status,
  userId, societyId,
}) => {
  const STATE = {
    creation: <AssemblyReportCreate
      actions={actions}
      presence={isAttendanceSheetComplete}
      vote={isVotesComplete}
      next={() => next('creation')}
      userId={userId}
      societyId={societyId}
    />,
    edition: <AssemblyReportEdition
      assemblyStatus={status}
      actions={actions}
      report={report}
      userId={userId}
      societyId={societyId}
    />,
  };
  return (
    <div>{STATE[state]}</div>
  );
};

export default function AssemblyReport({
  assemblyStatus, isAttendanceSheetComplete, isVotesComplete, actions,
  userId, societyId,
}) {
  const [step, setStep] = useState('creation');
  const [report, setReport] = useState({});

  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (assemblyStatus === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
    actions.setActiveAssembly(Number.parseInt(id, 10));
  }, []);

  const handleNext = (value) => {
    if (step === 'creation') {
      setStep('edition');
      setReport({ ...value });
    }
  };

  return (
    <section className={styles.section}>
      <AssemblyHeader />
      <section className={styles.main}>
        <header className={shared.header}>
          <h2>
            {t('assembly.minutes.title')}
          </h2>
        </header>
        <AssemblyReportState
          state={step}
          actions={actions}
          next={handleNext}
          report={report}
          status={assemblyStatus}
          isVotesComplete={isVotesComplete}
          isAttendanceSheetComplete={isAttendanceSheetComplete}
          userId={userId}
          societyId={societyId}
        />
      </section>
    </section>
  );
}
