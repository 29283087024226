import React, { useEffect, useReducer, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AccountLoading from '../../../../components/library/Loading';
import OperationSuccess from '../../../../components/OperationSuccess';
import ErrorMessage from '../../../../components/Error/ErrorMessage';
import { addMoralShareholders, getShareholderShare } from '../../../../services/store/features/shareholders/utils';

import * as statuses from '../../../../services/store/states';

import OperationsReductionFormLayout from './OperationsReductionFormLayout';
import OperationsReductionFormShareholders from './OperationsReductionFormShareholders';
import OperationReductionFormTable from './OperationReductionFormTable';
import shareholdersReducer from './shareholdersReducer';

export default function OperationsReductionForm({
  status, shouldFetchShareholders, shareholders,
  isHolding, holdingId, societyId, openForm, actions,
}) {
  const getInitList = () => addMoralShareholders(shareholders)
    .map((shareholder) => ({ ...shareholder, buy: 0, sell: 0 }));

  const [{ list }, dispatch] = useReducer(
    shareholdersReducer,
    {
      list: getInitList(),
    },
  );

  const totalOfShares = useMemo(() => {
    if (shareholders.length > 0 && list.length > 0) {
      return list
        .reduce((acc, cur) => acc
          + getShareholderShare(cur, isHolding ? holdingId : societyId, cur.invest_by_society)
          + cur.buy - cur.sell, 0);
    }
    return 0;
  }, [list]);

  const { t } = useTranslation();

  useEffect(() => {
    if (shouldFetchShareholders) {
      actions.fetchAllShareholders();
    }
    if (list.length === 0 && shareholders.length > 0) {
      dispatch({
        type: 'SET_SHAREHOLDERS',
        payload: getInitList(),
      });
    }
  }, [shouldFetchShareholders, shareholders]);

  const handleShareholderSell = (e, shareholder) => {
    const shares = (e.target.value === '') ? e.target.value : Number.parseInt(e.target.value, 10);
    const shareholderShares = getShareholderShare(
      shareholder,
      societyId,
      shareholder.invest_by_society,
    );
    const sellShares = (shares === '' || shares < shareholderShares) ? shares : shareholderShares;
    dispatch({
      type: 'SELL_SHARES',
      payload: {
        value: (sellShares <= 0) ? 0 : sellShares,
        id: shareholder.id,
        fromSociety: shareholder.invest_by_society,
      },
    });
  };

  const getIsSubmittable = () => list.reduce(
    (acc, curr) => acc && (
      (getShareholderShare(curr) === 0 && curr.sell === 0)
      || (getShareholderShare(curr) !== 0 && curr.sell === 0)
    ),
    true,
  );

  const handleSubmit = () => {
    actions.createCapitalReduction('verified', list);
  };

  if (status === statuses.LOADING) {
    return <AccountLoading />;
  }

  if (status === statuses.SUCCESS) {
    return <OperationSuccess />;
  }

  return (
    <OperationsReductionFormLayout
      isHolding={isHolding}
      societyId={societyId}
      isSubmitDisabled={getIsSubmittable()}
      onSubmit={handleSubmit}
    >
      {
        (status === statuses.FAIL)
        && (
          <ErrorMessage
            message={t('operation.error.technical')}
          />
        )
      }
      <OperationsReductionFormShareholders openForm={openForm} />
      <OperationReductionFormTable
        shareholdersList={list}
        id={isHolding ? holdingId : societyId}
        totalOfShares={totalOfShares}
        onShareholderSell={handleShareholderSell}
      />
    </OperationsReductionFormLayout>
  );
}
