import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button/Button';
import InputText from 'components/library/Input/InputText/InputText';
import AccountLoading from 'components/library/Loading';
import OperationSuccess from 'components/OperationSuccess';
import ErrorMessage from 'components/Error/ErrorMessage';
import styles from 'views/Operations/OperationsCapital/OperationsCapital.module.css';
import {
  getShareholderShare,
} from 'services/store/features/shareholders/utils';
import * as statuses from 'services/store/states';

import stylesForm from './OperationCapitalForm.module.css';

export default function OperationsCapitalForm({
  societyId, holdingId, status, openForm, shareholders, actions,
  isHolding, operation,
}) {
  const [totalOfShares, setTotalOfShares] = useState(0);
  const [shareholdersList, setShareholdersList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (shareholders.status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, []);

  useEffect(() => {
    if (shareholdersList.length === 0 && shareholders.list && shareholders.list.length > 0) {
      const allShareholders = shareholders.list.map((shareholder) => ({ ...shareholder, buy: 0 }));
      setShareholdersList(allShareholders);
      setTotalOfShares(allShareholders
        .reduce((acc, cur) => acc
          + getShareholderShare(cur, isHolding ? holdingId : societyId, cur.invest_by_society)
          + cur.buy, 0));
    }
  }, [shareholders]);

  const handleSubmit = () => {
    const isComplete = shareholdersList
      .filter((i) => i.buy !== 0)
      .filter((i) => i.toComplete).length === 0;
    const operationStatus = isComplete ? 'verified' : 'waiting';
    actions.createNewCapitalGain(operationStatus, shareholdersList);
  };

  const handleShareholdersBuy = (e, shareholder) => {
    const value = e.target.value || 0;
    const { id, invest_by_society: investBySociety } = shareholder;
    const newShareholdersList = shareholdersList.map((currentShareholder) => {
      if (currentShareholder.id === id
        && (currentShareholder?.invest_by_society === investBySociety)) {
        return {
          ...currentShareholder,
          buy: (e.target.value.trim() !== '') ? Number.parseInt(value, 10) : '',
        };
      }
      return currentShareholder;
    });
    setShareholdersList(newShareholdersList);
    const newTotalOfShares = newShareholdersList.reduce((acc, cur) => (
      acc
      + getShareholderShare(cur, isHolding ? holdingId : societyId, cur.invest_by_society)
      + (cur.buy || 0)
    ), 0);
    setTotalOfShares(newTotalOfShares);
  };

  if (operation.status === statuses.IDLE) {
    return <Redirect to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`} />;
  }

  if (status === statuses.LOADING) {
    return (
      <div className={styles.loading}>
        <div className={styles.header}>
          <h3>
            {t('operation.capital')}
          </h3>
        </div>
        <div>
          {t('operation.capitalLoading')}
        </div>
        <AccountLoading />
      </div>
    );
  }

  if (status === statuses.SUCCESS) {
    return (
      <OperationSuccess
        societyId={societyId}
        isHolding={isHolding}
      />
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <h3>
          {t('operation.capital')}
        </h3>
      </div>
      {
        (status === 'failed')
        && (
          <ErrorMessage
            message={t('operation.error.technical')}
          />
        )
      }
      <h4>
        {t('operation.shareholdersList')}
      </h4>
      <p className={`${styles.description} ${stylesForm.create}`}>
        {t('operation.shareholdersHint')}
      </p>
      <Button
        label={t('operation.shareholderCreation')}
        onButtonClick={openForm}
      />
      <form
        className={stylesForm.form}
      >
        <ul className={`${stylesForm.grid} ${stylesForm.gridTitle}`}>
          <li>{t('operation.shareholders')}</li>
          <li className={stylesForm.number}>{t('operation.sharesBefore')}</li>
          <li className={stylesForm.number}>{t('operation.sharesBuy')}</li>
          <li className={stylesForm.number}>{t('operation.sharesBuy')}</li>
          <li className={stylesForm.number}>{t('operation.newAllocation')}</li>
        </ul>
        {
            shareholdersList && shareholdersList.map((shareholder) => (
              <ul
                className={stylesForm.grid}
                key={`${shareholder.siren ? 'society' : ''}${shareholder.id}`}
              >
                <li className={(shareholder.toComplete) ? stylesForm.waiting : ''}>
                  <span>
                    {(shareholder.siren) ? (shareholder.societyName || shareholder.siren) : `${shareholder.firstName} ${shareholder.lastName}`}
                  </span>
                  {
                    (shareholder.toComplete) && (
                      <span className={stylesForm.hint}>
                        En attente de validation par l&apos;actionnaire
                      </span>
                    )
                  }
                </li>
                <li className={stylesForm.number}>
                  {
                    getShareholderShare(
                      shareholder,
                      isHolding ? holdingId : societyId,
                      !!shareholder.siren,
                    )
                  }
                </li>
                <li>
                  <InputText
                    type="number"
                    valueInput={shareholder.buy || ''}
                    onTextChange={(e) => handleShareholdersBuy(e, shareholder)}
                  />
                </li>
                <li className={stylesForm.number}>
                  {getShareholderShare(
                    shareholder,
                    isHolding ? holdingId : societyId,
                    !!shareholder.siren,
                  ) + (shareholder?.buy || 0)}
                </li>
                <li className={stylesForm.number}>
                  {
                    Number.isNaN(((getShareholderShare(
                      shareholder,
                      isHolding ? holdingId : societyId,
                      !!shareholder.siren,
                    ) + shareholder.buy) * 100) / totalOfShares)
                      ? 0
                      : (((getShareholderShare(
                        shareholder,
                        isHolding ? holdingId : societyId,
                        !!shareholder.siren,
                      ) + shareholder.buy) * 100) / totalOfShares)
                        .toFixed(2)
                  }
                  %
                </li>
              </ul>
            ))
          }
      </form>
      <div className={stylesForm.buttonGroup}>
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/capital`}
          className={stylesForm.outline}
        >
          {t('operation.previous')}
        </Link>
        <Button
          label={t('operation.create')}
          className={styles.button}
          onButtonClick={() => handleSubmit('verified')}
          disabled={shareholdersList.reduce((acc, curr) => acc
            && !(curr.buy && (curr.buy !== '0') && (curr.buy !== 0) && (curr.buy !== '')), true)}
        />
      </div>
    </div>
  );
}
