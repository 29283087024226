import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import * as statuses from '../../../services/store/states';
import styles from './DocumentsPreview.module.css';

export default function DocumentsPreview({
  societyId, documents = [], status, actions,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchDocumentsData(societyId);
    }
  }, [societyId]);
  if (status === statuses.LOADING) {
    return (
      <div>
        <p>
          {`${t(statuses.LOADING)}... `}
        </p>
      </div>
    );
  }
  if (status === statuses.FAIL) {
    return (
      <div>
        <p>
          {`${t('error.title')}`}
        </p>
      </div>
    );
  }
  if (status === 'success' && documents.length === 0) {
    return (
      <div
        className={styles.container}
      >
        <p
          className={styles.loading}
        >
          {t('documents.empty')}
        </p>
        <button
          type="button"
          className={styles.button}
        >
          {t('documents.request')}
        </button>
      </div>
    );
  }
  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.content}
      >
        <div
          className={styles.row}
        >
          <h2
            className={styles.title}
          >
            {t('documents.recent')}
          </h2>
          <Link
            className={styles.link}
            to={`/${societyId}/documents`}
          >
            {t('all')}
          </Link>
        </div>
      </div>
      <ul
        className={styles.list}
      >
        {
          documents.map((doc) => (
            <Link
              key={doc.title}
              className={styles.item}
              to={`/${societyId}/documents`}
              onClick={() => actions.setSelectedDocument(doc)}
            >
              <div
                className={styles.documentTitle}
              >
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faFile}
                />
                <div>
                  <p
                    className={styles.document}
                  >
                    { doc.title }
                  </p>
                  <p
                    className={styles.date}
                  >
                    { doc.date }
                  </p>
                </div>
              </div>
            </Link>
          ))
        }
      </ul>
    </div>
  );
}
