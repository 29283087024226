import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import InputText from 'components/library/Input/InputText/InputText';
import InputDate from 'components/library/Input/InputDate/InputDate';
import InputPhone from 'components/library/Input/InputPhone/InputPhone';
import InputFile2 from 'components/library/Input/InputFile2/InputFile2';
import Button from 'components/library/Button/Button';
import * as statuses from 'services/store/states';

import ShareholdersFormRespondent from './ShareholdersFormRespondent';
import styles from './ShareholdersForm.module.css';

export default function ShareholdersForm({
  contact, status, closePopUp, holdingId, actions,
}) {
  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState({ day: '', month: '', year: '' });
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [profilePicture, setProfilePicture] = useState({
    state: 'DEFAULT',
    file: null,
    url: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    actions.setStatus('editing');
    setFirstName(contact?.firstName);
    setLastName(contact?.lastName);
    const birthdayArray = contact?.birthday.split('/');
    if (birthdayArray.length === 3) {
      setBirthday({
        day: birthdayArray[0],
        month: birthdayArray[1],
        year: birthdayArray[2],
      });
    }
    setPhone(contact?.phone);
    setMail(contact?.mail);
    setAddress(contact?.address);
    setCity(contact?.city);
    setZipCode(contact?.zipCode);
  }, []);

  const submit = () => {
    const newShareholders = {
      firstName,
      lastName,
      phone,
      mail,
      address,
      city,
      zipCode,
      birthday: `${birthday.day}/${birthday.month}/${birthday.year}`,
      img: profilePicture.url,
    };
    actions.updateShareholders(holdingId, contact.id, newShareholders);
  };

  if (step === 0) {
    return <ShareholdersFormRespondent setStep={() => setStep(1)} />;
  }

  if (step === 1) {
    if (status === 'success') {
      return (
        <div>
          <main className={styles.success}>
            <div className={styles.successCheckmark}>
              <div className={styles.checkIcon}>
                <span className={`${styles.iconLine} ${styles.lineTip}`} />
                <span className={`${styles.iconLine} ${styles.lineLong}`} />
                <div className={styles.iconCircle} />
                <div className={styles.iconFix} />
              </div>
            </div>
            <p className={styles.successTitle}>
              {t('shareholders.success')}
            </p>
          </main>
        </div>
      );
    }
    return (
      <div>
        <form className={`${styles.content} ${styles.form}`}>
          <div className={styles.row}>
            <InputText
              label={t('firstName')}
              valueInput={firstName}
              onTextChange={(e) => setFirstName(e.target.value)}
              required
            />
            <InputText
              label={t('lastName')}
              valueInput={lastName}
              onTextChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <InputDate
            label={t('shareholders.edit.birthday')}
            day={birthday.day}
            month={birthday.month}
            year={birthday.year}
            onChange={(bd) => setBirthday(bd)}
            includeDay
          />
          <InputPhone
            label={t('shareholders.edit.birthday')}
            phone={phone}
            setPhone={(str) => setPhone(str)}
          />
          <InputText
            label={t('shareholders.edit.mail')}
            valueInput={mail}
            onTextChange={(e) => setMail(e.target.value)}
            required
          />
          <InputText
            label={t('shareholders.edit.address')}
            valueInput={address}
            onTextChange={(e) => setAddress(e.target.value)}
          />
          <div className={styles.row}>
            <InputText
              label={t('shareholders.edit.zip')}
              valueInput={zipCode}
              onTextChange={(e) => setZipCode(e.target.value)}
            />
            <InputText
              label={t('shareholders.edit.city')}
              valueInput={city}
              onTextChange={(e) => setCity(e.target.value)}
            />
          </div>
          <InputFile2
            label={t('shareholders.edit.profilePicture')}
            state={profilePicture.state}
            file={profilePicture.url}
            handleFile={(files) => {
              const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'tiff', 'svg'];
              const { name: fileName } = files[0];
              const fileExtension = fileName.split('.').pop();
              if (allowedExtensions.includes(fileExtension)) {
                URL.revokeObjectURL(profilePicture.url);
                setProfilePicture({ state: 'SUCCESS', file: files[0], url: URL.createObjectURL(files[0]) });
              }
            }}
          />
        </form>
        <footer className={styles.footer}>
          <Button
            type="custom"
            label={t('cancel')}
            className={styles.tertiaryButton}
            onButtonClick={closePopUp}
          />
          <Button
            type="secondary"
            label={status === statuses.LOADING ? t('shareholders.edit.save.loading') : t('shareholders.edit.save.button')}
            onButtonClick={submit}
            icon={(status === statuses.LOADING) ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
          />
        </footer>
      </div>
    );
  }
  return null;
}
