import * as types from './type';
import { createNewHistory, getHistory } from '../../../clients/clientAPI';
import { getLastHistory, getLastHoldingHistory } from './selectors';
import { getShareholderShare } from '../shareholders/utils';
import { removeKeyProperty } from '../../../../utils/utils';
import { keysToCamel } from '../../../../utils/converter';
import { historyToApi } from './adapter';

export function fetchHistory() {
  return async (dispatch, getState) => {
    const { id, idHolding } = getState().society;
    if (id) {
      dispatch({
        type: types.FETCH_HISTORY_REQUEST,
      });
      try {
        let { data: history } = await getHistory(id);
        if (idHolding) {
          const { data: dataHolding } = await getHistory(idHolding);
          history = [
            ...history,
            ...dataHolding.map((currentHistory) => ({ ...currentHistory, holding: true })),
          ];
        }
        dispatch({
          type: types.FETCH_HISTORY_SUCCESS,
          payload: keysToCamel(history),
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_HISTORY_FAILED,
          payload: err,
        });
      }
    }
  };
}

export function fetchHoldingHistory() {
  return async (dispatch, getState) => {
    const { idHolding } = getState().society;
    if (idHolding) {
      dispatch({ type: types.FETCH_HOLDING_HISTORY_REQUEST });
      try {
        const { data: histories } = await getHistory(idHolding);
        dispatch({
          type: types.FETCH_HOLDING_HISTORY_SUCCESS,
          payload: [
            ...getState().history.history.filter((history) => history.holding !== true),
            ...keysToCamel(histories.map((history) => ({ ...history, holding: true }))),
          ],
        });
      } catch (err) {
        console.error(err);
        dispatch({ type: types.FETCH_HOLDING_HISTORY_FAILED, payload: err });
      }
    }
  };
}

export function fetchTargetHistory() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({ type: types.FETCH_TARGET_HISTORY_REQUEST });
      try {
        const { data: histories } = await getHistory(id);
        dispatch({
          type: types.FETCH_TARGET_HISTORY_SUCCESS,
          payload: [
            ...getState().history.history.filter((history) => history.holding !== true),
            ...keysToCamel(histories).map((history) => ({ ...history, holding: true })),
          ],
        });
      } catch (err) {
        console.error(err);
        dispatch({ type: types.FETCH_TARGET_HISTORY_FAILED, payload: err });
      }
    }
  };
}

export function setActiveHistory(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_HISTORY,
      payload: index,
    });
  };
}

export function createHistoryFromOperation(operations, callback) {
  return async (dispatch, getState) => {
    const { shareholders: shareholdersList, holding } = getState().shareholders;
    const { creation } = getState().operations;
    const { society } = getState();

    const isHolding = window.location.href.includes('holding');
    const shareholders = [...shareholdersList, ...(holding || [])];

    const societyId = isHolding ? society.idHolding : society.id;

    const { withoutKey } = removeKeyProperty('id', isHolding ? getLastHoldingHistory(getState()) : getLastHistory(getState()));
    const sharesNumber = (creation.type !== 'cession')
      ? shareholders.reduce((acc, curr) => acc + getShareholderShare(curr) + (curr.buy || 0), 0)
      : (isHolding
        ? getLastHoldingHistory(getState())
        : getLastHistory(getState()))?.parameters?.sharesNumber || 0;

    const shareholdersWithOperation = Array.from(
      new Set(
        operations.reduce((acc, { fromUserId, toUserId }) => {
          const newShareholders = acc;
          if (fromUserId) newShareholders.push(fromUserId);
          if (toUserId) newShareholders.push(toUserId);
          return newShareholders;
        },
        []),
      ),
    );

    const newHistory = {
      ...withoutKey,
      societyId,
      date: {
        begin: creation.date,
      },
      name: creation.name,
      shareholders: [
        ...withoutKey.shareholders
          ?.filter((id) => !shareholdersWithOperation.includes(id)),
        ...shareholdersWithOperation,
      ],
      parameters: {
        ...creation.parameters,
        sharesNumber,
        valorisation: sharesNumber * creation.parameters.sharesPrice,
      },
      holding: window.location.href.includes('holding'),
      type: creation.type,
    };

    const newHistoryResponse = await createNewHistory(historyToApi(newHistory));
    dispatch({
      type: 'CREATE_HISTORY_SUCCESS',
      payload: {
        ...newHistory,
        ...keysToCamel(newHistoryResponse),
      },
    });

    callback(newHistoryResponse.data?.id);
  };
}
