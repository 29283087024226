import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from '../../components/library/Loading';
import { getRedirections, getSocietyInfo } from '../../services/clients/clientAPI';
import ImgPlaceholder from '../../components/ImgPlaceholder';
import getProjectId from '../../utils/getProjectId';
import { keysToCamel } from '../../utils/converter';
import * as statuses from '../../services/store/states';

import styles from './ProjectSelection.module.css';

export default function ProjectSelection() {
  const [status, setStatus] = useState(statuses.IDLE);
  const [projects, setProjects] = useState([]);

  const history = useHistory();
  const { t } = useTranslation();

  const getSociety = async (redirection) => {
    const id = getProjectId(redirection);
    const response = await getSocietyInfo(id);
    return response;
  };

  useEffect(async () => {
    const fetchProjectList = async () => {
      setStatus(statuses.LOADING);
      try {
        const { data } = await getRedirections();
        const results = await Promise
          .all(data.redirections.map((redirection) => getSociety(redirection)));
        const societies = results
          .map((response, key) => ({
            ...response.data,
            redirection: data.redirections[key],
          }))
          .filter((project) => project.id);
        setProjects(keysToCamel(societies));
        setStatus(statuses.SUCCESS);
      } catch (err) {
        setStatus(statuses.FAIL);
      }
    };
    await fetchProjectList();
  }, []);

  if (status === statuses.LOADING) {
    return (
      <Loading />
    );
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h1>
          Vos espaces de post-investissement
        </h1>
        <p className={styles.description}>
          Retrouver toutes les informations de la société et vos documents
          relatifs à votre investissement sur votre espace dédié.
        </p>
      </header>
      <main className={styles.main}>
        <ul className={styles.list}>
          { projects.map((project) => (
            <li
              key={project.id}
              className={styles.item}
            >
              <div
                className={styles.society}
                role="button"
                tabIndex="-1"
                onClick={() => history.push(`/${project.id}`)}
                onKeyDown={() => history.push(`/${project.id}`)}
              >
                <ImgPlaceholder
                  src={project.coverUrl}
                  alt={project.name}
                />
                <p className={styles.name}>
                  { project.societyName }
                </p>
                <p className={styles.owner}>
                  {`${project.firstName} ${project.lastName}`}
                </p>
              </div>
            </li>
          ))}
        </ul>
        {
          (projects.length === 0)
          && (
            <div className={styles.empty}>
              <p>
                {t('noSpace')}
              </p>
            </div>
          )
        }
      </main>
    </section>
  );
}
