export default function shareholdersReducer(
  state = { list: [] },
  { type, payload },
) {
  switch (type) {
    case 'SET_SHAREHOLDERS':
      return {
        ...state,
        list: payload,
      };
    case 'SELL_SHARES':
      return {
        ...state,
        list: state.list.map((shareholder) => (
          (
            shareholder.id === payload.id
            && shareholder.invest_by_society === payload.fromSociety
          )
            ? { ...shareholder, sell: payload.value }
            : shareholder
        )),
      };
    default:
      return state;
  }
}
