import React, { useEffect, useState } from 'react';

import fetchMedia from '../../utils/getFile';

import * as statuses from '../../services/store/states';
import styles from './ImgPlaceholder.module.css';

export default function ImgPlaceholder({
  src, alt, className, children,
}) {
  const [status, setStatus] = useState(statuses.LOADING);
  const [url, setUrl] = useState();

  const shouldFetch = () => {
    const regex = new RegExp(/media\.ayomi\.fr\/media/);
    return regex.test(src);
  };

  useEffect(() => {
    let cancel = false;
    if (shouldFetch() && src && !cancel) {
      const handleSetUrl = (data) => {
        if (!cancel) {
          setUrl(data);
          setStatus('SUCCESS');
        }
      };
      fetchMedia(handleSetUrl, null, `${src}?refresh_token=${window.localStorage.getItem('auth_refresh')}`);
    } else {
      setStatus('SUCCESS');
      setUrl(src);
    }
    return () => { cancel = true; };
  }, [src]);

  if (status === statuses.LOADING) {
    return (
      <div
        className={`${styles.placeholder} ${className}`}
      >
        <div className={styles.loading} />
      </div>
    );
  }

  if ((url === undefined || status === statuses.FAIL) && children) {
    return children;
  }

  return (
    <img
      src={url}
      alt={alt}
      className={className}
      onError={() => setStatus(statuses.FAIL)}
    />
  );
}
