import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as statuses from '../../../services/store/states';

import shared from '../shared/assembly.module.css';
import AssemblyPlanItem from './AssemblyPlanItem';
import styles from './AssemblyPlan.module.css';

export default function AssemblyPlan({
  status, societyId, userId, plan, roles, actions, isAuthorized,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
  }, []);

  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h2>
          {t('assembly.planification.title')}
        </h2>
        {
          isAuthorized
          && (
            <Link
              to={`/${societyId}/assemblee/create`}
              className="button"
            >
              {t('assembly.planification.button')}
            </Link>
          )
        }
      </header>
      {
        (plan && plan.length > 0)
          ? (
            <ul className={styles.list}>
              {
              plan.map((assembly) => (
                <AssemblyPlanItem
                  key={assembly.id}
                  assembly={assembly}
                  deleteAssembly={() => actions.deleteAssemblyPlanById(assembly.id)}
                  userId={userId}
                  societyId={societyId}
                  roles={roles}
                  actions={actions}
                />
              ))
            }
            </ul>
          )
          : (
            <div className={shared.center}>
              <h3>
                {t('assembly.planification.empty')}
              </h3>
              {
                isAuthorized
                && (
                  <Link
                    to={`/${societyId}/assemblee/create`}
                    className={`button ${styles.plan}`}
                  >
                    {t('assembly.planification.title')}
                  </Link>
                )
              }
            </div>
          )
      }
    </section>
  );
}
