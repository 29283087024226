import React, { useRef } from 'react';

import * as statuses from '../../../../services/store/states';
import stylesInput from '../shared/Input.module.css';
import styles from './InputDate.module.css';

export default function InputDate({
  label, hint,
  disabled, state,
  day = '', month = '', year = '',
  includeDay = false,
  onChange,
  className,
  required,
  name,
  onBlur,
}) {
  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const getContainerClass = () => {
    if (disabled) {
      return `${styles.disabled} ${className}`;
    }
    if (state === 'SUCCESS') {
      return `${styles.success} ${className}`;
    }
    if (state === statuses.FAIL) {
      return `${styles.container} ${className}`;
    }
    return `${styles.container} ${className}`;
  };

  const getInputClass = () => {
    if (disabled) {
      return `${stylesInput.input} ${styles.inputDisabled}`;
    }
    if (state === 'SUCCESS') {
      return `${stylesInput.input} ${styles.inputSuccess}`;
    }
    if (state === statuses.FAIL) {
      return `${stylesInput.input} ${styles.inputError}`;
    }
    return stylesInput.input;
  };

  const getHintClass = () => {
    if (disabled) {
      return `${styles.hint} ${styles.hintDisabled}`;
    }
    if (state === 'SUCCESS') {
      return `${styles.hint} ${styles.hintSuccess}`;
    }
    if (state === statuses.FAIL) {
      return `${styles.hint} ${styles.hintError}`;
    }
    return styles.hint;
  };

  const handleMonthChange = (e) => {
    if (e.target.value === '') {
      if (includeDay) {
        onChange({ day, month: '', year }, e);
      } else {
        onChange({ month: '', year }, e);
      }
      return;
    }
    const monthInput = (e.target.value);
    if (!Number.isNaN(monthInput)) {
      if (monthInput >= 0 && monthInput <= 12 && monthInput.length < 3) {
        if (includeDay) {
          onChange({ day, month: e.target.value, year }, e);
        } else {
          onChange({ month: e.target.value, year }, e);
        }
        if (monthInput.length === 2) {
          monthRef.current.blur();
          yearRef.current.focus();
        }
      }
    }
  };

  const handleMonthBlur = (e) => {
    if (e.target.value !== '') {
      const monthInput = e.target.value;
      if (!Number.isNaN(monthInput)) {
        if (monthInput >= 0 && monthInput <= 12 && monthInput.length < 3) {
          const options = { minimumIntegerDigits: 2, useGrouping: false };
          if (typeof onBlur === 'function') {
            onBlur({
              day,
              month: Number.parseInt(e.target.value, 10).toLocaleString('fr-FR', options),
              year,
            }, e);
          }
        }
      }
    }
  };

  const handleYearChange = (e) => {
    if (e.target.value === '') {
      if (includeDay) {
        onChange({ day, month, year: '' }, e);
      } else {
        onChange({ month, year: '' }, e);
      }
      return;
    }
    const yearInput = e.target.value;
    if (!Number.isNaN(yearInput) && yearInput.length < 5) {
      if (includeDay) {
        onChange({ day, month, year: e.target.value }, e);
      } else {
        onChange({ day, month, year: e.target.value }, e);
      }
    }
  };

  const handleDayChange = (e) => {
    if (e.target.value === '') {
      onChange({ day: '', month, year }, e);
      return;
    }
    const dayInput = (e.target.value);
    if (!Number.isNaN(dayInput)) {
      if (dayInput >= 0 && dayInput <= 31 && dayInput.length < 3) {
        if (dayInput !== '00') {
          onChange({ day: dayInput, month, year }, e);
          if (dayInput.length === 2) {
            dayRef.current.blur();
            monthRef.current.focus();
          }
        }
      }
    }
  };

  const handleDayBlur = (e) => {
    if (e.target.value !== '') {
      const dayInput = e.target.value;
      if (!Number.isNaN(dayInput)) {
        if (dayInput >= 0 && dayInput <= 31 && dayInput.length < 3) {
          if (dayInput !== '00') {
            const options = { minimumIntegerDigits: 2, useGrouping: false };
            if (typeof onBlur === 'function') {
              onBlur({
                day: Number.parseInt(dayInput, 10).toLocaleString('fr-FR', options),
                month,
                year,
              }, e);
            }
          }
        }
      }
    }
  };

  return (
    <div className={getContainerClass()}>
      <p
        className={styles.label}
      >
        {(required) && (<span className={styles.required}> * </span>)}
        { label }
      </p>
      <div className={styles.row}>
        <div
          className={getInputClass()}
          hidden={!includeDay}
        >
          <input
            ref={dayRef}
            type="text"
            className={styles.month}
            placeholder="DD"
            value={day || ''}
            onChange={handleDayChange}
            name={(name) || undefined}
            onBlur={handleDayBlur}
          />
        </div>
        <span
          className={styles.separator}
          hidden={!includeDay}
        >
          /
        </span>
        <div className={getInputClass()}>
          <input
            ref={monthRef}
            type="text"
            className={styles.month}
            placeholder="MM"
            value={month || ''}
            onChange={handleMonthChange}
            name={(name) || undefined}
            onBlur={handleMonthBlur}
          />
        </div>
        <span className={styles.separator}>
          /
        </span>
        <div className={getInputClass()}>
          <input
            ref={yearRef}
            type="text"
            className={styles.year}
            placeholder="YYYY"
            value={year || ''}
            onChange={handleYearChange}
            name={(name) || undefined}
          />
        </div>
      </div>
      <p
        className={getHintClass()}
      >
        {hint}
      </p>
    </div>
  );
}
