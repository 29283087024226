import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OperationsShareholders from './OperationsShareholders';
import { resetCreation } from '../../../services/store/features/shareholders/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    resetCreation,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(OperationsShareholders);
