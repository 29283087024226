import * as types from './types';

export function openPopUp(title, description, children) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_POP_UP,
      payload: {
        isOpen: true,
        title,
        description,
        children,
      },
    });
  };
}

export function closePopUp() {
  return async (dispatch) => {
    dispatch({
      type: types.SET_POP_UP,
      payload: {
        isOpen: false,
        title: null,
        description: null,
        children: null,
      },
    });
  };
}
