import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InputText from '../../../components/library/Input/InputText/InputText';
import InputDate from '../../../components/library/Input/InputDate/InputDate';
import Button from '../../../components/library/Button/Button';
import * as statuses from '../../../services/store/states';

import formValidation from '../OperationsCreation/formValidation';

import styles from './OperationsEdit.module.css';

function OperationsEdit({
  operationHistory, history, societyId, actions,
}) {
  const [historySelected, setHistorySelected] = useState(-1);
  const [newHistory, setNewHistory] = useState({});
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    if (operationHistory.status === statuses.IDLE) {
      actions.fetchHistory();
    }
  }, []);

  useEffect(() => {
    if (historySelected !== -1) {
      const selected = operationHistory.history
        .find((h) => h.id === Number.parseInt(historySelected, 10));
      if (selected) {
        const date = new Date(selected.date.begin).toLocaleString('fr', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const selectedDate = (typeof selected.date.begin === 'string')
          ? {
            day: date.split('/')[0],
            month: date.split('/')[1],
            year: date.split('/')[2],
          }
          : selected.date.begin;
        setNewHistory({
          ...selected,
          sharesPrice: selected.parameters.sharesPrice,
          emissionPrice: selected.parameters.emissionPrice,
          unitSharePrice: selected.parameters.unitSharePrice,
          date: selectedDate,
        });
      } else {
        console.error('[ERROR] selected operation history is undefined :', historySelected, operationHistory.history);
      }
    }
  }, [historySelected]);

  const handleChange = (event, newValue) => {
    setNewHistory((state) => ({
      ...state,
      [event.target.name]: event.target.value || newValue,
    }));
  };

  const submitForUpdate = () => {
    const errorsForm = formValidation(newHistory);
    if (Object.keys(errors).length > 0) {
      setErrors(errorsForm);
      return;
    }
    const { date } = newHistory;
    const operation = {
      id: newHistory.id,
      shareholders: newHistory.shareholders,
      type: (newHistory.type === 'souscription') ? 'souscription' : 'cession',
      date: `${date.day}/${date.month}/${date.year}`,
      name: newHistory?.name,
      oldName: operationHistory.history
        .find((h) => h.id === Number.parseInt(historySelected, 10)).name,
      parameters: {
        ...newHistory?.parameters,
        sharesPrice: Number.parseInt(newHistory.sharesPrice, 10),
        emissionPrice: Number.parseInt(newHistory.emissionPrice, 10),
        unitSharePrice: (newHistory.type === 'souscription')
          ? Number.parseInt(newHistory.sharesPrice, 10)
            + Number.parseInt(newHistory.emissionPrice, 10)
          : Number.parseInt(newHistory.unitSharePrice, 10),
      },
    };
    actions.setOperation(operation);
    if (newHistory.type === 'souscription') {
      history.push(`/${societyId}/actionnaires/operations/edit/capital`);
    } else {
      history.push(`/${societyId}/actionnaires/operations/edit/update/transfert`);
    }
  };

  return (
    <div className={styles.choices}>
      <div className={styles.header}>
        <h2>Ajouter une operation</h2>
        <Link
          to={`/${societyId}/actionnaires/operations/edit/choice`}
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        <h3>
          {t('operation.editOperation')}
        </h3>
        <div>
          <form>
            <select
              className={styles.selection}
              value={historySelected}
              onChange={(e) => {
                setHistorySelected(e.target.value);
              }}
            >
              <option
                value={-1}
                disabled
              >
                {`-- ${t('operation.choice')} --`}
              </option>
              {
                operationHistory.history.map((h) => (
                  <option key={h.id} value={h.id}>
                    { h.name }
                  </option>
                ))
              }
            </select>
            {
              (historySelected !== -1)
              && (
                <div>
                  <InputText
                    label={t('operation.name')}
                    hint={t('operation.nameHint')}
                    name="name"
                    valueInput={newHistory?.name || ''}
                    onTextChange={handleChange}
                    state={errors.name && statuses.FAIL}
                  />
                  {
                    (newHistory.type === 'souscription')
                    && (
                      <div>
                        <InputText
                          label={t('operation.parValue')}
                          type="number"
                          name="sharesPrice"
                          valueInput={newHistory?.sharesPrice}
                          onTextChange={handleChange}
                          state={errors.sharesPrice && statuses.FAIL}
                          hint={errors.sharesNumber}
                          icon="€"
                        />
                        <InputText
                          label={t('operation.premium')}
                          type="number"
                          name="emissionPrice"
                          valueInput={newHistory?.emissionPrice}
                          onTextChange={handleChange}
                          state={errors.emissionPrice && statuses.FAIL}
                          hint={errors.emissionPrice}
                          icon="€"
                        />
                        <p className={styles.label}>
                          {t('operation.unitValue')}
                        </p>
                        <div className={styles.disabledInput}>
                          {
                            (Number.parseInt(newHistory?.sharesPrice, 10) || 0)
                            + (Number.parseInt(newHistory?.emissionPrice, 10) || 0)
                          }
                          €
                        </div>
                      </div>
                    )
                  }
                  {
                    (newHistory.type === 'cession')
                    && (
                      <div>
                        <InputText
                          label={t('operation.unitValue')}
                          type="number"
                          name="unitSharePrice"
                          valueInput={newHistory?.unitSharePrice}
                          onTextChange={handleChange}
                          state={errors.unitSharePrice && statuses.FAIL}
                          hint={errors.unitSharePrice}
                          icon="€"
                        />
                      </div>
                    )
                  }
                  <InputDate
                    label={t('operation.date')}
                    day={newHistory?.date?.day || ''}
                    month={newHistory?.date?.month || ''}
                    year={newHistory?.date?.year || ''}
                    onChange={(date, e) => {
                      e.target.name = 'date';
                      e.target.value = '';
                      handleChange(e, date);
                    }}
                    includeDay
                    state={errors.date && statuses.FAIL}
                    hint={errors.date}
                  />
                </div>
              )
            }
          </form>
          <Button
            label={t('operation.next')}
            onButtonClick={submitForUpdate}
            disabled={historySelected === -1}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(OperationsEdit);
