import React from 'react';

import stylesInput from '../shared/Input.module.css';
import styles from './TextArea.module.css';

export default function TextArea({
  label, placeholder, hint,
  valueInput, onTextChange,
  name, refs, required,
  error,
}) {
  return (
    <div className={`${styles.container}`}>
      <p
        className={styles.label}
      >
        { (required) && <span className={stylesInput.required}>*</span>}
        { label }
      </p>
      <div className={`${stylesInput.textarea} ${(error) ? styles.inputError : ''}`}>
        <textarea
          placeholder={placeholder}
          name={name}
          value={valueInput}
          onChange={onTextChange}
          ref={refs}
        />
      </div>
      {
        (error)
          ? <p className={`${styles.hint} ${styles.error}`}>{ error }</p>
          : <p className={`${styles.hint} ${styles.error}`}>{ hint }</p>
      }
      <p
        className={styles.hint}
      >
        {hint}
      </p>
    </div>
  );
}
