import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Searchbar from 'components/Searchbar/Searchbar';

export default function ShareholdersHeaderSearchbar({ query, actions }) {
  const { t } = useTranslation();
  return (
    <Searchbar
      placeholder={t('shareholders.search')}
      query={query}
      actions={actions}
    />
  );
}

ShareholdersHeaderSearchbar.defaultProps = {
  query: '',
};

ShareholdersHeaderSearchbar.propTypes = {
  query: PropTypes.string,
  actions: PropTypes.shape({
    setQuery: PropTypes.func.isRequired,
  }).isRequired,
};
