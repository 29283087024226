import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CKEditor from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

import Button from '../../../components/library/Button/Button';
import InputText from '../../../components/library/Input/InputText/InputText';
import * as statuses from '../../../services/store/states';

import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import shared from '../shared/assembly.module.css';

import template from './template';
import styles from './AssemblySummons.module.css';

export default function AssemblySummonsForm({
  assembly, actions, next, back, societyId, roles,
}) {
  const [object, setObject] = useState(assembly?.convocation?.object || 'Convocation assemblée générale');
  const [body, setBody] = useState(assembly?.convocation?.body || template.default);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const textAreaRef = useRef();
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  const submit = () => {
    if (!object) {
      setErrors((state) => ({
        ...state,
        object: t('assembly.summons.editor.error.object'),
      }));
    }
    if (!body) {
      setErrors((state) => ({
        ...state,
        body: t('assembly.summons.editor.error.body'),
      }));
    } else if (body && object) {
      const newAssembly = {
        ...assembly,
        convocation: {
          object,
          body,
        },
      };
      actions.updateAssemblyPlanById(newAssembly.id, newAssembly);
      next();
    }
  };

  const includeTextToCurrentPos = (event, text, ref) => {
    ref.current.model.change((writer) => writer
      .insertText(text, ref.current.model.document.selection.getFirstPosition()));
  };

  const handleTemplateChange = (e) => {
    if (e.target.value === 'default') {
      setSelectedTemplate('default');
      setBody(template.default);
    }
    if (e.target.value === 'custom') {
      setSelectedTemplate('custom');
      setBody(template.custom);
    }
  };

  return (
    <section className={styles.summons}>
      <AssemblyHeader
        societyId={societyId}
        roles={roles}
      />
      <section className={shared.section}>
        <h2 className={styles.title}>
          { assembly?.title }
        </h2>
        <div className={styles.content}>
          <header className={styles.spacedRow}>
            <h3 className={styles.subTitle}>
              {t('assembly.summons.title')}
            </h3>
          </header>
          <main className={`${styles.editor}`}>
            <form className={styles.form}>
              <InputText
                label={t('assembly.summons.editor.name')}
                type="text"
                name="object"
                valueInput={object || ''}
                onTextChange={(e) => setObject(e.target.value)}
                state={errors.object && statuses.FAIL}
                hint={errors.object}
              />
              <p>Template</p>
              <select
                value={selectedTemplate}
                className={styles.dropdown}
                onChange={handleTemplateChange}
              >
                <option value="default">
                  Default
                </option>
                <option value="custom">
                  {t('assembly.summons.editor.custom')}
                </option>
              </select>
              <div className={styles.textEditor}>
                <CKEditor
                  editor={BalloonEditor}
                  data={body}
                  onChange={(event, editor) => {
                    if (editor.getData() !== '') setBody(editor.getData());
                    else setBody('<p></p>');
                  }}
                  onInit={(editor) => {
                    textAreaRef.current = editor;
                  }}
                />
              </div>
            </form>
            <div className={styles.side}>
              <div className={styles.description}>
                <h4>
                  {t('assembly.summons.editor.help.assembly')}
                </h4>
                <p>
                  {t('assembly.summons.editor.help.modality')}
                  <br />
                  <br />
                  {t('assembly.summons.editor.help.doubt')}
                </p>
              </div>
              <div>
                <p className={styles.subtitle}>
                  {t('assembly.summons.editor.variables')}
                </p>
                <p className={styles.hint}>
                  {t('assembly.summons.editor.variablesHint')}
                  {' '}
                  {'{{ variable }}'}
                </p>
                <p>
                  {t('assembly.summons.editor.shareholders')}
                </p>
                <div className={styles.buttonGrid}>
                  <Button
                    label={t('assembly.summons.editor.firstname')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{firstName}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.lastname')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{lastName}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.address')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{address}}', textAreaRef)}
                  />
                  <Button
                    label="Email"
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{mail}}', textAreaRef)}
                  />
                </div>
                <p>
                  {t('assembly.summons.editor.assembly')}
                </p>
                <div className={styles.buttonGrid}>
                  <Button
                    label="Date"
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{date}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.place')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{place}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.time')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{time}}', textAreaRef)}
                  />
                </div>
                <p>
                  {t('assembly.summons.editor.society')}
                </p>
                <div className={styles.buttonGrid}>
                  <Button
                    label={t('assembly.summons.editor.societyName')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{societyName}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.societyAddress')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{societyAddress}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.societyLastname')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{societyLastName}}', textAreaRef)}
                  />
                  <Button
                    label={t('assembly.summons.editor.societyFirstname')}
                    size="small"
                    variant="outline"
                    onButtonClick={(e) => includeTextToCurrentPos(e, '{{societyFirstName}}', textAreaRef)}
                  />
                </div>
              </div>
            </div>
          </main>
          <div className={styles.submit}>
            <button
              type="button"
              className={styles.outline}
              onClick={back}
            >
              {t('assembly.summons.editor.back')}
            </button>
            <Button
              label={t('assembly.summons.editor.submit')}
              onButtonClick={submit}
            />
          </div>
        </div>
      </section>
    </section>
  );
}
