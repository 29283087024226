import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OperationsChoice from './OperationsChoice';
import { setSocietyId } from '../../../services/store/features/society/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { setOperation, addAllAyomiOperations } from '../../../services/store/features/operations/actions';

const mapStateToProps = (state) => ({
  societyId: state.society.id,
  idHolding: state.society.idHolding,
  userId: state.user.id,
  history: state.history,
  shareholders: state.shareholders,
  operationImported: state.society.operation_ayomi_imported,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSocietyId, fetchHistory, fetchAllShareholders, setOperation, addAllAyomiOperations,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsChoice);
