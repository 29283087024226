import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import ChatContactList from '../../components/Chat/ChatContactList';
import ChatRoom from '../../components/Chat/ChatRoom';

import * as events from '../../services/socket/eventType';
import { onUserCreation } from '../../services/socket/events';
import * as statuses from '../../services/store/states';
import { SocketContext } from '../../services/socket/socket';

import styles from './Messages.module.css';

export default function Messages({
  userStatus, shareholdersStatus, contactStatus, userId, activeId, societyId, actions,
}) {
  const socket = useContext(SocketContext);
  const [isConnected, setIsConnected] = useState(true);
  const { t } = useTranslation();
  const { society } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (userStatus === statuses.IDLE) {
      actions.fetchUserInfo(userId, socket);
    }
  }, []);

  useEffect(() => {
    if (societyId && shareholdersStatus === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (societyId
      && userStatus === statuses.SUCCESS
      && shareholdersStatus === statuses.SUCCESS
      && contactStatus) {
      onUserCreation(socket, userId);
      if (activeId === null) {
        actions.setMostRecentConversationAsActiveRoom();
      }
    }
  }, [societyId, userStatus, shareholdersStatus, contactStatus]);

  useEffect(() => {
    socket.on(events.CONNECT_ERROR, () => {
      setIsConnected(false);
    });
    return () => {
      socket.off(events.CONNECT_ERROR);
    };
  }, []);

  if (!isConnected) {
    return (
      <section>
        <p>
          {' '}
          {t('chat.notAvailable')}
          {' '}
        </p>
      </section>
    );
  }
  if (!societyId && !userId) {
    return (
      <section>
        <p>
          {' '}
          {t('chat.notAvailable')}
          {' '}
        </p>
      </section>
    );
  }
  return (
    <section className={styles.chat}>
      <Header
        title={t('navigation.chat')}
      />
      {
        (shareholdersStatus === statuses.SUCCESS)
        && (
          <main className={styles.content}>
            <ChatContactList />
            <ChatRoom />
          </main>
        )
      }
    </section>
  );
}
