import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventsPreview from './EventsPreview';
import { fetchEventsData, resetEventStatus } from '../../../services/store/features/events/actions';
import { openPopUp } from '../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  events: state.events.events,
  status: state.events.status,
  error: state.events.error,
  isInvestor: state.user.isInvestor,
  societyId: state.society.id,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchEventsData,
    openPopUp,
    resetEventStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsPreview);
