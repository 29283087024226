import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as statuses from '../../../services/store/states';
import Loading from '../../../components/library/Loading';
import { addMoralShareholdersFromHistory } from '../../../services/store/features/shareholders/utils';

import styles from './ShareholdersAccountList.module.css';

export default function ShareholdersAccountList({
  status, shareholders, actions, id, lastHistory, history,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (history === statuses.IDLE) {
      actions.fetchHistory();
    }
  }, []);

  const getSortedShareholders = () => {
    const filteredShareholders = shareholders
      .filter((shareholder) => shareholder?.societies?.includes(id));

    const allShareholders = addMoralShareholdersFromHistory(filteredShareholders, lastHistory);

    const getDate = (value) => {
      if (!value) return new Date();
      const [date] = value.split(' ');
      const [day, month, year] = date.split('/');
      return new Date(year, month, day);
    };

    allShareholders
      .sort((a, b) => {
        if (!a?.history || !b?.history) return 0;
        if (a?.history[0]?.date !== b?.history[0]?.date) {
          return (getDate(a?.history[0]?.date)
            - getDate(b?.history[0]?.date));
        }
        return Number.parseInt(b?.history[0]?.total, 10)
          - Number.parseInt(a?.history[0]?.total, 10);
      });

    return allShareholders;
  };

  return (
    <main className={styles.content}>
      {
          (status === statuses.IDLE || status === statuses.LOADING)
          && <Loading />
        }
      {
          (status === statuses.SUCCESS)
          && (
            <>
              <div className={styles.row}>
                <p className={styles.label}>N°</p>
                <p className={styles.label}>Date</p>
                <p className={styles.label}>{t('name')}</p>
                <p className={styles.label}>Email</p>
                <p className={`${styles.label} ${styles.number}`}>{t('history.sharesNumber')}</p>
              </div>
              {
                 (shareholders && shareholders.length > 0)
                 && getSortedShareholders().map((shareholder, key) => (
                   <div
                     key={`${((shareholder.sharesHistories && shareholder.sharesHistories.length > 0) && (shareholder.sharesHistories[shareholder?.sharesHistories?.length - 1]?.societyName ? shareholder?.societyName : '')) || ''} ${shareholder.id})`}
                     className={styles.row}
                   >
                     <p>{key + 1}</p>
                     <p>
                       {
                         (shareholder.sharesHistories
                         && shareholder.sharesHistories.length > 0)
                         && shareholder.sharesHistories[shareholder?.sharesHistories?.length - 1]
                           ?.date
                       }
                     </p>
                     <p>
                       {
                         shareholder?.societyName
                           ? shareholder.societyName
                           : `${shareholder.firstName} ${shareholder.lastName}`
                       }
                     </p>
                     <p>{shareholder.email}</p>
                     <p className={styles.number}>
                       {
                         shareholder.sharesHistories
                         && shareholder.sharesHistories.length > 0
                         && shareholder.sharesHistories[shareholder?.sharesHistories?.length - 1]
                           ?.total
                           ?.toLocaleString()
                       }
                     </p>
                   </div>
                 ))
               }
            </>
          )
        }
    </main>
  );
}
