import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Society from 'components/Society';
import DocumentsPreview from 'components/Documents/DocumentsPreview';
import EventsPreview from 'components/Events/EventsPreview';
import ReportsPreview from 'components/DashboardReportPreview';

import styles from './Dashboard.module.css';

export default function Dashboard({
  id, user, actions,
}) {
  const { society, userId } = useParams();

  useEffect(() => {
    if (!id) {
      actions.setSocietyId(society);
    }
    if (!user) {
      actions.fetchUserInfo(userId);
    }
  }, []);

  return (
    <section className={styles.dashboard}>
      <Society />
      <div className={styles.container}>
        <div>
          <DocumentsPreview />
          <ReportsPreview />
        </div>
        <EventsPreview />
      </div>
    </section>
  );
}
