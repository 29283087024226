import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addNewShareholders } from '../../../services/store/features/shareholders/actions';

import OperationsShareholdersFormPersonHandler from './OperationsShareholdersFormPersonHandler';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    addNewShareholders,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(OperationsShareholdersFormPersonHandler);
