import { getSocietyInfo, updateSocietyInfo } from '../../../clients/clientAPI';
import * as types from './types';
import { keysToCamel } from '../../../../utils/converter';

export function setSocietyId(id) {
  return (dispatch) => {
    dispatch({ type: types.SET_SOCIETY_ID, payload: id });
  };
}

export function fetchHoldingData(id) {
  return async (dispatch) => {
    if (!id) { return; }
    dispatch({
      type: types.FETCH_HOLDING_REQUEST,
    });
    try {
      const { data } = await getSocietyInfo(id);
      dispatch({
        type: types.FETCH_HOLDING_SUCCEEDED,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_HOLDING_FAILED,
        payload: err,
      });
    }
  };
}

export function fetchSocietyData(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_SOCIETY_REQUEST,
    });
    try {
      if (id) {
        const { data } = await getSocietyInfo(id);
        dispatch(fetchHoldingData(data.id_holding));
        dispatch({
          type: types.FETCH_SOCIETY_SUCCEEDED,
          payload: keysToCamel(data),
        });
      } else if (getState().society.id) {
        const { data } = await getSocietyInfo(getState().society.id);
        dispatch(fetchHoldingData(data.id_holding));
        dispatch({
          type: types.FETCH_SOCIETY_SUCCEEDED,
          payload: data,
        });
      } else {
        dispatch({
          type: types.FETCH_SOCIETY_FAILED,
          payload: 'No society selected',
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_SOCIETY_FAILED,
        payload: err,
      });
    }
  };
}

const setFileUrl = (file) => {
  try {
    if (typeof file === 'string') return file;
    return URL.createObjectURL(file);
  } catch (err) {
    console.error(err);
    return '';
  }
};

export function updateSocietyData(society) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    dispatch({ type: types.UPDATE_SOCIETY_REQUEST });
    try {
      await updateSocietyInfo(id, society);
      dispatch({
        type: types.UPDATE_SOCIETY_SUCCESS,
        payload: {
          ...getState().society,
          ...society,
          coverUrl: setFileUrl(society.coverUrl),
          logoUrl: setFileUrl(society.logoUrl),
        },
      });
      window.setTimeout(() => dispatch({ type: types.UPDATE_SOCIETY_RESET }, 500));
    } catch (err) {
      dispatch({ type: types.UPDATE_SOCIETY_FAIL, payload: err });
    }
  };
}
