import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportView from './ReportView';

import { fetchReportsData, setSelectedReport } from '../../services/store/features/report/actions';

const mapStateToProps = (state) => ({
  reports: state.reports.reports.data,
  status: state.reports.reports.status,
  roles: state.roles.roles,
  isInvestor: state.society.is_member && !state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSelectedReport,
    fetchReportsData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
