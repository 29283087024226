import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import InputText from '../../../components/library/Input/InputText/InputText';
import TextArea from '../../../components/library/Input/TextArea/TextArea';
import Button from '../../../components/library/Button/Button';
import useOutsideClick from '../../../components/library/hook/clickOutside';
import useForm from '../../../hook/useForm';
import * as statuses from '../../../services/store/states';

import validate from './validate';

import styles from './AssemblyResolution.module.css';

export default function AssemblyResolution({
  resolution, submit, deleteResolution, close,
}) {
  const { t } = useTranslation();

  const popupRef = useRef();
  useOutsideClick(popupRef, close);

  const submitHandler = (values) => {
    const newResolution = {
      ...resolution,
      ...values,
    };
    submit(newResolution);
    close();
  };

  const handleDelete = () => {
    deleteResolution(resolution.id);
    close();
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submitHandler, validate, resolution);

  return (
    <div className={styles.popUpContainer}>
      <section
        className={styles.popUp}
        ref={popupRef}
      >
        <h1 className={styles.title}>
          {t('assembly.resolutions.create')}
        </h1>
        <InputText
          label={t('assembly.resolutions.name')}
          name="title"
          valueInput={values.title || ''}
          onTextChange={handleChange}
          state={errors.title && statuses.FAIL}
          hint={errors.title}
        />
        <TextArea
          label={t('assembly.resolutions.content')}
          name="description"
          valueInput={values.description || ''}
          onTextChange={handleChange}
        />
        <div className={styles.buttonGroup}>
          <Button
            label={t('assembly.resolutions.back')}
            variant="outline"
            onButtonClick={close}
          />
          {
            (resolution && resolution.id && deleteResolution)
            && (
              <Button
                label={t('assembly.resolutions.delete')}
                variant="custom"
                className={styles.delete}
                onButtonClick={handleDelete}
              />
            )
          }
          <Button
            label={t('assembly.resolutions.validate')}
            onButtonClick={handleSubmit}
          />
        </div>
      </section>
    </div>
  );
}
