import React from 'react';
import { Notifications, Warning } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from '../Documentation.module.css';

export default function DocumentationReport({ societyId }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>
        {t('documentation.report.title')}
      </h2>
      <p className={styles.description}>
        {t('documentation.report.description')}
      </p>
      <h3>
        {t('documentation.report.step1.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.report.step1.description')}
        <NavLink
          to={`/${societyId}/rapport`}
          className={styles.link}
        >
          <Notifications className={styles.icon} />
          {t('documentation.report.step1.report')}
        </NavLink>
        {t('documentation.report.step1.description2')}
      </p>
      <h3>
        {t('documentation.report.step2.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.report.step2.description')}
      </p>
      <h3>
        {t('documentation.report.step3.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.report.step3.description')}
      </p>
      <p className={styles.warning}>
        <Warning className={styles.warningIcon} />
        {t('documentation.report.step3.warning')}
      </p>
    </>
  );
}
