import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './AssemblyCreation.module.css';
import shared from '../shared/assembly.module.css';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';

export default function AssemblyCreationSuccess({ societyId }) {
  const { t } = useTranslation();

  return (
    <section className={`${styles.section}`}>
      <header className={shared.header}>
        <h2>
          { t('assembly.creation.title') }
        </h2>
      </header>
      <main className={styles.success}>
        <SuccessAnimation />
        <h3 className={styles.successTitle}>
          { t('assembly.creation.success') }
        </h3>
        <p>
          { t('assembly.creation.preparation') }
        </p>
        <Link
          to={`/${societyId}/assemblee/plan`}
          className="button"
        >
          { t('assembly.creation.see') }
        </Link>
      </main>
    </section>
  );
}
