import { createNewOperation } from '../../../clients/clientAPI';
import { operationToApi } from './adapter';

// eslint-disable-next-line import/prefer-default-export
export async function createOperationsByShareholders(
  societyId, selectedShareholders, creation, operationStatus, historyId,
) {
  /*
   * Create an operation for each shareholder who bought
   * shares
   */
  const operations = selectedShareholders
    .filter((shareholder) => (
      (shareholder.buy && (shareholder.buy !== 0) && (shareholder.buy !== '') && (shareholder.buy !== '0'))
      || (shareholder.sell && (shareholder.sell !== 0) && (shareholder.sell !== '') && (shareholder.sell !== '0'))
    ))
    .map((shareholder) => ({
      ...creation,
      societyId,
      historyId,
      holding: window.location.href.includes('holding'),
      date: (typeof creation.date === 'string') ? (creation.date) : (`${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year}`),
      quantity: shareholder.buy !== 0 ? shareholder.buy : shareholder.sell,
      from: {
        shareholderId: -1,
      },
      to: {
        shareholderId: (shareholder.siren) ? Number.parseInt(shareholder.id.toString().replace('society', ''), 10) : shareholder.id,
      },
      to_society: shareholder.invest_by_society,
      status: `${operationStatus}`,
      parameters: {
        ...creation.parameters,
        sharesNumber: selectedShareholders.reduce((acc, s) => acc + (s.shares + (s.buy !== '' ? s.buy : 0)), 0),
      },
    }));
  const results = operations.map((operation) => createNewOperation(
    operationToApi({ ...operation, societyId }),
  ));
  const response = await Promise.all(results);
  return operations.map((operation, i) => ({
    ...operation,
    id: response[i].data.id,
    counter: response[i].data.counter,
  }));
}
