import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header/Header';
import DocumentsSearchbar from '../../components/Documents/DocumentsSearchbar';
import DocumentsSelection from '../../components/Documents/DocumentsSelection/DocumentsSelection';
import DocumentsTable from '../../components/Documents/DocumentsTable/DocumentsTable';
import DocumentRequest from '../../components/Documents/DocumentsRequest';
import DocumentsVisualisation from '../../components/Documents/DocumentsVisualisation';
import * as statuses from '../../services/store/states';
import { getFavoritesFilesSelection, getSortedDocumentsByQuery } from '../../services/store/features/documents/selectors';

import styles from './DocumentsByFolder.module.css';

export default function DocumentsByFolder({
  query, sort, status, documents, favorites, roles, actions, selectedDocument,
}) {
  const { folder } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    actions.setFolder(folder);
    if (status === statuses.IDLE) {
      actions.fetchDocumentsData();
    }
  }, []);

  const handlePopUp = () => {
    actions.openPopUp(
      'Demande de documents',
      'Besoin d\'un document ? On vous envoie les documents concernant l’opération',
      (<DocumentRequest />),
    );
  };
  return (
    <section className={styles.documents}>
      <Header
        title={(folder === 'favoris') ? t('documents.favorite') : folder}
        searchbar={<DocumentsSearchbar placeholder={t('documents.search')} />}
        button={{ label: t('documents.request'), action: handlePopUp, style: styles.button }}
        backFunction={history.goBack}
      />
      <main className={styles.content}>
        { (selectedDocument) && <DocumentsVisualisation />}
        {
          (query === '')
          && (
            <div className={styles.wrapper}>
              <DocumentsSelection
                title={t('documents.favorite')}
                documents={getFavoritesFilesSelection(documents, favorites)}
                selectDocument={(document) => actions.setSelectedDocument(document)}
                handleChange={(id, value) => actions.updateDocument(id, value)}
                favorites={favorites}
              />
            </div>
          )
        }
        <DocumentsTable
          documents={getSortedDocumentsByQuery(documents, query, sort)}
          updateDocumentById={actions.updateDocument}
          selectDocument={(document) => actions.setSelectedDocument(document)}
          sort={actions.setSort}
          roles={roles}
          favorites={favorites}
        />
      </main>
    </section>
  );
}
