import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DescriptionIcon from '@material-ui/icons/Description';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import styles from './AssemblyRegister.module.css';

export default function AssemblyRegisterArchive({
  archive, openDocument,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <li className={styles.list}>
      <button
        type="button"
        className={styles.archiveButton}
        onClick={() => setIsOpen((state) => !state)}
      >
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <DescriptionIcon />
          </div>
          <p className={styles.title}>
            {archive.title}
          </p>
        </div>
        {
          (isOpen)
            ? <ExpandLessIcon />
            : <ChevronRightIcon />
        }
      </button>
      {
        (isOpen)
        && (
          <ul className={styles.documentsList}>
            {
              (archive?.documents && (archive.documents.length > 1))
                ? archive.documents.map((doc) => (
                  <div
                    key={doc.id}
                    className={styles.documentContainer}
                  >
                    <button
                      type="button"
                      className={styles.documentButton}
                      aria-label={`Voir ${doc.title}`}
                      onClick={() => openDocument(doc.url)}
                    >
                      <DescriptionIcon fontSize="large" />
                    </button>
                    <p className={styles.documentTitle}>{doc.title}</p>
                  </div>
                ))
                : (
                  <p>{ t('assembly.registry.emptyAssembly') }</p>
                )
            }
          </ul>
        )
      }
    </li>
  );
}
