import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'views/Operations/OperationsCreation/OperationCreation.module.css';

export default function OperationHeader({ type, step = 0 }) {
  const { t } = useTranslation();
  const OPERATION_TYPE = {
    transfert: 'operation.steps.transfer',
    capital: 'operation.steps.capital',
    reduction: 'operation.steps.reduction',
  };
  return (
    <div className={styles.steps}>
      <p className={`${styles.step} ${(step === 0) ? styles.active : ''}`}>
        <span className={styles.number}>
          1
        </span>
        {t('operation.steps.creation')}
      </p>
      <p className={`${styles.step} ${(step === 1) ? styles.active : ''}`}>
        <span className={styles.number}>
          2
        </span>
        {t(OPERATION_TYPE[type])}
      </p>
    </div>
  );
}
