import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './EventsPreview.module.css';
import EventsDescription from '../EventsDescription';
import ImgPlaceholder from '../../ImgPlaceholder';

function EventsPreviewList({
  societyId, history, events, openPopUp, reset,
}) {
  const openDescription = (event) => {
    reset();
    openPopUp(
      null,
      null,
      <EventsDescription event={event} />,
    );
  };

  const handleClick = (event) => {
    if (event.assembly) {
      history.push(`${societyId}//assemblee/investisseur/${event.assembly}`);
    } else {
      openDescription(event);
    }
  };

  return (
    <ul className={styles.list}>
      {
        events.map((item) => (
          <button
            type="button"
            className={styles.item}
            key={item.id}
            onClick={() => handleClick(item)}
          >
            <ImgPlaceholder
              alt={item?.title || ''}
              className={styles.img}
              src={item?.imagePath}
            />
            <div>
              <p className={styles.title}>
                { item?.title || '' }
              </p>
              <p className={styles.date}>
                {`${item.date} ${(item.isAllDay) ? '' : `- ${item?.hourBeginning}`}`}
              </p>
            </div>
          </button>
        ))
      }
    </ul>
  );
}

export default withRouter(EventsPreviewList);
