import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SettingsInvestorsRow.module.css';

export default function SettingsInvestorsRow({ investor }) {
  const { t } = useTranslation();
  return (
    <div className={styles.investor}>
      <p>
        {`${investor.firstName} ${investor.lastName}`}
      </p>
      <p>{investor.email}</p>
      <p>
        {
          investor.firstLogin
            ? t('settings.investors.invitation-send')
            : t('settings.investors.user-activated')
        }
      </p>
    </div>
  );
}
