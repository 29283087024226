import React, { useRef } from 'react';

import Button from '../../../components/library/Button/Button';
import useOutsideClick from '../../../components/library/hook/clickOutside';

import styles from './AssemblyResolution.module.css';

export default function AssemblyResolutionReader({
  resolution, close,
}) {
  const popupRef = useRef();
  useOutsideClick(popupRef, close);

  return (
    <div className={styles.popUpContainer}>
      <section
        className={styles.popUp}
        ref={popupRef}
      >
        <h2 className={styles.title}>
          {
            resolution.title
          }
        </h2>
        <p>
          {
            resolution.description
          }
        </p>
        <div className={styles.buttonGroup}>
          <Button
            label="Fermer"
            variant="outline"
            onButtonClick={close}
          />
        </div>
      </section>
    </div>
  );
}
