export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED';

export const FETCH_PLANNED_REPORTS_REQUEST = 'FETCH_PLANNED_REPORTS_REQUEST';
export const FETCH_PLANNED_REPORTS_SUCCESS = 'FETCH_PLANNED_REPORTS_SUCCESS';
export const FETCH_PLANNED_REPORTS_FAILED = 'FETCH_PLANNED_REPORTS_FAILED';

export const FETCH_REPORTS_BY_ID_REQUEST = 'FETCH_REPORTS_BY_ID_REQUEST';
export const FETCH_REPORTS_BY_ID_SUCCESS = 'FETCH_REPORTS_BY_ID_SUCCESS';
export const FETCH_REPORTS_BY_ID_FAILED = 'FETCH_REPORTS_BY_ID_FAILED';

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_PUBLISHED_SUCCESS = 'CREATE_REPORT_PUBLISHED_SUCCESS';
export const CREATE_REPORT_FAILED = 'CREATE_REPORT_FAILED';

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILED = 'UPDATE_REPORT_FAILED';
export const UPDATE_REPORT_PUBLISHED_SUCCESS = 'UPDATE_REPORT_PUBLISHED_SUCCESS';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILED = 'DELETE_REPORT_FAILED';

export const SET_FOLDER = 'SET_FOLDER';

export const SET_EDITION_REPORT = 'SET_EDITION_REPORT';

export const RESET_ACTIVE_REPORTS = 'RESET_ACTIVE_REPORTS';
