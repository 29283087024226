import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import TextArea from '../../library/Input/TextArea/TextArea';
import Button from '../../library/Button/Button';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../../Error/ErrorAnimation/ErrorAnimation';
import useOutsideClickAlert from '../../library/hook/clickOutside';

import * as statuses from '../../../services/store/states';
import styles from './AssemblyMessage.module.css';

export default function AssemblyMessage({ status, submit, close }) {
  const [message, setMessage] = useState('');
  const popup = useRef();
  useOutsideClickAlert(popup, close);
  if (status === 'success') {
    return (
      <div className={styles.message}>
        <section className={`${styles.popup} ${styles.notification}`} ref={popup}>
          <SuccessAnimation />
          <h2>Votre message a été envoyé</h2>
          <p>
            Vous recevrez une réponse sur la boîte e-mail associée à votre compte
          </p>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
        </section>
      </div>
    );
  }
  if (status === statuses.FAIL) {
    return (
      <div className={styles.message}>
        <section className={`${styles.popup} ${styles.notification}`} ref={popup}>
          <ErrorAnimation />
          <h2>Votre message n&apos;a pas été envoyé</h2>
          <p>
            Un problème technique est survenue, le message n&apos;a pas pu être envoyé.
            Nous vous invitons à réessayer ultérieurement.
            Si le problème persiste, contactez notre équipe.
          </p>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
        </section>
      </div>
    );
  }
  return (
    <div className={styles.message}>
      <section className={styles.popup} ref={popup}>
        <h2>
          Envoyer une question
        </h2>
        <TextArea
          label="Votre message"
          valueInput={message}
          onTextChange={(e) => setMessage(e.target.value)}
        />
        <div className={styles.buttonGroup}>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
          <Button
            label={(status !== statuses.LOADING) ? 'Envoyer' : 'Envoi en cours'}
            icon={(status === statuses.LOADING) && <FontAwesomeIcon icon={faSpinner} spin />}
            onButtonClick={() => submit(message)}
          />
        </div>
      </section>
    </div>
  );
}
