import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DashboardReportPreview from './DashboardReportPreview';
import { fetchReportsData } from '../../services/store/features/report/actions';

const mapStateToProps = (state) => ({
  status: state.reports.reports.status,
  reports: state.reports.reports.data.slice(0, 2),
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchReportsData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardReportPreview);
