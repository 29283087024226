import React from 'react';
import { ArrowForwardIos, Person, Warning } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from '../Documentation.module.css';

export default function DocumentationShareholders({ societyId }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>
        {t('documentation.shareholders.title')}
      </h2>
      <p className={styles.description}>
        {t('documentation.shareholders.description')}
      </p>
      <p className={styles.warning}>
        <Warning className={styles.warningIcon} />
        {t('documentation.shareholders.warning')}
      </p>
      <h3>
        {t('documentation.shareholders.step1.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.shareholders.step1.description')}
        <span className={`${styles.step} ${styles.paragraph}`}>
          <Person className={styles.textIcon} />
          {t('documentation.shareholders.step1.shareholders')}
        </span>
        <ArrowForwardIos className={styles.textIcon} />
        <NavLink
          to={`/${societyId}/actionnaires/operations/edit/choice`}
          className={styles.link}
        >
          {t('documentation.shareholders.step1.link')}
        </NavLink>
        {t('documentation.shareholders.step1.select')}
        <span className={`${styles.step} ${styles.span}`}>
          {t('documentation.shareholders.step1.increase')}
        </span>
        {t('documentation.shareholders.step1.description2')}
      </p>
      <h3>
        {t('documentation.shareholders.step2.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.shareholders.step2.description')}
        <NavLink
          to={`/${societyId}/actionnaires/operations/edit/choice`}
          className={styles.link}
        >
          {t('documentation.shareholders.step2.add')}
        </NavLink>
      </p>
      <h3>
        {t('documentation.shareholders.step3.title')}
      </h3>
      <p className={styles.paragraph}>
        {t('documentation.shareholders.step3.description')}
        <NavLink
          to={`/${societyId}/actionnaires/operations/edit/choice`}
          className={styles.link}
        >
          {t('documentation.shareholders.step2.add')}
        </NavLink>
        {t('documentation.shareholders.step3.description2')}
      </p>
      <p>
        {t('documentation.shareholders.step3.end')}
      </p>
    </>
  );
}
