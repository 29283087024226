import React from 'react';
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';

import styles from './Onboarding.module.css';

export default function Onboarding({ stepIndex, isUserAuthorized, actions }) {
  const { t } = useTranslation();
  return (
    <Joyride
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      stepIndex={stepIndex}
      callback={actions.handleNext}
      styles={{
        options: {
          primaryColor: '#526af2',
          width: (stepIndex === 0) ? 500 : 300,
        },
      }}
      locale={{
        back: t('onboarding.back'),
        close: t('onboarding.close'),
        last: t('onboarding.last'),
        next: t('onboarding.next'),
        open: t('onboarding.open'),
        skip: t('onboarding.skip'),
      }}
      steps={(!isUserAuthorized) ? [
        {
          content: (
            <>
              <div>
                <h1 className={styles.onboarding}>
                  {t('onboarding.title')}
                </h1>
                <p>
                  {t('onboarding.description')}
                </p>
              </div>
            </>
          ),
          placement: 'center',
          target: 'body',
        },
        {
          target: '.userInvestment',
          content: t('onboarding.document'),
        },
        {
          target: '.report',
          content: t('onboarding.report'),
        },
        {
          target: '.assembly',
          content: t('onboarding.assembly'),
        },
        {
          target: '.documentation',
          content: (
            <>
              <p>
                {t('onboarding.documentation.help')}
              </p>
              <p>
                {t('onboarding.documentation.contact')}
              </p>
            </>
          ),
        },
      ]
        : [
          {
            content: (
              <>
                <div>
                  <h1 className={styles.onboarding}>
                    {t('onboarding.title')}
                  </h1>
                  <p>
                    {t('onboarding.description')}
                  </p>
                </div>
              </>
            ),
            placement: 'center',
            target: 'body',
          },
          {
            target: '.report',
            content: t('onboarding.reportOwner'),
          },
          {
            target: '.assembly',
            content: t('onboarding.assemblyOwner'),
          },
          {
            target: '.captable',
            spotlightClicks: true,
            hideFooter: true,
            content: (
              <>
                <div>
                  <p>
                    {t('onboarding.captable.title')}
                  </p>
                  <p className={styles.description}>
                    {t('onboarding.captable.description')}
                  </p>
                </div>
              </>
            ),
          },
          {
            target: '.operation',
            content: (
              t('onboarding.operation')
            ),
          },
          {
            target: '.addOperation',
            spotlightClicks: true,
            hideFooter: true,
            content: (
              <>
                <div>
                  <p>
                    {t('onboarding.addOperation.title')}
                  </p>
                  <p className={styles.description}>
                    {t('onboarding.addOperation.description')}
                  </p>
                </div>
              </>
            ),
          },
          {
            target: '.increaseOperation',
            content: (
              <>
                <div>
                  <p>
                    {t('onboarding.increaseOperation.title')}
                  </p>
                  <p className={styles.description}>
                    {t('onboarding.increaseOperation.description')}
                  </p>
                </div>
              </>
            ),
          },
          {
            target: '.ayomiOperation',
            content: t('onboarding.ayomiOperation'),
          },
          {
            target: '.documentation',
            content: (
              <>
                <p>
                  {t('onboarding.documentation.help')}
                </p>
                <p>
                  {t('onboarding.documentation.contact')}
                </p>
              </>
            ),
          },
        ]}
      run
    />
  );
}
