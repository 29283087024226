/* eslint-disable */
import React from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import styles from './ShareholdersTableRows.module.css';

export default function ShareholdersTableRow({ contact, openContact }) {
  const { t } = useTranslation();
  return (
    <li
      className={styles.tableRow}
    >
      <button
        type="button"
        className={styles.chevron}
        onClick={openContact}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <p className={styles.tableCell}>
        { contact?.invest_by_society ? contact?.societyName : `${contact.firstName} ${contact.lastName}`}
      </p>
      <p
        className={`${styles.tableCell} ${styles.onlyDesktop}`}
      >
        { t(`history.shareholders.${contact?.category?.toLowerCase()}`) }
      </p>
      <p className={styles.tableCell}>
        { contact.shares?.toLocaleString() || 0}
      </p>
      <p className={styles.tableCell}>
        { `${Number(contact.capital).toFixed(2)}%` }
      </p>
    </li>
  );
}
