import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblySummons from './AssemblySummons';
import {
  deleteAssemblyResolution,
  fetchAssemblyPlan,
  setActiveAssembly,
  updateAssemblyPlanById,
} from '../../../services/store/features/assembly/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';
import { splitDate } from '../../../utils/formatDate';
import { addToast } from '../../../services/store/features/toasts/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyPlan,
    fetchAllShareholders,
    setActiveAssembly,
    fetchHistory,
    updateAssemblyPlanById,
    deleteAssemblyResolution,
    addToast,
  }, dispatch),
});

const format = (values) => {
  if (values && typeof values.date === 'string') {
    const [time, date] = values.date.split(' ');
    const [day, month, year] = splitDate(date);
    return {
      time,
      ...values,
      date: {
        day,
        month,
        year,
      },
    };
  }
  return values;
};

const mapStateToProps = (state) => ({
  status: state.assembly.plan.status,
  assembly: format(state.assembly.plan.list
    .filter((item) => item
        && Number.parseInt(item.id, 10) === Number.parseInt(state.assembly?.selected, 10))[0]),
  shareholders: state.shareholders.shareholders
    .filter((shareholder) => shareholder.isInvestor === true),
  statusShareholders: state.shareholders.status,
  history: {
    status: state.history.status,
  },
  userId: state.user.id,
  societyId: state.society.id,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblySummons);
