import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyCreation from './AssemblyCreation';
import { createAssembly, resetAssemblyCreation } from '../../../services/store/features/assembly/actions';

const mapStateToProps = (state) => ({
  status: state.assembly.creation.status,
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    createAssembly,
    resetAssemblyCreation,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyCreation);
