import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closePopUp } from 'services/store/features/popup/actions';
import { getShareholdersExportLink, resetExportStatus } from 'services/store/features/shareholders/actions';

import ShareholdersExport from './ShareholdersExport';

const mapStateToProps = (state) => ({
  status: state.shareholders.exportStatus,
  exportLink: state.shareholders.export,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    closePopUp,
    getShareholdersExportLink,
    resetExportStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersExport);
