import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addNewShareholders, resetCreation } from 'services/store/features/shareholders/actions';

import OperationsShareholdersAsk from './OperationsShareholdersAsk';

const mapStateToProps = (state) => ({
  status: state.shareholders.creation.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    addNewShareholders,
    resetCreation,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsShareholdersAsk);
