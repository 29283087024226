import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Info, TrendingDown } from '@material-ui/icons';

import Button from '../../../components/library/Button/Button';
import AccountLoading from '../../../components/library/Loading';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../../../components/Error/ErrorAnimation/ErrorAnimation';
import * as statuses from '../../../services/store/states';
import logo from '../../../asset/logo.jpg';

import styles from './OperationsChoice.module.css';

const Choice = ({
  icon, link, title, description, disabled, className,
}) => (
  <Link
    to={link}
    className={`${styles.choice} ${(disabled) ? styles.disabled : ''} ${className}`}
  >
    <div className={styles.iconContainer}>
      { icon }
    </div>
    <h4>
      { title }
    </h4>
    <p>
      { description }
    </p>
  </Link>
);

export default function OperationsChoice({
  societyId, history, shareholders, isHolding, operationImported, actions,
}) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [error, setError] = useState();
  const [isAyomiOpen, setIsAyomiOpen] = useState(false);
  const { society } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    actions.setOperation();
    if (!societyId) {
      actions.setSocietyId(society);
    }
  }, []);

  useEffect(() => {
    if (history.status === statuses.IDLE) {
      actions.fetchHistory();
    }
    if (shareholders.status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, [societyId]);

  const openAyomiOperationPopUp = () => setIsAyomiOpen(true);
  const closeAyomiOperationPopUp = () => setIsAyomiOpen(false);
  const handleAyomiOperation = () => {
    actions.addAllAyomiOperations(setStatus, setError);
  };

  if (!societyId && !(history.status === 'success') && !(shareholders.status === 'success')) {
    return <p>{`${t('operation.loading')} ...`}</p>;
  }
  return (
    <div className={styles.choices}>
      {
        (isAyomiOpen)
        && (
          <div className={`fadeIn ${styles.background}`}>
            {
              (status === statuses.IDLE)
              && (
                <section className={styles.popup}>
                  <h1>
                    {t('operation.ayomi.warning.title')}
                  </h1>
                  <p>
                    {t('operation.ayomi.warning.description')}
                  </p>
                  <div className={styles.buttonGroup}>
                    <Button
                      label={t('operation.ayomi.warning.no')}
                      variant="ghost"
                      onButtonClick={closeAyomiOperationPopUp}
                    />
                    <Button
                      label={t('operation.ayomi.warning.yes')}
                      onButtonClick={handleAyomiOperation}
                    />
                  </div>
                </section>
              )
            }
            {
              (status === statuses.LOADING)
              && (
                <section className={styles.popup}>
                  <AccountLoading />
                  <h1 className={styles.center}>
                    {t('operation.ayomi.loading')}
                  </h1>
                </section>
              )
            }
            {
              (status === 'SUCCESS')
              && (
                <section className={styles.popup}>
                  <SuccessAnimation />
                  <h1>
                    {t('operation.ayomi.success')}
                  </h1>
                  <Button
                    label={t('close')}
                    onButtonClick={closeAyomiOperationPopUp}
                    className={styles.buttonError}
                  />
                </section>
              )
            }
            {
              (status === statuses.FAIL)
              && (
                <section className={styles.popup}>
                  <ErrorAnimation />
                  <h1 className={styles.errorTitle}>
                    {t('operation.ayomi.error')}
                  </h1>
                  <p className={styles.error}>
                    { error }
                  </p>
                  <Button
                    label={t('close')}
                    onButtonClick={closeAyomiOperationPopUp}
                    className={styles.buttonError}
                  />
                </section>
              )
            }
          </div>
        )
      }
      <div className={styles.header}>
        <h2>{t('operation.create')}</h2>
        <Link
          to={`/${societyId}/actionnaires/operations/`}
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        <h3>
          {t('operation.action')}
        </h3>
        {
          (window.location.href.includes('holding') && (!history.history || history.history.length === 0))
          && (
            <p className={styles.warning}>
              <Info className={styles.icon} />
              {t('operation.createOperationBeforeHolding')}
            </p>
          )
        }
        <div className={styles.row}>
          <Choice
            icon={<SyncAltIcon />}
            link={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/transfert`}
            title={t('operation.transfer')}
            description={t('operation.transferHint')}
            disabled={window.location.href.includes('holding')
              ? ((!shareholders.holding
                  || shareholders.holding.length < 1))
              : (!shareholders.shareholders || shareholders.shareholders.length < 1)}
          />
          <Choice
            icon={<TrendingUpIcon />}
            link={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/capital`}
            title={(history.history.length === 0) ? t('operation.creation') : t('operation.capital')}
            description={t('operation.capitalHint')}
            className="increaseOperation"
            disabled={(window.location.href.includes('holding') && (!history.history || history.history.length === 0))}
          />
          <Choice
            icon={<TrendingDown />}
            link={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/reduction`}
            title={t('operation.capitalReduction')}
            description={t('operation.capitalReductionHint')}
            className="increaseOperation"
            disabled={(window.location.href.includes('holding') && (!history.history || history.history.length === 0))}
          />
          {
            (!isHolding && !operationImported)
            && (
              <button
                type="button"
                className={`${styles.choice} ${(operationImported || status !== statuses.IDLE) ? styles.disabled : ''} ayomiOperation`}
                onClick={openAyomiOperationPopUp}
                disabled={operationImported || status !== statuses.IDLE}
              >
                <div className={styles.iconContainer}>
                  <img
                    src={logo}
                    alt="Ayomi"
                    className={styles.logo}
                  />
                </div>
                <h4>
                  {t('operation.ayomi.title')}
                </h4>
                <p>
                  {t('operation.ayomi.description')}
                </p>
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}
