import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Feedback from './Feedback';
import { sendFeedback, resetFeedback } from '../../services/store/features/contacts/actions';
import { setSocietyId } from '../../services/store/features/society/actions';

const mapStateToProps = (state) => ({
  status: state.contacts.feedback.status,
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    sendFeedback,
    resetFeedback,
    setSocietyId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
