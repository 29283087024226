import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DocumentsVisualisation from '../../../components/Documents/DocumentsVisualisation';
import * as statuses from '../../../services/store/states';

import AssemblyRegisterArchive from './AssemblyRegisterArchive';
import styles from './AssemblyRegister.module.css';

export default function AssemblyRegister({
  status, archives, societyId, isAuthorized,
  selectedDocument, actions,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAssemblyArchives();
    }
  }, []);

  return (
    <section className={styles.register}>
      { (selectedDocument) && <DocumentsVisualisation />}
      <header className={styles.header}>
        <h2>
          {t('assembly.registry.title')}
        </h2>
      </header>
      {
        (archives && archives.length > 0)
          ? (
            <ul className={styles.list}>
              {
              archives.map((archive) => (
                <AssemblyRegisterArchive
                  key={archive.id}
                  archive={archive}
                  openDocument={actions.setSelectedDocument}
                />
              ))
            }
            </ul>
          )
          : (
            <div className={styles.center}>
              <h3>
                {t('assembly.registry.empty')}
              </h3>
              {
                isAuthorized
                && (
                  <Link
                    to={`/${societyId}/assemblee/create`}
                    className={`button ${styles.plan}`}
                  >
                    {t('assembly.planification.title')}
                  </Link>
                )
              }
            </div>
          )
      }
    </section>
  );
}
