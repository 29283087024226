import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  userstatus: statuses.IDLE,
  error: null,
  documents: [],
  selectedDocument: null,
  userselectedDocument: null,
  creation: {
    status: statuses.IDLE,
    error: null,
  },
  usercreation: {
    status: statuses.IDLE,
    error: null,
  },
  query: '',
  userquery: '',
  folder: null,
  userfolder: null,
  sort: {
    value: '',
    asc: true,
  },
  usersort: {
    value: '',
    asc: true,
  },
  documentRequest: {
    status: statuses.IDLE,
    error: null,
  },
};

export default function documentsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_DOCUMENTS_SUCCEEDED:
      return {
        ...state,
        documents: [...payload],
        status: statuses.SUCCESS,
      };
    case types.FETCH_DOCUMENTS_FAILED:
      return {
        ...state,
        status: statuses.FAIL,
        error: payload,
      };
    case types.FETCH_USER_DOCUMENTS_REQUEST:
      return {
        ...state,
        userstatus: statuses.LOADING,
      };
    case types.FETCH_USER_DOCUMENTS_SUCCEEDED:
      return {
        ...state,
        userdocuments: [
          ...payload,
          {
            id: 'medlin',
            title: 'Réduction d\'impôt loi "Madelin" - Déclaration des revenus',
            date: '21/05/2022',
            url: `https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}media.ayomi.fr/media/public/document/declaration_revenus.pdf`,
          },
        ],
        userstatus: statuses.SUCCESS,
      };
    case types.FETCH_USER_DOCUMENTS_FAILED:
      return {
        ...state,
        userstatus: statuses.FAIL,
        usererror: payload,
      };
    case types.UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.UPDATE_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        status: statuses.SUCCESS,
        documents: payload,
      };
    case types.UPDATE_DOCUMENT_FAILED:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.SEND_DOCUMENT_REQUEST:
      return {
        ...state,
        documentRequest: {
          ...state.documentRequest,
          status: statuses.LOADING,
        },
      };
    case types.SEND_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        documentRequest: {
          ...state.documentRequest,
          status: statuses.SUCCESS,
        },
      };
    case types.SEND_DOCUMENT_FAILED:
      return {
        ...state,
        documentRequest: {
          ...state.documentRequest,
          status: 'failed',
        },
      };
    case types.RESET_DOCUMENT_REQUEST:
      return {
        ...state,
        documentRequest: {
          status: statuses.IDLE,
          error: null,
        },
      };
    case types.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.DELETE_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        documents: state.documents.filter((doc) => doc.id !== payload),
      };
    case types.DELETE_DOCUMENT_FAILED:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.DELETE_USER_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        userdocuments: state.userdocuments.filter((doc) => doc.id !== payload),
      };
    case types.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.UPLOAD_DOCUMENT_SUCCEEDED:
      return (!payload.iss) ? {
        ...state,
        creation: {
          ...state.creation,
          status: 'SUCCESS',
        },
        documents: [...state.documents, payload],
      } : {
        ...state,
        creation: {
          ...state.creation,
          status: 'SUCCESS',
        },
        usercreation: {
          ...state.creation,
          status: 'SUCCESS',
        },
        userdocuments: [...(state?.userdocuments || []), payload],
      };
    case types.UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: 'FAIL',
        },
      };
    case types.UPLOAD_DOCUMENT_RESET:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.IDLE,
        },
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_FOLDER:
      return {
        ...state,
        folder: payload,
      };
    case types.SET_SORT:
      return {
        ...state,
        sort: {
          value: payload,
          asc: (payload === state.sort.value) ? !state.sort.asc : true,
        },
      };
    case types.SET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: payload,
      };
    case types.SET_USER_SELECTED_DOCUMENT:
      return {
        ...state,
        userselectedDocument: payload,
      };
    case types.UPLOAD_USER_DOCUMENT_RESET:
      return {
        ...state,
        usercreation: {
          status: statuses.IDLE,
          error: null,
        },
      };
    default:
      return state;
  }
}
