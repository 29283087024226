import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Button from 'components/library/Button/Button';
import useOutsideClickAlert from 'components/library/hook/clickOutside';
import * as statuses from 'services/store/states';
import { sortByDate } from 'services/store/features/history/utils';

import formatDate from '../../../utils/formatDate';
import ShareholdersHeaderSearchbar from '../ShareholdersHeaderSearchbar';
import ShareholdersCapitalizationGraph from '../ShareholdersCapitalizationGraph/ShareholdersCapitalizationGraph';
import ShareholdersParameters from '../ShareholdersParameters/ShareholdersParameters';
import ShareholdersExport from '../ShareholdersExport';

import styles from './ShareholdersCapitalization.module.css';

export default function ShareholdersCapitalization({
  history, capital, isHolding = false, actions,
}) {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (history.status === statuses.IDLE) {
      actions.fetchHistory();
    }
  }, []);

  useOutsideClickAlert(dropdownRef, () => {
    setIsHistoryOpen(false);
  });

  const openExportPopUp = () => {
    actions.openPopUp(
      t('shareholders.export.title'),
      t('shareholders.export.description'),
      <ShareholdersExport />,
    );
  };

  const isDate = (date) => !Number.isNaN(date.getTime());

  const getDate = (dateString) => {
    const date = formatDate(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (isDate(date)) return date.toLocaleString('fr', options);
    return dateString;
  };

  const getReversedHistory = () => {
    const reversedHistory = sortByDate([...history.history]);
    reversedHistory.reverse();
    return reversedHistory;
  };

  if (!history || history.history.filter((currentHistory) => (currentHistory?.holding
    || false) === isHolding).length === 0) {
    return (
      <div className={styles.capitalisation}>
        <div className={styles.empty}>
          <h3>
            {t('history.empty')}
          </h3>
          <p>
            {t('history.emptyHint')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.capitalisation}>
      <div className={styles.row}>
        <h2 className={styles.title}>
          {t('history.title')}
        </h2>
        <div className={styles.tools}>
          <ShareholdersHeaderSearchbar />
          <Button
            label={t('shareholders.export.button')}
            onButtonClick={openExportPopUp}
            disabled={!capital || capital.length === 0}
          />
        </div>
      </div>
      <div className={styles.history}>
        <p className={styles.date}>
          {`${getDate(history?.date?.begin)} ${history?.name ? `- ${history?.name}` : ''}`}
        </p>
        {
          (history.hasHistory)
          && (
            <div ref={dropdownRef}>
              <button
                type="button"
                className={styles.links}
                onClick={() => setIsHistoryOpen((state) => !state)}
              >
                {t('history.see')}
                {
                  (isHistoryOpen)
                    ? <ExpandLessIcon />
                    : <ExpandMoreIcon />
                }
              </button>
              {
                (history.history && isHistoryOpen)
                && (
                  <ul className={styles.historyList}>
                    {
                      getReversedHistory()
                        .filter((currentHistory) => (currentHistory?.holding
                          || false) === isHolding)
                        .map((historyDate, i) => (
                          <button
                            type="button"
                            className={styles.historyButton}
                            onClick={() => {
                              actions.setActiveHistory(historyDate.id);
                              setIsHistoryOpen(false);
                            }}
                            key={historyDate.date.begin}
                          >
                            {`${getDate(historyDate?.date?.begin)} - ${(i !== 0) ? getDate(history.history[i - 1]?.date?.begin) : t('today')} - ${historyDate.name}`}
                          </button>
                        ))
                    }
                  </ul>
                )
              }
            </div>
          )
        }
      </div>
      {
        (capital && capital.length > 0) && (
          <ShareholdersCapitalizationGraph
            capital={capital}
          />
        )
      }
      <ShareholdersParameters
        parameters={history.parameters}
        isHolding={isHolding}
      />
    </div>
  );
}
