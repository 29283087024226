import { keysToCamel, keysToSnake } from '../../../../utils/converter';

export const formatAssembly = (assembly) => {
  const [date, time] = assembly.date.split(' ');
  return ({
    ...assembly,
    date,
    time,
  });
};

export const formatAssemblies = (data) => keysToCamel(data).map(formatAssembly);

export const formatAssemblyForBack = (archive) => keysToSnake({
  id: archive.id,
  convocation: {
    body: archive.convocation?.body || '',
    object: archive.convocation?.object || '',
  },
  invitations: archive.invitations || [],
  resolutions: archive.resolutions || [],
  date: `${(archive.date?.day) ? `${archive.date.day}/${archive.date.month}/${archive.date.year}` : archive.date} ${archive.time}`,
  place: archive.place,
  societyId: archive.societyId,
  title: archive.title,
});
