import { keysToSnake } from '../../../../utils/converter';

// eslint-disable-next-line import/prefer-default-export
export const historyToApi = (values) => keysToSnake({
  date: {
    begin: values.date.begin,
    end: values.date.end,
  },
  name: values.name,
  operationStatus: values.operationStatus,
  parameters: {
    sharesPrice: values.parameters.sharesPrice,
    sharesNumber: values.parameters.sharesNumber,
  },
  societyId: values.societyId,
  shareholders: values.shareholders,
});
