import {
  getExportShareholders,
  updateShareholdersById,
} from '../../../clients/clientAPI';
import { setContactsFromShareholders } from '../chat/actions';
import * as types from './types';
import formatDate from '../../../../utils/formatDate';
import { keysToCamel } from '../../../../utils/converter';
import {
  createShareholder,
  createShareholdersSociety,
  getInvestorsShareholders,
  getShareholders,
} from '../../../clients/shareholders';
import { legalShareholderToApi, shareholderToApi } from './adapter';

const sortHistory = (shareholder) => {
  const { sharesHistories } = shareholder;
  if (sharesHistories) {
    sharesHistories.sort((a, b) => formatDate(a.date) - formatDate(b.date));
    return ({
      ...shareholder,
      sharesHistories,
    });
  }
  return ({
    ...shareholder,
    sharesHistories: [],
  });
};

export function fetchAllShareholders() {
  return async (dispatch, getState) => {
    const { id, idHolding: holdingId } = getState().society;
    if (id) {
      dispatch({ type: types.FETCH_SHAREHOLDERS_REQUEST });
      try {
        const { data } = await getShareholders(id);
        if (holdingId) {
          const { data: holdingData } = await getShareholders(holdingId);
          dispatch({
            type: types.FETCH_HOLDING_SUCCESS,
            payload: keysToCamel(holdingData).map(sortHistory),
          });
        }
        dispatch({
          type: types.FETCH_SHAREHOLDERS_SUCCESS,
          payload: keysToCamel(data).map(sortHistory),
        });
        dispatch(setContactsFromShareholders(keysToCamel(data)));
      } catch (err) {
        console.error(err);
        dispatch({
          type: types.FETCH_SHAREHOLDERS_FAIL,
          payload: err,
        });
      }
    }
  };
}

export function fetchHoldingShareholders() {
  return async (dispatch, getState) => {
    const { idHolding } = getState().society;
    try {
      const { data: holdingData } = await getInvestorsShareholders(idHolding);
      dispatch({
        type: types.FETCH_HOLDING_SUCCESS,
        payload: keysToCamel(holdingData)?.map(sortHistory),
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export function fetchTargetShareholders() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    try {
      const { data } = await getInvestorsShareholders(id);
      dispatch({
        type: types.FETCH_SHAREHOLDERS_SUCCESS,
        payload: keysToCamel(data)?.map(sortHistory),
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export function addLegalShareholder(shareholder, onSuccess = null) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_SHAREHOLDER_REQUEST });
    try {
      const { data } = await createShareholdersSociety(legalShareholderToApi(shareholder));
      dispatch({
        type: types.CREATE_SHAREHOLDER_SUCCESS,
        payload: keysToCamel(data),
      });
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: types.CREATE_SHAREHOLDER_FAIL, payload: err });
    }
  };
}

export function addNaturalShareholder(shareholder, onSuccess = null) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_SHAREHOLDER_REQUEST });
    try {
      const { data } = await createShareholder(shareholderToApi(shareholder));
      dispatch({
        type: types.CREATE_SHAREHOLDER_SUCCESS,
        payload: keysToCamel(data),
      });
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: types.CREATE_SHAREHOLDER_FAIL, payload: err });
    }
  };
}

export function addNewShareholders(newShareholder, callback = null) {
  return async (dispatch, getState) => {
    const { id, idHolding } = getState().society;
    const isHolding = window.location.href.includes('holding');
    const formattedShareholder = {
      ...newShareholder,
      societyId: isHolding ? idHolding : id,
      societies: isHolding ? [id, idHolding] : [id],
    };
    if (newShareholder.siren) {
      dispatch(addLegalShareholder(
        formattedShareholder,
        callback,
      ));
    } else {
      dispatch(addNaturalShareholder(
        formattedShareholder,
        callback,
      ));
    }
  };
}

export function resetCreation() {
  return (dispatch) => {
    dispatch({ type: types.RESET_CREATION });
  };
}

export function updateShareholders(holdingId, shareholderId, value) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_SHAREHOLDER_REQUEST });
    try {
      const newShareholders = getState().shareholders.shareholders
        .map((shareholder) => ((shareholder.id === shareholderId)
          ? { ...shareholder, ...value }
          : shareholder
        ));
      await updateShareholdersById(shareholderId, value);
      dispatch({
        type: types.UPDATE_SHAREHOLDER_SUCCESS,
        payload: newShareholders,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_SHAREHOLDER_FAIL,
        payload: err,
      });
    }
  };
}

export function getShareholdersExportLink(fields) {
  return async (dispatch, getState) => {
    const { id: societyId } = getState().society;
    dispatch({ type: types.EXPORT_SHAREHOLDERS_REQUEST });
    try {
      const { data } = await getExportShareholders(societyId, fields);
      dispatch({
        type: types.EXPORT_SHAREHOLDERS_SUCCESS,
        payload: data.url,
      });
    } catch (err) {
      dispatch({
        type: types.EXPORT_SHAREHOLDERS_FAIL,
        payload: err,
      });
    }
  };
}

export function resetExportStatus() {
  return async (dispatch) => {
    dispatch({
      type: types.EXPORT_SHAREHOLDERS_RESET,
    });
  };
}

export function setQuery(query) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function setActiveTabs(tab) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_TABS,
      payload: tab,
    });
  };
}

export function setActiveContact(contact) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_CONTACT,
      payload: contact,
    });
  };
}

export function setCategoryFilter(filters) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CATEGORY_FILTER,
      payload: filters,
    });
  };
}

export function setStatus(value) {
  return async (dispatch) => {
    dispatch({ type: types.SET_STATUS, payload: value });
  };
}
