import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setQuery } from 'services/store/features/shareholders/actions';

import ShareholdersHeaderSearchbar from './ShareholdersHeaderSearchbar';

const mapStateToProps = (state) => ({
  query: state.shareholders.query,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersHeaderSearchbar);
