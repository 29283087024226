import { getLastHistory, getLastHoldingHistory } from '../history/selectors';
import formatDate from '../../../../utils/formatDate';
import {
  addMoralShareholders,
  addMoralShareholdersFromHistory,
  getShareholdersCapital,
  getShareholderShare,
} from './utils';
import { createShareholdersHistory } from '../../../clients/clientAPI';
import { shareHistoryToApi } from './adapter';

export const getHistoryAndShareholders = (state) => {
  const isHolding = window.location.href.includes('holding');
  const { shareholders: target, holding } = state.shareholders;
  const { idHolding: holdingId, id: societyId } = state.society;
  const { historyToDisplay, holdingHistoryToDisplay } = state.history;

  const toDisplay = (isHolding)
    ? (holdingHistoryToDisplay || getLastHoldingHistory(state)?.id)
    : (historyToDisplay || getLastHistory(state)?.id);
  const shareholders = isHolding ? holding : target;
  const id = isHolding ? holdingId : societyId;
  return [toDisplay, shareholders, id];
};

export function getActiveContactById(state) {
  const { shareholders, holding, contact } = state.shareholders;
  const isHolding = window.location.href.includes('holding');

  if (contact !== null) {
    return (isHolding ? holding : shareholders)
      .find((shareholder) => shareholder.id === contact);
  }

  return null;
}

export const getLastSharesHistoryBySocietyId = (shareholder, societyId) => {
  const { sharesHistories } = shareholder;
  if (!sharesHistories) return undefined;
  const societyHistories = sharesHistories
    .filter((shareHistory) => shareHistory.societyId === societyId);
  societyHistories.sort((a, b) => {
    const dateA = formatDate(a.date);
    const dateB = formatDate(b.date);
    return dateA - dateB;
  });
  return societyHistories[societyHistories.length - 1];
};

export function getShareholdersByQuery(shareholders, query) {
  if (!query) return shareholders;
  return shareholders.filter((item) => {
    let objectToStr = `${item.firstName} ${item.lastName} ${item.mail} ${item.phone} ${item.address}`;
    objectToStr = objectToStr.toLowerCase();
    return (objectToStr.search(query.toLowerCase()) !== -1);
  });
}

export function getActiveShareholders(state) {
  const [toDisplay, shareholders, id] = getHistoryAndShareholders(state);

  let activeShareholders = [];

  if (toDisplay && shareholders) {
    activeShareholders = shareholders
      .filter((shareholder) => shareholder?.societies?.includes(id))
      .filter((shareholder) => {
        const selectedHistory = shareholder.sharesHistories?.find((h) => h.historyId === toDisplay);
        return selectedHistory && (selectedHistory?.total > 0);
      });

    activeShareholders = (toDisplay)
      ? addMoralShareholdersFromHistory(activeShareholders, toDisplay)
      : addMoralShareholders(activeShareholders);
  }

  return getShareholdersCapital(activeShareholders, toDisplay);
}

export function getCurrentShareholders(state, historyId) {
  const isHolding = window.location.href.includes('holding');
  const {
    shareholders, holding, query, categoryFilters,
  } = state.shareholders;
  let activeShareholders = isHolding ? holding : shareholders;
  const activeHistory = (!historyId)
    ? state.history.history[0]
    : state.history.history.find((h) => h.historyId === historyId);
  if (activeHistory?.shareholders) {
    const shareholdersToInclude = activeHistory.shareholders;
    activeShareholders = activeShareholders
      .filter((shareholder) => (
        shareholdersToInclude.includes(shareholder.id) || shareholder.shares === 0))
      .map((i) => ({
        ...i,
        shares: i.sharesHistories?.find((h) => h.historyId === activeHistory.id)?.total || 0,
      }));
  }
  if (query) {
    activeShareholders = getShareholdersByQuery(activeShareholders, query);
  }
  const nbPart = activeShareholders?.reduce(
    (acc, currentShareholders) => acc + currentShareholders.shares, 0,
  ) || 0;

  activeShareholders = activeShareholders?.map((contact) => ({
    ...contact,
    capital: ((contact.shares * 100) / nbPart).toFixed(0),
  }));
  if (categoryFilters.length > 0) {
    activeShareholders = activeShareholders
      .filter((contact) => categoryFilters.includes(contact.category));
  }
  return activeShareholders;
}

export function getCapitalData(state) {
  // TODO : Modifier cette fonction de la mort
  const isHolding = window.location.href.includes('holding');
  const { historyToDisplay, holdingHistoryToDisplay } = state.history;

  const toDisplay = isHolding ? holdingHistoryToDisplay : historyToDisplay;

  const filteredHistory = state.history.history.filter((h) => h.holding === isHolding);

  const shareholders = isHolding ? state.shareholders.holding : state.shareholders.shareholders;

  if (shareholders) {
    let shareholdersData = shareholders;
    const activeHistory = (filteredHistory.length > 0 && toDisplay)
      ? state.history.history.find((h) => h.id === toDisplay)
      : (filteredHistory[0] || []);
    if (activeHistory?.shareholders) {
      shareholdersData = shareholdersData
        .filter((shareholder) => activeHistory.shareholders.includes(shareholder.id));
    }
    shareholdersData = shareholdersData
      .filter((shareholder) => shareholder?.isHolding === isHolding)
      .map((shareholder) => ({
        ...shareholder,
        shares: shareholder.sharesHistories
          ?.find((h) => h.historyId === toDisplay)?.total || 0,
      }));
    const nbPart = shareholdersData.reduce(
      (acc, currentShareholders) => acc + (currentShareholders.sharesHistories
        ?.find((i) => (i.historyId === activeHistory?.id))?.total || 0), 0,
    );
    shareholdersData = shareholdersData.map((contact) => ({
      ...contact,
      capital: ((contact.sharesHistories
        ?.find((h) => h.historyId === activeHistory?.id)?.total * 100) / nbPart).toFixed(0),
    }));
    let categories = Array
      .from(new Set(shareholdersData.map((shareholder) => shareholder.category)));
    let capital = categories.map((category) => ({ name: category, percentage: 0 }));
    shareholdersData.forEach((shareholder) => {
      if (shareholder) {
        const categoryIndex = categories.indexOf(shareholder.category);
        if (categoryIndex !== -1) {
          capital = [
            ...capital.slice(0, categoryIndex),
            {
              ...capital[categoryIndex],
              percentage: capital[categoryIndex]?.percentage
                + Number.parseInt(shareholder.capital, 10),
            },
            ...capital.slice(categoryIndex + 1, capital.length),
          ];
        } else {
          categories = [...categories, shareholder.category];
          capital = [...capital, { name: shareholder.category, percentage: shareholder.capital }];
        }
      }
    });

    return { capital, sharesNumber: nbPart };
  }
  return [];
}

export const getCategories = (state) => {
  const { shareholders } = state.shareholders;
  let shareholdersData = shareholders;
  const nbPart = (shareholdersData) ? shareholdersData.reduce(
    (acc, currentShareholders) => acc + currentShareholders.shares, 0,
  ) : 0;
  shareholdersData = (shareholdersData)
    ? shareholdersData.map((contact) => ({
      ...contact,
      capital: ((contact.shares * 100) / nbPart).toFixed(0),
    }))
    : [];
  return Array.from(new Set(shareholdersData.map((shareholder) => shareholder.category)));
};

export async function createNewShareholdersHistory(
  id, selectedShareholders, newHistory,
  newOperations, shareholders, creation,
) {
  const shareholdersToUpdate = selectedShareholders
    .filter((shareholder) => {
      if (shareholder.buy && shareholder.buy !== 0) return true;
      if (shareholder.sell && shareholder.sell !== 0) return true;
      const lastShareHistory = getLastSharesHistoryBySocietyId(shareholder, id);
      if (!lastShareHistory) return false;
      return lastShareHistory.total !== 0;
    });

  const parsedShareholders = shareholdersToUpdate
    .map((shareholder) => ({
      date: (typeof creation.date === 'string') ? (creation.date) : (`${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`),
      societyId: id,
      shareholderId: shareholder.siren ? Number.parseInt(shareholder.id.toString().replace('society', ''), 10) : shareholder.id,
      historyId: newHistory.id,
      operations: [newOperations
        .find((operation) => operation.to.shareholderId === shareholder.id)?.id,
      ],
      from_society: !!shareholder.siren,
      buy: shareholder.buy || 0,
      sell: shareholder.sell || 0,
      total: (getShareholderShare(shareholder, id, !!shareholder.siren) || 0)
        + (shareholder.buy || 0) - (shareholder.sell || 0),
    }));

  const results = parsedShareholders.map((shareholder) => createShareholdersHistory(
    shareHistoryToApi(shareholder),
  ));
  await Promise.all(results);

  const uniqueShareholdersToUpdate = [...new Map(shareholdersToUpdate.map((s) => [s.id, s]))
    .values()];

  return uniqueShareholdersToUpdate
    .map((shareholder) => ({
      ...shareholder,
      shares: (getShareholderShare(shareholder, id) || 0)
        + Number.parseInt(shareholder?.buy, 10)
        - Number.parseInt(shareholder?.sell, 10),
      history: [
        ...(shareholder?.sharesHistories || []),
        ...parsedShareholders
          .filter((s) => s.userId === shareholder.id)
          .filter((s) => s.total !== 0)
          .map((parsed) => ({
            ...parsed,
            date: (typeof creation.date === 'string') ? (creation.date) : (`${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`),
          })),
      ],
    }));
}
