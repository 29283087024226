import React from 'react';
import InputText from '../../../components/library/Input/InputText/InputText';
import useForm from '../../../hook/useForm';
import styles from './AdminInformation.module.css';
import Button from '../../../components/library/Button/Button';

export default function AdminInformation({ society, actions }) {
  const submit = (values) => {
    const [month, day, year] = values.split('-');
    actions.updateSocietyData({
      ...values,
      lastCapitalIncrease: new Date(year, month, day),
    });
  };

  const validate = (values) => {
    console.log(values);
    return {};
  };

  const { values, handleChange } = useForm(
    submit,
    validate,
    {
      ...society,
      lastCapitalIncrease: new Date(society.lastCapitalIncrease).toISOString().split('T')[0],
    },
  );
  return (
    <form className={styles.form}>
      <h2>
        Informations générales
      </h2>
      <InputText
        label="Prénom"
        name="firstName"
        valueInput={values?.firstName || ''}
        onTextChange={handleChange}
      />
      <InputText
        label="Nom"
        name="lastName"
        valueInput={values?.lastName || ''}
        onTextChange={handleChange}
      />
      <InputText
        label="Nom de la société"
        name="name"
        valueInput={values?.name || ''}
        onTextChange={handleChange}
      />
      <h2>
        Dernière de levée de fonds
      </h2>
      <div>
        <p className={styles.label}>Date de la dernière levée de fonds</p>
        <input
          type="date"
          name="lastCapitalIncrease"
          value={values?.lastCapitalIncrease || ''}
          onChange={handleChange}
          className={styles.input}
        />
      </div>
      <InputText
        type="number"
        label="Valorisation"
        name="valuation"
        onChange={handleChange}
        valueInput={values?.valuation || ''}
      />
      <InputText
        type="number"
        label="Actions"
        name="share"
        onChange={handleChange}
        valueInput={values?.share || ''}
      />
      <Button
        label="Sauvegarder les changements"
        className={styles.button}
      />
    </form>
  );
}
