import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders } from '../../../../services/store/features/shareholders/actions';
import { createNewCapitalGain } from '../../../../services/store/features/operations/actions';
import * as statuses from '../../../../services/store/states';

// eslint-disable-next-line import/extensions
import OperationsReductionForm from './OperationsReductionForm.jsx';

const mapStateToProps = (state) => ({
  status: state.operations.creation.status,
  shouldFetchShareholders: state.shareholders.status === statuses.IDLE,
  shareholders: [
    ...(state.shareholders?.shareholders
      ?.map((shareholder) => ({ ...shareholder, holding: false })) || []),
    ...(state.shareholders?.holding?.map((shareholder) => ({ ...shareholder, holding: true }))
      || []),
  ],
  societyId: state.society.id,
  holdingId: state.society.idHolding,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    createCapitalReduction: createNewCapitalGain,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsReductionForm);
