import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  events: [],
  mails: '',
  assemblyGeneral: {
    status: statuses.IDLE,
    error: null,
    date: '',
  },
  status: statuses.IDLE,
  creationStatus: statuses.IDLE,
  error: null,
};

export default function eventsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_EVENTS_REQUIRED:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_EVENTS_FAILED:
      return {
        ...state,
        status: statuses.FAIL,
        error: payload,
      };
    case types.FETCH_EVENTS_SUCCEEDED:
      return {
        ...state,
        status: statuses.SUCCESS,
        events: payload.data,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: (payload.assemblyDate) ? statuses.SUCCESS : statuses.IDLE,
          data: payload.assemblyGeneral,
        },
      };
    case types.CREATE_EVENTS_REQUEST:
      return {
        ...state,
        creationStatus: statuses.LOADING,
      };
    case types.CREATE_EVENTS_SUCCEEDED:
      return {
        ...state,
        creationStatus: statuses.SUCCESS,
        events: [...state.events, payload],
      };
    case types.CREATE_EVENTS_FAILED:
      return {
        ...state,
        creationStatus: 'failed',
        error: payload,
      };
    case types.RESET_EVENTS:
      return {
        ...state,
        creationStatus: statuses.IDLE,
      };
    case types.CREATE_ASSEMBLY_REQUEST:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: statuses.LOADING,
        },
      };
    case types.UPDATE_ASSEMBLY_REQUEST:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: statuses.LOADING,
        },
      };
    case types.UPDATE_ASSEMBLY_SUCCEEDED:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: statuses.SUCCESS,
          date: payload,
        },
      };
    case types.CREATE_ASSEMBLY_SUCCEEDED:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: statuses.SUCCESS,
          date: payload,
        },
      };
    case types.UPDATE_ASSEMBLY_FAILED:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: 'failed',
        },
      };
    case types.CREATE_ASSEMBLY_FAILED:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: 'failed',
        },
      };
    case types.OPEN_ASSEMBLY_POPUP:
      return {
        ...state,
        assemblyGeneral: {
          ...state.assemblyGeneral,
          status: statuses.IDLE,
        },
      };
    case types.DELETE_EVENT_REQUEST:
      return {
        ...state,
        creationStatus: statuses.LOADING,
      };
    case types.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        creationStatus: statuses.SUCCESS,
        events: state.events.filter((event) => event.id !== payload),
      };
    case types.DELETE_EVENT_FAILED:
      return {
        ...state,
        creationStatus: 'failed',
        error: payload,
      };
    case types.UPDATE_EVENT_REQUEST:
      return {
        ...state,
        creationStatus: statuses.LOADING,
      };
    case types.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        creationStatus: statuses.SUCCESS,
        events: payload,
      };
    case types.UPDATE_EVENT_FAILED:
      return {
        ...state,
        creationStatus: 'failed',
        error: payload,
      };
    default:
      return state;
  }
}
