import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from './Navigation';
import { fetchUserInfo, logOut } from '../../services/store/features/user/actions';
import { handleNext } from '../../services/store/features/onboarding/actions';

const mapStateToProps = (state) => ({
  userStatus: state.user.status,
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
  name: state.society.name || 'Ayomi',
  society: state.society,
  isActive: state.onboarding.isActive,
  hasHolding: state.society.idHolding !== undefined,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    handleNext,
    fetchUserInfo,
    logOut,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
