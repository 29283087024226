import { getCurrentShareholders } from '../shareholders/selectors';

export function getActiveOperation(state) {
  const {
    query, typeFilter, nameFilter, operations,
  } = state.operations;

  let newOperations = [...operations];

  if (query) {
    const { shareholders } = state.shareholders;
    newOperations = newOperations.filter((operation) => {
      const from = shareholders.filter(
        (shareholder) => (
          shareholder.id === operation.from.shareholderId
        ),
      )[0];
      const to = shareholders.filter(
        (shareholder) => (
          shareholder.id === operation.to.shareholderId
        ),
      )[0];
      let operationToStr = `${operation.type} ${operation.name}
      ${from?.firstName || ''} ${from?.lastName || ''}
      ${to?.firstName || ''} ${to?.lastName || ''}`;
      operationToStr = operationToStr.toLowerCase();
      return (operationToStr.search(query.toLowerCase()) !== -1);
    });
  }
  if (typeFilter.length > 0) {
    newOperations = newOperations.filter((operation) => typeFilter.includes(operation.type));
  }
  if (nameFilter.length > 0) {
    newOperations = newOperations.filter((operation) => nameFilter.includes(operation.name));
  }

  newOperations.sort((a, b) => a.counter - b.counter);

  return newOperations;
}

export function getTypes(state) {
  const { operations } = state.operations;
  return Array.from(new Set(operations.map((operation) => operation.type)));
}

export function getNames(state) {
  const { operations } = state.operations;
  return Array.from(new Set(operations.map((operation) => operation.name)));
}

export function getSharesAvailable(state) {
  if (state.history.history[0]?.name === state.operations.creation?.name) {
    const numberOfShares = state.history.history[0]?.parameters.sharesNumber;
    const currentShareholders = getCurrentShareholders(state);
    const availableShares = numberOfShares - currentShareholders
      ?.reduce((acc, shareholder) => acc + shareholder.shares - shareholder.quantity, 0);
    const numberOfNewSharesBuy = state.operations.capital.selectedShareholders
      ?.reduce((acc, shareholder) => acc + Number.parseInt(shareholder.quantity, 10), 0);
    return (availableShares) - numberOfNewSharesBuy;
  }
  const currentNumberOfShares = state.history.history[0]?.parameters?.sharesNumber;
  const newNumberOfShares = state.operations.creation.parameters.sharesNumber;
  const numberOfNewSharesBuy = state.operations.capital.selectedShareholders
    .reduce((acc, shareholder) => acc + (
      (shareholder.quantity) ? Number.parseInt(shareholder.quantity, 10) : 0), 0);
  return (newNumberOfShares - currentNumberOfShares) - numberOfNewSharesBuy;
}
