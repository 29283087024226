export default function validate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Le prénom du responsable est requis';
  } else if (values.firstName.trim() === '') {
    errors.firstName = 'Le prénom du responsable ne peut pas être vide';
  }
  if (!values.lastName) {
    errors.lastName = 'Le prénom du responsable est requis';
  } else if (values.lastName.trim() === '') {
    errors.lastName = 'Le prénom du responsable ne peut pas être vide';
  }
  if (!values.email) {
    errors.email = 'L\'adresse e-mail est requise';
  }
  return errors;
}
