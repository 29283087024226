import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import stylesForm from 'components/Operation/OperationCapitalForm/OperationCapitalForm.module.css';
import SuccessAnimation from 'components/SuccessAnimation/SuccessAnimation';

export default function OperationSuccess({ societyId, isHolding }) {
  const { t } = useTranslation();
  return (
    <section className={stylesForm.success}>
      <SuccessAnimation />
      <h3>
        {t('operation.succeed')}
      </h3>
      <Link
        to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations`}
        className={stylesForm.button}
      >
        {t('operation.back')}
      </Link>
    </section>
  );
}
