import { sortByDate } from './utils';

export const getLastHoldingHistory = (state) => {
  const holdingHistories = sortByDate(state.history.history
    .filter((history) => history.holding === true));
  return holdingHistories[holdingHistories.length - 1];
};

export const getLastHistory = (state) => {
  const histories = sortByDate(state.history.history
    .filter((history) => history.holding !== true));
  return histories[histories.length - 1];
};

export const getHoldingHistoryById = (state, id) => state.history.history
  ?.filter((history) => history.holding === true)
  ?.find((history) => history.id === id) || getLastHoldingHistory(state);

export const getHistoryById = (state, id) => state.history.history
  ?.filter((history) => history.holding !== true)
  ?.find((history) => history.id === id) || getLastHistory(state);

export const getHistories = (state) => state.history.history
  ?.filter((history) => history.holding !== true);

export const getHoldingHistories = (state) => state.history.history
  ?.filter((history) => history.holding === true);
