import * as types from './types';
import {
  notify,
} from '../../../clients/clientAPI';
import { keysToCamel } from '../../../../utils/converter';
import formatDate from '../../../../utils/formatDate';
import {
  createReport,
  deleteReportsById,
  getPlannedReports,
  getReports,
  updateReportById,
} from '../../../clients/report';
import { reportToApi } from './adapter';

function getReportsYear(reports) {
  if (reports) {
    const years = reports?.map((report) => formatDate(report?.publicationDate)?.getFullYear());
    return Array.from(new Set(years)).sort((a, b) => b - a);
  }
  return [];
}

export function fetchReportsData() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({ type: types.FETCH_REPORTS_REQUEST });
      try {
        const { data } = await getReports(id);
        dispatch({
          type: types.FETCH_REPORTS_SUCCESS,
          payload: {
            reports: keysToCamel(data),
            folders: getReportsYear(keysToCamel(data)),
          },
        });
      } catch (err) {
        console.error(err);
        dispatch({ type: types.FETCH_REPORTS_FAILED });
      }
    }
  };
}

export function fetchPlannedReportsData() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    dispatch({ type: types.FETCH_PLANNED_REPORTS_REQUEST });
    try {
      const { data } = await getPlannedReports(id);
      dispatch({
        type: types.FETCH_PLANNED_REPORTS_SUCCESS,
        payload: keysToCamel(data),
      });
    } catch (err) {
      dispatch({ type: types.FETCH_PLANNED_REPORTS_FAILED });
    }
  };
}

export function setSelectedReport(id) {
  return async (dispatch, getState) => {
    const reports = getState().reports.plannedReports.data;
    dispatch({ type: types.FETCH_REPORTS_BY_ID_REQUEST });
    try {
      dispatch({
        type: types.FETCH_REPORTS_BY_ID_SUCCESS,
        payload: reports.find((report) => (`${report.id}` === id)),
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_REPORTS_BY_ID_FAILED,
        payload: err,
      });
    }
  };
}

export function deleteReport(id) {
  return async (dispatch, getState) => {
    dispatch({ type: types.DELETE_REPORT_REQUEST });
    try {
      await deleteReportsById(id);
      const reports = getState().reports.plannedReports.data;
      dispatch({
        type: types.DELETE_REPORT_SUCCESS,
        payload: reports.filter((report) => report.id !== id),
      });
    } catch (err) {
      dispatch({ type: types.DELETE_REPORT_FAILED, payload: err });
    }
  };
}

export function editReport(report) {
  return (dispatch) => {
    dispatch({ type: types.SET_EDITION_REPORT, payload: report });
  };
}

const getDates = (date) => ((date.month < 10 && date.month.length < 2)
  ? `${date.day}/0${date.month}/${date.year}`
  : `${date.day}/${date.month}/${date.year}`);

export function publishReport(publication, shouldNotify = false, isCreated = false) {
  return async (dispatch, getState) => {
    const { id: societyId } = getState().society;
    dispatch({ type: types.CREATE_REPORT_REQUEST });
    const date = new Date();
    const publicationDate = getDates({
      day: `${date?.getDate() < 10 ? 0 : ''}${date.getDate()}`,
      month: `${(date.getMonth() + 1) < 10 ? 0 : ''}${date.getMonth() + 1}`,
      year: date.getYear() + 1900,
    });
    let report = {
      ...getState().reports.activeReport.data,
      publicationDate: publication,
      published: (publication === publicationDate),
    };

    if (report.period.begin.day && report.period.end.day) {
      report = {
        ...report,
        period: {
          begin: `${report.period.begin.day}/${report.period.begin.month}/${report.period.begin.year}`,
          end: `${report.period.end.day}/${report.period.end.month}/${report.period.end.year}`,
        },
      };
    }
    try {
      const { data } = (!isCreated)
        ? await createReport(reportToApi({ ...report, societyId }))
        : await updateReportById(report.id, reportToApi({
          ...report,
          societyId,
        }));
      if (shouldNotify) await notify();
      if (publication === publicationDate) {
        dispatch({
          type: types.CREATE_REPORT_PUBLISHED_SUCCESS,
          payload: {
            data: keysToCamel({ ...report, ...data, id: (!isCreated) ? data?.id : report.id }),
            id: (!isCreated) ? data?.id : report.id,
            isCreated,
            folders: getReportsYear([...getState().reports.reports.data, report]),
          },
        });
      } else {
        dispatch({
          type: types.CREATE_REPORT_SUCCESS,
          payload: {
            data: keysToCamel({ ...report, ...data, id: (!isCreated) ? data?.id : report.id }),
            id: (!isCreated) ? data?.id : report.id,
            isCreated,
            folders: getReportsYear([...getState().reports.reports.data, report]),
          },
        });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_REPORT_FAILED, payload: err });
    }
  };
}

export const compareDate = (a, b, sens) => {
  const d1 = formatDate(a);
  const d2 = formatDate(b);
  if (d1 < d2) return (sens === 'asc') ? -1 : 1;
  if (d1 > d2) return (sens === 'asc') ? 1 : -1;
  return 0;
};

export function getActiveReports(reports, folder) {
  const newReport = (folder)
    ? reports
      .filter((report) => formatDate(report?.publicationDate)?.getFullYear() === folder)
    : reports;
  newReport.sort((a, b) => compareDate(a.publicationDate, b.publicationDate, 'dsc'));
  return newReport;
}

export function setActiveFolder(folder) {
  return (dispatch) => {
    dispatch({ type: types.SET_FOLDER, payload: folder });
  };
}

export function resetActiveReport() {
  return (dispatch) => {
    dispatch({ type: types.RESET_ACTIVE_REPORTS });
  };
}
