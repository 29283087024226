export const FETCH_SOCIETY_REQUEST = 'FETCH_SOCIETY_REQUEST';
export const FETCH_SOCIETY_SUCCEEDED = 'FETCH_SOCIETY_SUCCEEDED';
export const FETCH_SOCIETY_FAILED = 'FETCH_SOCIETY_FAILED';

export const FETCH_HOLDING_REQUEST = 'FETCH_HOLDING_REQUEST';
export const FETCH_HOLDING_SUCCEEDED = 'FETCH_HOLDING_SUCCEEDED';
export const FETCH_HOLDING_FAILED = 'FETCH_HOLDING_FAILED';

export const UPDATE_SOCIETY_REQUEST = 'UPDATE_SOCIETY_REQUEST';
export const UPDATE_SOCIETY_SUCCESS = 'UPDATE_SOCIETY_SUCCESS';
export const UPDATE_SOCIETY_FAIL = 'UPDATE_SOCIETY_FAIL';
export const UPDATE_SOCIETY_RESET = 'UPDATE_SOCIETY_RESET';

export const SET_SOCIETY_ID = 'SET_SOCIETY_ID';
