import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DocumentsVisualisation from './DocumentsVisualisation';
import { setSelectedDocument, setSelectedUserDocument } from '../../../services/store/features/documents/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSelectedDocument,
    setSelectedUserDocument,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(DocumentsVisualisation);
