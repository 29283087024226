import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportEdition from './ReportEdition';

import {
  editReport, setSelectedReport, resetActiveReport, fetchReportsData,
} from '../../services/store/features/report/actions';
import { openPopUp } from '../../services/store/features/popup/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  report: state.reports.activeReport.data,
  status: state.reports.reports.status,
  societyId: state.society.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    editReport,
    setSelectedReport,
    resetActiveReport,
    openPopUp,
    setSocietyId,
    fetchUserInfo,
    fetchReportsData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportEdition);
