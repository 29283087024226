import { connect } from 'react-redux';
import OperationModifyTransfert from './OperationModifyTransfert';

const mapStateToProps = (state) => ({
  operation: state.operations.creation,
  shareholders: state.shareholders.shareholders,
  history: state.history,
  currentOperations: state.operations.operations.filter(
    (o) => o.name === (state.operations.creation?.oldName || state.operations.creation?.name),
  ),
});

export default connect(mapStateToProps)(OperationModifyTransfert);
