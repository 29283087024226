import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../library/Button/Button';
import styles from './ContactCard.module.css';

export default function ContactCard({ title, contact }) {
  const { t } = useTranslation();
  return (
    <div
      className={styles.card}
    >
      <div className={styles.colorLines} />
      <div className={styles.content}>
        <h3>
          { title }
        </h3>
        <p>
          { contact.name }
        </p>
        <div className={styles.grid}>
          <div className={`${styles.cell} ${styles.gridA}`}>
            <p className={styles.title}>
              {t('contact.phone')}
            </p>
            <p className={styles.cellContent}>
              { contact.number }
            </p>
          </div>
          <div className={`${styles.cell} ${styles.gridB}`}>
            <p className={styles.title}>
              {t('contact.available')}
            </p>
            <p className={styles.cellContent}>
              {t('contact.week')}
            </p>
          </div>
          <div className={`${styles.cell} ${styles.gridC}`}>
            <p className={styles.title}>
              {t('contact.time')}
            </p>
            <p className={styles.cellContent}>
              9h30 - 19h30
            </p>
          </div>
          <div className={`${styles.cell} ${styles.gridD}`}>
            <p className={styles.title}>
              {t('contact.mail')}
            </p>
            <p className={styles.cellContent}>
              { contact.mail }
            </p>
          </div>
          <div
            className={`${styles.cell} ${styles.gridE}`}
          >
            <Button
              label={t('contact.rdv')}
              onButtonClick={() => document.location.assign(contact.calendar)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
