import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faPen, faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '../../Button/Button';
import styles from './InputFile2.module.css';

export default function InputFileSuccess({
  label, file,
  onDrop, onDragLeave, onDragOver, hover,
  cancel, hideOpen,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <p className={styles.label}>
        { label }
      </p>
      <div
        className={`${styles.desktop} ${styles.dropfile}  ${(hover) ? styles.hover : ''}`}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <div className={`${styles.illustration} ${(hover) ? styles.hover : ''}`}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faCheck}
          />
        </div>
        <p className={styles.description}>
          {t('library.file.valid')}
        </p>
        {
          (!hideOpen)
          && (
            <Button
              type="ghost"
              label={t('library.file.open')}
              icon={<FontAwesomeIcon icon={faDownload} />}
              className={styles.download}
              onButtonClick={() => {
                window.open(file, '_blank').focus();
              }}
            />
          )
        }
      </div>
      <div className={`${styles.desktop} ${styles.hintColumn}`}>
        <Button
          type="ghost"
          label={t('library.file.modify')}
          icon={<FontAwesomeIcon icon={faPen} />}
          className={styles.action}
          onButtonClick={cancel}
        />
      </div>
    </div>
  );
}
