import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openPopUp, closePopUp } from 'services/store/features/popup/actions';
import { fetchUserInfo } from 'services/store/features/user/actions';
import { handleNext } from 'services/store/features/onboarding/actions';
import ShareholdersHeader from './ShareholdersHeader';

const mapStateToProps = (state) => ({
  userStatus: state.user.status,
  isOwner: state.society.isOwner,
  roles: state.roles.roles,
  isActive: state.onboarding.isActive,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchUserInfo,
    openPopUp,
    closePopUp,
    handleNext,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersHeader);
