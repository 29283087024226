import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateShareholders, setStatus } from 'services/store/features/shareholders/actions';

import ShareholdersForm from './ShareholdersForm';

const getHoldingId = (holdings, name) => holdings.map((holding) => holding.name).indexOf(name);

const mapStateToProps = (state) => ({
  status: state.shareholders.status,
  holdingId: getHoldingId(state.shareholders.shareholders, state.shareholders.activeTab),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateShareholders,
    setStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersForm);
