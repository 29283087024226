export default function validate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Le prénom est requis';
  } else if (values.firstName.trim() === '') {
    errors.firstName = 'Le prénom ne peut pas être vide';
  }
  if (!values.lastName) {
    errors.lastName = 'Le prénom est requis';
  } else if (values.lastName.trim() === '') {
    errors.lastName = 'Le prénom ne peut pas être vide';
  }
  if (!values.mail) {
    errors.mail = 'L\'email est requis';
  } else if (!/\S+@\S+\.\S+/.test(values.mail)) {
    errors.mail = 'L\'email n\'est pas valide';
  }
  return errors;
}
