import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCategoryFilter } from '../../../services/store/features/shareholders/actions';

import ShareholdersTableRowsTitle from './ShareholdersTableRowsTitle';
import { getCategories } from '../../../services/store/features/shareholders/selectors';

const mapStateToProps = (state) => ({
  categories: getCategories(state),
  categoriesSelected: state.shareholders.categoryFilters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCategoryFilter,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersTableRowsTitle);
