import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EventsPopUp.module.css';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import Button from '../../library/Button/Button';

export default function EventsPopUpSuccess({ event, actions }) {
  const { t } = useTranslation();
  return (
    <section className={styles.success}>
      <main className={styles.content}>
        <SuccessAnimation />
        <p className={styles.title}>
          {
            (event)
              ? t('events.edit.update')
              : t('events.add.update')
          }
        </p>
      </main>
      <footer
        className={styles.footer}
      >
        <Button
          label={t('close')}
          onButtonClick={actions.closePopUp}
        />
      </footer>
    </section>
  );
}
