import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../library/Input/Checkbox/Checkbox';
import Button from '../library/Button/Button';
import SuccessAnimation from '../SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../Error/ErrorAnimation/ErrorAnimation';

import OperationsShareholdersFormSociety from './OperationsShareholdersFormSociety';
import OperationsShareholdersFormPerson from './OperationsShareholdersFormPerson';

import styles from './OperationsShareholdersForm.module.css';

export default function OperationsShareholdersForm({
  back, callback, status, actions, isHolding,
}) {
  const [formToDisplay, setFormToDisplay] = useState('individual');

  useEffect(() => () => actions.resetCreation(), []);

  const { t } = useTranslation();

  const FORM = {
    company: <OperationsShareholdersFormSociety callback={callback} isHolding={isHolding} />,
    individual: <OperationsShareholdersFormPerson callback={callback} isHolding={isHolding} />,
  };

  if (status === 'fail') {
    return (
      <section className={styles.error}>
        <ErrorAnimation />
        <h3>
          {
            t('error.title')
          }
        </h3>
        <p>
          {t('shareholders.edit.creationError')}
        </p>
      </section>
    );
  }

  if (status === 'success') {
    return (
      <section className={styles.success}>
        <SuccessAnimation />
        <h3>
          {t('shareholders.edit.creationSuccess')}
        </h3>
        <Button
          label={t('back')}
          onButtonClick={back}
        />
      </section>
    );
  }

  return (
    <div className={styles.form}>
      <div>
        <div className={styles.row}>
          <Checkbox
            checked={formToDisplay === 'individual'}
            onChange={() => setFormToDisplay('individual')}
            label={t('shareholders.edit.individual')}
          />
          <Checkbox
            label={t('shareholders.edit.company')}
            checked={formToDisplay === 'company'}
            onChange={() => setFormToDisplay('company')}
          />
        </div>
        {
          FORM[formToDisplay]
        }
      </div>
    </div>
  );
}
