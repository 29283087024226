import React from 'react';

import ShareholdersTableRows from 'components/ShareholdersTableRows';

import styles from './ShareholdersTable.module.css';

export default function ShareholdersTable({ status, shareholdersNumber }) {
  if (status === 'success' && shareholdersNumber === 0) {
    return null;
  }
  return (
    <main className={styles.content}>
      <h3 className={styles.title}>
        Table
      </h3>
      <ShareholdersTableRows />
    </main>
  );
}
