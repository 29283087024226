import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Button from '../../library/Button/Button';
import TextArea from '../../library/Input/TextArea/TextArea';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../../Error/ErrorAnimation/ErrorAnimation';

import * as statuses from '../../../services/store/states';
import styles from './DocumentRequest.module.css';

export default function DocumentRequest({
  status, societyId, userId, actions,
}) {
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    actions.resetDocumentRequest();
  }, []);

  const handleSubmit = () => {
    const request = {
      content: description,
      societyId,
      userId,
    };
    actions.sendDocumentRequest(request);
  };
  if (status === statuses.SUCCESS) {
    return (
      <section>
        <main className={`${styles.success} ${styles.content}`}>
          <SuccessAnimation />
          <p>
            {t('documents.requestSuccess')}
          </p>
        </main>
      </section>
    );
  }
  if (status === 'failed') {
    return (
      <section>
        <main className={`${styles.error} ${styles.content}`}>
          <ErrorAnimation />
          <p className={styles.title}>
            {t('documents.requestError.title')}
          </p>
          <p className={styles.description}>
            {t('documents.requestError.description')}
          </p>
        </main>
      </section>
    );
  }
  return (
    <section>
      <form className={styles.content}>
        <TextArea
          label={t('documents.requestObject')}
          valueInput={description}
          onTextChange={(e) => setDescription(e.target.value)}
        />
      </form>
      <footer className={styles.footer}>
        <Button
          type="custom"
          label={t('cancel')}
          className={styles.tertiaryButton}
          onButtonClick={actions.closePopUp}
        />
        <Button
          label={(status === statuses.LOADING) ? t('documents.requestSending') : t('documents.requestSend')}
          icon={(status === statuses.LOADING) && <FontAwesomeIcon icon={faSpinner} spin />}
          onButtonClick={handleSubmit}
          disabled={(description === '')}
        />
      </footer>
    </section>
  );
}
