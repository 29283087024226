import formatDate from '../../../../utils/formatDate';

export function getDocumentsByFolder(documents, folder, favorites) {
  if (folder === null) return documents;
  if (folder === 'favoris') {
    return documents.filter((doc) => favorites.includes(doc.id));
  }
  return documents.filter((doc) => (formatDate(doc.date).getFullYear().toString() === folder));
}

export function getDocumentsByQuery(documents, query) {
  return documents.filter((document) => {
    const documentToStr = document.title.toLowerCase();
    return (documentToStr.search(query.toLowerCase()) !== -1);
  });
}

export function sortText(a, b, asc, label) {
  if (asc) {
    if (a[label] < b[label]) return -1;
    if (a[label] > b[label]) return 1;
    return 0;
  }
  if (a[label] > b[label]) return -1;
  if (a[label] < b[label]) return 1;
  return 0;
}

const getDocumentAsString = ({ title, shareholder }) => `${title} ${(shareholder?.firstName) ? shareholder.firstName : ''} ${(shareholder?.lastName) ? shareholder.lastName : ''}`;

export function getSortedDocumentsByQuery(documents, query, sort) {
  const newDocuments = (documents || [])?.filter((document) => {
    const documentToStr = getDocumentAsString(document).toLowerCase();
    return (documentToStr.includes(query.toLowerCase()));
  });
  newDocuments.sort((a, b) => {
    if (sort.value === 'date') {
      const dateA = formatDate(a.date);
      const dateB = formatDate(b.date);
      return (sort.asc) ? dateA - dateB : dateB - dateA;
    }
    return sortText(a, b, sort.asc, sort.value);
  });
  return newDocuments;
}

export function getMostRecentFilesSelection(documents) {
  const newDocuments = (documents || [])?.sort((a, b) => {
    const dateA = formatDate(a.date);
    const dateB = formatDate(b.date);
    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  });
  return newDocuments.slice(0, 7);
}

export function getFavoritesFilesSelection(documents, favorites) {
  return documents.filter((doc) => favorites.includes(doc.id)).slice(0, 3);
}

export function getDocumentProps(state, key) {
  return {
    documents: state.documents[`${(key === 'user') ? key : ''}documents`],
    favorites: state.user.favoriteDocuments,
    folders: ['favoris', ...Array.from(new Set(state[key].documents.map((doc) => formatDate(doc.date).getFullYear())))],
    status: state.documents[`${(key === 'user') ? key : ''}status`],
    query: state.documents.query,
    sort: state.documents[`${(key === 'user') ? key : ''}sort`],
    selectedDocument: state.documents[`${(key === 'user') ? key : ''}selectedDocument`],
    societyId: state.society.id,
    userId: state.roles.iss,
    roles: state.roles.roles,
    domain: key,
    isAuthorized: state.society.isOwner,
    isUserDocument: key === 'user',
  };
}

export function getDocumentsByFolderProps(state, key) {
  return {
    documents: getDocumentsByFolder(
      state.documents[`${(key === 'user') ? key : ''}documents`],
      state.documents[`${(key === 'user') ? key : ''}folder`],
      state.user.favoriteDocuments,
    ),
    favorites: state.user.favoriteDocuments,
    status: state.documents[`${(key === 'user') ? key : ''}status`],
    query: state.documents[`${(key === 'user') ? key : ''}query`],
    sort: state.documents[`${(key === 'user') ? key : ''}sort`],
    selectedDocument: state.documents[`${(key === 'user') ? key : ''}selectedDocument`],
    societyId: state.society.id,
    roles: state.roles.roles,
    creation: state[key].creation.status,
    domain: key,
  };
}
