import { connect } from 'react-redux';

import DocumentUpload from './DocumentUpload';

const mapStateToProps = (state) => ({
  activeUserId: state.roles.iss,
  creation: {
    user: state.documents.usercreation.status,
    documents: state.documents.creation.status,
  },
  shareholders: {
    status: state.shareholders.status,
    list: state.shareholders.shareholders,
  },
});
export default connect(mapStateToProps)(DocumentUpload);
