import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportPlanification from './ReportPlanification';
import { fetchPlannedReportsData, deleteReport } from '../../services/store/features/report/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';
import { openPopUp, closePopUp } from '../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  status: state.reports.plannedReports.status,
  reports: state.reports.plannedReports.data,
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchPlannedReportsData,
    deleteReport,
    setSocietyId,
    fetchUserInfo,
    openPopUp,
    closePopUp,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPlanification);
