import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';

import ShareholdersHeader from 'components/Shareholders/ShareholdersHeader';

import OperationsChoice from '../OperationsChoice';
import OperationsCreation from '../OperationsCreation';
import OperationsTransfert from '../OperationsTransfert';
import OperationsCapital from '../OperationsCapital';
import OperationsEdit from '../OperationsEdit';
import OperationModifyTransfert from '../OperationModifyTransfert';
import OperationsReduction from '../OperationsReduction';
import styles from './Operations.module.css';

export default function Operations({
  user, societyId, actions, isHolding,
}) {
  const { society, userId } = useParams();
  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!user) {
      actions.fetchUserInfo(userId);
    }
  }, []);

  if (!societyId) return null;
  return (
    <section className={styles.operations}>
      <ShareholdersHeader id={societyId} isHolding={isHolding} />
      <main>
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/update/transfert`}
          component={() => <OperationModifyTransfert isHolding={isHolding} />}
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`}
          component={() => <OperationsChoice isHolding={isHolding} />}
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/create/:type`}
          component={() => <OperationsCreation isHolding={isHolding} />}
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit`}
          component={() => <OperationsEdit isHolding={isHolding} />}
          exact
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/transfert`}
          component={() => <OperationsTransfert isHolding={isHolding} />}
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/capital`}
          component={() => <OperationsCapital isHolding={isHolding} />}
        />
        <Route
          path={`/:society/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/reduction`}
          component={() => <OperationsReduction isHolding={isHolding} />}
        />
      </main>
    </section>
  );
}
