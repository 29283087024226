import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Report from './Report';
import { fetchReportsData, getActiveReports, setActiveFolder } from '../../services/store/features/report/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';
import { setSocietyId } from '../../services/store/features/society/actions';

const mapStateToProps = (state) => ({
  status: state.reports.reports.status,
  reports: getActiveReports(state.reports.reports.data, state.reports.reports.activeFolder),
  folders: state.reports.reports.folders,
  activeFolder: state.reports.reports.activeFolder,
  userStatus: state.user.status,
  isInvestor: state.society.is_member && !state.society.isOwner,
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchReportsData,
    setActiveFolder,
    fetchUserInfo,
    setSocietyId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
