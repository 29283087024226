export const FETCH_ARCHIVED_REQUEST = 'FETCH_ARCHIVED_REQUEST';
export const FETCH_ARCHIVED_SUCCESS = 'FETCH_ARCHIVED_SUCCESS';
export const FETCH_ARCHIVED_FAIL = 'FETCH_ARCHIVED_FAIL';

export const FETCH_PLAN_REQUEST = 'FETCH_PLAN_REQUEST';
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAIL = 'FETCH_PLAN_FAIL';

export const DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL';

export const CREATE_ASSEMBLY_REQUEST = 'CREATE_ASSEMBLY_CREATE';
export const CREATE_ASSEMBLY_SUCCESS = 'CREATE_ASSEMBLY_SUCCESS';
export const CREATE_ASSEMBLY_FAIL = 'CREATE_ASSEMBLY_FAIL';

export const DELETE_RESOLUTION_REQUEST = 'DELETE_RESOLUTION_REQUEST';
export const DELETE_RESOLUTION_SUCCESS = 'DELETE_RESOLUTION_SUCCESS';
export const DELETE_RESOLUTION_FAIL = 'DELETE_RESOLUTION_FAIL';

export const RESET_ASSEMBLY_CREATION = 'RESET_ASSEMBLY_CREATION';

export const SET_ASSEMBLY_SELECTED = 'SET_ASSEMBLY_SELECTED';

export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL';

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const SEND_MAIL_ERROR = 'SEND_MAIL_ERROR';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_RESET = 'SEND_MAIL_RESET';

export const SEND_REMINDER_REQUEST = 'SEND_REMINDER_REQUEST';
export const SEND_REMINDER_SUCCESS = 'SEND_REMINDER_SUCCESS';
export const SEND_REMINDER_FAILED = 'SEND_REMINDER_FAILED';
export const UPDATE_REMINDER = 'UPDATE_REMINDER';

export const SEND_VOTE_SUCCESS = 'SEND_VOTE_SUCCESS';

export const INVITATION_RESPONSE_SUCCESS = 'INVITATION_RESPONSE_SUCCESS';
