import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OperationsReductionFormShareholders() {
  const { t } = useTranslation();
  return (
    <>
      <h4>
        {t('operation.reduction.shareholdersList')}
      </h4>
      <p>
        {t('operation.reduction.shareholdersHint')}
      </p>
    </>
  );
}
