import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../library/Button/Button';
import AssemblyResolutionPopUp from '../../../views/Assembly/AssemblyResolution/AssemblyResolution';
import AssemblyResolutionReader from '../../../views/Assembly/AssemblyResolutionReader/AssemblyResolutionReader';

import styles from '../../../views/Assembly/AssemblyView/AssemblyView.module.css';
import grid from './AssemblyResolutions.module.css';

export default function AssemblyResolutions({
  assembly, edit = false, submit, deleteResolution,
}) {
  const [isResolutionPopUpOpen, setIsResolutionPopUpOpen] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState();

  const { t } = useTranslation();

  const openPopUp = (resolution) => {
    setSelectedResolution(resolution);
    setIsResolutionPopUpOpen(true);
  };

  return (
    <div className={`${styles.resolutions} ${grid.grid}`}>
      {
        (edit && isResolutionPopUpOpen)
        && (
          <AssemblyResolutionPopUp
            close={() => {
              setIsResolutionPopUpOpen(false);
            }}
            resolution={selectedResolution}
            submit={submit}
            deleteResolution={deleteResolution}
          />
        )
      }
      {
        (!edit && isResolutionPopUpOpen)
        && (
          <AssemblyResolutionReader
            resolution={selectedResolution}
            close={() => {
              setIsResolutionPopUpOpen(false);
            }}
          />
        )
      }
      <div className={`${styles.resolutionsHeader} ${styles.row}`}>
        <h3>
          {t('assembly.resolutions.title')}
        </h3>
        {
          (edit)
          && (
            <Button
              label={t('assembly.resolutions.add')}
              variant="ghost"
              onButtonClick={() => openPopUp()}
            />
          )
        }
      </div>
      <div className={grid.list}>
        {
            assembly.resolutions && assembly.resolutions.map((resolution, index) => (
              <button
                type="button"
                key={resolution.id}
                className={styles.resolution}
                onClick={() => {
                  if (resolution.description) {
                    openPopUp(resolution);
                  }
                }}
              >
                <p className={grid.description}>
                  <b>
                    {t('assembly.resolutions.description', { index: index + 1, title: resolution.title })}
                  </b>
                  {` ${resolution.description}`}
                </p>
              </button>
            ))
          }
      </div>
    </div>
  );
}
