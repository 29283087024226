import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';
import AccountLoading from '../../../components/library/Loading';
import * as statuses from '../../../services/store/states';

import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import shared from '../shared/assembly.module.css';

import AssemblySummonsForm from './AssemblySummonsForm';
import AssemblySummonsEditor from './AssemblySummonsEditor';
import AssemblySummonsSend from './AssemblySummonsSend';
import styles from './AssemblySummons.module.css';

export default function AssemblySummons({
  status, assembly, actions, statusShareholders,
  shareholders, history, societyId, roles, isAuthorized,
}) {
  const { id } = useParams();
  const [step, setStep] = useState('create');
  const { t } = useTranslation();
  const navigation = useHistory();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
    if (statusShareholders === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (history.status === statuses.IDLE) {
      actions.fetchHistory();
    }
    actions.setActiveAssembly(id);
  }, []);

  const SUMMONS_STATE = {
    create: (
      <AssemblySummonsForm
        status={status}
        assembly={assembly}
        shareholders={shareholders}
        actions={actions}
        next={() => setStep('generate')}
        societyId={societyId}
        roles={roles}
        isAuthorized={isAuthorized}
      />
    ),
    generate: (
      <AssemblySummonsEditor
        assembly={assembly}
        actions={actions}
        next={() => setStep('send')}
        back={() => setStep('create')}
        societyId={societyId}
        roles={roles}
        isAuthorized={isAuthorized}
      />
    ),
    send: (
      <AssemblySummonsSend
        assembly={assembly}
        actions={actions}
        back={() => setStep('generate')}
        next={() => navigation.push(`/${societyId}/assemblee/view/${id}`)}
        societyId={societyId}
        roles={roles}
        isAuthorized={isAuthorized}
      />
    ),
    default: (
      <p>Loading ...</p>
    ),
  };

  if (status === statuses.IDLE || status === statuses.LOADING) {
    return (
      <section className={styles.summons}>
        <AssemblyHeader
          roles={roles}
          societyId={societyId}
          isAuthorized={isAuthorized}
        />
        <AccountLoading />
      </section>
    );
  }

  if (step === 'end' && status === 'success') {
    return (
      <section className={styles.summons}>
        <AssemblyHeader
          roles={roles}
          societyId={societyId}
          isAuthorized={isAuthorized}
        />
        <section className={shared.section}>
          <h2 className={styles.title}>
            { assembly?.title }
          </h2>
          <div className={`${styles.content} ${styles.center}`}>
            <SuccessAnimation />
            <h3>
              {t('assembly.summons.success')}
            </h3>
            <Link
              to={`/${societyId}/assemblee/plan`}
              className="button"
            >
              {t('assembly.summons.back')}
            </Link>
          </div>
        </section>
      </section>
    );
  }

  return (SUMMONS_STATE[step] || null);
}
