import React, { useState, useRef } from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PanToolIcon from '@material-ui/icons/PanTool';

import Button from '../../library/Button/Button';
import useOutsideClickAlert from '../../library/hook/clickOutside';

import styles from './Assembly.module.css';

const getVoteResponse = (resolution, id) => {
  if (resolution.votes.no_response?.includes(id)) return 'no_response';
  if (resolution.votes.yes?.includes(id)) return 'yes';
  if (resolution.votes.no?.includes(id)) return 'no';
  return 'white';
};

export default function AssemblyVote({
  resolutions, id, submit, close,
}) {
  const [votes, setVotes] = useState(resolutions.map((resolution) => ({
    response: getVoteResponse(resolution, id),
    id: resolution.id,
  })));
  const popup = useRef();

  useOutsideClickAlert(popup, close);

  const handleVote = (voteId, response) => {
    if (id && id !== 0) {
      const index = votes.map((i) => i.id).indexOf(voteId);
      const newVote = {
        ...votes[index],
        id: voteId,
        response,
      };
      setVotes((state) => [
        ...state.slice(0, index),
        newVote,
        ...state.slice(index + 1),
      ]);
    } else {
      setVotes([{ id: 0, response }]);
    }
  };

  const handleSubmit = () => {
    submit(votes);
    close();
  };

  return (
    <div className={styles.vote}>
      <section
        className={styles.popup}
        ref={popup}
      >
        <h2>
          Voter les résolutions
        </h2>
        {
          resolutions.map((item, key) => {
            const vote = votes?.find((i) => i.id === item.id) || { id: item.id };
            return (
              <div
                key={item.id}
                className={styles.resolution}
              >
                <h3>
                  {`Résolution n°${key + 1} : ${item.title}`}
                </h3>
                <p>
                  { item.description }
                </p>
                <div className={styles.buttonGroup}>
                  <Button
                    label="Je vote pour"
                    variant="custom"
                    className={`${styles.button} ${(vote.response === 'yes') ? styles.selected : ''}`}
                    icon={<ThumbUpIcon />}
                    size="medium"
                    onButtonClick={() => handleVote(vote.id, 'yes')}
                  />
                  <Button
                    label="Je vote contre"
                    variant="custom"
                    className={`${styles.button} ${(vote.response === 'no') ? styles.selected : ''}`}
                    icon={<ThumbDownIcon />}
                    size="medium"
                    onButtonClick={() => handleVote(vote.id, 'no')}
                  />
                  <Button
                    label="Je m'abstiens"
                    variant="custom"
                    className={`${styles.button} ${(vote.response === 'white') ? styles.selected : ''}`}
                    icon={<PanToolIcon />}
                    size="medium"
                    onButtonClick={() => handleVote(vote.id, 'white')}
                  />
                </div>
              </div>
            );
          })
        }
        <div className={styles.footer}>
          <Button
            label="Retour"
            variant="outline"
            onButtonClick={close}
          />
          <Button
            label="Valider"
            onButtonClick={handleSubmit}
            disabled={((votes?.length || 0) !== resolutions.length)}
          />
        </div>
      </section>
    </div>
  );
}
