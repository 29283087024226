import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChatContactList from './ChatContactList';
import { fetchSocietyData } from '../../../services/store/features/society/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import {
  findContactsByQuery, setActiveRoom, updateContactsSocket, removeContactsSocket,
} from '../../../services/store/features/chat/actions';

const mapStateToProps = (state) => ({
  activeId: state.chat.activeId,
  statusContact: state.shareholders.status,
  contacts: findContactsByQuery(state.chat.contacts, state.chat.query)
    .filter((contact) => contact.id !== state.user.userId),
  group: state.chat.group,
  society: {
    status: state.society.status,
    chatRoom: state.society.chatRoom,
    logo: state.society.logoUrl,
    name: state.society.name,
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    fetchSocietyData,
    setActiveRoom,
    updateContactsSocket,
    removeContactsSocket,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContactList);
