import React, { useEffect, useState } from 'react';

import Button from '../../components/library/Button/Button';
import { getRedirections } from '../../services/clients/clientAPI';
import * as statuses from '../../services/store/states';

import styles from './Forbidden.module.css';

export default function Forbidden() {
  const [status, setStatus] = useState(statuses.IDLE);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjectList = async () => {
      setStatus(statuses.LOADING);
      try {
        const { data } = await getRedirections();
        setStatus('SUCCESS');
        setProjects(data.redirections);
      } catch (err) {
        setStatus(statuses.FAIL);
      }
    };
    fetchProjectList();
  }, []);

  return (
    <section className={styles.section}>
      <main className={styles.main}>
        <p className={styles.decor}>403</p>
        <div className={styles.content}>
          <h1>
            Vous n&apos;avez pas accès à cette page
          </h1>
          <p className={styles.description}>
            L&apos;accès à cet espace est restreint aux actionnaires de la société.
            Si vous pensez qu&apos;il s&apos;agit d&apos;une erreur, contactez-nous.
          </p>
          <div className={styles.buttonGroup}>
            <Button
              label="Nous contacter"
              variant="outline"
              onButtonClick={() => document.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'lendeers.com'}/contact/`)}
            />
            <Button
              label="Aller sur notre site"
              onButtonClick={() => document.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'lendeers.com'}/`)}
            />
          </div>
          {
            (status === 'SUCCESS') && (projects.length > 0)
            && (
              <div className={styles.project}>
                <p>
                  Vos espaces post-investissement:
                </p>
                <ul>
                  {
                  projects.map((project) => (
                    <a href={project} key={project}>
                      { project }
                    </a>
                  ))
                }
                </ul>
              </div>
            )
          }
        </div>
      </main>
    </section>
  );
}
