import { keysToSnake } from '../../../../utils/converter';

// eslint-disable-next-line import/prefer-default-export
export const reportToApi = (data) => keysToSnake({
  bad: data.bad,
  begin: data.begin,
  end: data.end,
  expenses: data.expenses,
  general: data.general,
  good: data.good,
  help: data.help,
  publicationDate: data.publication,
  periodBeginningDate: data.period.begin,
  periodEndingDate: data.period.end,
  societyId: data.societyId,
  title: data.title,
  treasury: data.treasury,
  turnover: data.turnover,
});
