const formatDate = (dateStr) => {
  if (!dateStr) return new Date();
  if (dateStr.includes('/')) {
    const date = dateStr.split(' ')[0];
    const [day, month, year] = date.split('/');
    if (`${day}`.length > 2) {
      return new Date(day, month - 1, year);
    }
    return new Date(year, month - 1, day);
  }
  return new Date(dateStr);
};

export const splitDate = (dateStr) => {
  const date = formatDate(dateStr);
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
};

export default formatDate;
