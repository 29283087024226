import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders, addNewShareholders, resetCreation } from 'services/store/features/shareholders/actions';

import OperationsShareholderUpdate from './OperationsShareholderUpdate';

const mapStateToProps = (state) => ({
  status: state.shareholders.creation.status,
  shareholdersStatus: state.shareholders.status,
  shareholders: state.shareholders.shareholders,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    addNewShareholders,
    resetCreation,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsShareholderUpdate);
