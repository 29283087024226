import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ShareholdersTableRowsTitle from './ShareholdersTableRowsTitle';
import ShareholdersTableRow from './ShareholdersTableRow';

import * as statuses from '../../services/store/states';
import styles from './ShareholdersTableRows.module.css';

export default function ShareholdersTableRows({
  status, isInvestor, shareholders, actions,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, [status]);
  if (status === statuses.IDLE || status === statuses.LOADING) {
    return (
      <section className={styles.loading} />
    );
  }
  if (status === statuses.FAIL) {
    return (
      <section>
        <p>
          {t('error.title')}
        </p>
        <button
          type="button"
        >
          {t('error.button')}
        </button>
      </section>
    );
  }
  return (
    <section className={styles.table}>
      <div className={styles.container}>
        <ShareholdersTableRowsTitle />
        <ul className={styles.list}>
          {
            shareholders.activeShareholders.map(
              (contact) => (
                <ShareholdersTableRow
                  key={`${contact.invest_by_society ? 'society' : ''}${contact.id}`}
                  contact={contact}
                  openContact={() => actions.setActiveContact(contact.id)}
                  isInvestor={isInvestor}
                />
              ),
            )
          }
        </ul>
      </div>
    </section>
  );
}
