import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import loadScript from '../../../../utils/loadScript';

import inputStyles from '../shared/Input.module.css';
import InputText from '../InputText/InputText';
import styles from './InputAddress.module.css';

export default function InputAddress({ values, handleChange, disabled }) {
  let autoComplete;

  const autoCompleteRef = useRef(null);
  const { t } = useTranslation();

  const handlePlaceSelect = async () => {
    const addressObject = autoComplete.getPlace();
    const newAddress = `${addressObject.address_components.find((details) => details.types.includes('route'))?.long_name}`;
    handleChange({ target: { name: 'address', value: newAddress } });
    handleChange({
      target: {
        name: 'zipCode',
        value: addressObject.address_components.find((details) => details.types.includes('postal_code'))?.long_name,
      },
    });
    handleChange({
      target: {
        name: 'city',
        value: addressObject.address_components.find((details) => details.types.includes('locality'))?.long_name,
      },
    });
    handleChange({
      target: {
        name: 'country',
        value: addressObject.address_components.find((details) => details.types.includes('country'))?.long_name,
      },
    });
  };

  const handleScriptLoad = () => {
    if (window.google) {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
      );
      autoComplete.setFields(['address_components', 'formatted_address']);
      autoComplete.addListener('place_changed', () => handlePlaceSelect());
    }
  };

  useEffect(() => {
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDQyUNkEbrMMvJY-X0F6gx4y5B2KLv__U4&libraries=places',
      handleScriptLoad,
    );
  }, []);

  return (
    <div data-testid="input-street">
      <InputText
        label={t('settings.investors.form.streetNumber')}
        name="streetNumber"
        onTextChange={handleChange}
        valueInput={values?.streetNumber || ''}
        disabled={disabled}
      />
      <label
        className={`${inputStyles.label} ${styles.label}`}
        htmlFor="address"
      >
        { t('settings.investors.form.address')}
      </label>
      <input
        className={`${inputStyles.input} ${styles.input} ${disabled ? styles.disabled : ''}`}
        id="address"
        name="address"
        ref={autoCompleteRef}
        onChange={handleChange}
        value={values?.address || ''}
        disabled={disabled}
      />
      <div className={styles.row}>
        <InputText
          label={t('settings.investors.form.zipCode')}
          name="zipCode"
          onTextChange={handleChange}
          valueInput={values?.zipCode || ''}
          disabled={disabled}
        />
        <InputText
          label={t('settings.investors.form.city')}
          name="city"
          onTextChange={handleChange}
          valueInput={values?.city || ''}
          disabled={disabled}
        />
      </div>
      <InputText
        label={t('settings.investors.form.country')}
        name="country"
        onTextChange={handleChange}
        valueInput={values?.country || ''}
        disabled={disabled}
      />
    </div>
  );
}
