export default function validate(values, type, t, date) {
  const errors = {};
  if (!values.name) {
    errors.name = t('operation.error.name');
  }
  if (!values.date) {
    errors.date = t('operation.error.date');
  }
  if (values.date) {
    if ((Number.parseInt(values.date.year, 10) > (new Date().getFullYear() + 1))) {
      errors.date = t('operation.error.dateInFuture');
    }
    const operationDate = new Date(values.date.year, values.date.month - 1, values.date.day);
    if (date) {
      let lastHistoryDate = new Date(date);
      lastHistoryDate.setHours(0, 0, 0);
      if (date.includes('/')) {
        const [day, month, year] = date?.split(' ')[0]?.split('/');
        lastHistoryDate = new Date(year, month - 1, day);
      }
      if (operationDate < lastHistoryDate) {
        errors.date = t('operation.error.dateInPast');
      }
    }
  }
  return errors;
}
