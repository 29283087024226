import * as types from './types';

const initialState = {
  isActive: false,
  stepIndex: 0,
};

export default function onboardingReducer(state = initialState, { type }) {
  switch (type) {
    case types.NEXT_STEP:
      return {
        ...state,
        isActive: true,
        stepIndex: state.stepIndex + 1,
      };
    default:
      return state;
  }
}
