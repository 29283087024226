import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OperationHeader from 'components/Operation/OperationHeader';
import * as statuses from 'services/store/states';

import styles from './OperationsReduction.module.css';
import OperationsReductionForm from './OperationsReductionForm';

export default function OperationsReduction({ status, societyId, isHolding }) {
  const { t } = useTranslation();
  if (status === statuses.IDLE) {
    return <Redirect to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`} />;
  }
  return (
    <div className={styles.transfert}>
      <div className={styles.header}>
        <OperationHeader
          type="reduction"
          step={1}
        />
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`}
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        <OperationsReductionForm
          isHolding={isHolding}
        />
      </div>
    </div>
  );
}
