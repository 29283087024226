export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';

export const FETCH_MESSAGES_REQUEST = 'SET_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'SET_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAIL = 'SET_MESSAGES_FAIL';

export const SEND_MESSAGES_REQUEST = 'SEND_MESSAGES_REQUEST';
export const SEND_MESSAGES_SUCCESS = 'SEND_MESSAGES_SUCCESS';
export const SEND_MESSAGES_FAIL = 'SEND_MESSAGES_FAIL';

export const SEND_GROUP_MESSAGES_REQUEST = 'SEND_GROUP_MESSAGES_REQUEST';
export const SEND_GROUP_MESSAGES_SUCCESS = 'SEND_GROUP_MESSAGES_SUCCESS';
export const SEND_GROUP_MESSAGES_FAIL = 'SEND_GROUP_MESSAGES_FAIL';

export const RECEIVE_GROUP_MESSAGES_SUCCESS = 'RECEIVE_GROUP_MESSAGES_SUCCESS';

export const RESET_GROUP_NOTIFICATION = 'RESET_GROUP_NOTIFICATION';

export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_QUERY = 'SET_QUERY';
