import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as statuses from '../../services/store/states';

import SocietyInfo from './SocietyInfo';
import SocietyHeader from './SocietyHeader';
import SocietyDescription from './SocietyDescription';
import SocietyShare from './SocietyShare';
import styles from './Society.module.css';

export default function Society({
  id, society, actions,
}) {
  useEffect(() => {
    if (id && society.status === statuses.IDLE) {
      const { fetchSocietyData } = actions;
      fetchSocietyData();
    }
  }, [id]);

  if (society.status !== 'failed') {
    return (
      <section>
        <SocietyHeader
          src={society?.coverUrl}
        />
        <div
          className={styles.societyWrapper}
        >
          <SocietyInfo
            status={society?.status}
            name={society?.societyName}
            owner={society?.owner}
            social={society?.social}
            logoUrl={society?.logoUrl}
          />
          <SocietyDescription
            status={society?.status}
            name={society?.name}
            description={society?.description}
            website={society?.websiteUrl}
          />
          <SocietyShare
            share={society?.sharesPrice}
            valuation={society?.valuation}
            update={society?.lastCapitalIncrease}
          />
        </div>
      </section>
    );
  }
  return null;
}

Society.defaultProps = {
  society: {
    status: statuses.IDLE,
  },
};

Society.propsType = {
  society: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.string,
    social: PropTypes.string,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    share: PropTypes.number,
    valuation: PropTypes.number,
    coverUrl: PropTypes.string,
  }),
  actions: PropTypes.shape({
    fetchSocietyData: PropTypes.func.isRequired,
  }),
};
