import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button/Button';
import * as statuses from 'services/store/states';

import styles from '../Setting.module.css';
import investorsStyles from './SettingsInvestors.module.css';
import SettingsInvestorsRow from './SettingsInvestorsRow';
import SettingsInvestorsCreation from './SettingsInvestorsCreation';

export default function SettingsInvestors({ status, investors, actions }) {
  const [showCreation, setShowCreation] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, []);

  const openInvestorCreation = () => setShowCreation(true);
  const closeInvestorCreation = () => setShowCreation(false);

  if (showCreation) {
    return (<SettingsInvestorsCreation close={closeInvestorCreation} />);
  }
  return (
    <main className={styles.content}>
      <div className={investorsStyles.header}>
        <div>
          <p className={investorsStyles.title}>
            {t('settings.investors.access')}
          </p>
          <p className={investorsStyles.description}>
            {t('settings.investors.access-description')}
          </p>
        </div>
        <Button
          label={t('settings.investors.add')}
          onButtonClick={openInvestorCreation}
        />
      </div>
      <div className={investorsStyles.table}>
        <div className={investorsStyles.row}>
          <p>
            {t('settings.investors.account')}
          </p>
          <p>
            {t('settings.investors.email')}
          </p>
          <p>
            {t('settings.investors.status')}
          </p>
        </div>
        {
          (investors?.length > 0)
            ? (
              investors.map((investor) => (
                <SettingsInvestorsRow
                  key={investor.id}
                  investor={investor}
                />
              ))
            )
            : (
              <div className={investorsStyles.empty}>
                <p className={investorsStyles.title}>
                  { t('settings.investors.empty') }
                </p>
                <Button
                  label={t('settings.investors.add')}
                  size="small"
                  onButtonClick={openInvestorCreation}
                />
              </div>
            )
        }
      </div>
    </main>
  );
}
