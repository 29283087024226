export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCEEDED = 'FETCH_DOCUMENTS_SUCCEEDED';
export const FETCH_DOCUMENTS_FAILED = 'FETCH_DOCUMENTS_FAILED';

export const SET_QUERY = 'SET_QUERY';
export const SET_FOLDER = 'SET_FOLDER';
export const SET_SORT = 'SET_SORT';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCEEDED = 'UPDATE_DOCUMENT_SUCCEEDED';
export const UPDATE_DOCUMENT_FAILED = 'UPDATE_DOCUMENT_FAILED';

export const SEND_DOCUMENT_REQUEST = 'SEND_DOCUMENT_REQUEST';
export const SEND_DOCUMENT_SUCCEEDED = 'SEND_DOCUMENT_SUCCEEDED';
export const SEND_DOCUMENT_FAILED = 'SEND_DOCUMENT_FAILED';
export const RESET_DOCUMENT_REQUEST = 'RESET_DOCUMENT_REQUEST';

export const SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCEEDED = 'DELETE_DOCUMENT_SUCCEEDED';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

export const DELETE_USER_DOCUMENT_REQUEST = 'DELETE_USER_DOCUMENT_REQUEST';
export const DELETE_USER_DOCUMENT_SUCCEEDED = 'DELETE_USER_DOCUMENT_SUCCEEDED';
export const DELETE_USER_DOCUMENT_FAILED = 'DELETE_USER_DOCUMENT_FAILED';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCEEDED = 'UPLOAD_DOCUMENT_SUCCEEDED';
export const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';
export const UPLOAD_DOCUMENT_RESET = 'UPLOAD_DOCUMENT_RESET';
export const UPLOAD_USER_DOCUMENT_RESET = 'UPLOAD_USER_DOCUMENT_RESET';

export const FETCH_USER_DOCUMENTS_REQUEST = 'FETCH_USER_DOCUMENTS_REQUEST';
export const FETCH_USER_DOCUMENTS_SUCCEEDED = 'FETCH_USER_DOCUMENTS_SUCCEEDED';
export const FETCH_USER_DOCUMENTS_FAILED = 'FETCH_USER_DOCUMENTS_FAILED';

export const SET_USER_SELECTED_DOCUMENT = 'SET_USER_SELECTED_DOCUMENT';
