import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

import InputFile2 from '../../../components/library/Input/InputFile2/InputFile2';
import InputText from '../../../components/library/Input/InputText/InputText';
import TextArea from '../../../components/library/Input/TextArea/TextArea';
import Button from '../../../components/library/Button/Button';
import StateHandler from '../../../components/StateHandler/StateHandler';
import { keysToSnake } from '../../../utils/converter';
import * as statuses from '../../../services/store/states';

import styles from '../Setting.module.css';

export default function SettingsSociety({ society, user, actions }) {
  const { society: societyId, userId } = useParams();
  const [profilePicture, setProfilePicture] = useState({
    state: 'DEFAULT',
    file: null,
    url: null,
  });
  const [coverPicture, setCoverPicture] = useState({
    state: 'DEFAULT',
    file: null,
    url: null,
  });
  const [facebook, setFacebook] = useState({
    url: '',
    state: statuses.IDLE,
  });
  const [twitter, setTwitter] = useState({
    url: '',
    state: statuses.IDLE,
  });
  const [linkedIn, setLinkedIn] = useState({
    url: '',
    state: statuses.IDLE,
  });
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState({
    url: '',
    state: statuses.IDLE,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!society.id) {
      actions.setSocietyId(societyId);
    }
    if (!user.id) {
      actions.fetchUserInfo(userId, societyId);
    }
  }, []);

  useEffect(() => {
    if (society.status === statuses.SUCCESS) {
      setProfilePicture((state) => (
        { ...state, file: society.logoUrl, url: society.logoUrl }));
      setCoverPicture((state) => (
        { ...state, file: society.coverUrl, url: society.coverUrl }
      ));
      setFacebook({
        url: society.facebookUrl,
        state: statuses.IDLE,
      });
      setTwitter({
        url: society.twitterUrl,
        state: statuses.IDLE,
      });
      setLinkedIn({
        url: society.linkedinUrl,
        state: statuses.IDLE,
      });
      setWebsite({
        url: society.websiteUrl,
        state: statuses.IDLE,
      });
      setDescription(society.description);
    }
    if (society.status === statuses.IDLE) {
      actions.fetchSocietyData();
    }
  }, [society]);

  const isValidLink = (str, callback) => {
    if (str) {
      try {
        // eslint-disable-next-line no-new
        new URL(str);
      } catch (err) {
        callback((state) => ({
          ...state,
          state: statuses.FAIL,
          error: t('settings.url.notValid'),
        }));
        return false;
      }
      callback((state) => ({
        ...state,
        state: 'SUCCESS',
        error: null,
      }));
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (
      (!facebook.url || isValidLink(facebook.url, setFacebook))
      && (!twitter.url || isValidLink(twitter.url, setTwitter))
      && (!linkedIn.url || isValidLink(linkedIn.url, setLinkedIn))
      && (!website.url || isValidLink(website.url, setWebsite))
    ) {
      actions.updateSocietyData(keysToSnake({
        email: society.email,
        firstName: society.firstName,
        lastName: society.lastName,
        sharePrice: society.sharePrice,
        valuation: society.valuation,
        societyName: society.societyName,
        description,
        websiteUrl: website.url,
        facebookUrl: facebook.url,
        twitterUrl: twitter.url,
        linkedinUrl: linkedIn.url,
        coverUrl: coverPicture.file,
        logoUrl: profilePicture.file,
      }));
      if (!isActive) setIsActive(true);
    }
  };

  if (!user.id && !society.id) return null;

  return (
    <main className={styles.content}>
      <StateHandler
        status={society.update}
        message={{
          succeeded: t('settings.success'),
          failed: t('settings.fail'),
        }}
        isActive={isActive}
      />
      <h2 className={styles.title}>
        Images
      </h2>
      <div className={styles.row}>
        <InputFile2
          label={t('settings.logo')}
          state={profilePicture.state}
          file={profilePicture.url}
          handleFile={(files) => {
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'tiff', 'svg'];
            const { name: fileName } = files[0];
            const fileExtension = fileName.split('.').pop();
            if (allowedExtensions.includes(fileExtension)) {
              URL.revokeObjectURL(profilePicture.url);
              setProfilePicture({ state: 'SUCCESS', file: files[0], url: URL.createObjectURL(files[0]) });
            }
          }}
          cancel={() => setProfilePicture({ state: statuses.IDLE, file: null, url: null })}
        />
        <InputFile2
          label={t('settings.cover')}
          state={coverPicture.state}
          file={coverPicture.url}
          handleFile={(files) => {
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'tiff', 'svg'];
            const { name: fileName } = files[0];
            const fileExtension = fileName.split('.').pop();
            if (allowedExtensions.includes(fileExtension)) {
              URL.revokeObjectURL(coverPicture.url);
              setCoverPicture({ state: 'SUCCESS', file: files[0], url: URL.createObjectURL(files[0]) });
            }
          }}
          cancel={() => setCoverPicture({ state: statuses.IDLE, file: null, url: null })}
        />
      </div>
      <hr className={styles.hr} />
      <h2 className={styles.social}>
        {t('settings.social')}
      </h2>
      <InputText
        label={t('settings.facebook')}
        valueInput={facebook.url}
        onTextChange={(e) => setFacebook((state) => (
          { ...state, url: e.target.value }))}
        state={facebook.state}
        hint={facebook.error}
        onBlur={(e) => isValidLink(e.target.value, setFacebook)}
      />
      <InputText
        label={t('settings.twitter')}
        valueInput={twitter.url}
        onTextChange={(e) => setTwitter((state) => (
          { ...state, url: e.target.value }))}
        state={twitter.state}
        hint={twitter.error}
        onBlur={(e) => isValidLink(e.target.value, setTwitter)}
      />
      <InputText
        label={t('settings.linkedIn')}
        valueInput={linkedIn.url}
        onTextChange={(e) => setLinkedIn((state) => (
          { ...state, url: e.target.value }))}
        state={linkedIn.state}
        hint={linkedIn.error}
        onBlur={(e) => isValidLink(e.target.value, setLinkedIn)}
      />
      <hr className={`${styles.marginTop} ${styles.hr}`} />
      <h2 className={styles.social}>
        Description
      </h2>
      <TextArea
        label={t('settings.description')}
        valueInput={description}
        onTextChange={(e) => setDescription(e.target.value)}
      />
      <InputText
        label={t('settings.website')}
        valueInput={website.url}
        onTextChange={(e) => setWebsite((state) => ({ ...state, url: e.target.value }))}
        state={website.state}
        hint={website.error}
        onBlur={(e) => isValidLink(e.target.value, setWebsite)}
      />
      <Button
        label={
          (society.status === statuses.LOADING)
            ? t('settings.saving')
            : t('settings.save')
        }
        icon={
          (society.status === statuses.LOADING)
            ? (<FontAwesomeIcon icon={faSpinner} spin />)
            : null
        }
        className={styles.button}
        onButtonClick={handleSubmit}
      />
    </main>
  );
}
