import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  id: null,
  status: statuses.IDLE,
  update: statuses.IDLE,
  error: null,
  holding: {
    status: statuses.IDLE,
    error: null,
  },
};

export default function societyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SOCIETY_ID:
      return { ...state, id: payload };
    case types.FETCH_SOCIETY_REQUEST:
      return { ...state, status: statuses.LOADING };
    case types.FETCH_SOCIETY_SUCCEEDED:
      return {
        ...state,
        ...payload,
        status: statuses.SUCCESS,
      };
    case types.FETCH_SOCIETY_FAILED:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.UPDATE_SOCIETY_REQUEST:
      return { ...state, update: statuses.LOADING };
    case types.UPDATE_SOCIETY_FAIL:
      return { ...state, update: 'failed', error: payload };
    case types.UPDATE_SOCIETY_SUCCESS:
      return {
        ...state,
        ...payload,
        update: statuses.SUCCESS,
      };
    case types.FETCH_HOLDING_REQUEST:
      return {
        ...state,
        holding: {
          ...state.holding,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_HOLDING_SUCCEEDED:
      return {
        ...state,
        holding: {
          ...state.holding,
          status: statuses.SUCCESS,
          ...payload,
        },
      };
    case types.FETCH_HOLDING_FAILED:
      return {
        ...state,
        holding: {
          ...state.holding,
          status: statuses.FAIL,
          error: payload,
        },
      };
    default:
      return state;
  }
}
