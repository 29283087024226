import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputText from 'components/library/Input/InputText/InputText';
import InputDate from 'components/library/Input/InputDate/InputDate';
import Checkbox from 'components/library/Input/Checkbox/Checkbox';
import TextArea from 'components/library/Input/TextArea/TextArea';
import InputFile2 from 'components/library/Input/InputFile2/InputFile2';
import Button from 'components/library/Button/Button';
import * as statuses from 'services/store/states';

import useForm from '../../../hook/useForm';
import validate from './validate';
import styles from './EventsPopUp.module.css';

export default function EventsPopUpForm({ event, actions, next }) {
  const [img, setImg] = useState(event?.img || null);
  const { t } = useTranslation();

  const handleFile = (files) => {
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'tiff', 'svg'];
    const { name: fileName } = files[0];
    const fileExtension = fileName.split('.').pop();
    if (allowedExtensions.includes(fileExtension)) {
      setImg(files[0]);
    }
  };

  const submit = (values) => {
    next({
      ...values,
      img,
      date: `${values.date.day}/${values.date.month}/${values.date.year}`,
    });
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submit, (form) => validate(t, form), event || { allDay: false });

  return (
    <section className={styles.event}>
      <main className={styles.content}>
        <p>
          (
          <span className={styles.required}>
            *
          </span>
          )
          {' '}
          {t('events.form.required')}
        </p>
        <InputText
          label={t('events.form.name')}
          name="title"
          valueInput={values.title || ''}
          onTextChange={handleChange}
          state={errors.title ? statuses.FAIL : 'DEFAULT'}
          hint={errors.title}
          required
        />
        <InputDate
          label={t('events.form.date')}
          name="date"
          day={values.date?.day || ''}
          month={values.date?.month || ''}
          year={values.date?.year || ''}
          onChange={(date, e) => {
            e.target.name = 'date';
            e.target.value = '';
            handleChange(e, date);
          }}
          state={errors.date ? statuses.FAIL : 'DEFAULT'}
          hint={errors.date}
          required
          includeDay
        />
        <Checkbox
          name="isAllDay"
          label={t('events.form.allDay')}
          checked={values.isAllDay}
          onChange={(bool, e) => {
            e.target.name = 'isAllDay';
            e.target.value = '';
            handleChange(e, bool);
          }}
        />
        {
          (!values.isAllDay)
          && (
            <div className={styles.timetable}>
              <div className={styles.time}>
                <p>
                  <span className={styles.requiredMargin}>
                    *
                  </span>
                  {t('events.form.begin')}
                </p>
                <input
                  type="time"
                  name="hourBeginning"
                  value={values.hourBeginning || ''}
                  className={(errors.hourBeginning) ? styles.inputError : null}
                  onChange={handleChange}
                />
                {
                  (errors.hourBeginning)
                  && (
                    <p className={styles.hintError}>
                      { errors.hourBeginning }
                    </p>
                  )
                }
              </div>
              <div className={styles.time}>
                <p>
                  <span className={styles.requiredMargin}>
                    *
                  </span>
                  {t('events.form.end')}
                </p>
                <input
                  type="time"
                  name="hourEnding"
                  value={values.hourEnding || ''}
                  className={(errors.hourEnding) ? styles.inputError : null}
                  onChange={handleChange}
                />
                {
                  (errors.hourEnding) && <p className={styles.hintError}>{ errors.hourEnding }</p>
                }
              </div>
            </div>
          )
        }
        <InputText
          label={t('events.form.place')}
          name="place"
          valueInput={values.place || ''}
          hint={errors.place}
          state={errors.place ? statuses.FAIL : 'DEFAULT'}
          onTextChange={handleChange}
          required
        />
        <TextArea
          label={t('events.form.description')}
          name="description"
          valueInput={values.description || ''}
          error={errors.description}
          onTextChange={handleChange}
          required
        />
        <InputFile2
          label={t('events.form.cover')}
          file={img}
          state={(img) ? 'SUCCESS' : 'DEFAULT'}
          handleFile={handleFile}
          cancel={() => setImg(null)}
        />
      </main>
      <footer
        className={styles.footer}
      >
        <Button
          type="custom"
          label={t('cancel')}
          className={styles.tertiaryButton}
          onButtonClick={actions.closePopUp}
        />
        <Button
          type="secondary"
          label={t('events.form.continue')}
          onButtonClick={handleSubmit}
        />
      </footer>
    </section>
  );
}
