import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faStar } from '@fortawesome/free-solid-svg-icons';

import styles from './DocumentsFolder.module.css';

export default function DocumentsFolders({ folders, isLoading }) {
  const location = useLocation();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <section className={styles.folders}>
        <h2>
          {t('documents.folders')}
        </h2>
        <ul className={styles.list}>
          <li className={`${styles.item} ${styles.skeleton}`} />
          <li className={`${styles.item} ${styles.skeleton}`} />
        </ul>
      </section>
    );
  }

  return (
    <section className={styles.folders}>
      <h2>
        {t('documents.folders')}
      </h2>
      <ul className={styles.list}>
        {
          folders.map((folder) => (
            <Link
              className={styles.item}
              key={folder}
              to={`${location.pathname}/${folder}`}
            >
              <FontAwesomeIcon
                className={styles.folderIcon}
                icon={(folder === 'favoris') ? faStar : faFolder}
              />
              <p>
                {
                (folder === 'favoris')
                  ? t('documents.favorite')
                  : folder
              }
              </p>
            </Link>
          ))
        }
      </ul>
    </section>
  );
}
