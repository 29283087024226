import React, { useState, useEffect } from 'react';

import ErrorAnimation from 'components/Error/ErrorAnimation/ErrorAnimation';
import SuccessAnimation from 'components/SuccessAnimation/SuccessAnimation';
import Button from 'components/library/Button/Button';
import * as statuses from 'services/store/states';

import OperationsShareholderUpdateForm from './OperationsShareholderUpdateForm';
import styles from './OperationsShareholderUpdate.module.css';

export default function OperationsShareholderUpdate({
  back, status, shareholdersStatus, shareholders, actions,
}) {
  const [selectedshareholderId, setSelectedshareholderId] = useState(-1);
  const [selectedShareholders, setSelectedShareholders] = useState(null);
  const [shouldDisplayForm, setShouldDisplayForm] = useState(false);

  useEffect(() => {
    if (shareholdersStatus === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    return () => actions.resetCreation();
  }, []);

  if (status === 'fail') {
    return (
      <section className={styles.error}>
        <ErrorAnimation />
        <h3> Un problème technique est survenue </h3>
        <p>
          Le nouvel actionnaire n&apos; a pas été créé.
          Veuillez nous excuser pour la gêne occasionnée. Essayez plus tard,
          si l&apos;erreur persite, contactez notre support.
        </p>
      </section>
    );
  }

  if (status === 'success') {
    return (
      <section className={styles.success}>
        <SuccessAnimation />
        <h3>
          Le nouvel actionnaire a été créer avec succès !
        </h3>
        <Button
          label="Retour"
          onButtonClick={back}
        />
      </section>
    );
  }

  if (shouldDisplayForm && selectedShareholders) {
    return (
      <OperationsShareholderUpdateForm
        actions={actions}
        selectedShareholders={selectedShareholders}
      />
    );
  }

  return (
    <section>
      <h3>
        Reprendre les informations d’un actionnaire existant
      </h3>
      <p className={styles.label}>
        Choisissez un actionnaire:
      </p>
      <select
        className={styles.dropdown}
        value={selectedshareholderId}
        onChange={(e) => {
          const id = Number.parseInt(e.target.value, 10);
          setSelectedshareholderId(id);
          const newShareholder = shareholders.filter((contact) => contact.id === id)[0];
          const birthday = newShareholder.birthday.split('/');
          setSelectedShareholders({
            ...newShareholder,
            birthday: {
              day: birthday[0],
              month: birthday[1],
              year: birthday[2],
            },
            oldId: id,
          });
        }}
      >
        <option value={-1}> Choisissez un actionnaire </option>
        {
          shareholders.map((shareholder) => (
            <option
              key={shareholder.id}
              value={shareholder.id}
            >
              {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail }
            </option>
          ))
        }
      </select>
      <Button
        label="Valider"
        onButtonClick={() => setShouldDisplayForm(true)}
        disabled={(selectedShareholders === -1)}
      />
    </section>
  );
}
