import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload, faCamera, faSpinner, faTimes, faCheck, faClock,
} from '@fortawesome/free-solid-svg-icons';

import * as statuses from '../../../../services/store/states';
import Button from '../../Button/Button';
import InputFileDefault from './InputFileDefault';
import InputFileLoading from './InputFileLoading';
import InputFileWaiting from './InputFileWaiting';
import InputFileSuccess from './InputFileSuccess';
import InputFileError from './InputFileError';

import styles from './InputFile2.module.css';

export default function InputFile2({
  label, handleFile, state = 'DEFAULT', cancel, file, error, hideOpen = false,
}) {
  const [hover, setHover] = useState(false);
  const [errorFile, setErrorFile] = useState(null);

  const inputRef = useRef(null);

  const cameraRef = useRef(null);

  const stopEvent = (e) => {
    e.preventDefault();
  };

  const onFilesAdded = (e) => {
    const { files } = e.target;
    const allowedExtensions = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'tiff', 'bmp', 'pjp', 'svg', 'webp', 'pdf', 'png', 'gif'];
    const { name: fileName } = files[0];
    const fileExtension = fileName.split('.').pop();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorFile('Ce type de fichier n\'est pas authorisé');
    } else {
      setErrorFile(null);
      handleFile(files);
    }
  };

  const onDrop = (e) => {
    stopEvent(e);
    const { files } = e.dataTransfer;
    handleFile(files);
    setHover(false);
  };

  const onDragOver = (e) => {
    stopEvent(e);
    setHover(true);
  };

  const onDragLeave = (e) => {
    stopEvent(e);
    setHover(false);
  };

  const openFileDialog = () => {
    inputRef.current.click();
  };

  const openCamera = () => {
    cameraRef.current.click();
  };

  if (state === 'DEFAULT' || state === statuses.IDLE) {
    return (
      <InputFileDefault
        label={label}
        inputRef={inputRef}
        cameraRef={cameraRef}
        openCamera={openCamera}
        onFilesAdded={onFilesAdded}
        openFileDialog={openFileDialog}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        hover={hover}
        error={errorFile}
      />
    );
  }
  if (state === statuses.LOADING || state === 'UPLOADING') {
    return (
      <InputFileLoading
        label={label}
        inputRef={inputRef}
        cameraRef={cameraRef}
        openCamera={openCamera}
        onFilesAdded={onFilesAdded}
        openFileDialog={openFileDialog}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        hover={hover}
        cancel={cancel}
      />
    );
  }

  if (state === 'WAITING') {
    return (
      <InputFileWaiting
        label={label}
        file={file}
        inputRef={inputRef}
        cameraRef={cameraRef}
        openCamera={openCamera}
        onFilesAdded={onFilesAdded}
        openFileDialog={openFileDialog}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        hover={hover}
        cancel={cancel}
      />
    );
  }
  if (state === 'SUCCESS') {
    return (
      <InputFileSuccess
        label={label}
        file={file}
        hideOpen={hideOpen}
        inputRef={inputRef}
        cameraRef={cameraRef}
        openCamera={openCamera}
        onFilesAdded={onFilesAdded}
        openFileDialog={openFileDialog}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        hover={hover}
        cancel={cancel}
      />
    );
  }
  if (state === statuses.FAIL) {
    return (
      <InputFileError
        label={label}
        file={file}
        error={error}
        inputRef={inputRef}
        cameraRef={cameraRef}
        openCamera={openCamera}
        onFilesAdded={onFilesAdded}
        openFileDialog={openFileDialog}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        hover={hover}
        cancel={cancel}
      />
    );
  }
  return (
    <div className={styles.container}>
      <p className={styles.label}>
        { label }
      </p>
      <div className={styles.onMobile}>
        <p className={styles.hint}>
          Format accepté: PDF, JPG, JPEG, PNG, TIFF, SVG, BMP, WepP - Couleur
        </p>
        <p className={styles.hint}>
          Taille max: 4Mo
        </p>
        {
          (state === statuses.FAIL)
          && (
            <p className={styles.hintError}>
              Une erreur est survenue, le fichier n&apos;a pas été ajouté.
              Vérifier la taille et le format de votre image. Si le problème persiste,
              prevenez l&apos;équipe technique.
            </p>
          )
        }
      </div>
      <div
        className={`${styles.onMobile}`}
      >
        <input
          type="file"
          accept="image/*,application/pdf"
          ref={inputRef}
          onChange={onFilesAdded}
          hidden
        />
        <input
          type="file"
          id="imageFile"
          capture="environment"
          accept="image/*,application/pdf,capture=camera"
          ref={cameraRef}
          onChange={onFilesAdded}
          hidden
        />
        <div className={styles.row}>
          <Button
            icon={<FontAwesomeIcon className={styles.icon} icon={faCamera} />}
            label="Prendre une photo"
            className={styles.button}
            type="secondary"
            onButtonClick={openCamera}
          />
          <Button
            label="Choisir un fichier"
            variant="outline"
            className={styles.buttonFile}
            type="secondary"
            onButtonClick={openFileDialog}
          />
        </div>
      </div>
      <div
        className={`${styles.desktop} ${styles.dropfile}  ${(hover) ? styles.hover : ''}`}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <div className={`${styles.illustration} ${(hover) ? styles.hover : ''}`}>
          {
            (state === statuses.IDLE || state === 'DEFAULT')
            && (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faFileUpload}
              />
            )
          }
          {
            (state === 'UPLOADING' || state === statuses.LOADING)
            && (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faSpinner}
                spin
              />
            )
          }
          {
            (state === 'WAITING')
            && (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faClock}
              />
            )
          }
          {
            (state === statuses.FAIL)
            && (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faTimes}
              />
            )
          }
          {
            (state === 'SUCCESS')
            && (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCheck}
              />
            )
          }
        </div>
        <input
          type="file"
          ref={inputRef}
          accept="image/*,application/pdf"
          onChange={onFilesAdded}
          hidden
        />
        <input
          type="file"
          id="imageFile"
          capture="environment"
          accept="image/*,application/pdf,capture=camera"
          onChange={onFilesAdded}
          ref={cameraRef}
          hidden
        />
        <p className={styles.dropzone}>
          Glisser-déposer le fichier, ou
        </p>
        <div className={styles.row}>
          <Button
            label="Choisir un fichier"
            className={styles.buttonFile}
            type="secondary"
            onButtonClick={openFileDialog}
          />
          <Button
            icon={<FontAwesomeIcon className={styles.icon} icon={faCamera} />}
            label=""
            className={styles.button}
            type="secondary"
            onButtonClick={openCamera}
          />
        </div>
      </div>
      <div className={`${styles.desktop} ${styles.hintColumn}`}>
        <p className={styles.hint}>
          Format accepté: PDF, JPG, JPEG, PNG, TIFF, SVG, BMP, WepP - Couleur
        </p>
        <p className={styles.hint}>
          Taille max: 4Mo
        </p>
        {
          (state === statuses.FAIL)
          && (
            <p className={styles.hintError}>
              Une erreur est survenue, le fichier n&apos;a pas été ajouté.
              Vérifier la taille et le format de votre image.
              Si le problème persiste, prevenez l&apos;équipe technique.
            </p>
          )
        }
      </div>
    </div>
  );
}
