export const FETCH_EVENTS_REQUIRED = 'FETCH_EVENTS_REQUIRED';
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED';
export const FETCH_EVENTS_SUCCEEDED = 'FETCH_EVENTS_SUCCEEDED';

export const CREATE_EVENTS_REQUEST = 'CREATE_EVENTS_REQUEST';
export const CREATE_EVENTS_SUCCEEDED = 'CREATE_EVENTS_SUCCEEDED';
export const CREATE_EVENTS_FAILED = 'CREATE_EVENTS_FAILED';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED';

export const OPEN_ASSEMBLY_POPUP = 'OPEN_ASSEMBLY_POPUP';
export const CREATE_ASSEMBLY_REQUEST = 'CREATE_ASSEMBLY_REQUEST';
export const CREATE_ASSEMBLY_SUCCEEDED = 'CREATE_ASSEMBLY_SUCCEEDED';
export const CREATE_ASSEMBLY_FAILED = 'CREATE_ASSEMBLY_FAILED';

export const UPDATE_ASSEMBLY_REQUEST = 'UPDATE_ASSEMBLY_REQUEST';
export const UPDATE_ASSEMBLY_SUCCEEDED = 'UPDATE_ASSEMBLY_SUCCEEDED';
export const UPDATE_ASSEMBLY_FAILED = 'UPDATE_ASSEMBLY_FAILED';

export const RESET_EVENTS = 'RESET_EVENTS';
