import React from 'react';
import { useTranslation } from 'react-i18next';

import TableDropdown from '../../TableDropdown/TableDropdown';
import styles from '../OperationTable.module.css';

export default function OperationTableTitle({
  types, selectedTypes, names, selectedNames, actions,
}) {
  const { t } = useTranslation();

  const handleTypes = (type) => {
    if (selectedTypes.includes(type)) {
      actions.setType(selectedTypes.filter((currType) => currType !== type));
    } else {
      actions.setType([...selectedTypes, type]);
    }
  };

  const handleNames = (name) => {
    if (selectedNames.includes(name)) {
      actions.setName(selectedNames.filter((currName) => currName !== name));
    } else {
      actions.setName([...selectedNames, name]);
    }
  };

  return (
    <li className={styles.title}>
      <p>ID</p>
      <p>Date</p>
      <div className={styles.button}>
        <p>{t('operation.table.operation')}</p>
        <TableDropdown
          categories={types}
          categoriesSelected={selectedTypes}
          handleClick={handleTypes}
        />
      </div>
      <p>{t('operation.table.quantity')}</p>
      <p>{t('operation.table.from')}</p>
      <p>{t('operation.table.to')}</p>
      <div className={styles.button}>
        <p>{t('operation.table.title')}</p>
        <TableDropdown
          categories={names}
          categoriesSelected={selectedNames}
          handleClick={handleNames}
        />
      </div>
    </li>
  );
}
