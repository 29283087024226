import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/library/Button/Button';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';
import * as statuses from '../../../services/store/states';

import OperationsShareholders from '../OperationsShareholders';

import OperationModifyTransfertForm from './OperationModifyTransfertForm';
import styles from './OperationModifyTransfert.module.css';

export default function OperationModifyTransfert({
  operation, currentOperations, shareholders, history, actions,
}) {
  const [operationsList, setOperationsList] = useState([...currentOperations]);
  const [submissionList, setSubmissionList] = useState([]);
  const [isShareholderFormOpen, setIsShareholderFormOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (hasSubmitted && submissionList.length === operationsList.length) {
      actions.updateTransfert(submissionList);
    }
  }, [submissionList]);

  const submit = (values) => {
    setSubmissionList((list) => [
      ...list.filter((l) => l.id !== values.id),
      {
        ...operation,
        ...values,
        from: {
          shareholderId: Number.parseInt(values.from, 10),
        },
        to: {
          shareholderId: Number.parseInt(values.to, 10),
        },
        quantity: Number.parseInt(values.quantity, 10),
      },
    ]);
  };

  if (hasSubmitted && operation.status === statuses.SUCCESS) {
    return (
      <section className={styles.transfert}>
        <div
          className={styles.header}
        >
          <h2>
            {t('operation.editTransfer')}
          </h2>
          <Link
            to="/actionnaires/operations/edit/choice"
            className={styles.link}
          >
            {t('operation.back')}
          </Link>
        </div>
        <div className={styles.success}>
          <SuccessAnimation />
          <h3>
            {t('operation.editTransferSucceed')}
          </h3>
          <Link
            to="/actionnaires/operations"
            className={styles.button}
          >
            {t('operation.back')}
          </Link>
        </div>
      </section>
    );
  }
  return (
    <section className={styles.transfert}>
      <div
        className={styles.header}
      >
        <h2>
          {t('operation.editTransfer')}
        </h2>
        <Link
          to="/actionnaires/operations/edit/choice"
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        {
          (isShareholderFormOpen)
            ? (
              <OperationsShareholders
                back={() => setIsShareholderFormOpen(false)}
              />
            )
            : (
              <div className={styles.formContainer}>
                <form className={styles.form}>
                  {
                    operationsList.map((op) => (
                      <OperationModifyTransfertForm
                        key={op.id}
                        operation={op}
                        shareholders={shareholders}
                        history={history}
                        submit={submit}
                        hasSubmitted={hasSubmitted}
                        openForm={() => setIsShareholderFormOpen(true)}
                        deleteOperation={(id) => setOperationsList(
                          (list) => list.filter((o) => o.id !== id),
                        )}
                      />
                    ))
                  }
                  <Button
                    label={t('operation.addTransfer')}
                    onButtonClick={() => setOperationsList((list) => [
                      ...list,
                      {
                        id: Math.max(...list.map((i) => i.id)) + 1,
                        name: operation.name,
                        isNew: true,
                      },
                    ])}
                  />
                </form>
                <div className={styles.buttonGroup}>
                  <Button
                    label={t('operation.next')}
                    onButtonClick={() => setHasSubmitted(true)}
                    disabled={operationsList.length === 0}
                  />
                </div>
              </div>
            )
        }
      </div>
    </section>
  );
}

OperationModifyTransfert.propTypes = {
  operation: Proptypes.shape({}).isRequired,
};
