import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

import styles from './Searchbar.module.css';

export default function Searchbar({ placeholder, query = '', actions }) {
  return (
    <div
      className={styles.border}
    >
      <SearchIcon
        className={styles.icon}
      />
      <input
        type="text"
        value={query}
        onChange={(e) => actions.setQuery(e.target.value)}
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  );
}

Searchbar.defaultProps = {
  query: '',
};

Searchbar.propTypes = {
  query: PropTypes.string,
  actions: PropTypes.shape({
    setQuery: PropTypes.func.isRequired,
  }).isRequired,
};
