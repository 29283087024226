import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from '../Documentation.module.css';

export default function DocumentationDashboard({ societyId }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>
        {t('documentation.dashboard.title')}
      </h2>
      <p className={styles.description}>
        {t('documentation.dashboard.description1')}
      </p>
      <p className={styles.inline}>
        {t('documentation.dashboard.description2')}
        <NavLink
          to={`/${societyId}/settings`}
          className={styles.link}
        >
          <SettingsIcon className={styles.icon} />
          {t('documentation.dashboard.settings')}
        </NavLink>
      </p>
    </>
  );
}
