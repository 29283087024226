import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowBack, GroupAdd, Person,
} from '@material-ui/icons';

import Button from 'components/library/Button/Button';

import styles from '../../Setting.module.css';
import creationStyles from './SettingsInvestorsCreation.module.css';
import SettingsInvestorsCreationBulk from './SettingsInvestorsCreationBulk';
import SettingsInvestorsCreationForm from './SettingsInvestorsCreationForm';

const formState = {
  IDLE: 'IDLE',
  BULK: 'BULK',
  CREATE: 'CREATE',
};

export default function SettingsInvestorsCreation({ close }) {
  const [form, setForm] = useState(formState.IDLE);
  const { t } = useTranslation();

  const onFormSelect = (e) => setForm(e.currentTarget.dataset.id);

  return (
    <main className={styles.content}>
      <Button
        label={t('settings.investors.back')}
        onButtonClick={close}
        variant="ghost"
        icon={<ArrowBack />}
        className={creationStyles.back}
      />
      <div>
        <p className={creationStyles.title}>
          {t('settings.investors.add-investor.title')}
        </p>
        <p className={creationStyles.description}>
          {t('settings.investors.add-investor.description')}
        </p>
      </div>
      <div className={creationStyles.list}>
        <button
          type="button"
          className={`${creationStyles.add} ${(form === formState.BULK) ? creationStyles.selected : ''}`}
          data-id={formState.BULK}
          onClick={onFormSelect}
        >
          <span className={creationStyles.icon}>
            <GroupAdd />
          </span>
          <span>
            {t('settings.investors.add-investor.bulk')}
          </span>
        </button>
        <button
          type="button"
          data-id={formState.CREATE}
          className={`${creationStyles.add} ${form === formState.CREATE ? creationStyles.selected : ''}`}
          onClick={onFormSelect}
        >
          <span className={creationStyles.icon}>
            <Person />
          </span>
          <span>
            {t('settings.investors.add-investor.add')}
          </span>
        </button>
      </div>
      {
        (form === formState.BULK)
        && <SettingsInvestorsCreationBulk close={close} />
      }
      {(form === formState.CREATE) && <SettingsInvestorsCreationForm close={close} />}
    </main>
  );
}
