import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputText from '../../library/Input/InputText/InputText';
import InputDate from '../../library/Input/InputDate/InputDate';
import InputPhone from '../../library/Input/InputPhone/InputPhone';
import InputFile from '../../library/Input/InputFile2/InputFile2';

import * as statuses from '../../../services/store/states';
import styles from './OperationsShareholdersFormPerson.module.css';

export default function OperationsShareholdersFormPerson({
  shareholder = {}, file = null, handleFile, errors = {}, actions,
}) {
  const [displayFileError, setDisplayFileError] = useState(true);

  const { t } = useTranslation();

  const getFileState = () => {
    if (file) return 'SUCCESS';
    if (displayFileError && errors.img) return statuses.FAIL;
    return 'DEFAULT';
  };

  return (
    <div>
      <p className={styles.required}>
        <span>
          *
        </span>
        {t('required')}
      </p>
      <div className={styles.row}>
        <InputText
          label={t('firstName')}
          name="firstName"
          valueInput={shareholder.firstName || ''}
          onTextChange={actions}
          state={errors.firstName && statuses.FAIL}
          hint={errors.firstName}
          required
        />
        <InputText
          label={t('lastName')}
          name="lastName"
          valueInput={shareholder.lastName || ''}
          onTextChange={actions}
          state={errors.lastName && statuses.FAIL}
          hint={errors.lastName}
          required
        />
      </div>
      <InputText
        label={t('shareholders.edit.mail')}
        name="email"
        valueInput={shareholder.email || ''}
        onTextChange={actions}
        state={errors.email && statuses.FAIL}
        hint={errors.email}
        required
      />
      <InputDate
        label={t('shareholders.edit.birthday')}
        name="birthday"
        day={shareholder?.birthday?.day || ''}
        month={shareholder?.birthday?.month || ''}
        year={shareholder?.birthday?.year || ''}
        onChange={(date, e) => {
          e.target.name = 'birthday';
          e.target.value = '';
          actions(e, date);
        }}
        includeDay
        state={errors.birthday && statuses.FAIL}
        hint={errors.birthday}
      />
      <InputText
        label={t('shareholders.edit.address')}
        name="address"
        valueInput={shareholder.address || ''}
        onTextChange={actions}
        state={errors.address && statuses.FAIL}
        hint={errors.address}
      />
      <div className={styles.row}>
        <InputText
          label={t('shareholders.edit.zip')}
          name="zipCode"
          valueInput={shareholder.zipCode || ''}
          onTextChange={actions}
          state={errors.zipCode && statuses.FAIL}
          hint={errors.zipCode}
        />
        <InputText
          label={t('shareholders.edit.city')}
          name="city"
          valueInput={shareholder.city || ''}
          onTextChange={actions}
          state={errors.city && statuses.FAIL}
          hint={errors.city}
        />
      </div>
      <InputPhone
        label={t('shareholders.edit.phone')}
        name="number"
        phone={shareholder.number || ''}
        setPhone={(phone, e) => {
          e.target.name = 'number';
          e.target.value = phone;
          actions(e);
        }}
        state={errors.number && statuses.FAIL}
        hint={errors.number}
      />
      <label
        htmlFor="categoryList"
        className={styles.label}
      >
        {t('shareholders.edit.category')}
        <input
          list="category"
          id="categoryList"
          name="category"
          className={styles.select}
          value={shareholder.category}
          onChange={actions}
        />
      </label>
      <datalist id="category">
        <option value="Fondateur">
          {
            t('shareholders.edit.type.founder')
          }
        </option>
        <option value="Investisseur">
          {
            t('shareholders.edit.type.investor')
          }
        </option>
        <option value="Salarié">
          {
            t('shareholders.edit.type.employed')
          }
        </option>
      </datalist>
      <InputFile
        label={t('shareholders.edit.profilePicture')}
        handleFile={handleFile}
        file={file}
        state={getFileState()}
        error={errors.img}
        cancel={() => setDisplayFileError(false)}
      />
    </div>
  );
}
