import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  contacts: [],
  group: {
    lastMessageStatus: statuses.IDLE,
    id: '',
    messages: [],
  },
  query: '',
  activeId: null,
};

export default function chatReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_CONTACTS:
      return {
        ...state,
        contacts: payload.contacts || [],
        group: {
          ...state.group,
          messages: (payload.group)
            ? [...state.group.messages, ...payload.group]
            : state.group.messages,
        },
      };
    case types.SET_ACTIVE_ROOM:
      return {
        ...state,
        activeId: payload.id,
        group: {
          ...state.group,
          ...payload.payload,
        },
      };
    case types.SEND_MESSAGES_SUCCESS:
      return {
        ...state,
        contacts: payload,
      };
    case types.SEND_MESSAGES_REQUEST:
      return {
        ...state,
        lastMessageStatus: statuses.LOADING,
      };
    case types.SEND_MESSAGES_FAIL:
      return {
        ...state,
        lastMessageStatus: 'fail',
      };
    case types.SEND_GROUP_MESSAGES_REQUEST:
      return {
        ...state,
        group: {
          ...state.group,
          lastMessageStatus: statuses.LOADING,
        },
      };
    case types.SEND_GROUP_MESSAGES_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          lastMessageStatus: statuses.SUCCESS,
          messages: [...state.group.messages, payload],
        },
      };
    case types.RECEIVE_GROUP_MESSAGES_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          lastMessageStatus: statuses.SUCCESS,
          messages: [...state.group.messages, payload.message],
          hasNewMessages: payload.hasNewMessage,
        },
      };
    case types.RESET_GROUP_NOTIFICATION:
      return {
        ...state,
        group: {
          ...state.group,
          hasNewMessages: false,
        },
      };
    default:
      return state;
  }
}
