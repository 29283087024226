import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MovementsRegister from './MovementsRegister';

import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';
import { fetchAllOperations, setQuery } from '../../services/store/features/operations/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';
import { getActiveOperation } from '../../services/store/features/operations/selectors';

const mapStateToProps = (state) => ({
  status: state.operations.status,
  shareholders: {
    status: state.shareholders.status,
    list: (window.location.href.includes('holding')) ? state.shareholders.holding : state.shareholders.shareholders,
  },
  operations: getActiveOperation(state),
  query: state.operations.query,
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllOperations,
    fetchAllShareholders,
    fetchUserInfo,
    setQuery,
    setSocietyId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovementsRegister);
