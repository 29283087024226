export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAIL = 'FETCH_USER_INFO_FAIL';

export const SET_USER_SOCKETS = 'SET_USER_SOCKETS';
export const SET_QUERY = 'SET_QUERY';
export const SET_SORT = 'SET_SORT';
export const SET_FOLDER = 'SET_FOLDER';
export const SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCEEDED = 'UPDATE_DOCUMENT_SUCCEEDED';
export const UPDATE_DOCUMENT_FAILED = 'UPDATE_DOCUMENT_FAILED';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';

export const DELETE_USER_DOCUMENT_REQUEST = 'DELETE_USER_DOCUMENT_REQUEST';
export const DELETE_USER_DOCUMENT_SUCCESS = 'DELETE_USER_DOCUMENT_SUCCESS';
export const DELETE_USER_DOCUMENT_FAIL = 'DELETE_USER_DOCUMENT_FAIL';

export const UPLOAD_USER_DOCUMENT_REQUEST = 'UPLOAD_USER_DOCUMENT_REQUEST';
export const UPLOAD_USER_DOCUMENT_SUCCESS = 'UPLOAD_USER_DOCUMENT_SUCCESS';
export const UPLOAD_USER_DOCUMENT_FAILED = 'UPLOAD_USER_DOCUMENT_FAILED';
export const UPLOAD_USER_DOCUMENT_RESET = 'UPLOAD_USER_DOCUMENT_RESET';
