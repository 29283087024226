import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Documents from '../Documents/Documents';
import {
  fetchUserInfo, setUserDocumentSort,
  updateDocument,
} from '../../services/store/features/user/actions';
import { setSocietyId } from '../../services/store/features/society/actions';

import { openPopUp, closePopUp } from '../../services/store/features/popup/actions';
import {
  fetchUserDocumentsData,
  setSelectedUserDocument,
  uploadUserDocument, resetUserUploadDocument, deleteUserDocument,
} from '../../services/store/features/documents/actions';
import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';
import { getDocumentProps } from '../../services/store/features/documents/selectors';

const mapStateToProps = (state) => ({ ...getDocumentProps(state, 'user'), isRelatedDocument: true });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchDocumentsData: fetchUserDocumentsData,
    setSelectedDocument: setSelectedUserDocument,
    updateDocument,
    setSort: setUserDocumentSort,
    openPopUp,
    closePopUp,
    setSocietyId,
    fetchUserInfo,
    fetchAllShareholders,
    deleteDocument: deleteUserDocument,
    uploadDocument: uploadUserDocument,
    resetUploadDocument: resetUserUploadDocument,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
