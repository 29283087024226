import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setActiveContact } from 'services/store/features/shareholders/actions';
import { openPopUp, closePopUp } from 'services/store/features/popup/actions';
import { setActiveRoom } from 'services/store/features/chat/actions';
import { getActiveContactById } from 'services/store/features/shareholders/selectors';

import ShareholdersDescription from './ShareholdersDescription';

const mapStateToProps = (state) => ({
  contact: getActiveContactById(state),
  isPopUpOpen: state.popup.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setActiveContact,
    setActiveRoom,
    openPopUp,
    closePopUp,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersDescription);
