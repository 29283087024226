import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ShareholdersCapitalizationGraph.module.css';

const colors = ['#4F67F3', '#001699', '#FFCE51', '#DE330D', '#02C57E'];

export default function ShareholdersCapitalizationGraph({ capital }) {
  const { t } = useTranslation();

  return (
    <div className={styles.capitalisation}>
      <h3 className={styles.title}>
        {t('history.allocation')}
      </h3>
      <div className={styles.graph}>
        {
          capital.map((shareholder, key) => (
            <div
              key={shareholder.name}
              className={`${styles.category}`}
              style={{
                width: `${shareholder.percentage}%`,
              }}
            >
              <div
                className={styles.graphContent}
                style={{
                  backgroundColor: colors[colors.length % (key + 1)],
                }}
              />
              <p className={styles.clip}>
                {`${shareholder.societyName || `${shareholder.firstName} ${shareholder.lastName}`} (${shareholder.percentage}%)`}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  );
}
