const getFormData = (object) => {
  if (object) {
    return Object.keys(object).reduce((formData, key) => {
      if (object[key] !== undefined) {
        if (key === 'coverUrl' || key === 'logoUrl' || key === 'img') {
          formData.append(key, object[key]);
        } else if (typeof object[key] === 'object') {
          formData.append(key, JSON.stringify(object[key]));
        } else {
          formData.append(key, object[key]);
        }
      }
      return formData;
    }, new FormData());
  }
  return {};
};

export default getFormData;
