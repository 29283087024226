import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders } from '../../../../../services/store/features/shareholders/actions';
// eslint-disable-next-line import/extensions
import SettingsInvestorsCreationBulk from './SettingsInvestorsCreationBulk.jsx';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllShareholders }, dispatch),
});

export default connect(null, mapDispatchToProps)(SettingsInvestorsCreationBulk);
