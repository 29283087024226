import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders, setActiveContact } from '../../services/store/features/shareholders/actions';
import ShareholdersTableRows from './ShareholdersTableRows';
import { getActiveShareholders } from '../../services/store/features/shareholders/selectors';

const mapStateToProps = (state) => ({
  status: state.shareholders.status,
  shareholders: getActiveShareholders(state),
  isInvestor: state.user.isInvestor,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    setActiveContact,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersTableRows);
