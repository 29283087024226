export const FETCH_SHAREHOLDERS_REQUEST = 'FETCH_SHAREHOLDERS_REQUEST';
export const FETCH_SHAREHOLDERS_SUCCESS = 'FETCH_SHAREHOLDERS_SUCCESS';
export const FETCH_HOLDING_SUCCESS = 'FETCH_HOLDING_SUCCESS';
export const FETCH_SHAREHOLDERS_FAIL = 'FETCH_SHAREHOLDERS_FAIL';

export const CREATE_SHAREHOLDER_REQUEST = 'CREATE_SHAREHOLDER_REQUEST';
export const CREATE_SHAREHOLDER_SUCCESS = 'CREATE_SHAREHOLDER_SUCCESS';
export const CREATE_SHAREHOLDER_FAIL = 'CREATE_SHAREHOLDER_FAIL';

export const ADD_TARGET_SHAREHOLDER = 'ADD_TARGET_SHAREHOLDER';

export const CREATE_HOLDING_REQUEST = 'CREATE_HOLDING_REQUEST';
export const CREATE_HOLDING_SUCCESS = 'CREATE_HOLDING_SUCCESS';
export const CREATE_HOLDING_FAIL = 'CREATE_HOLDING_FAIL';

export const SET_QUERY = 'SET_QUERY';
export const SET_ACTIVE_TABS = 'SET_ACTIVE_TABS';
export const SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT';
export const SET_STATUS = 'SET_STATUS';
export const SET_ACTIVE_HISTORY = 'SET_ACTIVE_HISTORY';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';

export const UPDATE_SHAREHOLDER_REQUEST = 'UPDATE_SHAREHOLDER_REQUEST';
export const UPDATE_SHAREHOLDER_SUCCESS = 'UPDATE_SHAREHOLDER_SUCCESS';
export const UPDATE_SHAREHOLDER_FAIL = 'UPDATE_SHAREHOLDER_FAIL';

export const EXPORT_SHAREHOLDERS_REQUEST = 'EXPORT_SHAREHOLDERS_REQUEST';
export const EXPORT_SHAREHOLDERS_SUCCESS = 'EXPORT_SHAREHOLDERS_SUCCESS';
export const EXPORT_SHAREHOLDERS_FAIL = 'EXPORT_SHAREHOLDERS_FAIL';
export const EXPORT_SHAREHOLDERS_RESET = 'EXPORT_SHAREHOLDERS_RESET';

export const RESET_CREATION = 'RESET_CREATION';
