export default function validate(values, shareholdersQuantity) {
  const errors = [];
  values.transfers.forEach(({ from, to, quantity }, key) => {
    const error = {};
    if (!from) {
      error.from = 'Le champs est requis';
    }
    if (!to) {
      error.to = 'Le champs est requis';
    }
    if (!quantity) {
      error.quantity = 'Le nombre de titre transferé est requis';
    } else if (quantity > shareholdersQuantity[key]) {
      error.quantity = 'Le nombre de titre transféré ne peut pas être supérieur au nombre de titre de l\'actionnaire';
    }
    errors.push(error);
  });
  if (errors.filter((curr) => Object.keys(curr).length > 0).length === 0) {
    return {};
  }
  return { transfers: errors };
}
