import { connect } from 'react-redux';
// eslint-disable-next-line import/extensions
import ShareholdersAccount from './ShareholdersAccount.jsx';

const mapStateToProps = (state) => ({
  society: state.society.id,
  user: state.user.id,
  roles: state.roles.roles,
});

export default connect(mapStateToProps)(ShareholdersAccount);
