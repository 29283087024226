export const FETCH_HISTORY_REQUEST = 'FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILED = 'FETCH_HISTORY_FAILED';

export const FETCH_HOLDING_HISTORY_REQUEST = 'FETCH_HOLDING_HISTORY_REQUEST';
export const FETCH_HOLDING_HISTORY_SUCCESS = 'FETCH_HOLDING_HISTORY_SUCCESS';
export const FETCH_HOLDING_HISTORY_FAILED = 'FETCH_HOLDING_HISTORY_FAILED';

export const FETCH_TARGET_HISTORY_REQUEST = 'FETCH_TARGET_HISTORY_REQUEST';
export const FETCH_TARGET_HISTORY_SUCCESS = 'FETCH_TARGET_HISTORY_SUCCESS';
export const FETCH_TARGET_HISTORY_FAILED = 'FETCH_TARGET_HISTORY_FAILED';

export const CREATE_HISTORY_REQUEST = 'CREATE_HISTORY_REQUEST';
export const CREATE_HISTORY_SUCCESS = 'CREATE_HISTORY_SUCCESS';
export const CREATE_HISTORY_FAIL = 'CREATE_HISTORY_FAIL';

export const ADD_TARGET_HISTORY = 'ADD_TARGET_HISTORY';

export const SET_ACTIVE_HISTORY = 'SET_ACTIVE_HISTORY';

export const UPDATE_HISTORY_SUCCESS = 'UPDATE_HISTORY_SUCCESS';
