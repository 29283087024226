import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyRegister from './AssemblyRegister';
import { fetchAssemblyArchives } from '../../../services/store/features/assembly/actions';
import { setSelectedDocument } from '../../../services/store/features/documents/actions';

const mapStateToProps = (state) => ({
  status: state.assembly.archives.status,
  archives: state.assembly.archives.list,
  selectedDocument: state.documents.selectedDocument,
  societyId: state.society.id,
  userId: state.user.id,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyArchives,
    setSelectedDocument,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyRegister);
