import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../../components/library/Button/Button';
import formatDate from '../../../utils/formatDate';

import styles from './AssemblyPlan.module.css';

export default function AssemblyPlanItem({
  assembly, deleteAssembly, societyId, actions,
}) {
  const [convocationDate, setConvocationDate] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    let date;
    if (!convocationDate && assembly.date && typeof assembly.date === 'string') {
      date = formatDate(assembly.date);
    } else if (!convocationDate && assembly.date) {
      date = new Date(assembly.date.year, assembly.date.month, assembly.date.day);
    }
    if (!date) return;
    if (date instanceof Date && !Number.isNaN(date)) {
      date.setDate(date.getDate() - 15);
      setConvocationDate(date.toLocaleDateString('fr-FR'));
    } else {
      setConvocationDate(date);
    }
  }, [assembly]);

  const handleDeleteConfirmation = () => {
    const handleDelete = () => {
      deleteAssembly();
      actions.closePopUp();
    };
    actions.openPopUp(
      t('assembly.planification.confirmation'),
      '',
      <div className={styles.confirmation}>
        <Button
          label={t('cancel')}
          variant="ghost"
          onButtonClick={actions.closePopUp}
        />
        <Button
          label={t('validate')}
          onButtonClick={handleDelete}
        />
      </div>,
    );
  };

  return (
    <div className={styles.assemblyItem}>
      <h3>
        { assembly.title }
      </h3>
      <div className={styles.row}>
        <p>
          <b>
            {
              (assembly.hasSentConvocation)
                ? t('assembly.planification.date')
                : t('assembly.planification.temporaryDate')
            }
          </b>
          { (typeof assembly?.date === 'string') ? assembly.date : ` ${assembly.date?.day}/${assembly.date?.month}/${assembly.date?.year}`}
        </p>
        <p>
          <b>
            {t('assembly.planification.place')}
          </b>
          { assembly.place }
        </p>
      </div>
      {
        (!assembly.hasSentConvocation && convocationDate)
        && (
          <div className={styles.notification}>
            <WarningIcon />
            <p>
              {t('assembly.planification.warningDate', { date: convocationDate })}
            </p>
          </div>
        )
      }
      <div className={styles.tools}>
        <Button
          label={t('assembly.planification.delete')}
          variant="ghost"
          onButtonClick={handleDeleteConfirmation}
        />
        <Link
          to={`/${societyId}/assemblee/view/${assembly.id}`}
          className="button"
        >
          {
            (assembly.hasSentConvocation)
              ? t('assembly.planification.see')
              : t('assembly.planification.button')
          }
        </Link>
      </div>
    </div>
  );
}
