import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSocietyData } from '../../services/store/features/society/actions';
// eslint-disable-next-line import/extensions
import SocietyManager from './SocietyManager.jsx';

const mapStateToProps = (state) => ({
  status: state.society.status.toUpperCase(),
  isForbidden: (state.society.status.toUpperCase() === 'SUCCESS') && !state.society.isMember && !state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchSocietyData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocietyManager);
