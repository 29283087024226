import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  reports: {
    status: statuses.IDLE,
    error: null,
    data: [],
    folders: [],
    activeFolder: '',
  },
  plannedReports: {
    status: statuses.IDLE,
    error: null,
    data: [],
  },
  activeReport: {
    status: statuses.IDLE,
    error: null,
    data: {
      title: '',
      period: {
        begin: {
          day: '',
          month: '',
          year: '',
        },
        end: {
          day: '',
          month: '',
          year: '',
        },
      },
      turnover: 0,
      treasury: 0,
      expenses: 0,
      general: '',
      good: '',
      bad: '',
      help: '',
    },
  },
};

export default function reportReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          status: statuses.SUCCESS,
          data: [...payload.reports],
          folders: payload.folders,
          activeFolder: payload.folders[0] || null,
        },
      };
    case types.FETCH_REPORTS_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          status: 'failed',
          error: payload,
        },
      };
    case types.FETCH_PLANNED_REPORTS_REQUEST:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_PLANNED_REPORTS_SUCCESS:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: statuses.SUCCESS,
          data: payload,
        },
      };
    case types.FETCH_PLANNED_REPORTS_FAILED:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: 'failed',
          error: payload,
        },
      };
    case types.DELETE_REPORT_REQUEST:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: statuses.LOADING,
        },
      };
    case types.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: statuses.SUCCESS,
          data: payload,
        },
      };
    case types.DELETE_REPORT_FAILED:
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports,
          status: 'failed',
          error: payload,
        },
      };
    case types.SET_FOLDER:
      return {
        ...state,
        reports: {
          ...state.reports,
          activeFolder: payload,
        },
      };
    case types.SET_EDITION_REPORT:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          data: {
            ...state.activeReport.data,
            ...payload,
          },
        },
      };
    case types.CREATE_REPORT_REQUEST:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          status: statuses.LOADING,
        },
      };
    case types.CREATE_REPORT_SUCCESS:
      if (!payload.isCreated) {
        return {
          ...state,
          plannedReports: {
            ...state.plannedReports,
            folders: payload.folders,
            data: [
              ...state.plannedReports.data,
              payload.data,
            ],
          },
          activeReport: {
            ...state.activeReport,
            status: statuses.SUCCESS,
          },
        };
      }
      return {
        ...state,
        plannedReports: {
          ...state.plannedReports.data,
          data: [
            ...state.plannedReports.data.filter((report) => report.id !== payload.id),
            payload.data,
          ],
        },
        activeReport: {
          ...state.activeReport,
          status: statuses.SUCCESS,
        },
      };
    case types.CREATE_REPORT_PUBLISHED_SUCCESS:
      if (!payload.isCreated) {
        return {
          ...state,
          reports: {
            ...state.reports,
            folders: payload.folders,
            activeFolder: payload.folders[0] || null,
            data: [
              ...state.reports.data,
              payload.data,
            ],
          },
          activeReport: {
            ...state.activeReport,
            status: statuses.SUCCESS,
          },
        };
      }
      return {
        ...state,
        reports: {
          ...state.reports.data,
          data: [
            ...state.reports.data.filter((report) => report.id !== payload.id),
            payload.data,
          ],
        },
        activeReport: {
          ...state.activeReport,
          status: statuses.SUCCESS,
        },
      };
    case types.CREATE_REPORT_FAILED:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          status: 'failed',
          error: payload,
        },
      };
    case types.FETCH_REPORTS_BY_ID_REQUEST:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          data: {
            ...payload,
          },
          status: statuses.IDLE,
        },
      };
    case types.FETCH_REPORTS_BY_ID_FAILED:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          status: 'failed',
          error: payload,
        },
      };
    case types.RESET_ACTIVE_REPORTS:
      return {
        ...state,
        activeReport: {
          status: statuses.IDLE,
          error: null,
          data: initialState.activeReport.data,
        },
      };
    default:
      return state;
  }
}
