import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// eslint-disable-next-line import/extensions
import AssemblyInvitation from './AssemblyInvitation.jsx';
import { fetchAssemblyPlan } from '../../../services/store/features/assembly/actions';

const mapStateToProps = (state) => ({
  status: state.assembly.plan.status,
  plan: state.assembly.plan.list.map((assembly) => {
    const [date, time] = (typeof assembly.date === 'string')
      ? assembly.date.split(' ')
      : [assembly.date, assembly.time];
    return {
      ...assembly,
      date,
      time,
    };
  }),
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyPlan,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyInvitation);
