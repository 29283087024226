const links = (societyId, t) => ([
  {
    url: `/${societyId}/assemblee/registre`,
    label: t('assembly.registry.navigation'),
    exact: true,
  },
  {
    url: `/${societyId}/assemblee/plan`,
    label: t('assembly.planification.navigation'),
    exact: false,
    roles: ['POST-INVEST_ASSEMBLY_CREATE', 'POST-INVEST_ASSEMBLY_UPDATE', 'POST-INVEST_ASSEMBLY_DELETE'],
  },
]);

export const investorLinks = (societyId, t) => ([
  {
    url: `/${societyId}/assemblee/registre`,
    label: t('assembly.registry.navigation'),
    exact: true,
  },
  {
    url: `/${societyId}/assemblee/invitation`,
    label: t('assembly.invitation.navigation'),
    exact: true,
  },
]);

export default links;
