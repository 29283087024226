import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Operations from './Operations';
import { setSocietyId } from '../../../services/store/features/society/actions';
import { fetchUserInfo } from '../../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  societyId: state.society.id,
  user: state.user.id,
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSocietyId, fetchUserInfo }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Operations);
