import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Contacts from './Contacts';
import { fetchContacts } from '../../services/store/features/contacts/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
  status: state.contacts.status,
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchContacts,
    setSocietyId,
    fetchUserInfo,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
