/* eslint-disable import/prefer-default-export */
import { getContacts, createFeedback } from '../../../clients/clientAPI';
import * as types from './types';

export function fetchContacts() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({ type: types.FETCH_CONTACTS_REQUEST });
      try {
        const { data } = await getContacts(id);
        dispatch({
          type: types.FETCH_CONTACTS_SUCCESS,
          payload: data.contacts,
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_CONTACTS_FAIL,
          payload: err,
        });
      }
    }
  };
}

export function sendFeedback(feedback) {
  return async (dispatch) => {
    dispatch({ type: types.SEND_FEEDBACK_REQUEST });
    try {
      await createFeedback(feedback);
      dispatch({ type: types.SEND_FEEDBACK_SUCCESS });
    } catch (err) {
      dispatch({ type: types.SEND_FEEDBACK_FAIL });
    }
  };
}

export function resetFeedback() {
  return async (dispatch) => dispatch({ type: types.RESET_FEEDBACK });
}
