import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ShareholdersParameters.module.css';

const Parameter = ({
  title, value, isInteger, isPrice = false, date,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.parameter}>
      <p className={styles.parameterTitle}>
        { title }
      </p>
      <p className={styles.number}>
        {
          (isPrice)
          && ((isInteger)
            ? t('currencyNoCents', { value })
            : t('currency', { value }))
        }
        {
          (!isPrice) && t('number', { value })
        }
      </p>
      <p className={styles.date}>
        { date }
      </p>
    </div>
  );
};
export default function ShareholdersParameters({ parameters }) {
  const { t } = useTranslation();
  if (parameters) {
    return (
      <div>
        <h3 className={styles.title}>
          {t('history.parameters')}
        </h3>
        <div className={styles.row}>
          <Parameter
            title={t('history.valuation')}
            value={parameters.valorisation}
            date={parameters.date}
            isInteger
            isPrice
          />
          <Parameter
            title={t('history.sharePrice')}
            value={parameters.sharesPrice}
            date={parameters.date}
            isPrice
          />
          <Parameter
            title={t('history.sharesNumber')}
            value={parameters.sharesNumber}
            date={parameters.date}
            isInteger
            isPrice={false}
          />
        </div>
      </div>
    );
  }
  return null;
}
