import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportEditionPlanification from './ReportEditionPlanification';
import { publishReport } from '../../../services/store/features/report/actions';
import { closePopUp } from '../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  status: state.reports.activeReport.status,
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    closePopUp,
    publishReport,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportEditionPlanification);
