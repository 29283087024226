import * as types from './types';
import * as statuses from '../../states';

import {
  addAyomiOperation, createNewHistory,
  createNewOperation,
  createShareholdersHistory,
  getAllOperation,
} from '../../../clients/clientAPI';

import { fetchHoldingHistory, fetchTargetHistory } from '../history/actions';
import {
  createNewShareholdersHistory,
  getCurrentShareholders,
  getLastSharesHistoryBySocietyId,
} from '../shareholders/selectors';
import { fetchHoldingShareholders, fetchTargetShareholders } from '../shareholders/actions';
import { createHistory } from '../history/utils';
import { createOperationsByShareholders } from './utils';
import { keysToCamel } from '../../../../utils/converter';
import { operationToApi } from './adapter';
import { shareHistoryToApi } from '../shareholders/adapter';
import { getLastHistory, getLastHoldingHistory } from '../history/selectors';
import { historyToApi } from '../history/adapter';

export function fetchAllOperations() {
  return async (dispatch, getState) => {
    const { id, idHolding } = getState().society;
    if (id) {
      dispatch({
        type: types.FETCH_OPERATIONS_REQUEST,
      });
      try {
        let { data: operations } = await getAllOperation(id);
        if (idHolding) {
          const { data: holdingData } = await getAllOperation(idHolding);
          operations = [
            ...keysToCamel(operations),
            ...holdingData.map((operation) => ({ ...operation, holding: true })),
          ];
        }
        dispatch({
          type: types.FETCH_OPERATIONS_SUCCEEDED,
          payload: keysToCamel(operations),
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_OPERATIONS_FAILED,
          payload: err,
        });
      }
    }
  };
}

export function fetchTargetOperations() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      try {
        const { data: operations } = await getAllOperation(id);
        dispatch({
          type: types.FETCH_OPERATIONS_SUCCEEDED,
          payload: keysToCamel(operations),
        });
      } catch (err) {
        console.error(err);
      }
    }
  };
}

export function createNewCapitalGain(operationStatus, selectedShareholders) {
  return async (dispatch, getState) => {
    dispatch({ type: types.ADD_OPERATION_REQUEST });

    const { creation, status } = getState().operations;
    const { history } = getState().history;
    const { society } = getState();

    const isHolding = window.location.href.includes('holding');
    const id = (isHolding) ? society.idHolding : society.id;
    const shareholders = isHolding
      ? getState().shareholders.holding
      : getState().shareholders.shareholders;

    try {
      if (status === statuses.IDLE) {
        const promise = dispatch(fetchAllOperations());
        await Promise.resolve(promise);
      }

      const newHistory = await createHistory(
        id, history, creation, selectedShareholders, operationStatus,
      );

      dispatch({
        type: 'CREATE_HISTORY_SUCCESS',
        payload: {
          ...newHistory,
          holding: isHolding,
        },
      });

      const newOperations = await createOperationsByShareholders(
        id, selectedShareholders, creation, operationStatus, newHistory.id,
      );
      const newShareholdersList = await createNewShareholdersHistory(
        id, selectedShareholders, newHistory, newOperations, shareholders, creation,
      );
      dispatch({ type: types.ADD_MULTIPLE_OPERATIONS_SUCCEEDED, payload: newOperations });
      dispatch({ type: 'UPDATE_SHAREHOLDER_SUCCESS', payload: newShareholdersList });

      if (!isHolding) {
        dispatch(fetchTargetShareholders());
      }

      if (!isHolding && society.idHolding) {
        dispatch(fetchHoldingHistory());
        dispatch(fetchHoldingShareholders());
      }

      if (isHolding) {
        dispatch(fetchTargetShareholders());
        dispatch(fetchTargetHistory());
        dispatch(fetchTargetOperations());
        dispatch(fetchHoldingShareholders());
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: types.ADD_MULTIPLE_OPERATIONS_FAILED, payload: err });
    }
  };
}

const formatHistory = (creation, operations, state) => ({
  date: {
    begin: `${creation.date.day}/${creation.date.month}/${creation.date.year}`,
  },
  name: creation.name,
  operationStatus: 'verified',
  operations,
  parameters: {
    sharesNumber: (creation.holding)
      ? getLastHoldingHistory(state).parameters.sharesNumber
      : getLastHistory(state).parameters.sharesNumber,
    sharesPrice: creation.parameters.unitSharePrice,
  },
  societyId: state.society.id,
});

const formatShareHistories = (operations, historyId, societyId, holding, state) => {
  let sharesHistories = [];
  const shareholders = (holding) ? state.shareholders.holding : state.shareholders.shareholders;
  operations.forEach(({
    fromUserId, toUserId, quantity, date, id,
  }) => {
    if (fromUserId) {
      const shareholder = shareholders.find(
        ({ id: shareholderId }) => shareholderId === fromUserId,
      );
      const lastSharesHistory = getLastSharesHistoryBySocietyId(shareholder, societyId);
      const multipleCession = sharesHistories
        .find(({ shareholderId }) => shareholderId === fromUserId);
      let shareHistory;
      if (multipleCession) {
        shareHistory = {
          buy: multipleCession.buy,
          date,
          historyId,
          operations: [...multipleCession.operations, id],
          shareholderId: fromUserId,
          societyId,
          total: multipleCession.total - quantity,
        };
        sharesHistories = sharesHistories.map((curr) => (
          (curr.shareholderId === fromUserId)
            ? shareHistory
            : curr
        ));
      } else {
        shareHistory = {
          buy: 0,
          date,
          historyId,
          operations: [id],
          shareholderId: fromUserId,
          societyId,
          total: lastSharesHistory.total - quantity,
        };
        sharesHistories.push(shareHistory);
      }
    }
    if (toUserId) {
      const shareholder = shareholders.find(
        ({ id: shareholderId }) => shareholderId === toUserId,
      );
      const lastSharesHistory = getLastSharesHistoryBySocietyId(shareholder, societyId);
      const multipleCession = sharesHistories
        .find(({ shareholderId }) => shareholderId === toUserId);
      let shareHistory;
      if (multipleCession) {
        shareHistory = {
          buy: multipleCession.buy + quantity,
          date,
          historyId,
          operations: [...multipleCession.operations, id],
          shareholderId: toUserId,
          societyId,
          total: multipleCession.total + quantity,
        };
        sharesHistories = sharesHistories.map((curr) => (
          (curr.shareholderId === toUserId)
            ? shareHistory
            : curr
        ));
      } else {
        shareHistory = {
          buy: quantity,
          date,
          historyId,
          operations: [id],
          shareholderId: toUserId,
          societyId,
          total: (lastSharesHistory?.total || 0) + quantity,
        };
        sharesHistories.push(shareHistory);
      }
    }
  });
  return sharesHistories;
};

const updateShareholders = (creation, id, dispatch) => {
  if (!creation.holding) {
    dispatch(fetchTargetShareholders());
  }

  if (!creation.holding && id) {
    dispatch(fetchHoldingHistory());
    dispatch(fetchHoldingShareholders());
  }

  if (creation.holding) {
    dispatch(fetchTargetShareholders());
    dispatch(fetchTargetHistory());
    dispatch(fetchTargetOperations());
  }
};

export function addMultipleTransferOperation(operations) {
  return async (dispatch, getState) => {
    dispatch({ type: types.ADD_OPERATION_REQUEST });
    const { creation } = getState().operations;
    const id = (!creation.holding) ? getState().society.id : getState().society.idHolding;
    try {
      const responses = await Promise.all(operations.map(
        (operation) => createNewOperation(operationToApi({ ...operation, societyId: id })),
      ));
      const history = formatHistory(
        creation,
        responses.map(({ data }) => data.id),
        getState(),
      );
      const { data } = await createNewHistory(historyToApi(history));
      const sharesHistory = formatShareHistories(
        responses.map(({ data: newOperation }) => keysToCamel(newOperation)),
        data.id,
        id,
        creation.holding,
        getState(),
      );

      await Promise.all(sharesHistory.map(
        (shareHistory) => createShareholdersHistory(shareHistoryToApi(shareHistory)),
      ));

      dispatch({
        type: types.ADD_MULTIPLE_OPERATIONS_SUCCEEDED,
        payload: responses.map(({ data: operationData }) => keysToCamel(operationData)),
      });

      updateShareholders(creation, id, dispatch);
    } catch (err) {
      console.error(err);
    }
  };
}

export function addAllAyomiOperations(setStatus, setError) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    setStatus(statuses.LOADING);
    try {
      const { data } = await addAyomiOperation(id);
      if (data && !data.error) {
        dispatch({
          type: types.ADD_AYOMI_OPERATIONS_SUCCESS,
          payload: [
            ...data.operations,
            ...data.operations_holding.map((operation) => ({ ...operation, holding: true })),
          ],
        });
        dispatch({
          type: 'ADD_AYOMI_HISTORY_SUCCESS',
          payload: [
            ...data.history,
            ...data.history_holding.map((history) => ({ ...history, holding: true })),
          ],
        });
        dispatch({
          type: 'ADD_AYOMI_SHAREHOLDER_SUCCESS',
          payload: {
            shareholders: Array.isArray(data.shareholder)
              ? data.shareholder
              : [data.shareholder],
            holding: Array.isArray(data.shareholder_holding)
              ? data.shareholder_holding
              : [data.shareholder_holding],
          },
        });
        setStatus('SUCCESS');
      } else {
        dispatch({
          type: types.ADD_AYOMI_OPERATIONS_FAILED,
          payload: data?.error,
        });
        setStatus(statuses.FAIL);
        setError(data?.error);
      }
    } catch (err) {
      console.error(err);
      setStatus(statuses.FAIL);
      setError(`${err} `);
    }
  };
}

export function setOperation(operation) {
  return (dispatch) => {
    dispatch({ type: types.SET_OPERATION_CREATION, payload: operation });
  };
}

export function setQuery(query) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function setType(type) {
  return (dispatch) => {
    dispatch({
      type: types.SET_TYPE_FILTER,
      payload: type,
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: types.SET_NAME_FILTER,
      payload: name,
    });
  };
}

export function initSelectedShareholders() {
  return (dispatch, getState) => {
    const { creation } = getState().operations;
    const currentShareholders = getCurrentShareholders(getState());
    const { shareholders } = getState().shareholders;
    const selectedShareholders = currentShareholders.map((shareholder) => {
      if (shareholder.lastOperation === creation.name) {
        if (shareholder.oldId) {
          const oldNumberOfAction = shareholders
            .filter((oldShareholder) => oldShareholder.id === shareholder.oldId)[0]?.shares || 0;
          return {
            ...shareholder,
            quantity: shareholder.shares - oldNumberOfAction,
            shares: oldNumberOfAction,
          };
        }
        return {
          ...shareholder,
          quantity: shareholder.shares,
          shares: 0,
        };
      }
      return { ...shareholder, quantity: 0 };
    });
    dispatch({
      type: types.SET_SELECTED_SHAREHOLDERS,
      payload: selectedShareholders,
    });
  };
}
