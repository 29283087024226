import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChatContactListSearchbar from './ChatContactListSearchbar';
import { setQuery } from '../../../services/store/features/chat/actions';

const mapStateToProps = (state) => ({
  query: state.chat.query,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContactListSearchbar);
