import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  error: null,
  isInvestor: true,
  documents: [],
  favoriteDocuments: [],
  query: '',
  sort: {
    value: '',
    asc: true,
  },
  selectedDocument: null,
  creation: {
    status: statuses.IDLE,
    error: null,
  },
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        ...payload,
        status: statuses.SUCCESS,
        error: null,
      };
    case types.FETCH_USER_INFO_FAIL:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.SET_USER_SOCKETS:
      return {
        ...state,
        socketId: payload,
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        favoriteDocuments: (payload.value)
          ? [...state.favoriteDocuments, payload.id]
          : state.favoriteDocuments.filter((document) => document !== payload.id),
        status: statuses.SUCCESS,
        error: null,
      };
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.DELETE_USER_DOCUMENT_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.DELETE_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: statuses.SUCCESS,
        documents: state.documents.filter((document) => document.id !== payload),
      };
    case types.DELETE_USER_DOCUMENT_FAIL:
      return {
        ...state,
        status: 'failed',
        error: payload,
      };
    case types.UPLOAD_USER_DOCUMENT_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.UPLOAD_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, payload],
        creation: {
          ...state.creation,
          status: 'SUCCESS',
        },
      };
    case types.UPLOAD_USER_DOCUMENT_FAILED:
      return {
        ...state,
        creation: {
          status: statuses.FAIL,
          error: payload,
        },
      };
    case types.UPLOAD_USER_DOCUMENT_RESET:
      return {
        ...state,
        creation: {
          status: statuses.IDLE,
          error: null,
        },
      };
    case types.SET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: payload,
      };
    case types.SET_SORT:
      return {
        ...state,
        sort: {
          value: payload,
          asc: (payload === state.sort.value) ? !state.sort.asc : true,
        },
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_FOLDER:
      return {
        ...state,
        folder: payload,
      };
    default:
      return state;
  }
}
