import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Button from '../../../components/library/Button/Button';
import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import DocumentsVisualisation from '../../../components/Documents/DocumentsVisualisation';
import AssemblyViewPresence from './AssemblyViewPresence';
import AssemblyResolutions from '../../../components/Assembly/AssemblyResolutions/AssemblyResolutions';
import AssemblyDocuments from '../../../components/Assembly/AssemblyDocuments/AssemblyDocuments';
import * as statuses from '../../../services/store/states';

import shared from '../shared/assembly.module.css';
import styles from './AssemblyView.module.css';

export default function AssemblyView({
  status, userStatus, assembly, selectedDocument, actions,
  invitations, shareholdersStatus, historyStatus,
  userId, societyId, roles, isAuthorized,
}) {
  const { society, id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (status === statuses.IDLE) {
      actions.fetchAssemblyPlan(society);
    }
    if (shareholdersStatus === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (historyStatus === statuses.IDLE) {
      actions.fetchHistory();
    }
    if (userStatus === statuses.IDLE) {
      actions.fetchUserInfo(society);
    }
    actions.setActiveAssembly(id);
  }, []);

  const openDocument = (url) => {
    actions.setSelectedDocument(url);
  };

  const addNewResolution = (resolutions, newResolution) => {
    const newResolutionList = (Array.isArray(resolutions))
      ? [...resolutions, newResolution]
      : [newResolution];
    actions.updateAssemblyPlanById(
      Number.parseInt(id, 10),
      { ...assembly, resolutions: newResolutionList },
    );
  };

  const updateResolution = (resolutions, newResolution) => {
    const newResolutionList = resolutions.map(
      (resolution) => ((resolution.id === newResolution.id) ? newResolution : resolution),
    );
    actions.updateAssemblyPlanById(
      Number.parseInt(id, 10),
      { ...assembly, resolutions: newResolutionList },
    );
  };

  const handleSubmit = (newResolution) => {
    const { resolutions } = assembly;
    if (newResolution.id === undefined) {
      addNewResolution(resolutions, newResolution);
    } else {
      updateResolution(resolutions, newResolution);
    }
  };

  const deleteResolution = (index) => {
    actions.deleteAssemblyResolution(Number.parseInt(index, 10), assembly.id);
  };

  const isReminderDisabled = () => {
    if (assembly.reminder) {
      const lastReminder = new Date(assembly.reminder);
      lastReminder.setDate(lastReminder.getDate() + 2);
      if (lastReminder > new Date()) {
        return true;
      }
    }
    return false;
  };

  if (assembly) {
    return (
      <section
        className={shared.container}
      >
        {
          (assembly.hasSendReminder)
          && (
            <div className={`bounceIn ${styles.toast}`}>
              <p>
                {t('assembly.preview.reminderSend')}
              </p>
            </div>
          )
        }
        <AssemblyHeader
          roles={roles}
          societyId={societyId}
          isAuthorized={isAuthorized}
        />
        <section className={shared.section}>
          { (selectedDocument) && <DocumentsVisualisation selectedDocument={selectedDocument} />}
          <p className={styles.help}>
            {t('assembly.preview.help')}
            <Link
              to={`/${societyId}/contacts`}
              className={styles.link}
            >
              {t('assembly.preview.contact')}
            </Link>
          </p>
          <header className={styles.header}>
            <h2>
              { assembly.title }
            </h2>
            {
              (assembly.hasSentConvocation)
                ? (
                  <div className={styles.buttonTools}>
                    {
                      (!assembly.presence || assembly.presence.length === 0)
                      && (
                        <Button
                          label={t('assembly.preview.reminder')}
                          className="button"
                          onButtonClick={() => actions.reminder(id)}
                          disabled={isReminderDisabled()}
                        />
                      )
                    }
                    {
                      (!assembly.presence || assembly.presence.length === 0)
                      && (
                        <Link
                          to={`/${societyId}/assemblee/presence/${id}`}
                          className="button"
                        >
                          {t('assembly.preview.attendance')}
                        </Link>
                      )
                    }
                    {
                      (!assembly.report)
                      && (
                        <Link
                          to={`/${societyId}/assemblee/proces/${id}`}
                          className="button"
                        >
                          {t('assembly.preview.report')}
                        </Link>
                      )
                    }
                  </div>
                )
                : (
                  <div className={styles.buttonTools}>
                    <Link
                      to={`/${societyId}/assemblee/convocation/${id}`}
                      className="button"
                    >
                      {t('assembly.preview.summons')}
                    </Link>
                  </div>
                )
            }
          </header>
          {
            (assembly.hasSentConvocation)
              ? (
                <div className={styles.fixedRow}>
                  <div
                    className={styles.date}
                  >
                    <p>
                      <span className={styles.bold}>
                        {t('assembly.preview.date')}
                      </span>
                      { typeof assembly.date === 'string' ? assembly.date : `${assembly.date.day}/${assembly.date.month}/${assembly.date.year}` }
                    </p>
                  </div>
                  <div
                    className={styles.date}
                  >
                    <p>
                      <span className={styles.bold}>
                        {t('assembly.preview.place')}
                      </span>
                      { assembly.place }
                    </p>
                  </div>
                </div>
              )
              : (
                <div className={styles.fixedRow}>
                  <div
                    className={styles.date}
                  >
                    <p>
                      <span className={styles.bold}>
                        {t('assembly.preview.temporaryDate')}
                      </span>
                      { typeof assembly.date === 'string' ? assembly.date : `${assembly.date.day}/${assembly.date.month}/${assembly.date.year}` }
                    </p>
                  </div>
                  <div
                    className={styles.date}
                  >
                    <p>
                      <span className={styles.bold}>
                        {t('assembly.preview.place')}
                      </span>
                      { assembly.place }
                    </p>
                  </div>
                </div>
              )
          }
          {
            (assembly.report)
            && (
              <div className={`bounceIn ${styles.notification}`}>
                <CheckCircleIcon className={styles.notificationIcon} />
                <p>
                  {t('assembly.preview.signature')}
                </p>
              </div>
            )
          }
          <div className={styles.grid}>
            <AssemblyViewPresence
              id={id}
              invitations={invitations}
              userId={userId}
              societyId={societyId}
              roles={roles}
            />
            <AssemblyResolutions
              assembly={assembly}
              submit={handleSubmit}
              deleteResolution={deleteResolution}
              edit={!assembly.hasSentConvocation}
              roles={roles}
            />
            <AssemblyDocuments
              assembly={assembly}
              openDocument={openDocument}
              edit={!assembly.hasSentConvocation}
              roles={roles}
              assemblyId={id}
              societyId={society}
            />
          </div>
        </section>
      </section>
    );
  }
  return (
    <p>Loading ...</p>
  );
}
