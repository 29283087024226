/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route, useHistory,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';

import verifyToken from '../store/features/roles/actions';
import Navigation from '../../components/Navigation';
import Dashboard from '../../views/Dashboard';
import Shareholders from '../../views/Shareholders';
import Contacts from '../../views/Contacts';
import Feedback from '../../views/Feedback';
import Documents from '../../views/Documents';
import DocumentsByFolder from '../../views/DocumentsByFolder';
import Report from '../../views/Report';
import ReportPlanification from '../../views/ReportPlanification';
import ReportView from '../../views/ReportView';
import PopUp from '../../components/PopUp';
import Messages from '../../views/Messages';
import styles from '../../components/Navigation/Navigation.module.css';
import Settings from '../../views/Settings';
import ReportEdition from '../../views/ReportEdition';
import InvestorDocument from '../../views/InvestorDocument';
import InvestorDocumentByFolder from '../../views/InvestorDocumentByFolder';
import MovementsRegister from '../../views/MovementsRegister';
import Operations from '../../views/Operations/Operations';
import Assembly from '../../views/Assembly';
import AssemblyView from '../../views/Assembly/AssemblyView';
import AssemblySummons from '../../views/Assembly/AssemblySummons';
import AssemblyPresence from '../../views/Assembly/AssemblyPresence';
import AssemblyReport from '../../views/Assembly/AssemblyReport';
import AssemblyInvestors from '../../views/AssemblyInvestors';
import ProjectSelection from '../../views/ProjectSelection';
import Loading from '../../components/library/Loading';
import Forbidden from '../../views/Forbidden';
import Admin from '../../views/Admin';
import SocietyManager from '../../components/SocietyManager';
import Documentation from '../../views/Documentation';
import { handleNext } from '../store/features/onboarding/actions';
import Onboarding from '../../components/Onboarding';
import ShareholdersAccount from '../../views/ShareholdersAccount';
import * as statuses from '../store/states';

const ROUTES = [
  {
    path: '/:society/assemblee/investisseur/:id',
    component: AssemblyInvestors,
    auth: true,
  },
  {
    path: '/:society/assemblee/proces/:id',
    component: AssemblyReport,
    auth: true,
  },
  {
    path: '/:society/assemblee/presence/:id',
    component: AssemblyPresence,
    auth: true,
  },
  {
    path: '/:society/assemblee/convocation/:id',
    component: AssemblySummons,
    auth: true,
  },
  {
    path: '/:society/assemblee/view/:id',
    component: AssemblyView,
    auth: true,
  },
  {
    path: '/:society/assemblee',
    component: Assembly,
    auth: true,
  },
  {
    path: '/:society/chat',
    component: Messages,
    auth: true,
  },
  {
    path: '/:society/rapport/edition/:id',
    component: ReportEdition,
    auth: true,
  },
  {
    path: '/:society/rapport/edition',
    component: ReportEdition,
    auth: true,
  },
  {
    path: '/:society/rapport/planification',
    component: ReportPlanification,
    auth: true,
  },
  {
    path: '/:society/rapport/:id',
    component: ReportView,
    auth: true,
  },
  {
    path: '/:society/rapport',
    component: Report,
    auth: true,
  },
  {
    path: '/:society/documents/:folder',
    component: DocumentsByFolder,
    auth: true,
  },
  {
    path: '/:society/documents',
    component: Documents,
    auth: true,
  },
  {
    path: '/:society/settings',
    component: Settings,
    auth: true,
  },
  {
    path: '/:society/investments/:folder',
    component: InvestorDocumentByFolder,
    auth: true,
  },
  {
    path: '/:society/investments',
    component: InvestorDocument,
    auth: true,
  },
  {
    path: '/:society/actionnaires/operations/edit',
    component: Operations,
    auth: true,
  },
  {
    path: '/:society/holding/operations/edit',
    component: Operations,
    auth: true,
    customProps: { isHolding: true },
  },
  {
    path: '/:society/actionnaires/operations',
    component: MovementsRegister,
    auth: true,
  },
  {
    path: '/:society/holding/operations',
    component: MovementsRegister,
    auth: true,
    customProps: { isHolding: true },
  },
  {
    path: '/:society/holding/liste',
    component: ShareholdersAccount,
    customProps: { isHolding: true },
    auth: true,
  },
  {
    path: '/:society/actionnaires/liste',
    component: ShareholdersAccount,
    auth: true,
  },
  {
    path: '/:society/actionnaires',
    component: Shareholders,
    auth: true,
  },
  {
    path: '/:society/holding',
    component: Shareholders,
    customProps: { isHolding: true },
    auth: true,
  },
  {
    path: '/:society/contacts/feedback',
    component: Feedback,
    auth: true,
  },
  {
    path: '/:society/contacts',
    component: Contacts,
    auth: true,
  },
  {
    path: '/documentation',
    component: Documentation,
    auth: true,
  },
  {
    path: '/forbidden',
    component: Forbidden,
    auth: true,
  },
  {
    path: '/admin',
    component: Admin,
    auth: true,
    roles: ['POST-INVEST_ADMIN'],
  },
  {
    path: '/:society',
    component: Dashboard,
    auth: true,
  },
  {
    path: '/',
    component: ProjectSelection,
    auth: true,
  },
];

export default function RouteConfig() {
  return (
    <Router>
      <Switch>
        {ROUTES.map((route) => (
          <ConnectedRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Router>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes({
  path, auth, routes, status, roles,
  authorizedRole, society, isAuthorized, isUserAuthorized,
  stepIndex, actions, customProps, firstLogin, ...route
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get('debug');
    if (debug === 'true') {
      history.push('/debug');
    }
    if (auth || status === statuses.IDLE) {
      dispatch(verifyToken());
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0 && status === statuses.SUCCESS) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (history.length === 1) {
            document.location.assign(`https//${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/`);
          } else {
            history.goBack();
          }
        }
      }
    }
  }, [status]);

  if (path === '/forbidden') {
    return (
      <Route
        path={path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={routes} />
        )}
      />
    );
  }

  if (status === statuses.SUCCESS || path === '/forbidden') {
    return (
      <div className={styles.container}>
        <Navigation />
        <SocietyManager path={path}>
          {
            (society?.toUpperCase() === statuses.SUCCESS && firstLogin === true)
            && (
              <Onboarding
                stepIndex={stepIndex}
                actions={actions}
                isUserAuthorized={isUserAuthorized}
              />
            )
          }
          <Route
            path={path}
            render={(props) => (
              // pass the sub-routes down to keep nesting
              <route.component {...props} {...customProps} routes={routes} />
            )}
          />
        </SocietyManager>
        <PopUp />
      </div>
    );
  }

  return <Loading />;
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
  society: state.society.status,
  firstLogin: state.society.first_login,
  isUserAuthorized: state.society.isOwner,
  stepIndex: state.onboarding.stepIndex,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ handleNext }, dispatch),
});

const ConnectedRouteWithSubRoutes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteWithSubRoutes);
