import React from 'react';

import AdminInformation from '../AdminInformation';
import AdminShareholders from '../AdminShareholders';
import AdminOperations from '../AdminOperations';

export default function AdminManager({ state }) {
  const STATE_MANAGER = {
    information: <AdminInformation />,
    shareholders: <AdminShareholders />,
    operations: <AdminOperations />,
  };
  return (
    <main>
      { STATE_MANAGER[state] }
    </main>
  );
}
