import axios from './axiosClient';

export const getArchivedAssembly = (societyId) => axios.get('/assembly/archives', { params: { society_id: societyId } });
export const getPlannedAssembly = (societyId) => axios.get('/assembly/plan', { params: { society_id: societyId } });
export const getPlannedAssemblyDocument = (assemblyId) => axios.get(`/documents/?assembly_id=${assemblyId}`);
export const addDocumentToAssembly = (assemblyId, formData) => axios.post(`/documents/?assembly_id=${assemblyId}`, formData);
export const deletePlannedAssembly = (id) => axios.delete(`/assembly/plan/${id}`);
export const createPlannedAssembly = (assembly) => axios.post('/assembly/plan', assembly);
export const updatePlannedAssemblyById = (id, assembly) => axios.put(`/assembly/plan/${id}`, assembly);
export const deleteResolution = (id) => axios.delete(`/assembly/resolution/${id}`);
export const generateOfficialReport = (societyId, report) => axios.post('/generate', { report, societyId });
export const sendAssemblyInvitations = (assemblyId) => axios.post(`/assembly/send-invitations/${assemblyId}`);
export const sendReminderAssembly = (assembly) => axios.post(`/assembly/send-invitations-not-responded/${assembly}`);
export const sendVoteReminderAssembly = (societyId, assembly) => axios.post('/reminder/vote', {
  data: {
    assembly,
    societyId,
  },
});
export const sendResponseAssemblyInvitation = (invitationResponse) => axios.put('/assembly/invitations', invitationResponse);
export const voteResolution = (resolution) => axios.post('/assembly/resolution/vote', resolution);
