import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OperationsEdit from './OperationsEdit';

import { setOperation } from '../../../services/store/features/operations/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';

const mapStateToProps = (state) => ({
  currentOperation: (state.operations.creation?.status === 'edit')
    ? {
      name: state.operations.creation?.name,
      sharesPrice: state.operations.creation?.parameters?.sharesPrice,
      sharesNumber: state.operations.creation?.parameters?.sharesNumber,
    }
    : null,
  operationHistory: {
    status: state.history.status,
    history: state.history.history,
  },
  societyId: state.society.id,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setOperation,
    fetchHistory,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsEdit);
