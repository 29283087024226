import * as types from './types';
import * as statuses from '../../states';
import { getNewVotes } from './actions';

const initialState = {
  archives: {
    status: statuses.IDLE,
    error: null,
    list: [],
  },
  plan: {
    status: statuses.IDLE,
    error: null,
    list: [],
  },
  creation: {
    status: statuses.IDLE,
    error: null,
  },
  resolutions: {
    status: statuses.IDLE,
    error: null,
  },
  selected: null,
  mailStatus: statuses.IDLE,
};

export default function assemblyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_ARCHIVED_REQUEST:
      return {
        ...state,
        archives: {
          ...state.archives,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_ARCHIVED_SUCCESS:
      return {
        ...state,
        archives: {
          ...state.archives,
          status: statuses.SUCCESS,
          list: payload,
        },
      };
    case types.FETCH_ARCHIVED_FAIL:
      return {
        ...state,
        archives: {
          ...state.archives,
          status: 'failed',
          error: payload,
        },
      };
    case types.FETCH_PLAN_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'success',
          error: null,
          list: payload,
        },
      };
    case types.FETCH_PLAN_FAIL:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'failed',
          error: payload,
        },
      };
    case types.DELETE_PLAN_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: statuses.LOADING,
        },
      };
    case types.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'success',
          list: [...state.plan.list.filter((i) => i.id !== payload)],
        },
      };
    case types.DELETE_PLAN_FAIL:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'failed',
          error: payload,
        },
      };
    case types.CREATE_ASSEMBLY_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: statuses.LOADING,
        },
      };
    case types.CREATE_ASSEMBLY_SUCCESS:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: 'success',
        },
        plan: {
          ...state.plan,
          list: [...state.plan.list, payload],
        },
      };
    case types.CREATE_ASSEMBLY_FAIL:
      return {
        ...state,
        creation: {
          ...state.creation,
          status: 'failed',
          error: payload,
        },
      };
    case types.RESET_ASSEMBLY_CREATION:
      return {
        ...state,
        creation: {
          status: statuses.IDLE,
          error: null,
        },
      };
    case types.SET_ASSEMBLY_SELECTED:
      return {
        ...state,
        selected: payload,
      };
    case types.UPDATE_PLAN_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: statuses.LOADING,
        },
      };
    case types.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'success',
          list: payload,
        },
      };
    case types.UPDATE_PLAN_FAIL:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'fail',
          error: payload,
        },
      };
    case types.SEND_MAIL_RESET:
      return {
        ...state,
        mailStatus: statuses.IDLE,
      };
    case types.SEND_MAIL_SUCCESS:
      return {
        ...state,
        mailStatus: 'success',
      };
    case types.SEND_MAIL_REQUEST:
      return {
        ...state,
        mailStatus: statuses.LOADING,
      };
    case types.SEND_MAIL_ERROR:
      return {
        ...state,
        mailStatus: statuses.FAIL,
      };
    case types.UPDATE_REMINDER:
      return {
        ...state,
        plan: {
          ...state.plan,
          list: payload,
        },
      };
    case types.DELETE_RESOLUTION_REQUEST:
      return {
        ...state,
        resolutions: {
          ...state.resolutions,
          status: statuses.LOADING,
        },
      };
    case types.DELETE_RESOLUTION_FAIL:
      return {
        ...state,
        resolutions: {
          ...state.resolutions,
          status: statuses.FAIL,
          error: payload,
        },
      };
    case types.DELETE_RESOLUTION_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          list: state.plan.list.map((assembly) => (
            (assembly.id === payload.assembly)
              ? {
                ...assembly,
                resolutions: assembly.resolutions
                  .filter((resolution) => resolution.id !== payload.id),
              }
              : assembly
          )),
        },
      };
    case types.SEND_VOTE_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          list: state.plan.list.map((assembly) => (
            (assembly.id === Number.parseInt(payload.assemblyId, 10))
              ? {
                ...assembly,
                resolutions: assembly.resolutions.map((resolution) => {
                  if (payload.votes.map((vote) => vote.id).includes(resolution.id)) {
                    return {
                      ...getNewVotes(payload.votes, resolution, payload.id),
                    };
                  }
                  return resolution;
                }),
              }
              : assembly
          )),
        },
      };
    case types.INVITATION_RESPONSE_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          list: state.plan.list.map((assembly) => (
            (assembly.id === Number.parseInt(payload.assemblyId, 10))
              ? {
                ...assembly,
                invitations: assembly.invitations.map((guest) => ((guest.mail === payload.userEmail)
                  ? { ...guest, presence: payload.presence }
                  : guest)),
              }
              : assembly
          )),
        },
      };
    default:
      return state;
  }
}
