import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/library/Button/Button';
import InputText from 'components/library/Input/InputText/InputText';
import SuccessAnimation from 'components/SuccessAnimation/SuccessAnimation';
import ErrorAnimation from 'components/Error/ErrorAnimation/ErrorAnimation';
import * as statuses from 'services/store/states';

import useForm from '../../../hook/useForm';
import validate from './FormValidationRules';
import styles from './OperationsShareholdersAsk.module.css';

export default function OperationsShareholdersAsk({ status, back, actions }) {
  useEffect(() => () => {
    actions.resetCreation();
  }, []);

  const submit = (data) => {
    const newShareholders = {
      ...data,
      shares: 0,
      holdingName: 'default',
      sendInvitation: true,
      toComplete: true,
    };
    actions.addNewShareholders(newShareholders);
  };

  const {
    values, handleChange, handleSubmit, errors,
  } = useForm(submit, validate);

  if (status === 'fail') {
    return (
      <section className={styles.error}>
        <ErrorAnimation />
        <h3> Un problème technique est survenue </h3>
        <p>
          L&apos;invitation n&apos;a pas pu être envoyé à votre actionnaires.
          Veuillez nous excuser pour la gêne occasionnée. Essayez plus tard,
          si l&apos;erreur persite, contactez notre support.
        </p>
      </section>
    );
  }

  if (status === 'success') {
    return (
      <section className={styles.success}>
        <SuccessAnimation />
        <h3>
          Une invitation a été envoyé à votre actionnaires
          pour remplir ses informations personnelles
        </h3>
        <Button
          label="Retour"
          onButtonClick={back}
        />
      </section>
    );
  }

  return (
    <section>
      <h3 className={styles.title}>
        Demander à votre actionnaire de remplir ses informations personnelles
      </h3>
      <form className={styles.form}>
        <div className={styles.row}>
          <InputText
            label="Prénom"
            type="text"
            name="firstName"
            valueInput={values.firstName || ''}
            onTextChange={handleChange}
            state={errors.firstName && statuses.FAIL}
            hint={errors.firstName}
          />
          <InputText
            label="Nom"
            valueInput={values.lastName || ''}
            type="text"
            name="lastName"
            onTextChange={handleChange}
            state={errors.lastName && statuses.FAIL}
            hint={errors.lastName}
          />
        </div>
        <InputText
          label="E-mail"
          name="mail"
          valueInput={values.mail || ''}
          onTextChange={handleChange}
          state={errors.mail && statuses.FAIL}
          hint={errors.mail}
        />
      </form>
      <Button
        label={(status === statuses.LOADING) ? 'Validation en cours...' : 'Valider les informations'}
        onButtonClick={handleSubmit}
        icon={(status === statuses.LOADING) ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
      />
    </section>
  );
}
