const links = (t, societyId) => [
  {
    url: `/${societyId}/rapport`,
    label: t('reports.navigation.published'),
    exact: true,
  },
  {
    url: `/${societyId}/rapport/planification`,
    label: t('reports.navigation.plan'),
    exact: true,
    roles: ['POST-INVEST_REPORT_CREATE', 'POST-INVEST_REPORT_UPDATE', 'POST-INVEST_REPORT_DELETE'],
  },
  {
    url: `/${societyId}/rapport/edition`,
    label: t('reports.navigation.edit'),
    exact: false,
    roles: ['POST-INVEST_REPORT_CREATE', 'POST-INVEST_REPORT_UPDATE', 'POST-INVEST_REPORT_DELETE'],
  },
];

export const investorLinks = (t, societyId) => [
  {
    url: `/${societyId}/rapport`,
    label: t('reports.navigation.published'),
    exact: true,
  },
];

export default links;
