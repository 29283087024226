import { keysToSnake } from '../../../../utils/converter';
import getFormDataFromObj from '../../../../utils/getFormDataFromObj';
import formatDate from '../../../../utils/formatDate';

// eslint-disable-next-line import/prefer-default-export
export const shareholderToApi = (shareholder) => {
  const formData = getFormDataFromObj(keysToSnake({
    email: shareholder.email,
    firstName: shareholder.firstName,
    lastName: shareholder.lastName,
    number: shareholder.number,
    birthday: shareholder.birthday,
    city: shareholder.city,
    zipCode: shareholder.zipCode,
    country: shareholder.country,
    iss: shareholder.iss,
    societyId: shareholder.societyId,
    societies: shareholder.societies,
  }));
  formData.append('img', shareholder.img);
  return formData;
};

export const legalShareholderToApi = (legalShareholder) => {
  const formData = getFormDataFromObj(keysToSnake({
    email: legalShareholder.email,
    firstName: legalShareholder.firstName,
    lastName: legalShareholder.lastName,
    number: legalShareholder.number,
    birthday: legalShareholder.birthday,
    city: legalShareholder.city,
    zipCode: legalShareholder.zipCode,
    country: legalShareholder.country,
    iss: legalShareholder.iss,
    societyId: legalShareholder.societyId,
    societies: legalShareholder.societies,
    siren: legalShareholder.siren,
    societyName: legalShareholder.societyName,
    rcsCity: legalShareholder.rcsCity,
    formJuridique: legalShareholder.formJuridique,
  }));
  formData.append('img', legalShareholder.img);
  return formData;
};

export const formatDateToApi = (date) => `${date.getDate()}/${(date.getMonth() < 9) ? 0 : ''}${(date.getMonth() + 1)}/${date.getFullYear()} 00:00`;

export const shareHistoryToApi = (shareHistory) => keysToSnake({
  buy: shareHistory.buy,
  date: formatDateToApi(formatDate(shareHistory.date)),
  historyId: shareHistory.historyId,
  operations: shareHistory.operations,
  shareholderId: shareHistory.shareholderId,
  societyId: shareHistory.societyId,
  total: shareHistory.total,
});
