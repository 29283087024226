import * as types from './type';
import * as statuses from '../../states';
import { sortByDate } from './utils';

const initialState = {
  status: statuses.IDLE,
  error: null,
  history: [],
  historyToDisplay: -1,
  holdingHistoryToDisplay: -1,
};

export default function historyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_HISTORY_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        history: sortByDate(payload),
        historyToDisplay: sortByDate(payload)
          .filter((h) => h.holding !== true)
          ?.at(-1)
          ?.id || 0,
        holdingHistoryToDisplay: sortByDate(payload)
          .filter((h) => h.holding === true)?.at(-1)?.id || 0,
      };
    case types.FETCH_HISTORY_FAILED:
      return {
        ...state,
        status: 'FAIL',
        error: payload,
      };
    case types.SET_ACTIVE_HISTORY:
      return (window.location.href.includes('holding'))
        ? {
          ...state,
          holdingHistoryToDisplay: payload,
        }
        : {
          ...state,
          historyToDisplay: payload || 0,
        };
    case types.CREATE_HISTORY_SUCCESS:
      return {
        ...state,
        history: sortByDate([payload, ...state.history]),
        historyToDisplay: sortByDate([payload, ...state.history])
          .filter((h) => h?.holding === false).at(-1)?.id,
        holdingHistoryToDisplay: sortByDate([payload, ...state.history])
          .filter((h) => h?.holding === true).at(-1)?.id,
      };
    case types.UPDATE_HISTORY_SUCCESS:
      return {
        ...state,
        history: sortByDate([
          payload.history,
          ...state.history.filter((history) => history.id !== payload.id),
        ]),
        historyToDisplay: sortByDate([
          payload.history,
          ...state.history.filter((history) => history.id !== payload.id),
        ]).filter((h) => h?.holding === false)
          ?.find((h) => h.operationStatus === 'verified')?.id,
        holdingHistoryToDisplay: sortByDate([
          payload.history,
          ...state.history.filter((history) => history.id !== payload.id),
        ]).filter((h) => h?.holding === true)
          ?.find((h) => h.operationStatus === 'verified')?.id,
      };
    case 'ADD_AYOMI_HISTORY_SUCCESS':
      return {
        ...state,
        history: sortByDate([...payload, ...state.history]),
        historyToDisplay: sortByDate([...payload, ...state.history])
          .filter((h) => h?.holding === false)
          ?.at(-1)?.id,
        holdingHistoryToDisplay: sortByDate([
          payload.history,
          ...state.history.filter((history) => history.id !== payload.id),
        ]).filter((h) => h?.holding === true)?.at(-1)?.id,
      };
    case types.ADD_TARGET_HISTORY:
      return {
        ...state,
        history: sortByDate([payload, ...state.history]),
        historyToDisplay: sortByDate([payload, ...state.history])
          .filter((h) => h?.holding === false)
          ?.find((h) => h.operationStatus === 'verified')?.id,
      };
    case types.FETCH_TARGET_HISTORY_REQUEST:
      return {
        ...state,
        status: statuses.LOADING,
      };
    case types.FETCH_TARGET_HISTORY_SUCCESS:
      return {
        ...state,
        status: statuses.SUCCESS,
        history: [
          ...state.history.filter((history) => history.holding === true),
          ...payload,
        ],
      };
    case types.FETCH_TARGET_HISTORY_FAILED:
      return {
        ...state,
        status: statuses.FAIL,
        error: payload,
      };
    default:
      return state;
  }
}
