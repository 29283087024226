import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllShareholders, setQuery } from 'services/store/features/shareholders/actions';
import {
  createNewCapitalGain,
  initSelectedShareholders,
} from 'services/store/features/operations/actions';
import { getSharesAvailable } from 'services/store/features/operations/selectors';

import OperationsCapitalForm from './OperationCapitalForm';

const mapStateToProps = (state) => ({
  shareholders: {
    status: state.shareholders.status,
    query: state.shareholders.query,
    list: [
      ...(state.shareholders?.shareholders
        ?.map((shareholder) => ({ ...shareholder, holding: false })) || []),
      ...(state.shareholders?.holding?.map((shareholder) => ({ ...shareholder, holding: true }))
        || []),
    ],
  },
  sharesAvailable: getSharesAvailable(state),
  status: state.operations.creation.status,
  isCreated: (state.operations.creation.id),
  societyId: state.society.id,
  holdingId: state.society.idHolding,
  userId: state.user.id,
  operation: state.operations.creation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllShareholders,
    setQuery,
    createNewCapitalGain,
    initSelectedShareholders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsCapitalForm);
