import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import * as statuses from '../../services/store/states';

import links, { investorLinks } from './navigation';
import styles from './Report.module.css';

export default function Report({
  status, userStatus, folders, activeFolder, reports, roles, societyId, userId, actions, isInvestor,
}) {
  const { t } = useTranslation();
  const { userId: user, society } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
  }, []);

  useEffect(() => {
    if (societyId && status === statuses.IDLE) {
      actions.fetchReportsData();
    }
    if (userStatus === statuses.IDLE) {
      actions.fetchUserInfo(user);
    }
  }, [societyId]);
  if (!societyId && !userId) return null;
  return (
    <section className={styles.report}>
      {
        (isInvestor)
          ? (
            <Header
              title={t('reports.report')}
              navigation={investorLinks(t, societyId, userId)}
              roles={roles}
            />
          )
          : (
            <Header
              title={t('reports.report')}
              navigation={links(t, societyId, userId)}
              roles={roles}
            />
          )
      }
      <main className={styles.content}>
        <h2>
          {t('reports.publish')}
        </h2>
        {
          (folders && folders.length > 0)
          && (
            <div className={styles.folders}>
              {
                folders.map((folder) => (
                  <button
                    className={`${(folder === activeFolder) ? styles.active : ''} ${styles.folder}`}
                    type="button"
                    key={folder}
                    onClick={() => actions.setActiveFolder(folder)}
                  >
                    <span>
                      { folder }
                    </span>
                    <span className={styles.highlight} />
                  </button>
                ))
              }
            </div>
          )
        }
        {
          (reports && reports.length > 0)
            ? (
              <ul className={styles.list}>
                {
                  reports.map((report) => (
                    <Link
                      to={`/${societyId}/rapport/${report.id}`}
                      key={report.id}
                      type="button"
                      className={styles.reportLink}
                    >
                      <div className={styles.reportTitle}>
                        <p className={styles.title}>
                          { report.title }
                        </p>
                        <p className={styles.period}>
                          {t('reports.period', { begin: report.period.begin, end: report.period.end })}
                        </p>
                      </div>
                      <span className={styles.publication}>
                        {t('reports.publishedDate', { date: report.publicationDate })}
                      </span>
                    </Link>
                  ))
                }
              </ul>
            )
            : (
              <div className={styles.empty}>
                <p className={styles.title}>{ t('reports.empty') }</p>
                {
                  !isInvestor
                  && (
                    <NavLink
                      className={styles.button}
                      to={`/${societyId}/rapport/edition`}
                    >
                      { t('reports.navigation.edit') }
                    </NavLink>
                  )
                }
              </div>
            )
        }
      </main>
    </section>
  );
}
