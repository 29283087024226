import React, { useState } from 'react';

import * as statuses from '../../services/store/states';

import AdminHeader from './AdminHeader';
import AdminManager from './AdminManager';
import styles from './Admin.module.css';

export default function Admin() {
  const [active, setActive] = useState(statuses.IDLE);

  const getButtonClass = (state) => `${styles.navigation} ${(state === active) ? styles.active : ''}`;

  return (
    <section className={styles.admin}>
      <AdminHeader>
        <button
          type="button"
          className={getButtonClass('information')}
          onClick={() => setActive('information')}
        >
          Information
        </button>
        <button
          type="button"
          className={getButtonClass('shareholders')}
          onClick={() => setActive('shareholders')}
        >
          Actionnaires
        </button>
        <button
          type="button"
          className={getButtonClass('operations')}
          onClick={() => setActive('operations')}
        >
          Opérations
        </button>
      </AdminHeader>
      <AdminManager
        state={active}
      />
    </section>
  );
}
