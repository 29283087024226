import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import ChatContactListSearchbar from '../ChatContactListSearchbar';
import { SocketContext } from '../../../services/socket/socket';
import { onJoinGroupChat } from '../../../services/socket/events';
import * as statuses from '../../../services/store/states';

import styles from './ChatContactList.module.css';
import ayomiLogo from './ayomi.png';

export default function ChatContactList({
  statusContact, contacts, group, society, actions, activeId,
}) {
  const socket = useContext(SocketContext);
  const [isContactListOpen, setIsContactListOpen] = useState(true);
  const [isGroupListOpen, setIsGroupListOpen] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    onJoinGroupChat(socket, society.chatRoom);
  }, [society.chatRoom]);

  useEffect(() => {
    if (statusContact === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (society.status === statuses.IDLE) {
      actions.fetchSocietyData();
    }
    socket.on('users', (users) => {
      actions.updateContactsSocket(users);
    });

    socket.on('user connected', (users) => {
      actions.updateContactsSocket(users);
    });

    socket.on('user disconnected', (user) => {
      actions.removeContactsSocket(user);
    });
  }, []);

  const handleChatRoomSelection = (type, payload) => {
    switch (type) {
      case 'contact':
        actions.setActiveRoom(payload.id);
        break;
      case 'group':
        actions.setActiveRoom('group', {
          name: payload.name,
          description: payload.description,
          logo: payload.logo,
          id: payload.id,
        });
        break;
      case 'support':
        actions.setActiveRoom('group', {
          name: 'Ayomi',
          description: t('chat.help'),
          logo: ayomiLogo,
          id: society.support,
          messages: [],
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.chat} ${(activeId === null) ? styles.show : styles.hide}`}>
      <div className={styles.searchbarContainer}>
        <ChatContactListSearchbar />
      </div>
      <button
        type="button"
        className={styles.listButton}
        onClick={() => setIsContactListOpen((state) => !state)}
      >
        <span className={styles.title}>
          Contacts
        </span>
        <FontAwesomeIcon icon={(!isContactListOpen) ? faChevronDown : faChevronUp} />
      </button>
      {
        (isContactListOpen)
        && (
          <ul className={styles.list}>
            {
              contacts.map((contact) => (
                <button
                  type="button"
                  key={contact.id}
                  className={styles.contact}
                  onClick={() => handleChatRoomSelection('contact', contact)}
                >
                  <div className={styles.contactInfo}>
                    <div className={styles.colorLine} />
                    <div className={styles.connection}>
                      {
                        (contact.img)
                          ? (
                            <img
                              className={styles.contactPicture}
                              src={contact.img}
                              alt={contact.name}
                            />
                          )
                          : (
                            <div className={styles.contactLogo}>
                              {contact.name[0]}
                            </div>
                          )
                      }
                      <span className={(contact.isConnected) ? styles.online : styles.offline} />
                    </div>
                    <div>
                      <p className={styles.subtitle}>
                        {contact.name}
                      </p>
                      <p className={styles.description} />
                      { contact.description }
                    </div>
                  </div>
                  {(contact.hasNewMessages) && <span className={styles.notification}>!</span>}
                </button>
              ))
            }
          </ul>
        )
      }
      <button
        type="button"
        className={styles.listButton}
        onClick={() => setIsGroupListOpen((state) => !state)}
      >
        <span className={styles.title}>
          Groupes
        </span>
        <FontAwesomeIcon icon={(!isGroupListOpen) ? faChevronDown : faChevronUp} />
      </button>
      {
        (isGroupListOpen)
        && (
          <button
            type="button"
            key={society.chatRoom}
            className={styles.contact}
            onClick={() => handleChatRoomSelection('group', {
              logo: society.logo,
              name: society.name,
              description: t('chat.shareholdersGroup', { name: society.name }),
              id: society.chatRoom,
            })}
          >
            <div className={styles.contactInfo}>
              <div className={styles.colorLine} />
              {
                (society.logo)
                  ? (
                    <img
                      className={styles.contactPicture}
                      src={society.logo}
                      alt={society.name}
                    />
                  )
                  : (
                    <div className={styles.contactLogo}>
                      {society?.name?.substring(0, 1)}
                    </div>
                  )
              }
              <div>
                <p className={styles.subtitle}>
                  {society.name}
                </p>
                <p className={styles.description}>
                  {t('chat.shareholdersGroup', { name: society.name })}
                </p>
              </div>
            </div>
            {group.hasNewMessages && <span className={styles.notification}>!</span>}
          </button>
        )
      }
    </div>
  );
}
