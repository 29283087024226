import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/extensions
import ShareholdersAccountList from './ShareholdersAccountList.jsx';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { getLastHistory, getLastHoldingHistory } from '../../../services/store/features/history/selectors';
import { fetchHistory } from '../../../services/store/features/history/actions';

const mapStateToProps = (state) => {
  const isHolding = window.location.href.includes('holding');
  return ({
    status: state.shareholders.status?.toUpperCase(),
    shareholders: isHolding
      ? state.shareholders.holding?.map((shareholder) => ({ ...shareholder, holding: true }))
      : state.shareholders.shareholders?.map((shareholder) => ({ ...shareholder, holding: false })),
    id: isHolding ? state.society.idHolding : state.society.id,
    lastHistory: isHolding ? getLastHoldingHistory(state)?.id : getLastHistory(state)?.id,
    history: state.history.status,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllShareholders, fetchHistory }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersAccountList);
