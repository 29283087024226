import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Searchbar from '../../Searchbar/Searchbar';

export default function ChatContactListSearchbar({ query, actions }) {
  const { t } = useTranslation();
  return (
    <Searchbar
      placeholder={t('chat.search')}
      query={query}
      actions={actions}
    />
  );
}

ChatContactListSearchbar.defaultProps = {
  query: '',
};

ChatContactListSearchbar.propTypes = {
  query: PropTypes.string,
  actions: PropTypes.shape({
    setQuery: PropTypes.func.isRequired,
  }).isRequired,
};
