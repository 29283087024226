import React from 'react';

import * as statuses from '../../services/store/states';
import styles from './Society.module.css';

export default function societyDescription({
  status, name, description, website,
}) {
  if (status === statuses.LOADING) {
    return (<div className={`${styles.descriptionContainer} ${styles.descriptionContainerPlaceholder}`} />);
  }
  return (
    <div
      className={`fadeIn ${styles.descriptionContainer}`}
    >
      <p className={`h6 ${styles.descriptionTitle}`}>
        { name }
      </p>
      <div
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className={styles.descriptionLink}>
        <p> Site: </p>
        <a href={website}>
          { website }
        </a>
      </div>
    </div>
  );
}
