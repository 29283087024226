import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Society from './Society';

import { fetchSocietyData } from '../../services/store/features/society/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSocietyData,
  }, dispatch),
});

const mapStateToProps = (state) => ({
  id: state.society.id,
  society: {
    ...state.society,
    owner: `${state.society.firstName} ${state.society.lastName}`,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Society);
