import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import * as statuses from 'services/store/states';

import InputFile2 from '../../library/Input/InputFile2/InputFile2';
import styles from './DocumentUpload.module.css';
import InputText from '../../library/Input/InputText/InputText';
import Button from '../../library/Button/Button';
import SuccessAnimation from '../../SuccessAnimation/SuccessAnimation';
import ErrorAnimation from '../../Error/ErrorAnimation/ErrorAnimation';
import Loading from '../../library/Loading';

export default function DocumentUpload({
  creation, domain, shareholders, activeUserId, actions,
}) {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [userId, setUserId] = useState(activeUserId);

  useEffect(() => {
    actions.resetUploadDocument();
    if (shareholders.status === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
  }, []);

  const { t } = useTranslation();

  const handleUserSelection = (e) => {
    setUserId(e.target.value);
  };

  const handleUploadFile = (value) => {
    const newFile = value[0];
    setFile(newFile);
    setFilename(newFile.name);
  };

  const handleSubmit = () => {
    actions.uploadDocument(filename, file, userId);
  };

  if (shareholders.status === statuses.IDLE) {
    return (
      <main className={styles.container}>
        <Loading />
      </main>
    );
  }

  if (creation[domain] === 'SUCCESS') {
    return (
      <main className={`${styles.container} ${styles.success}`}>
        <SuccessAnimation />
        <p className={styles.title}>
          { t('documents.upload.success') }
        </p>
      </main>
    );
  }

  if (creation[domain] === statuses.FAIL) {
    return (
      <main className={`${styles.container} ${styles.fail}`}>
        <ErrorAnimation />
        <p className={styles.description}>
          {
            t('documents.upload.failed')
          }
        </p>
      </main>
    );
  }

  return (
    <>
      <main className={styles.container}>
        <InputFile2
          file={file}
          handleFile={handleUploadFile}
          state={(file) ? 'SUCCESS' : 'DEFAULT'}
          cancel={() => setFile(null)}
          hideOpen
        />
        {
          (file)
          && (
            <InputText
              label={t('documents.upload.filename')}
              valueInput={filename}
              onTextChange={(e) => setFilename(e.target.value)}
            />
          )
        }
        {
          (shareholders)
          && (
            <div className={styles.select}>
              <label htmlFor="userId">
                {t('documents.upload.user')}
              </label>
              <select
                id="userId"
                name="userId"
                onClick={handleUserSelection}
              >
                <option
                  value={activeUserId}
                >
                  {t('documents.upload.myself')}
                </option>
                {
                  shareholders.list
                    .filter((shareholder) => shareholder.auth_id)
                    .map((shareholder) => (
                      <option
                        key={shareholder.id}
                        value={shareholder.auth_id}
                      >
                        {`${shareholder.firstName} ${shareholder.lastName}`}
                      </option>
                    ))
                }
              </select>
            </div>
          )
        }
      </main>
      <footer className={styles.footer}>
        <Button
          label={
            (creation[domain] !== statuses.LOADING)
              ? t('documents.add.button')
              : ''
          }
          icon={(creation[domain] === statuses.LOADING)
            && <FontAwesomeIcon icon={faSpinner} spin />}
          onButtonClick={handleSubmit}
          disabled={!file}
        />
      </footer>
    </>
  );
}
