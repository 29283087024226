import { keysToCamel, keysToSnake } from '../../../../utils/converter';

export const formatEvent = (data) => keysToCamel(data);

export const formatEvents = (data) => data.map((event) => formatEvent(event));

export const formatBackEvent = (data) => ({
  ...keysToSnake({
    date: data.date,
    description: data.description,
    isAllDay: data.isAllDay,
    participantsEmail: data.participantsEmail,
    title: data.title,
    imagePath: data.imagePath,
    place: data.place,
    societyId: data.societyId,
    hourBeginning: data.hourBeginning,
    hourEnding: data.hourEnding,
  }),
  img: data.img,
});
