import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as statuses from 'services/store/states';

import Header from 'components/Header/Header';
import ShareholdersExport from '../ShareholdersExport';

import links, { investorLinks } from './navigation';

export default function ShareholdersHeader({
  id, userStatus, actions, roles, isOwner, isHolding, isActive,
}) {
  const { t } = useTranslation();

  const callback = () => {
    if (isActive) {
      actions.handleNext({ lifecycle: 'complete' });
    }
  };

  useEffect(() => {
    if (userStatus === statuses.IDLE) {
      actions.fetchUserInfo();
    }
  });

  const openExportPopUp = () => {
    actions.openPopUp(
      t('shareholders.export.title'),
      t('shareholders.export.description'),
      <ShareholdersExport />,
    );
  };

  if (!isOwner) {
    return (
      <Header
        title={t('shareholders.shareholders')}
        navigation={investorLinks(id, t, isHolding)}
        roles={roles}
      />
    );
  }

  return (
    <Header
      title={(isHolding) ? t('shareholders.holding') : t('shareholders.shareholders')}
      navigation={links(id, t, isHolding, callback)}
      button={{ label: t('shareholders.export.button'), action: openExportPopUp }}
      roles={roles}
    />
  );
}
