import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../../components/library/Button/Button';
import Checkbox from '../../../components/library/Input/Checkbox/Checkbox';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';
import button from '../../../components/library/Button/Button.module.css';
import * as statuses from '../../../services/store/states';

import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import shared from '../shared/assembly.module.css';

import styles from './AssemblyPresence.module.css';

export default function AssemblyPresence({
  status, invitations, societyId, actions,
}) {
  const [presence, setPresence] = useState([]);
  const [errors, setErrors] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (status.shareholders === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (status.assembly === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
    if (status.history === statuses.IDLE) {
      actions.fetchHistory();
    }
    actions.setActiveAssembly(id);
  }, []);

  useEffect(() => {
    if (
      status.shareholders === 'success'
      && status.assembly === 'success'
      && status.history === 'SUCCESS'
      && presence.length === 0
    ) {
      setPresence(invitations.map((invitation) => ({
        ...invitation,
        isPresent: (invitation.status === 'yes'),
        isTeller: false,
        isOnline: false,
        isOnSite: true,
        isRepresented: false,
        isRepresentedBy: null,
      })));
    }
  }, [invitations]);

  const checkHasTeller = () => presence
    .map((guest) => guest.isTeller)
    .reduce((acc, current) => (acc || current), false);

  const checkHasRepresentative = () => {
    let isValid = true;
    setPresence((state) => state
      .map((guest) => {
        if (guest.isRepresented
        && (guest.isRepresentedBy === null || guest.isRepresentedBy.trim() === '')) {
          isValid = false;
          return {
            ...guest,
            error: t('assembly.attendance.representativeEmpty'),
          };
        }
        return {
          ...guest,
          error: null,
        };
      }));
    return isValid;
  };

  const submit = () => {
    let err = [];
    if (!checkHasTeller()) {
      err = [...err, t('assembly.attendance.scrutineerEmpty')];
    }
    if (!checkHasRepresentative()) {
      err = [...err, t('assembly.attendance.representativeMissing')];
    }
    if (err.length === 0) {
      setHasSubmitted(true);
      actions.signAssemblyPresenceById(id, presence);
    }
    setErrors(err);
  };

  const handleChange = (guestId, key, value) => {
    if (key === 'isTeller' && value === true) {
      const index = presence.map((guest) => guest.id).indexOf(guestId);
      const newValue = {
        ...presence[index],
        [key]: value,
        isRepresented: false,
      };
      setPresence((state) => [
        ...state.slice(0, index).map((guest) => ({ ...guest, [key]: false })),
        newValue,
        ...state.slice(index + 1).map((guest) => ({ ...guest, [key]: false })),
      ]);
    } else {
      const index = presence.map((guest) => guest.id).indexOf(guestId);
      if (key === 'isRepresented' && value === true) {
        const newValue = {
          ...presence[index],
          [key]: value,
          isPresent: false,
          isOnSite: false,
          isOnline: false,
          isTeller: false,
        };
        setPresence((state) => [
          ...state.slice(0, index),
          newValue,
          ...state.slice(index + 1),
        ]);
      } else if (key === 'isPresent' && value === true) {
        const newValue = {
          ...presence[index],
          [key]: value,
          isRepresented: false,
        };
        setPresence((state) => [
          ...state.slice(0, index),
          newValue,
          ...state.slice(index + 1),
        ]);
      } else if (key === 'isOnline' || key === 'isOnSite') {
        const otherKey = (key === 'isOnline') ? 'isOnSite' : 'isOnline';
        const newValue = {
          ...presence[index],
          [key]: value,
          [otherKey]: !value,
          isRepresented: false,
        };
        setPresence((state) => [
          ...state.slice(0, index),
          newValue,
          ...state.slice(index + 1),
        ]);
      } else {
        const newValue = {
          ...presence[index],
          [key]: value,
        };
        setPresence((state) => [
          ...state.slice(0, index),
          newValue,
          ...state.slice(index + 1),
        ]);
      }
    }
  };

  if (status.assembly === 'success' && hasSubmitted) {
    return (
      <section
        className={shared.container}
      >
        <AssemblyHeader />
        <section className={shared.section}>
          <header className={shared.header}>
            <h2>
              {t('assembly.attendance.title')}
            </h2>
          </header>
          <main className={styles.success}>
            <SuccessAnimation />
            <h3>
              {t('assembly.attendance.succeed')}
            </h3>
            <Link
              to={`/${societyId}/assemblee/view/${id}`}
              className="button"
            >
              {t('assembly.minutes.backAssembly')}
            </Link>
          </main>
        </section>
      </section>
    );
  }
  if (status.shareholders === 'success' && status.assembly === 'success' && status.history === 'SUCCESS') {
    return (
      <section
        className={shared.container}
      >
        <AssemblyHeader />
        <section className={shared.section}>
          <header className={shared.header}>
            <h2>
              {t('assembly.attendance.title')}
            </h2>
          </header>
          {
            (errors && errors.length > 0)
            && (
              <div className={`bounceIn ${styles.errorHandler}`}>
                <WarningIcon
                  className={styles.icon}
                />
                <div>
                  {
                    errors.map((error) => (
                      <p key={error}>{error}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          <main className={styles.gridContainer}>
            <div className={`${styles.grid} ${styles.gridTitle}`}>
              <p className={styles.text}>
                {t('assembly.attendance.name')}
              </p>
              <p className={styles.text}>
                {t('assembly.attendance.category')}
              </p>
              <p className={styles.number}>
                {t('assembly.attendance.titleTab')}
              </p>
              <p className={styles.number}>
                {t('assembly.attendance.capital')}
              </p>
              <p>
                {t('assembly.attendance.attendance')}
              </p>
              <p>
                {t('assembly.attendance.scrutineer')}
              </p>
              <p>
                {t('assembly.attendance.online')}
              </p>
              <p>
                {t('assembly.attendance.onSpot')}
              </p>
              <p>
                {t('assembly.attendance.representative')}
              </p>
              <p className={styles.text}>
                {t('assembly.attendance.representBy')}
              </p>
            </div>
            {
              presence && presence.map((invitation) => (
                <div
                  className={`${styles.grid}`}
                  key={invitation.id}
                >
                  <p className={styles.text}>{invitation.name}</p>
                  <p className={styles.text}>{invitation.category}</p>
                  <p className={styles.number}>{invitation.shares}</p>
                  <p className={styles.number}>
                    {invitation.capital}
                    %
                  </p>
                  <Checkbox
                    checked={invitation.isPresent}
                    onChange={(bool) => handleChange(invitation.id, 'isPresent', bool)}
                  />
                  <Checkbox
                    checked={invitation.isTeller}
                    onChange={(bool) => handleChange(invitation.id, 'isTeller', bool)}
                  />
                  <Checkbox
                    checked={invitation.isOnline}
                    onChange={(bool) => handleChange(invitation.id, 'isOnline', bool)}
                  />
                  <Checkbox
                    checked={invitation.isOnSite}
                    onChange={(bool) => handleChange(invitation.id, 'isOnSite', bool)}
                  />
                  <Checkbox
                    checked={invitation.isRepresented}
                    onChange={(bool) => handleChange(invitation.id, 'isRepresented', bool)}
                  />
                  {
                    invitation.isRepresented
                    && (
                      <div
                        className={`
                          ${(invitation.error)
                          ? styles.error
                          : ''}
                           ${styles.represented}
                        `}
                      >
                        <select
                          value={invitation.isRepresentedBy || -1}
                          onChange={(e) => handleChange(invitation.id, 'isRepresentedBy', e.target.value)}
                        >
                          <option
                            value={-1}
                            disabled
                          >
                            Sélectionner votre représentant
                          </option>
                          {
                          presence.map((guest) => (
                            <option
                              key={guest.id}
                              value={guest.name}
                            >
                              { guest.name }
                            </option>
                          ))
                        }
                        </select>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </main>
          <div
            className={styles.buttonGroup}
          >
            <Link
              to={`/${societyId}/assemblee/view/${id}`}
              className={`${button.button} ${button.secondary} ${button.outline} ${styles.margin}`}
            >
              {t('assembly.attendance.back')}
            </Link>
            <Button
              label={t('assembly.attendance.sign')}
              onButtonClick={submit}
            />
          </div>
        </section>
      </section>
    );
  }
  return (
    <section
      className={shared.container}
    >
      <AssemblyHeader />
      <section className={shared.section}>
        <header className={shared.header}>
          <h2>
            {t('assembly.attendance.title')}
          </h2>
        </header>
        <main className={styles.success}>
          {t('assembly.attendance.loading')}
        </main>
      </section>
    </section>
  );
}
