import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventsDescription from './EventsDescription';
import { deleteEvent, resetEventStatus } from '../../../services/store/features/events/actions';
import { openPopUp, closePopUp } from '../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  status: state.events.creationStatus,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    deleteEvent,
    resetEventStatus,
    closePopUp,
    openPopUp,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsDescription);
