import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../library/Button/Button';
import OperationsShareholdersFormPerson from './OperationsShareholdersFormPerson';

import useForm from '../../../hook/useForm';
import validate from './formValidation';

import styles from './OperationsShareholdersFormPerson.module.css';

export default function OperationsShareholdersFormPersonHandler({ callback, actions }) {
  const [file, setFile] = useState(null);
  const { t } = useTranslation();

  const handleFile = (files) => {
    setFile(files[0]);
  };

  const submit = (shareholder) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      birthday,
      address,
      city,
      zipCode,
      category,
    } = shareholder;

    const newShareholder = {
      firstName,
      lastName,
      email,
      phone,
      birthday: (birthday) ? `${birthday.day}/${birthday.month}/${birthday.year}` : undefined,
      shares: 0,
      isHolding: false,
      address,
      city,
      zipCode,
      category,
      img: file,
      holdingName: 'default',
      lastOperation: null,
      isInvestor: shareholder.category !== 'Fondateur',
    };

    actions.addNewShareholders(newShareholder, callback);
  };

  const {
    values, handleChange, handleSubmit, errors,
  } = useForm(submit, (form) => validate(form, file));

  return (
    <form>
      <OperationsShareholdersFormPerson
        shareholder={values}
        actions={handleChange}
        errors={errors}
        file={file}
        handleFile={handleFile}
      />
      <Button
        label={t('validate')}
        className={styles.button}
        onButtonClick={handleSubmit}
      />
    </form>
  );
}
