import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DocumentsByFolder from './DocumentsByFolder';
import {
  fetchDocumentsData, setFolder,
  setSelectedDocument, setSort,
} from '../../services/store/features/documents/actions';
import { updateDocument } from '../../services/store/features/user/actions';
import { openPopUp } from '../../services/store/features/popup/actions';
import { setSocietyId } from '../../services/store/features/society/actions';
import { getDocumentsByFolderProps } from '../../services/store/features/documents/selectors';

const mapStateToProps = (state) => getDocumentsByFolderProps(state, 'documents');

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSocietyId,
    fetchDocumentsData,
    updateDocument,
    setFolder,
    openPopUp,
    setSelectedDocument,
    setSort,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsByFolder);
