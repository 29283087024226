import { connect } from 'react-redux';

import { getActiveShareholders } from 'services/store/features/shareholders/selectors';

import ShareholdersTable from './ShareholdersTable';

const mapStateToProps = (state) => ({
  status: state.shareholders.status,
  shareholdersNumber: getActiveShareholders(state).activeShareholders.length,
});

export default connect(mapStateToProps)(ShareholdersTable);
