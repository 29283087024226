import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import Button from '../../components/library/Button/Button';
import ContactCard from '../../components/ContactCard/ContactCard';

import styles from './Contacts.module.css';

export default function Contacts({
  contacts, userId, societyId, actions,
}) {
  const { t } = useTranslation();
  const { society, userId: user } = useParams();

  useEffect(() => {
    if (!societyId) {
      actions.setSocietyId(society);
    }
    if (!userId) {
      actions.fetchUserInfo(user, society);
    }
  }, []);

  useEffect(() => {
    if (societyId && contacts.length === 0) {
      actions.fetchContacts();
    }
  }, [societyId]);

  if (!societyId && !userId) return <div />;

  return (
    <section className={styles.contact}>
      <header className={styles.header}>
        <h1>
          Contact
        </h1>
        <div className={styles.separator} />
        <NavLink
          to={`/${societyId}/contacts`}
        >
          <Button
            variant="ghost"
            label="Contacts"
            className={styles.active}
          />
        </NavLink>
        <NavLink
          to={`/${societyId}/contacts/feedback`}
        >
          <Button
            type="custom"
            variant="ghost"
            label="Feedback"
            className={styles.passive}
          />
        </NavLink>
      </header>
      <main className={styles.content}>
        <h2>
          {t('contact.title')}
        </h2>
        <div className={styles.grid}>
          {
            contacts.map((contact) => (
              <ContactCard
                key={contact.title}
                title={contact.title}
                contact={contact}
              />
            ))
          }
        </div>
        <p className={styles.feedback}>
          {t('contact.feedback.description')}
          <a
            href={`/${societyId}/contacts/feedback`}
            className={styles.feedbackLink}
          >
            {t('contact.feedback.button')}
          </a>
        </p>
      </main>
    </section>
  );
}
