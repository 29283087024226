import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputText from 'components/library/Input/InputText/InputText';
import InputAddress from 'components/library/Input/InputAddress';
import InputDate from 'components/library/Input/InputDate/InputDate';
import InputPhone from 'components/library/Input/InputPhone/InputPhone';
import Button from 'components/library/Button/Button';

import styles from './SettingsInvestorsCreationForm.module.css';
import { addNewShareholders } from '../../../../../services/store/features/shareholders/actions';

export default function SettingsInvestorsCreationForm({ close }) {
  const [investor, setInvestor] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(addNewShareholders(investor, close));
  };

  const onChange = (e) => {
    setInvestor((curr) => ({ ...curr, [e.target.name]: e.target.value }));
  };

  const onBirthdayChange = (birthday) => onChange({ target: { name: 'birthday', value: birthday } });

  const onPhoneChange = (phone) => onChange({ target: { name: 'phone', value: phone } });

  return (
    <form className={styles.form}>
      <p className={styles.title}>
        {t('settings.investors.form.title')}
      </p>
      <p className={styles.description}>
        {t('settings.investors.form.description')}
      </p>
      <InputText
        label={t('settings.investors.form.firstname')}
        name="firstName"
        onTextChange={onChange}
        valueInput={investor.firstName}
        required
      />
      <InputText
        label={t('settings.investors.form.lastname')}
        name="lastName"
        onTextChange={onChange}
        valueInput={investor.lastName}
        required
      />
      <InputText
        label={t('settings.investors.form.email')}
        name="email"
        onTextChange={onChange}
        valueInput={investor.email}
        required
      />
      <InputText
        label={t('settings.investors.form.siren')}
        name="siren"
        onTextChange={onChange}
        valueInput={investor.siren}
      />
      {
            (investor.siren && (investor.siren.trim() !== ''))
          && (
            <>
              <InputText
                label={t('settings.investors.form.societyName')}
                name="societyName"
                onTextChange={onChange}
                valueInput={investor.societyName}
              />
              <InputText
                label={t('settings.investors.form.rcsCity')}
                name="rcsCity"
                onTextChange={onChange}
                valueInput={investor.rcsCity}
              />
              <InputText
                label={t('settings.investors.form.formJuridique')}
                name="formJuridique"
                onTextChange={onChange}
                valueInput={investor.formJuridique}
              />
            </>
          )
        }
      <InputAddress
        values={
          {
            streetNumber: investor.streetNumber,
            address: investor.address,
            zipCode: investor.zipCode,
            city: investor.city,
            country: investor.country,
          }
        }
        handleChange={onChange}
      />
      <InputDate
        label={t('settings.investors.form.birthday')}
        name="birthday"
        onChange={onBirthdayChange}
        day={investor.birthday?.day || ''}
        month={investor.birthday?.month || ''}
        year={investor.birthday?.year || ''}
        includeDay
      />
      <InputPhone
        label={t('settings.investors.form.phone')}
        name="phone"
        setPhone={onPhoneChange}
        phone={investor.phone}
      />
      <Button
        label={t('settings.investors.form.submit')}
        className={styles.submit}
        onButtonClick={onSubmit}
      />
    </form>
  );
}
