import formatDate from '../../../../utils/formatDate';

export const getShareholdersCapital = (shareholders, historyId) => {
  const nbPart = shareholders.reduce(
    (acc, currentShareholders) => acc
      + currentShareholders.sharesHistories.find((h) => h.historyId === historyId)?.total,
    0,
  );

  const activeShareholders = shareholders.map((contact) => ({
    ...contact,
    shares: contact.sharesHistories
      .find((h) => h.historyId === historyId)?.total || 0,
    capital: (((contact.sharesHistories
      .find((h) => h.historyId === historyId)?.total || 0) * 100) / nbPart).toFixed(2),
  }));

  return { nbPart, activeShareholders };
};

export const addMoralShareholdersFromHistory = (shareholders, historyId) => {
  /*
   *  Processing for detecting investment via as society
   *  or holding and as individual for the same shareholder
   *  for the history #id
   */

  const societyShareholders = [];
  const individualShareholders = [];

  shareholders.forEach((shareholder) => {
    const matchingHistories = (shareholder?.sharesHistories || [])
      .filter((h) => h.historyId === historyId);
    if (matchingHistories.length > 1) {
      matchingHistories
        .filter((history) => history.operations.length > 0)
        .forEach((history) => {
          const newShareholder = {
            ...shareholder,
            invest_by_society: !!shareholder.societyName,
            history: [{ ...history, date: shareholder.sharesHistories[0].date }],
          };
          if (shareholder.societyName) {
            societyShareholders.push(newShareholder);
          } else {
            individualShareholders.push(newShareholder);
          }
        });
    } else if (shareholder.societyName) {
      societyShareholders.push({ ...shareholder, invest_by_society: true });
    } else {
      individualShareholders.push(shareholder);
    }
    return matchingHistories.length === 1;
  });

  return [
    ...societyShareholders,
    ...individualShareholders,
  ];
};

export const mergeShareholders = (shareholders) => shareholders.reduce((acc, curr) => {
  if (acc.map((shareholder) => shareholder.id).includes(curr.id)) {
    return acc.map((shareholder) => {
      if (shareholder.id === curr.id) {
        return {
          ...shareholder,
          sharesHistories: [
            ...(shareholder?.sharesHistories || []),
            ...(curr?.sharesHistories || []),
          ],
        };
      }
      return shareholder;
    });
  }
  return [...acc, curr];
}, []);

export const addMoralShareholders = (shareholders) => {
  const societyShareholders = [];
  const individualShareholders = [];

  const uniqueShareholders = mergeShareholders(shareholders);

  uniqueShareholders.forEach((shareholder) => {
    if (shareholder.societyName && shareholder.societyName !== '') {
      const currentHistory = (shareholder?.sharesHistories || [])
        .filter((history) => history.from_society);
      societyShareholders.push({
        ...shareholder,
        id: shareholder.id,
        invest_by_society: true,
        sharesHistories: currentHistory,
        shares: currentHistory[currentHistory?.length - 1]?.total || 0,
      });
      individualShareholders.push({
        ...shareholder,
        invest_by_society: false,
        sharesHistories: (shareholder.sharesHistories || [])
          ?.filter((history) => !history.societyName),
      });
    } else {
      individualShareholders.push(shareholder);
    }
  });

  return [...societyShareholders, ...individualShareholders];
};

export const removeMoralShareholders = (shareholders) => shareholders
  .filter((shareholder) => !(shareholder.invest_by_society === true));

export const getShareholderShare = (shareholder, societyId, fromSociety) => {
  let histories = shareholder?.sharesHistories;
  if (!histories || histories.length < 1) {
    return 0;
  }
  if (societyId) {
    histories = histories
      .filter((history) => history?.societyId?.toString() === societyId.toString());
  }
  if (fromSociety !== undefined) {
    histories = histories
      .filter((history) => (!!history?.societyName)?.toString() === fromSociety.toString());
  }
  if (histories && histories.length > 0) {
    histories.sort((a, b) => formatDate(a.date) - formatDate(b.date));
    return histories[histories.length - 1]?.total || 0;
  }
  return 0;
};

export const createShareHistoryFromShareholder = (
  shareholder, historyId, societyId,
  operationId, date, buy = 0,
  fromSociety = false, sell = 0,
) => {
  const hasInvestWithSociety = fromSociety || shareholder.sharesHistories
    ?.filter((shareHistory) => !!shareHistory.societyName).length > 0;

  return [
    ...((buy !== 0 || getShareholderShare(shareholder, societyId, false) !== 0) ? [{
      historyId,
      societyId,
      operation: [operationId],
      date,
      buy,
      sell,
      total: buy + (getShareholderShare(shareholder, societyId) || 0) - sell,
      userId: shareholder.id,
    }] : []),
    ...(hasInvestWithSociety ? [{
      historyId,
      societyId,
      operation: [operationId],
      societyName: fromSociety,
      date,
      buy,
      sell,
      total: (buy + getShareholderShare(shareholder, societyId, true) - sell) || 0,
      userId: shareholder.id,
    }] : []),
  ];
};

const completeShareHistory = (
  shareholderToComplete, isSociety, id, societyId, operationId, creation,
) => {
  const shareHistory = [];
  if (isSociety
    && (getShareholderShare(shareholderToComplete, societyId, false) > 0)) {
    shareHistory.push({
      historyId: id,
      societyId,
      userId: shareholderToComplete.id,
      operations: [operationId],
      date: (typeof creation?.date === 'string') ? (creation?.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
      buy: 0,
      sell: 0,
      total: (getShareholderShare(shareholderToComplete, societyId, false) || 0),
      createdByCompleteMethod: true,
    });
  } else if ((shareholderToComplete?.sharesHistories || [])
    .filter((current) => (!!current.societyName).length > 0)
    && (getShareholderShare(shareholderToComplete, societyId, true) > 0)) {
    shareHistory.push({
      historyId: id,
      societyId,
      operations: [operationId],
      date: (typeof creation?.date === 'string') ? (creation?.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
      buy: 0,
      sell: 0,
      total: (getShareholderShare(shareholderToComplete, societyId, true) || 0),
      createdByCompleteMethod: true,
      userId: shareholderToComplete.id,
    });
  }
  return shareHistory;
};

export const createShareholdersAfterTransfer = (
  shareholders, holding, shareholdersList, operation, societyId, operationId, historyId, creation,
) => {
  const getHistory = (shareholder) => shareholder?.sharesHistories || [];
  const isHolding = window.location.href.includes('holding');
  const shareholderFrom = shareholders.find((i) => i?.id === operation.from.shareholderId);

  let shareHistories = [];

  const newShareholderFromHistory = {
    historyId,
    societyId,
    operations: [operationId],
    societyName: operation.societyName,
    date: (typeof creation.date === 'string') ? (creation.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
    buy: 0,
    sell: 0,
    total: (getShareholderShare(shareholderFrom, societyId, operation.from_society)
      || 0) - operation.quantity,
    shareholderId: shareholderFrom?.id,
  };

  const newShareholderFrom = {
    ...shareholderFrom,
    societies: shareholderFrom?.societies.includes(societyId)
      ? shareholderFrom?.societies
      : [...shareholderFrom?.societies, societyId],
    shares: getShareholderShare(shareholderFrom, societyId)
      - Number.parseInt(operation?.quantity, 10),
    history: [
      ...getHistory(shareholderFrom),
      newShareholderFromHistory,
      ...completeShareHistory(shareholderFrom, operation.from_society, historyId),
    ],
  };

  shareHistories.push(newShareholderFromHistory);

  const shareholdersTo = shareholders.find((i) => i?.id === operation.to.shareholderId);

  const shareholdersToHistory = {
    historyId,
    societyId,
    operations: [operationId],
    from_society: operation.to_society,
    date: (typeof creation.date === 'string') ? (creation.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
    buy: operation.quantity,
    sell: 0,
    total: (getShareholderShare(shareholdersTo, societyId, operation.to_society)
      || 0) + operation.quantity,
    shareholderId: shareholdersTo.id,
  };
  shareHistories.push(shareholdersToHistory);

  const newShareholderTo = {
    ...shareholdersTo,
    societies: shareholderFrom.societies.includes(societyId)
      ? shareholderFrom.societies
      : [...shareholderFrom.societies, societyId],
    shares: (Number.parseInt(shareholdersTo?.shares, 10) || 0)
      + Number.parseInt(operation.quantity, 10),
    history: [
      ...getHistory(shareholdersTo),
      shareholdersToHistory,
      ...completeShareHistory(
        shareholdersTo, operation.to_society, historyId,
        societyId, operationId, creation,
      ),
    ],
  };

  const newShareholders = [
    ...(isHolding ? holding : shareholdersList)
      .filter((shareholder) => !([newShareholderFrom.id, newShareholderTo.id]
        .includes(shareholder.id)))
      .map((shareholder) => {
        const shareholderHistories = [...createShareHistoryFromShareholder(
          shareholder, historyId, societyId, operationId,
          (typeof creation.date === 'string') ? (creation.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
        )];
        shareHistories = [...shareHistories, ...shareholderHistories];
        return ({
          ...shareholder,
          holding: isHolding,
          history: [
            ...getHistory(shareholder),
            ...createShareHistoryFromShareholder(
              shareholder, historyId, societyId, operationId,
              (typeof creation.date === 'string') ? (creation.date) : `${creation?.date?.day}/${creation?.date?.month}/${creation?.date?.year} 00:00`,
            ),
          ],
        });
      }),
    ...(
      (operation.to.shareholderId !== operation.from.shareholderId)
        ? [newShareholderFrom, newShareholderTo]
        : [{
          ...newShareholderFrom,
          history: [
            ...(newShareholderFrom?.history || []),
            shareholdersToHistory,
          ],
        }]
    ),
  ];

  return [newShareholders, shareHistories];
};
