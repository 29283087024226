import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OperationCreation from './OperationCreation';

import { setOperation } from '../../../services/store/features/operations/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';
import { setSocietyId } from '../../../services/store/features/society/actions';
import { getLastHistory, getLastHoldingHistory } from '../../../services/store/features/history/selectors';

const mapStateToProps = (state) => {
  const isHolding = window.location.href.includes('holding');
  return ({
    currentOperation: (state.operations.creation?.status === 'edit')
      ? {
        name: state.operations.creation.name,
        sharesPrice: isHolding ? state.society.holding.valo_nom : state.society.valo_nom,
        emissionPrice: isHolding ? state.society.holding.prime_unit : state.society.prime_unit,
        unitSharePrice: isHolding ? state.society.holding.share : state.society.share,
        sharesNumber: state.operations.creation?.parameters?.sharesNumber,
      }
      : null,
    operationHistory: {
      status: state.history.status,
      history: isHolding ? getLastHoldingHistory(state) : getLastHistory(state),
    },
    societyId: state.society.id,
    userId: state.user.id,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setOperation,
    fetchHistory,
    setSocietyId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationCreation);
