import * as types from './types';
import { getOldMessages } from '../../../clients/clientAPI';
import { onMessage, onGroupMessage } from '../../../socket/events';
import { SET_USER_SOCKETS } from '../user/types';

function resetHasNewMessage(id) {
  return (dispatch, getState) => {
    const { contacts } = getState().chat;
    const newContacts = [...contacts];
    const index = newContacts.map((contact) => contact.id).indexOf(id);
    newContacts[index] = { ...newContacts[index], hasNewMessages: false };
    dispatch({ type: types.SET_CONTACTS, payload: { contacts: newContacts } });
  };
}

function resetGroupNotification() {
  return (dispatch) => {
    dispatch({ type: types.RESET_GROUP_NOTIFICATION });
  };
}

export function setActiveRoom(id, payload = null) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_ROOM,
      payload: {
        id,
        payload,
      },
    });
    if (!((id === 'group' || id === 'support'))) {
      dispatch(resetHasNewMessage(id));
    }
    if (id === 'group') {
      dispatch(resetGroupNotification());
    }
  };
}

const messageFactory = (content, ownedByCurrentUser, date = null, id = null) => ({
  content,
  ownedByCurrentUser,
  date: date || Date.now(),
  id: id || Date.now(),
});

const getMessages = (otherUser, activeUser, data) => {
  const sendToActiveUser = data
    .filter((message) => message.from === otherUser && message.to === activeUser);
  const sendByActiveUser = data
    .filter((message) => message.from === activeUser && message.to === otherUser);
  const messages = [
    ...sendToActiveUser
      .map((message) => messageFactory(message.content, false, message.date, message.id)),
    ...sendByActiveUser
      .map((message) => messageFactory(message.content, true, message.date, message.id)),
  ];
  messages.sort((a, b) => a.date - b.date);
  return messages;
};

const getAllMessages = (contacts, otherUser, activeUser, data) => {
  const sendToGroup = data
    .filter((message) => message.from !== activeUser && message.to === otherUser);
  const sendByActiveUser = data
    .filter((message) => message.from === activeUser && message.to === otherUser);
  const contactsIndexes = contacts.map((contact) => contact.id);
  const createMessage = (content, owned, date, id, from) => {
    let user = {};
    const index = contactsIndexes.indexOf(from);
    if (index !== -1) {
      user = {
        name: contacts[index].name,
        img: contacts[index].img,
      };
    }
    return {
      ...messageFactory(content, owned, date, id),
      user,
    };
  };
  const messages = [
    ...sendToGroup
      .map((message) => createMessage(
        message.content, false, message.date, message.id, message.from,
      )),
    ...sendByActiveUser
      .map((message) => messageFactory(
        message.content, true, message.date, message.id,
      )),
  ];
  messages.sort((a, b) => a.date - b.date);
  return messages;
};

export function setContactsFromShareholders(list) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    const { id: userId } = getState().user;
    if (id) {
      const { data } = await getOldMessages(id, userId);
      const contactList = (list) ? list.map((shareholders) => {
        const messages = getMessages(shareholders.id, getState().user.userId, data?.messages || []);
        return ({
          name: `${shareholders.firstName} ${shareholders.lastName}`,
          description: shareholders.description,
          id: shareholders.id,
          img: shareholders.img,
          socketId: null,
          messages,
          hasNewMessages: false,
          isConnected: false,
        });
      }) : [];
      dispatch({
        type: types.SET_CONTACTS,
        payload: {
          contacts: contactList,
          group: getAllMessages(
            contactList, getState().society.chatRoom, getState().user.userId, data?.messages || [],
          ),
        },
      });
    }
  };
}

export function setMostRecentConversationAsActiveRoom() {
  return (dispatch, getState) => {
    const { contacts } = getState().chat;
    const contactsIndex = contacts.reduce((acc, contact) => {
      const { messages } = contact;
      if (messages.length > 0) {
        messages.sort((a, b) => a.date - b.date);
        const lastMessage = messages[messages.length - 1].date;
        if (lastMessage >= acc) return contact.id;
      }
      return acc;
    }, -1);
    if (contactsIndex !== -1) {
      dispatch(setActiveRoom(contactsIndex));
    }
  };
}

export function sendMessage(socket, content, to, userId) {
  return async (dispatch, getState) => {
    dispatch({ type: types.SEND_MESSAGES_REQUEST });
    // const { id: from } = getState().user;
    try {
      onMessage(socket, content, to, userId);
      // const { data } = createNewMessage({ content, from, to: userId });
      const newMessage = messageFactory(content, true);
      const { activeId } = getState().chat;
      if (activeId !== 'group' && activeId !== 'support') {
        const { contacts } = getState().chat;
        const newContacts = contacts;
        const index = newContacts.map((c) => c.id).indexOf(activeId);
        newContacts[index] = {
          ...newContacts[index],
          messages: [...newContacts[index].messages, newMessage],
        };
        dispatch({ type: types.SEND_MESSAGES_SUCCESS, payload: newContacts });
      } else {
        dispatch({ type: types.SEND_MESSAGES_SUCCESS, payload: { newMessage } });
      }
    } catch (err) {
      dispatch({ type: types.SEND_MESSAGES_FAIL });
    }
  };
}

export function sendGroupMessage(socket, content, groupId) {
  return async (dispatch) => {
    dispatch({ type: types.SEND_GROUP_MESSAGES_REQUEST });
    // const { id: userId } = getState().user;
    try {
      onGroupMessage(socket, content, groupId);
      // const { data } = await createNewMessage({ content, from: userId, to: groupId });
      const newMessage = messageFactory(content, true);
      dispatch({
        type: types.SEND_GROUP_MESSAGES_SUCCESS,
        payload: { ...newMessage },
      });
    } catch (err) {
      dispatch({ type: types.SEND_GROUP_MESSAGES_FAIL, payload: err });
    }
  };
}

export function receiveMessage(message) {
  return (dispatch, getState) => {
    const { contacts, activeId } = getState().chat;
    const index = contacts.map((c) => c.id).indexOf(message.from);
    if (index !== -1) {
      const newMessage = messageFactory(message.content, false);
      const newContacts = contacts;
      newContacts[index] = {
        ...newContacts[index],
        messages: [...newContacts[index].messages, newMessage],
        hasNewMessages: !(activeId === newContacts[index].id),
      };
      dispatch({ type: types.SEND_MESSAGES_SUCCESS, payload: newContacts });
    }
  };
}

export function receiveGroupMessage({ content, from }) {
  return (dispatch, getState) => {
    const { contacts, activeId } = getState().chat;
    const contactIndex = contacts.map((contact) => contact.id).indexOf(from);
    let user = {};
    if (contactIndex !== -1) {
      user = { name: contacts[contactIndex].name, img: contacts[contactIndex].img };
    }
    const newMessage = {
      ...messageFactory(content, false),
      user,
    };
    dispatch({
      type: types.RECEIVE_GROUP_MESSAGES_SUCCESS,
      payload: {
        message: newMessage,
        hasNewMessage: (activeId !== 'group'),
      },
    });
  };
}

export function updateContactsSocket(users) {
  return (dispatch, getState) => {
    const { contacts } = getState().chat;
    const activeUser = getState().user.userId;
    users.forEach((user) => {
      const index = contacts.map((contact) => contact.id).indexOf(user.userId);
      if (index !== -1) {
        contacts[index] = {
          ...contacts[index],
          socketId: user.socketId,
          isConnected: true,
        };
      }
      if (activeUser === user.userId) {
        dispatch({ type: SET_USER_SOCKETS, payload: user.socketId });
      }
    });
    dispatch({ type: types.SET_CONTACTS, payload: { contacts } });
  };
}

export function removeContactsSocket(user) {
  return (dispatch, getState) => {
    const { contacts } = getState().chat;
    const newContacts = [...contacts];
    const userIndex = contacts.map((c) => c.socketId).indexOf(user);
    newContacts[userIndex] = { ...contacts[userIndex], socketId: null, isConnected: false };
    dispatch({ type: types.SET_CONTACTS, payload: { contacts: newContacts } });
  };
}

export function setQuery(query) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function findContactsByQuery(contacts, query) {
  if (!query) return contacts;
  return contacts.filter((contact) => {
    const contactToStr = contact.name.toLowerCase();
    return (contactToStr.search(query.toLowerCase()) !== -1);
  });
}
