import axios from './axiosClient';
import getFormDataFromObj from '../../utils/getFormDataFromObj';

export const getRedirections = () => axios.get('/shareholders/redirections');

export const getSocietyInfo = (societyId) => axios.get('/society/', { params: { society_id: societyId } });
export const updateSocietyInfo = (societyId, society) => axios.put(`/society/${societyId}`, getFormDataFromObj(society));

export const getHistory = (societyId) => axios.get('/history/', { params: { society_id: societyId } });
export const createNewHistory = (newHistory) => axios.post('/history/', newHistory);
export const updateHistoryById = (id, history) => axios.put(`/history/${id}`, history);
export const addAyomiOperation = (societyId) => axios.get(`/operations/add_ayomi_operation/${societyId}`);

export const getContacts = (societyId) => axios.get('/contacts/', { params: { society_id: societyId } });
export const getExportShareholders = (societyId, fields) => axios.post('/export', { ...fields, societyId });

export const updateShareholdersById = (id, value) => axios.put(`/shareholders/${id}`, value);
export const createShareholdersHistory = (history) => axios.post('/shares-history/', history);
export const updateShareholdersHistory = (id, history) => axios.put(`/shares-history/${id}`, history);
export const deleteShareholdersHistory = (id) => axios.delete(`/shares-history/${id}`);

export const createFeedback = (feedback) => axios.post('/feedback', feedback);

export const getHolding = (id) => axios.get(`/${id}/holding`);

export const notify = () => axios.post('/notify');

export const getUserInfo = (societyId) => axios.get('/user', { params: { society_id: societyId } });
export const updateUserInfo = (user) => axios.put('/user', user);

export const getOldMessages = () => axios.get('/messages/');
export const createNewMessage = (message) => axios.post('/messages/', message);

export const getAllOperation = (societyId) => axios.get('/operations/', { params: { society_id: societyId } });
export const createNewOperation = (operation) => axios.post('/operations/', operation);
export const deleteAndCreateNewOperations = (societyId, operations) => axios.post('/operations/update', { data: { operations, societyId } });
export const deleteOperation = (societyId, operationId) => axios.delete(`/operations/${operationId}`, { params: { society_id: societyId } });

export const sendMail = (mail) => axios.post('/mail', mail);

export const logOutUser = () => axios.post('/logout');
