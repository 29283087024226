import React, { useState } from 'react';
import { Search } from '@material-ui/icons';
import styles from '../Admin.module.css';

export default function AdminHeader({ society, children, actions }) {
  const [societyId, setSocietyId] = useState(society.id || '');

  const handleSocietyChange = (e) => {
    setSocietyId(e.target.value);
  };

  const fetchSociety = () => {
    actions.fetchSocietyData(societyId);
  };

  const handleSocietyKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchSociety(societyId);
    }
  };
  return (
    <header>
      <div className={styles.row}>
        <div className={styles.description}>
          <h1>
            Dashboard
          </h1>
          <p>
            Gestion des espaces post-investissement
          </p>
        </div>
        <div className={styles.input}>
          <Search className={styles.icon} />
          <input
            type="text"
            placeholder="ID de la société"
            value={societyId}
            onChange={handleSocietyChange}
            onKeyDown={handleSocietyKeyDown}
          />
        </div>
      </div>
      <nav
        hidden={!society.id}
      >
        { children }
      </nav>
    </header>
  );
}
