import React, { useMemo } from 'react';

import stylesForm from 'components/Operation/OperationCapitalForm/OperationCapitalForm.module.css';
import InputText from 'components/library/Input/InputText/InputText';
import { getShareholderShare } from 'services/store/features/shareholders/utils';

export default function OperationReductionFormTableRow({
  shareholder, id, totalOfShares, onShareholderSell,
}) {
  const shares = useMemo(
    () => getShareholderShare(shareholder, id, shareholder.societyName !== ''),
    [shareholder, id],
  );

  return (
    <ul
      className={stylesForm.grid}
      key={`${shareholder.societyName ? 'society' : ''}${shareholder.id}`}
    >
      <li className={(shareholder.toComplete) ? stylesForm.waiting : ''}>
        <span>
          {shareholder.societyName || `${shareholder.firstName} ${shareholder.lastName}`}
        </span>
        {
          (shareholder.toComplete) && (
            <span className={stylesForm.hint}>
              En attente de validation par l&apos;actionnaire
            </span>
          )
        }
      </li>
      <li className={stylesForm.number}>
        {shares}
      </li>
      <li>
        <InputText
          type="number"
          valueInput={shareholder.sell || ''}
          onTextChange={(e) => onShareholderSell(e, shareholder)}
          disabled={shares === 0}
          minimum="0"
        />
      </li>
      <li className={stylesForm.number}>
        {shares - (shareholder?.sell || 0)}
      </li>
      <li className={stylesForm.number}>
        {
          Number.isNaN(((((shares || 0) - (shareholder.sell || 0)) * 100) / totalOfShares))
            ? 0
            : ((((shares || 0) - (shareholder.sell || 0)) * 100) / totalOfShares)
              .toFixed(2)
        }
        %
      </li>
    </ul>
  );
}
