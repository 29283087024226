import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OperationCapitalForm from 'components/Operation/OperationCapitalForm';
import OperationHeader from 'components/Operation/OperationHeader';

import OperationsShareholders from '../OperationsShareholders';
import styles from './OperationsCapital.module.css';

export default function OperationsCapital({ societyId, isHolding }) {
  const [isShareholderFormOpen, setIsShareholderFormOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={styles.transfert}>
      <div className={styles.header}>
        <OperationHeader
          type="capital"
          step={1}
        />
        <Link
          to={`/${societyId}/${isHolding ? 'holding' : 'actionnaires'}/operations/edit/choice`}
          className={styles.link}
        >
          {t('operation.back')}
        </Link>
      </div>
      <div className={styles.content}>
        {
          (isShareholderFormOpen)
            ? (
              <OperationsShareholders
                back={() => setIsShareholderFormOpen(false)}
                isHolding={isHolding}
              />
            )
            : (
              <OperationCapitalForm
                openForm={() => setIsShareholderFormOpen(true)}
                isHolding={isHolding}
              />
            )
        }

      </div>
    </div>
  );
}
