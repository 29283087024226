import * as types from './types';
import * as statuses from '../../states';
import { addToast } from '../toasts/actions';
import { formatBackEvent, formatEvent, formatEvents } from './adapter';
import {
  createNewEvent, deleteEventById, getEvents, updateEventById,
} from '../../../clients/event';

const placeholder = 'https://images.unsplash.com/photo-1615524152519-cce424198a2e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80';

export function fetchEventsData() {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    if (id) {
      dispatch({
        type: types.FETCH_EVENTS_REQUIRED,
      });
      try {
        const { data } = await getEvents(id);
        const events = formatEvents(data);
        const assemblyGeneral = events.filter((ev) => ev.title === 'Assemblée générale')[0];
        dispatch({
          type: types.FETCH_EVENTS_SUCCEEDED,
          payload: {
            data: events.map((event) => ({
              imagePath: placeholder,
              ...event,
            })),
            assemblyGeneral,
          },
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_EVENTS_FAILED,
          payload: err,
        });
      }
    }
  };
}

export function addEvent(newEvent) {
  return async (dispatch, getState) => {
    const { id } = getState().society;
    dispatch({
      type: types.CREATE_EVENTS_REQUEST,
    });
    try {
      const eventForApi = formatBackEvent({
        ...newEvent,
        societyId: id,
      });
      const { data } = await createNewEvent(eventForApi);
      dispatch({
        type: types.CREATE_EVENTS_SUCCEEDED,
        payload: formatEvent(data),
      });
    } catch (err) {
      dispatch({
        type: types.CREATE_EVENTS_FAILED,
        payload: err,
      });
    }
  };
}

export function updateEvent(id, event) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_EVENT_REQUEST,
    });
    if (!id) {
      throw new Error('L\'évènement ne contient pas d\'identifiant');
    }
    const { id: societyId } = getState().society;
    try {
      const { data } = await updateEventById(id, formatBackEvent({
        ...event,
        societyId,
      }));
      const { events } = getState().events;
      console.log('Event: ', data, events);
      dispatch({
        type: types.UPDATE_EVENT_SUCCESS,
        payload: events.map((curr) => (
          (curr.id === id)
            ? formatEvent(data)
            : curr
        )),
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_EVENT_FAILED,
        payload: err,
      });
      dispatch(addToast({
        type: statuses.FAIL,
        title: 'events.edit.fail',
        description: `${err || ''}`,
        shouldTranslate: true,
      }));
    }
  };
}

export function deleteEvent(id) {
  return async (dispatch, getState) => {
    const { id: societyId } = getState().society;
    dispatch({
      type: types.DELETE_EVENT_REQUEST,
    });
    try {
      await deleteEventById(societyId, id);
      dispatch({
        type: types.DELETE_EVENT_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_EVENT_FAILED,
        payload: err,
      });
      dispatch(addToast({
        type: 'error',
        title: 'events.delete.fail',
        description: `${err || ''}`,
        shouldTranslate: true,
      }));
    }
  };
}

export function resetEventStatus() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_EVENTS,
    });
  };
}

export function resetAssemblyStatus() {
  return (dispatch) => {
    dispatch({ type: types.OPEN_ASSEMBLY_POPUP });
  };
}

export function updateAssemblyDate(date) {
  return async (dispatch, getState) => {
    if (getState().events.assemblyGeneral.date) {
      dispatch({ type: types.UPDATE_ASSEMBLY_REQUEST });
      try {
        const newEvent = {
          ...getState().events.assemblyGeneral,
          date,
        };
        const { data } = await updateEventById(getState().events.assemblyGeneral.id, newEvent);
        if (data && !data.error) {
          dispatch({
            type: types.UPDATE_ASSEMBLY_SUCCEEDED,
            payload: date,
          });
        } else {
          dispatch({
            type: types.UPDATE_ASSEMBLY_FAILED,
            payload: '',
          });
          dispatch(addToast({
            type: statuses.FAIL,
            title: 'error.title',
            description: '',
          }));
        }
      } catch (err) {
        dispatch({
          type: types.UPDATE_ASSEMBLY_FAILED,
          payload: err,
        });
        dispatch(addToast({
          type: statuses.FAIL,
          title: 'error.title',
          description: `${err}`,
        }));
      }
    } else {
      dispatch({ type: types.CREATE_ASSEMBLY_REQUEST });
      try {
        const newEvent = {
          title: 'Assemblée générale',
          date,
        };
        const { data } = await createNewEvent(newEvent);
        dispatch({
          type: types.CREATE_ASSEMBLY_SUCCEEDED,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: types.CREATE_ASSEMBLY_FAILED,
          payload: err,
        });
      }
    }
  };
}
