import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyInvestors from './AssemblyInvestors';
import {
  fetchAssemblyPlan, setActiveAssembly, sendInvitationResponse, updateAssemblyPlanById,
  sendMessage, sendMessageReset, voteAssemblyResolution,
} from '../../services/store/features/assembly/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';
import { fetchAllShareholders } from '../../services/store/features/shareholders/actions';
import { fetchHistory } from '../../services/store/features/history/actions';
import { setSelectedDocument } from '../../services/store/features/documents/actions';
import { getCurrentShareholders } from '../../services/store/features/shareholders/selectors';

const mapStateToProps = (state) => {
  const currentAssembly = state.assembly.plan.list.find((i) => i.id === state.assembly.selected);
  return ({
    status: {
      assembly: state.assembly.plan.status,
      user: state.user.status,
      message: state.assembly.mailStatus,
      shareholders: state.shareholders.status,
      history: state.history.status,
    },
    user: {
      id: state.user.id,
      mail: state.user.mail,
    },
    selectedDocument: state.documents.selectedDocument,
    assembly: currentAssembly,
    shareholders: getCurrentShareholders(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyPlan,
    setActiveAssembly,
    sendInvitationResponse,
    fetchUserInfo,
    updateAssemblyPlanById,
    sendMessage,
    sendMessageReset,
    fetchAllShareholders,
    fetchHistory,
    voteAssemblyResolution,
    setSelectedDocument,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyInvestors);
