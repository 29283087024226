import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OperationsShareholdersForm from './OperationsShareholdersForm';
import { resetCreation } from '../../services/store/features/shareholders/actions';

const mapStateToProps = (state) => ({
  status: state.shareholders.creation.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    resetCreation,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsShareholdersForm);
