export default function validate(t, values) {
  const errors = {};
  if (!values.title) {
    errors.title = t('events.formError.name');
  }
  if (!values.date) {
    errors.date = t('events.formError.date');
  }
  if (values.date) {
    const today = new Date();
    const eventDate = new Date(values.date.year, values.date.month - 1, values.date.day);
    if (eventDate < today) {
      errors.date = t('events.formError.date_too_soon');
    }
  }
  if (!values.isAllDay && !values.hourBeginning) {
    errors.hourBeginning = t('events.formError.begin');
  }
  if (!values.isAllDay && !values.hourEnding) {
    errors.hourEnding = t('events.formError.end');
  }
  if (!values.place) {
    errors.place = t('events.formError.place');
  }
  if (!values.description) {
    errors.description = t('events.formError.description');
  }
  return errors;
}
