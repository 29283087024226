import React, { useState } from 'react';

import * as statuses from 'services/store/states';
import { splitDate } from '../../../utils/formatDate';

import EventsPopUpSuccess from './EventsPopUpSuccess';
import EventsPopUpFail from './EventsPopUpFail';
import EventsPopUpForm from './EventsPopUpForm';
import EventsPopUpInvitation from './EventsPopUpInvitation';

const getEditableEvent = (event) => {
  if (!event) return null;
  const [day, month, year] = splitDate(event.date);
  return {
    ...event,
    date: { day, month, year },
  };
};

export default function EventsPopUp({
  event, status, statusShareholders, shareholders, actions,
}) {
  const [state, setState] = useState('FORM');
  const [newEvent, setNewEvent] = useState(getEditableEvent(event));

  const nextStep = (values) => {
    setNewEvent(values);
    setState('INVITATION');
  };

  const submit = (shareholdersMails, mails) => {
    actions.addEvent({
      ...newEvent,
      participantsEmail: [...shareholdersMails, ...mails].join(';'),
    });
  };

  const handleUpdate = (shareholdersMails, mails) => {
    actions.updateEvent(event.id, {
      ...event,
      ...newEvent,
      participantsEmail: [...shareholdersMails, ...mails].join(';'),
    });
  };

  if (status === statuses.SUCCESS) {
    return (
      <EventsPopUpSuccess event={event} actions={actions} />
    );
  }
  if (status === statuses.FAIL) {
    return (
      <EventsPopUpFail actions={actions} />
    );
  }
  if (state === 'FORM') {
    return (
      <EventsPopUpForm
        event={newEvent}
        next={nextStep}
        actions={actions}
      />
    );
  }
  if (state === 'INVITATION') {
    return (
      <EventsPopUpInvitation
        statusShareholders={statusShareholders}
        shareholders={shareholders}
        status={status}
        actions={actions}
        submit={submit}
        handleUpdate={handleUpdate}
        event={event}
        back={() => setState(0)}
      />
    );
  }
}
