import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@material-ui/icons/Description';

import Button from '../../library/Button/Button';

import styles from '../../../views/Assembly/AssemblyView/AssemblyView.module.css';
import shared from '../../../views/Assembly/shared/assembly.module.css';
import * as statuses from '../../../services/store/states';
import { addDocumentToAssembly, getPlannedAssemblyDocument } from '../../../services/clients/assembly';
import { keysToCamel } from '../../../utils/converter';

import grid from './AssemblyDocuments.module.css';

export default function AssemblyDocuments({
  assemblyId, societyId, openDocument, edit = false,
}) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [uploadingStatus, setUploadingStatus] = useState(statuses.IDLE);
  const [documents, setDocuments] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (status === statuses.IDLE) {
        setStatus(statuses.LOADING);
        try {
          const { data } = await getPlannedAssemblyDocument(assemblyId);
          setDocuments(keysToCamel(data));
        } catch (err) {
          setStatus(statuses.FAIL);
        }
      }
    };
    fetchDocuments();
  }, []);

  const { t } = useTranslation();

  const addDocument = () => { inputRef.current.click(); };

  const handleFile = async (e) => {
    setUploadingStatus(statuses.LOADING);
    const file = e.target.files[0];
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', file.name);
      formData.append('society_id', societyId);
      const { data } = await addDocumentToAssembly(assemblyId, formData);
      setDocuments((state) => [...state, { title: file.name, ...data }]);
      setUploadingStatus('SUCCESS');
    } catch (err) {
      setUploadingStatus(statuses.FAIL);
    } finally {
      window.setTimeout(() => setUploadingStatus(statuses.IDLE), 2000);
    }
  };

  return (
    <div className={`${styles.documents} ${grid.grid}`}>
      <div className={`${styles.documentsHeader} ${styles.row}`}>
        <h3>
          Documents
        </h3>
        {
          (edit)
          && (
            <Button
              label={t('assembly.documents.add')}
              variant="ghost"
              onButtonClick={addDocument}
              disabled={uploadingStatus !== statuses.IDLE}
            />
          )
        }
      </div>
      <input
        type="file"
        className={styles.hidden}
        ref={inputRef}
        onChange={handleFile}
        hidden
      />
      <ul className={grid.documentsList}>
        {
            documents && documents.map((doc) => (
              <div
                key={doc.id}
                className={shared.documentContainer}
              >
                <button
                  type="button"
                  className={shared.documentButton}
                  aria-label={`Voir ${doc.title}`}
                  onClick={() => openDocument(doc)}
                >
                  <DescriptionIcon fontSize="large" />
                </button>
                <p className={shared.documentTitle}>{doc.title}</p>
              </div>
            ))
          }
      </ul>
    </div>
  );
}
