import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dashboard from './Dashboard';
import { setSocietyId } from '../../services/store/features/society/actions';
import { fetchUserInfo } from '../../services/store/features/user/actions';

const mapStateToProps = (state) => ({
  id: state.society.id,
  user: state.user.id,
  roles: state.roles.roles,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSocietyId,
    fetchUserInfo,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
