import * as events from './eventType';

export function onUserCreation(socket, userId) {
  // eslint-disable-next-line no-param-reassign
  socket.io.opts.query = { userId };
  socket.connect({ query: { userId } });
}

export function onMessage(socket, content, selectedUser, selectedUserId) {
  if (selectedUserId) {
    socket.emit(events.PRIVATE_MESSAGE, {
      content,
      to: selectedUser,
      selectedUserId,
    });
  }
}

export function onGroupMessage(socket, content, groupId) {
  if (groupId) {
    socket.emit(events.GROUP_MESSAGE, {
      content,
      groupId,
    });
  }
}

export function onJoinGroupChat(socket, groupId) {
  if (groupId) {
    socket.emit(events.JOIN_CHAT, { groupId });
  }
}
