import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import ReportPreview from '../../components/Report/ReportPreview/ReportPreview';
import * as statuses from '../../services/store/states';

import links, { investorLinks } from '../Report/navigation';
import styles from './ReportView.module.css';

export default function ReportView({
  status, reports, roles, isInvestor, actions,
}) {
  const { society, id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchReportsData();
    }
  }, []);

  return (
    <section className={styles.preview}>
      {
        (isInvestor)
          ? (
            <Header
              title={t('reports.report')}
              navigation={investorLinks(t, society)}
              roles={roles}
            />
          )
          : (
            <Header
              title={t('reports.report')}
              navigation={links(t, society)}
              roles={roles}
            />
          )
      }
      <ReportPreview report={reports.find((report) => `${report.id}` === id)} />
    </section>
  );
}
