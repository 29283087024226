import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import ChatRoom from './ChatRoom';
import {
  sendMessage, receiveMessage, sendGroupMessage, receiveGroupMessage, setActiveRoom,
} from '../../../services/store/features/chat/actions';

const mapStateToProps = (state) => ({
  room: (state.chat.activeId === 'group' || state.chat.activeId === 'support') ? state.chat.group : state.chat.contacts.filter((contact) => contact.id === state.chat.activeId)[0],
  id: state.chat.activeId,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    sendMessage, receiveMessage, sendGroupMessage, receiveGroupMessage, setActiveRoom,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
