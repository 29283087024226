import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssemblyView from './AssemblyView';
import {
  fetchAssemblyPlan, setActiveAssembly,
  updateAssemblyPlanById, reminder, deleteAssemblyResolution, getInvitations,
} from '../../../services/store/features/assembly/actions';
import { setSelectedDocument } from '../../../services/store/features/documents/actions';
import { fetchAllShareholders } from '../../../services/store/features/shareholders/actions';
import { fetchHistory } from '../../../services/store/features/history/actions';
import { fetchUserInfo } from '../../../services/store/features/user/actions';
import { setSocietyId } from '../../../services/store/features/society/actions';

const mapStateToProps = (state) => ({
  status: state.assembly.plan.status,
  assembly: state.assembly.plan.list
    .filter((i) => Number.parseInt(i.id, 10) === Number.parseInt(state.assembly?.selected, 10))[0],
  selected: state.assembly.selected,
  selectedDocument: state.documents.selectedDocument,
  invitations: getInvitations(state),
  shareholdersStatus: state.shareholders.status,
  historyStatus: state.history.status,
  userId: state.user.id,
  societyId: state.society.id,
  roles: state.roles.roles,
  userStatus: state.user.status,
  isAuthorized: state.society.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAssemblyPlan,
    fetchAllShareholders,
    fetchHistory,
    fetchUserInfo,
    setActiveAssembly,
    setSelectedDocument,
    setSocietyId,
    updateAssemblyPlanById,
    reminder,
    deleteAssemblyResolution,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyView);
