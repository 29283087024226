import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';

import ImgPlaceholder from '../../../components/ImgPlaceholder';
import styles from './AssemblyView.module.css';

export default function AssemblyViewPresence({
  id, invitations, societyId,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.presence}>
      <div className={`${styles.documentsHeader} ${styles.row}`}>
        <h3>
          {t('assembly.attendance.follow')}
        </h3>
      </div>
      <ul className={styles.contactList}>
        {
          (!invitations || invitations.length === 0)
          && (
            <div className={styles.invitation}>
              <p>
                {t('assembly.attendance.empty')}
              </p>
              <Link
                to={`/${societyId}/assemblee/convocation/${id}`}
                className="button"
              >
                {t('assembly.attendance.summons')}
              </Link>
            </div>
          )
        }
        {
          invitations && invitations.map((invitation) => (
            <div
              key={invitation.mail}
              className={styles.contactInfo}
            >
              <div className={styles.contactName}>
                {
                  (invitation.img)
                    ? (
                      <ImgPlaceholder
                        className={styles.contactPicture}
                        src={invitation.img}
                        alt={invitation.name}
                      />
                    )
                    : (
                      <div className={styles.contactLogo}>
                        {invitation?.name[0]}
                      </div>
                    )
                }
                <div>
                  <p className={styles.subtitle}>
                    { invitation.name }
                  </p>
                  <p className={styles.description}>
                    { invitation.category }
                  </p>
                </div>
              </div>
              <div>
                {
                  (invitation.status === 'yes') && (<p className={styles.success}><CheckCircleIcon /></p>)
                }
                {
                  (invitation.status === 'no') && (<p className={styles.error}><ErrorIcon /></p>)
                }
                {
                  (invitation.status === 'noResponse') && (<p className={styles.warn}><HelpIcon /></p>)
                }
              </div>
            </div>
          ))
        }
      </ul>
    </div>
  );
}
