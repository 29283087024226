import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PopUp from './PopUp';
import { closePopUp } from '../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  ...state.popup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    closePopUp,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
