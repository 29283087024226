import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as statuses from '../../../services/store/states';
import styles from './AssemblyInvitation.module.css';

export default function AssemblyInvitation({
  status, plan, actions,
}) {
  const { t } = useTranslation();
  const { society: societyId } = useParams();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAssemblyPlan();
    }
  }, []);

  return (
    <section className={styles.container}>
      <header>
        <h2 className={styles.title}>
          {t('assembly.invitation.title')}
        </h2>
      </header>
      <main>
        {
          (plan && plan.length > 0)
          && (
            <ul className={styles.list}>
              {
                plan.map((assembly) => (
                  <div
                    key={assembly.id}
                    className={styles.plan}
                  >
                    <p className={styles.invitation}>
                      { assembly.title }
                    </p>
                    <div className={styles.row}>
                      <p>
                        <b>
                          {
                            (assembly.hasSentConvocation)
                              ? t('assembly.planification.date')
                              : t('assembly.planification.temporaryDate')
                          }
                        </b>
                        { (typeof assembly?.date === 'string') ? assembly.date : ` ${assembly.date?.day}/${assembly.date?.month}/${assembly.date?.year}`}
                      </p>
                      <p>
                        <b>
                          {t('assembly.planification.place')}
                        </b>
                        { assembly.place }
                      </p>
                    </div>
                    <Link
                      to={`/${societyId}/assemblee/investisseur/${assembly.id}`}
                      className={styles.link}
                    >
                      {t('assembly.invitation.see')}
                    </Link>
                  </div>
                ))
              }
            </ul>
          )
        }
      </main>
    </section>
  );
}
